import { useQuery } from "react-query";
import { api } from "index";
import useMerchantSelectorStore from "features/MerchantSelector/hooks/useMerchantSelectorStore";
import {Advertisement} from "../../../api/interfaces/advertisements";

export function useAdvertisements(id?: Advertisement["id"]) {
  const merchantSelector = useMerchantSelectorStore();

  const selectedId = merchantSelector.useStore((state) => state.selected);
  const {
    data: advertisements,
    isError,
    isLoading,
  } = useQuery(
    ["ADVERTISEMENTS", selectedId, id],
    () => api.advertisements.readOne(id),
    {
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
      enabled: !!id,
      retry: 0,
    }
  );

  return { advertisements, isError, isLoading };
}
