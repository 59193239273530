import { useQuery } from "react-query";
import { api } from "../../../index";
import {useParams} from "react-router-dom";

export function useUserAnalytics() {
  const { id } = useParams();

  const { data, ...rest } = useQuery(["USER_ANALYTICS", Number(id)], () =>
    api.analytics.user(id)
  );

  return {
    analytics: data,
    ...rest,
    isAnalyticsLoading: rest.isLoading
  };
}
