import { useMapEvent } from "react-leaflet";
import {
  Button,
  Group,
  Loader,
  Modal,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import React, { useCallback, useEffect } from "react";
import useIdentifyLocation from "../hooks/useIdentifyLocation";
import { useLocationStore } from "../hooks/useLocationStore";
import { useServerConstants } from "hooks/useServerConstants";
import { useMerchantNavigation } from 'hooks/useMerchantNavigation';
import { useNavigate } from 'react-router-dom';

// Props
interface ComponentProps {
  opened: boolean;
  onClose: () => void;
}

// Component
export default function CreateObserver({ opened, onClose }: ComponentProps) {
  const { setCoordsLocation, location, searchCoords, isLoading } = useIdentifyLocation();
  const { Country } = useServerConstants();
  const { setCurrentLocation, setTempLocationData } = useLocationStore();
  const { route } = useMerchantNavigation();
  const navigate = useNavigate();

  useMapEvent("click", async (event) => {
    setCoordsLocation({
      lat: event.latlng.lat,
      lng: event.latlng.lng
    });
  });
  
  useEffect(() => {
    if (!location) return

    setCurrentLocation({
      title: location.display_name,
      coords: {
        lat: Number(location.lat),
        lng: Number(location.lon),
        bbox: location.boundingbox.map((i) => Number(i)),
      },
    });

  }, [location, setCurrentLocation])

  // Create Button
  const onCreate = useCallback(async () => {
    if (!location || !searchCoords) return;

    try {
      const countryId = Country.find(
        (item) =>
          item.code.toLowerCase() ===
          location.address.country_code.toLowerCase()
      )?.id;

      setTempLocationData({
        title: location.display_name,
        id: countryId || 1,
        coords: {
          lat: Number(searchCoords.lat),
          lng: Number(searchCoords.lng),
        }
      });
      navigate(`${route}/locations/create`);
    } catch (e) {
      console.log(e);
    }
  }, [location, Country, setTempLocationData, navigate, route]);

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      closeOnClickOutside={false}
      withCloseButton={false}
      zIndex={1000}
      padding={0}
    >
      <Stack p={"lg"} spacing={"lg"}>
        {isLoading && (
          <Group position="center">
            <Loader size="md" />
          </Group>
        )}

        {!isLoading && (
          <React.Fragment>
            <Group>
              <Title order={3}>Use location</Title>
            </Group>
            <Group position="center">
              <Text size="md">
                You want to use a location at{" "}
                <Text size="md" weight={500} color={"gray"}>
                  {location?.display_name}
                </Text>
              </Text>
            </Group>
            <Group position="right" spacing={"lg"}>
              <Button
                // loading={createLoading}
                variant="filled"
                color="teal"
                onClick={onCreate}
              >
                <Text size={"md"} weight={500}>
                  Confirm
                </Text>
              </Button>
              <Button
                onClick={onClose}
                variant="filled"
                color="red"
              >
                <Text size={"md"} weight={500}>
                  Cancel
                </Text>
              </Button>
            </Group>
          </React.Fragment>
        )}
      </Stack>
    </Modal>
  );
}
