import { useQuery } from "react-query";
import { Merchant } from "../../../api/interfaces/merchants";
import { api } from "index";
import { useState } from "react";

export const useMerchantTransferHistory = (merchantId?: Merchant["id"]) => {
  const [transferTypeId, setTransferTypeId] = useState<number>(7);
  const { data, ...rest } = useQuery(
    ["MERCHANT_TRANSFERS", merchantId, transferTypeId],
    () => api.merchants.getTransferHistory(merchantId!, transferTypeId, {})
  );

  return { transfers: data?.items, ...rest, transferTypeId, setTransferTypeId };
};
