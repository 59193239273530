import { useSearchParams } from "react-router-dom";
import { Tabs as MantineTabs, Text, createStyles } from "@mantine/core"
import { useEffect } from "react";

type Tab = {
    label: string;
    value: string;
    disabled?: boolean;
    position?: 'left' | 'right';
}

type TabsProps<T extends Tab> = {
    tabs: readonly T[];
    queryKey: string;
    defaultValue: T['value'];
}


export default function Tabs<T extends Tab>({ tabs, queryKey, defaultValue }: TabsProps<T>) {
    const { classes } = useStyles();
    const [params, setParams] = useSearchParams()

    const isDisabled = (value: string | null) => tabs.some((tab) => tab.value === value && tab.disabled === true);

    useEffect(() => {
        if (!!params.get(queryKey)) return
        params.set(queryKey, defaultValue)
        setParams(params)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params])

    const leftTabs = tabs.filter((tab) => tab.position !== 'right');
    const rightTabs = tabs.filter((tab) => tab.position === 'right');

    return (
        <MantineTabs classNames={classes} color="teal.7" defaultValue={defaultValue} value={params.get(queryKey)} onTabChange={(value) => {
            if (isDisabled(value) || !value) return
            params.set(queryKey, value)
            setParams(params);
        }}>
            <MantineTabs.List>
                {leftTabs.map((tab) => (
                    <MantineTabs.Tab value={tab.value} key={tab.value} disabled={isDisabled(tab.value)}>
                        <Text lh={1.5}>{tab.label}</Text>
                    </MantineTabs.Tab>
                ))}

                {rightTabs.map((tab, idx) => (
                    <MantineTabs.Tab value={tab.value} key={tab.value} disabled={isDisabled(tab.value)} ml={idx === 0 ? "auto" : undefined}>
                        <Text lh={1.5}>{tab.label}</Text>
                    </MantineTabs.Tab>
                ))}
            </MantineTabs.List>
        </MantineTabs>
    )
}

const useStyles = createStyles((theme) => ({
    root: {
    },
    tabsList: {
        border: "none"
    },
    tab: {
        border: "none",
        marginBottom: 0,
        padding: 12,
        "&:hover": {
            borderRadius: 0,
            backgroundColor: "#F8F9FA",
            ".mantine-Tabs-tabLabel": {
                color: theme.colors.dark[9],
            },
        },
        "&[data-active=true]": {
            borderRadius: 0,
            ".mantine-Tabs-tabLabel": {
                color: theme.colors.teal[7],
                fontWeight: 500,
            },
            "::before": {
                backgroundColor: theme.colors.teal[7],
                position: "absolute",
            }
        },
        "::before": {
            content: '""',
            display: "block",
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
            height: 2,
            borderTopLeftRadius: 2,
            borderTopRightRadius: 2,
            backgroundColor: "transparent",
        }
    },
    tabLabel: {
        color: "#ADB5BD",
        fontSize: 14,
        fontWeight: 400,
        lineHeight: 1.5
    },
}))