import { api } from "index";
import { useQueryClient } from "react-query";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { useModalStore } from "features/Modals/hooks/useModalStore";
import { useCallback } from "react";
import { useMerchantDetails } from "./useMerchantDetails";
import { showNotification } from "@mantine/notifications";
import { ErrorResponse } from "api/interfaces/api";

export const useMerchantDelete = () => {
    const queryClient = useQueryClient();
    const { merchant } = useMerchantDetails();
    const { mutateAsync: deleteMerchant, ...rest } = useMutation(() =>
        api.merchants.deleteMerchant(merchant?.id)
    );
    const { closeModal, setupModal, setModalStatus } = useModalStore();
    const navigate = useNavigate();

    const handleMerchantDelete = useCallback(() => {
        const onDelete = async () => {
            closeModal();
            try {
                await deleteMerchant();
                navigate("/account");
                await Promise.all([
                    queryClient.invalidateQueries(["SEARCH_MERCHANTS"]),
                ]);

                showNotification({
                    autoClose: 3000,
                    title: "Success!",
                    message: "Merchant successfully deleted!",
                    color: "green",
                });
            } catch (e: any) {
                const error: ErrorResponse = e.response.data;
                showNotification({
                    message: error.error ?? "Something went wrong.",
                    title: "Error!",
                    color: "red",
                    autoClose: 5000,
                });
            }
        };
        setupModal({
            type: "delete",
            content:
                "Please confirm your action. <br/>Selected Merchant will be deleted!",
            action: onDelete,
        });
        setModalStatus(true);
    }, [navigate, queryClient, deleteMerchant]);

    return { ...rest, handleMerchantDelete };
};
