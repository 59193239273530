import { useCallback, useEffect } from "react";
import styled from "@emotion/styled";
import { Icon } from "@iconify/react";
import { Link, useParams } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { Button, Group, Stack, Text } from "@mantine/core";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuth } from "features/Auth/hooks/useAuth";
import ContentLoader from "components/ContentLoader";
import useLocationList from "features/LocationList/hooks/useLocationList";
import { useAdmins } from "features/Admins/useAdmins";
import { LocationEventForm } from "../../../api/interfaces/locationEvents";
import { useLocationEvent } from "../../../features/LocationEventEdit/hooks/useLocationEvent";
import LocationEventEdit from "../../../features/LocationEventEdit";
import { useLocationEventCreate } from "../../../features/LocationEventEdit/hooks/useLocationEventCreate";
import { useLocationEventUpdate } from "../../../features/LocationEventEdit/hooks/useLocationEventUpdate";
import { useLocationEventDelete } from "../../../features/LocationEventEdit/hooks/useLocationEventDelete";
import { LocationEventAnalyticDashboard } from "features/LocationEventEdit/components/LocationEventAnalyticDashboard";
import { useMerchantNavigation } from "../../../hooks/useMerchantNavigation";
import { GameSettings } from "../../../api/interfaces/locationEvents";

// Validation Schema
const schema = yup
  .object({
    name: yup
      .string()
      .max(32, `Name is too long. Maximum length is 32 chars.`)
      .lowercase()
      .trim()
      .required("Required"),
    locationId: yup.string().required("Required"),
    eventId: yup.string().required("Required"),
    isGiftForPurchase: yup.boolean().required("Required"),
    adminId: yup.number(),
  })
  .required();

interface FormState {
  name: LocationEventForm["name"];
  locationId: LocationEventForm["locationId"];
  eventId: LocationEventForm["eventId"];
  contents: LocationEventForm["contents"];
  adminId?: LocationEventForm["adminId"];
  isGiftForPurchase: LocationEventForm["isGiftForPurchase"];
  contentsGifts: LocationEventForm["contentsGifts"];
  isSubsequentReward: LocationEventForm["isSubsequentReward"];
  contentsSubsequents: LocationEventForm["contentsSubsequents"];
  contentsInitials: LocationEventForm["contentsInitials"];
  isInitialReward: boolean;
  gamesList: LocationEventForm["gamesList"];
  gameSettings: GameSettings[],
}

// Page
export default function LocationEventUpdate() {
  const { user } = useAuth();
  const { id } = useParams();
  const { isLoading: adminsLoading } = useAdmins();
  const { isLoading: locationsLoading } = useLocationList();
  const { locationEvent } = useLocationEvent(Number(id));
  const { handleCreate } = useLocationEventCreate();
  const { handleUpdate } = useLocationEventUpdate(Number(id));
  const { handleDelete } = useLocationEventDelete(Number(id));
  const { route } = useMerchantNavigation();

  const form = useForm<FormState>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      locationId: null,
      eventId: null,
      contents: [],
      contentsGifts: [],
      isGiftForPurchase: false,
      isSubsequentReward: false,
      contentsSubsequents: [],
      contentsInitials: [],
      isInitialReward: false,
      gamesList: [],
      gameSettings: []
    },
  });

  const onSubmit = useCallback(
    async (input: FormState) => {
      const data = {} as LocationEventForm;
      try {
        Object.assign(data, {
          name: input.name,
          locationId: input.locationId,
          eventId: input.eventId,
          contents: input.contents,
          contentsGifts: input.contentsGifts,
          isGiftForPurchase: input.isGiftForPurchase,
          adminId: input.adminId,
          isSubsequentReward: input.isSubsequentReward,
          contentsSubsequents: input.contentsSubsequents,
          contentsInitials: input.contentsInitials,
          isInitialReward: input.isInitialReward,
          gamesList: input.gamesList.map(num => num.toString()),
          gameSettings: input.gameSettings
        });
        
        if (!locationEvent) {
          await handleCreate(data);
          console.log("CREATED EVENT");
        }

        if (!!locationEvent) {
          await handleUpdate({ ...locationEvent, ...data });
          console.log("UPDATED LOCATION EVENT");
        }
      } catch (e) {
        console.log(e);
      }
    },
    [locationEvent, form, handleCreate, handleUpdate]
  );
  // Update Form Data
  useEffect(() => {
    if (!locationEvent) return;
    form.reset({
      name: locationEvent.name,
      locationId: locationEvent.locationId,
      eventId: locationEvent.eventId,
      contents: locationEvent.contents.map((content) => content.id),
      contentsGifts: locationEvent.contentsGifts.map((content) => content.id),
      adminId: locationEvent.adminId,
      isGiftForPurchase: locationEvent.isGiftForPurchase,
      isSubsequentReward: locationEvent.isSubsequentReward,
      isInitialReward: locationEvent.isInitialReward,
      contentsSubsequents: locationEvent.contentsSubsequents.map(
        (content) => content.id
      ),
      contentsInitials: locationEvent.contentsInitials.map(
        (content) => content.id
      ),
      gamesList: locationEvent.gamesList.map(str => parseInt(str, 10)).map((id) => id),
      gameSettings: locationEvent.GameSettings
    });
  }, [locationEvent, form, user]);

  if ((id && !locationEvent) || adminsLoading || locationsLoading) {
    return <ContentLoader />;
  }

  return (
    <FormProvider {...form}>
      <Root onSubmit={form.handleSubmit(onSubmit)}>
        <Stack spacing={0}>
          <Group position="apart">
            <Group position="left">
              <BackLink to={`${route}/location-events`}>
                <span className={"icon"}>
                  <Icon icon={"fluent:arrow-left-12-filled"} />
                </span>
                <Text size="xl" weight={"bold"}>
                  Back to Location Events
                </Text>
              </BackLink>
            </Group>
            <Group position="right">
              {!!locationEvent && (
                <Button
                  leftIcon={
                    <Icon icon={"fluent:delete-24-filled"} height={18} />
                  }
                  onClick={handleDelete}
                  radius={"sm"}
                  color={"red"}
                  variant={"filled"}
                >
                  Delete
                </Button>
              )}
              {!locationEvent && (
                <Button
                  leftIcon={
                    <Icon icon={"fluent:delete-24-filled"} height={18} />
                  }
                  component={Link}
                  to={`${route}/location-events`}
                  radius={"sm"}
                  color={"red"}
                  variant={"filled"}
                >
                  Cancel
                </Button>
              )}

              <Button
                type="submit"
                leftIcon={<Icon icon={"fluent:save-24-filled"} height={18} />}
                radius={"sm"}
                color={"teal"}
                variant={"filled"}
                loading={form.formState.isSubmitting}
              >
                {locationEvent ? "Update" : "Create"}
              </Button>
            </Group>
          </Group>

          <LocationEventEdit />
          {id && <LocationEventAnalyticDashboard />}
        </Stack>
      </Root>
    </FormProvider>
  );
}

// Styling
const Root = styled.form`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const BackLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;

  color: #2c3e50;

  & {
    transition-property: color;
    transition-duration: 250ms;
    transition-timing-function: ease-in-out;
  }

  &:hover {
    color: #0083ce;
  }

  & .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    margin-right: 8px;
  }

  & .title {
    font-size: 20px;
    font-weight: bold;
  }
`;
