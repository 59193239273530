import { api } from "index";
import { useQuery } from "react-query";
import { useLocationStore } from "./useLocationStore";

export default function useIdentifyLocation() {
	const { state: { searchCoords }, setCoordsLocation } = useLocationStore()
	const { data: location, ...options } = useQuery(['LOCATIONS_BY_COORDS', searchCoords], () => api.openStreet.searchByCoords(searchCoords), { 
		retry: false,
		enabled: Boolean(searchCoords) 
	})

	return {
		location,
		searchCoords,
		setCoordsLocation,
		...options
	}
}
