import {useEffect, useMemo} from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";
import {
  Button,
  CSSObject,
  Group,
  Image,
  NumberInput,
  Paper,
  Select,
  Stack,
  Switch,
  TextInput,
  Tooltip,
} from "@mantine/core";
// import { TimeInput } from "@mantine/dates";
import {Controller, useFormContext, useWatch} from "react-hook-form";
import {useParams} from "react-router-dom";
import useImageContentList from "features/ImageContentList/hooks/useImageContentList";
import useTextContentList from "features/TextContentList/hooks/useTextContentList";
import useCouponsList from "features/CouponsList/hooks/useCouponsList";
import {useServerConstants} from "hooks/useServerConstants";
import {useTemporaryQr} from "../ContentList/hooks/useTemporaryQr";
import {Icon} from "@iconify/react";
import {DateTimePicker} from "@mantine/dates";
import usePaginatedData from "hooks/usePaginatedData";
import { TQuest } from "features/Quests/types";

// Component
export default function ContentEdit() {
  const {control, setValue} = useFormContext();
  const {id, merchantId} = useParams();
  const {ContentType, ExpirationType} = useServerConstants();
  const {textContents} = useTextContentList();
  const {imageContents} = useImageContentList();
  const {coupons} = useCouponsList();
  const {qr, handleGenerateQr} = useTemporaryQr();

  const currentDate = new Date();

  const contentTypeId = useWatch({
    control,
    name: "contentTypeId",
  });
  const expirationTypeId = useWatch({
    control,
    name: "expirationTypeId",
  });

  //   const limitSwitchStatus = useWatch({
  //     control,
  //     name: "activationLimit",
  //   });
  //   const receiveSwitchStatus = useWatch({
  //     control,
  //     name: "receiveLimit",
  //   });

  const contentTypeList = useMemo(() => {
    return ContentType.map((content) => ({
      value: String(content.id),
      label: content.name,
    }));
  }, [ContentType]);

  const showTextForms = useMemo(() => {
    return [1, 3, 5].includes(contentTypeId);
  }, [contentTypeId]);

  const showQRForms = useMemo(() => {
    return [2, 3].includes(contentTypeId);
  }, [contentTypeId]);

  const showImageForms = useMemo(() => {
    return [4, 5].includes(contentTypeId);
  }, [contentTypeId]);

  const textContentList = useMemo(() => {
    return textContents.map((content) => ({
      value: String(content.id),
      label: content.name,
    }));
  }, [textContents]);

  const expirationTypeList = useMemo(() => {
    return ExpirationType.map((content) => ({
      value: String(content.id),
      label: content.name,
    }));
  }, [ExpirationType]);

  const couponsData = useMemo(() => {
    if (!coupons) return [];
    return coupons.map((coupon: any) => {
      return {
        value: String(coupon.id),
        label: coupon.title,
      }
    })
  }, [contentTypeId]);

  const imageContentList = useMemo(() => {
    if (showQRForms) {
      return imageContents
        .filter((i) => i.imageContentTypeId === 1)
        .map((content) => ({
          value: String(content.id),
          label: content.name,
        }));
    } else {
      return imageContents
        .filter((i) => i.imageContentTypeId === 2)
        .map((content) => ({
          value: String(content.id),
          label: content.name,
        }));
    }
  }, [imageContents, showQRForms]);


  const isQrAvailable = useMemo(() => {
    if (!id) return false;
    const values = control._formValues;
    if (
      !values.totalQuantity ||
      !values.price ||
      !values.userReceivesOnReward ||
      !values.isPurchasable
    )
      return false;
    return true;
  }, [id, control._formValues]);

  useEffect(() => {
    if (!id) {
      setValue("contentTypeId", null)
    }
  }, [id]);

  const handleContentTypeChange = (field: any, value: any) => {
    field.onChange(Number(value));
    if (value != 4) {
      setValue("imageContentId", "");
    }
  };

  const { items: questsData } = usePaginatedData<TQuest>({
    path: `/admin/merchants/${merchantId}/quests`,
    defaultLimit: 1000,
    queryKey: ["QUEST_LIST"],
  });
  
  const questsList = useMemo(() => {
    return questsData.map((quest) => ({
      value: String(quest.id),
      label: quest.title,
    }));
  }, [questsData]);

  return (
    <Root radius="md" withBorder p={"lg"}>
      <Group grow spacing={"lg"} align={"flex-start"}>
        <Stack spacing="lg">
          {/* Name */}
          <Stack>
            <Controller
              name="name"
              control={control}
              render={({field, fieldState}) => (
                <TextField
                  label="Name"
                  placeholder="Enter content name"
                  variant="filled"
                  error={fieldState.error?.message}
                  required
                  classNames={{input: "input-field"}}
                  {...field}
                />
              )}
            />
          </Stack>

          {/* Text Content */}
          {showTextForms && (
            <Stack>
              <Controller
                name="textContentId"
                control={control}
                render={({field}) => (
                  <SelectField
                    label="Text Content"
                    placeholder="Select text content"
                    required
                    variant="filled"
                    searchable
                    data={textContentList}
                    classNames={{input: "input-field"}}
                    {...field}
                    value={String(field.value)}
                    onChange={(value) => field.onChange(Number(value))}
                  />
                )}
              />
            </Stack>
          )}

          {contentTypeId == 6 && (
            <Stack>
              <Controller
                name="couponsId"
                control={control}
                render={({field}) => (
                  <SelectField
                    label="Coupons"
                    placeholder="Select coupon"
                    required
                    variant="filled"
                    searchable
                    data={couponsData}
                    classNames={{input: "input-field"}}
                    {...field}
                    value={String(field.value)}
                    onChange={(value) => field.onChange(Number(value))}
                  />
                )}
              />
            </Stack>
          )}

          {contentTypeId === 7 && (
            <Stack>
              <Controller
                name="questId"
                control={control}
                render={({field}) => (
                  <SelectField
                    label="Quests"
                    placeholder="Select quest"
                    required
                    variant="filled"
                    searchable
                    data={questsList}
                    classNames={{input: "input-field"}}
                    {...field}
                    value={String(field.value)}
                    onChange={(value) => field.onChange(Number(value))}
                  />
                )}
              />
            </Stack>
          )}

          {/* Image and QR Content */}
          {(showImageForms || showQRForms) && (
            <Stack>
              <Controller
                name="imageContentId"
                control={control}
                render={({field}) => (
                  <SelectField
                    label={
                      showImageForms && !showQRForms
                        ? "Image Content"
                        : "QR Content"
                    }
                    placeholder={
                      showImageForms && !showQRForms
                        ? "Select image content"
                        : "Select qr content"
                    }
                    required
                    variant="filled"
                    searchable
                    data={imageContentList}
                    classNames={{input: "input-field"}}
                    {...field}
                    value={String(field.value)}
                    onChange={(value) => field.onChange(Number(value))}
                  />
                )}
              />
            </Stack>
          )}
        </Stack>
        <Stack spacing="lg">
          {/* Content Type */}
          <Stack>
            <Controller
              name="contentTypeId"
              control={control}
              render={({field}) => (
                <SelectField
                  label="Content Type"
                  placeholder="Select content type"
                  required
                  variant="filled"
                  searchable
                  data={contentTypeList}
                  classNames={{input: "input-field"}}
                  {...field}
                  value={String(field.value)}
                  onChange={(value) => handleContentTypeChange(field, value)}
                />
              )}
            />
          </Stack>
        </Stack>
      </Group>

      <Group spacing={40} align={"flex-start"}>
        <Stack mt={20}>
          <Controller
            name="isPurchasable"
            control={control}
            render={({field}) => (
              <Switch
                label="Is purchasable"
                styles={SwitcherStyles}
                checked={field.value}
                onChange={field.onChange}
              />
            )}
          />
        </Stack>

        <Group mt={20} align={"flex-start"}>
          <Controller
            name="isTransferable"
            control={control}
            render={({field}) => (
              <Switch
                label="Is transferable"
                styles={SwitcherStyles}
                checked={field.value}
                onChange={field.onChange}
              />
            )}
          />
          <Tooltip
            multiline
            width={220}
            withArrow
            label="If this flag is enabled, the user will be able to transfer this award to another user in the mobile application."
          >
            <Icon icon="material-symbols:info-outline"/>
          </Tooltip>
        </Group>
      </Group>

      <Group grow spacing={"lg"} mt={20} align={"flex-start"}>
        <Stack>
          <Controller
            name="price"
            control={control}
            render={({field}) => (
              <NumberInput
                label="Price in Qubi сoins"
                placeholder="Enter content price"
                variant="filled"
                precision={2}
                // required
                hideControls
                classNames={{input: "input-field"}}
                min={0}
                {...field}
                disabled={contentTypeId == 6}
              />
            )}
          />
        </Stack>
        <Stack>
          <Controller
            name="totalQuantity"
            control={control}
            render={({field}) => (
              <NumberInput
                label="Total quantity"
                placeholder="Enter total quantity"
                variant="filled"
                required
                hideControls
                classNames={{input: "input-field"}}
                min={0}
                {...field}
              />
            )}
          />
        </Stack>
        <Stack>
          <Controller
            name="leftQuantity"
            control={control}
            render={({field}) => (
              <NumberInput
                label="Left quantity"
                disabled
                variant="filled"
                required
                hideControls
                classNames={{input: "input-field"}}
                {...field}
              />
            )}
          />
        </Stack>
        <Stack>
          <Controller
            name="userReceivesOnReward"
            control={control}
            render={({field}) => (
              <NumberInput
                label="User reward in Qubi coins"
                placeholder="Enter user reward"
                variant="filled"
                precision={2}
                required
                hideControls
                classNames={{input: "input-field"}}
                min={0}
                {...field}
                disabled={contentTypeId == 6}
              />
            )}
          />
        </Stack>
      </Group>


      <Group spacing={"lg"} mt={20} align={"flex-start"}>
        <Stack>
          <Controller
            name="expirationTypeId"
            control={control}
            render={({field}) => (
              <SelectField
                label="Expiration Type"
                placeholder="Select expiration type"
                variant="filled"
                searchable
                data={expirationTypeList}
                classNames={{input: "input-field"}}
                {...field}
                value={String(field.value || 1)}
                onChange={(value) => field.onChange(Number(value))}
              />
            )}
          />
        </Stack>
        {expirationTypeId === 2 && (
          <Stack>
            <Controller
              name="expireDate"
              control={control}
              render={({field,fieldState}) => (
                <DatePickField
                  label="Expire Date"
                  placeholder="Pick expire date"
                  valueFormat="MMMM DD, YYYY HH:mm"
                  variant="filled"
                  minDate={currentDate}
                  classNames={{ input: "input-field" }}
                  error={fieldState.error?.message}
                  value={field.value ? new Date(field.value) : undefined}
                  onChange={field.onChange}
                />
              )}
            />
          </Stack>
        )}

        {expirationTypeId === 3 && (
          <Stack>
            <Controller
              name="daysToExpire"
              control={control}
              render={({field}) => (
                <NumberInput
                  label="Days to Expire"
                  placeholder="Enter days to expire"
                  variant="filled"
                  precision={0}
                  hideControls
                  classNames={{input: "input-field"}}
                  min={0}
                  {...field}
                  // disabled={contentTypeId == 6}
                />
              )}
            />
          </Stack>
        )}
      </Group>


      {/* <Group grow spacing={"lg"} mt={20} align={"flex-start"}>
        <Controller
          name="activationLimit"
          control={control}
          render={({ field, fieldState }) => (
            <Switch
              label="Toggle activate limit"
              error={fieldState.error?.message}
              checked={field.value}
              onChange={field.onChange}
            />
          )}
        />
        <Controller
          name="timeActivateLimitStart"
          control={control}
          render={({ field, fieldState }) => (
            <TimeInput
              label="Activate time start"
              disabled={!limitSwitchStatus}
              error={fieldState.error?.message}
              {...field}
            />
          )}
        />
        <Controller
          name="timeActivateLimitEnd"
          control={control}
          render={({ field, fieldState }) => (
            <TimeInput
              label="Activate time end"
              disabled={!limitSwitchStatus}
              error={fieldState.error?.message}
              {...field}
            />
          )}
        />
      </Group> */}
      {/* <Group grow spacing={"lg"} mt={10} align={"flex-start"}>
        <Controller
          name="receiveLimit"
          control={control}
          render={({ field }) => (
            <Switch
              mt={20}
              label="Toggle receive limit"
              checked={field.value}
              onChange={field.onChange}
            />
          )}
        />
        <Controller
          name="timeReceiveLimitStart"
          control={control}
          render={({ field }) => (
            <TimeInput
              label="Receive time start"
              disabled={!receiveSwitchStatus}
              {...field}
            />
          )}
        />
        <Controller
          name="timeReceiveLimitEnd"
          control={control}
          render={({ field, fieldState }) => (
            <TimeInput
              label="Receive time end"
              disabled={!receiveSwitchStatus}
              error={fieldState.error?.message}
              {...field}
            />
          )}
        />
      </Group> */}
      {isQrAvailable && (
        <Stack mt={16}>
          <Stack
            style={{
              width: 300,
              height: 300,
              background: "#f1f1f1",
              borderRadius: 8,
              padding: 16,
            }}
          >
            {qr && (
              <a target={"_blank"} rel={"noreferrer"} href={qr}>
                <Image styles={{image: {borderRadius: 8}}} src={qr}/>
              </a>
            )}
          </Stack>
          <Stack style={{width: 300}}>
            <Button onClick={() => handleGenerateQr(id)}>Generate QR</Button>
          </Stack>
        </Stack>
      )}
    </Root>
  );
}

// CSS Helper
const SwitcherStyles: Record<"input" | "label" | "root" | "body", CSSObject> = {
  root: {
    flexDirection: "column",
    alignItems: "flex-start",
    cursor: "pointer",
  },
  label: {
    paddingLeft: 0,
    order: 1,
    marginBottom: 4,
    fontSize: 14,
    fontWeight: 500,
    color: "#212529",
    wordBreak: "break-word",
    cursor: "default",
  },
  input: {
    order: 2,
    cursor: "pointer",
  },
  body: {
    gap: 6,
  },
};

const InputCSS = css`
  display: flex;
  font-size: 14px;
  font-weight: 400;
  background: #fcfdfe;
  color: #9fa2b4;
  line-height: 1.5;
  min-height: 42px;
  outline: none;
  padding: 10px 16px;
  width: 100%;
  transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out,
  color 0.2s ease-in-out;

  &:hover,
  &:focus {
    color: #252733;
    border-color: #0095e5;
  }

  &::placeholder {
    color: rgb(75, 80, 109, 0.5);
  }
`;

const FieldCSS = css`
  flex: 1;

  & .input-field {
    ${InputCSS};
  }
`;
// Styling
const Root = styled(Paper) <any>`
  overflow: hidden;
`;

const TextField = styled<any>(TextInput)`
  ${FieldCSS};
  position: relative;

  .mantine-TextInput-error {
    position: absolute;
    left: 0;
    bottom: -24px;
  }

  & .input-field {
    border: 1px solid ${({error}) => (error ? "#f03e3e" : "#f0f1f7")};
  }
`;

const SelectField = styled(Select)`
  ${FieldCSS};

  & .input-field {
    border: 1px solid ${({error}) => (error ? "#f03e3e" : "#f0f1f7")};
  }
`;

const DatePickField = styled(DateTimePicker)`
  ${FieldCSS};
`;
