import { api } from "index";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { ICouponOrigin } from "api/interfaces/coupon";
import useMerchantSelectorStore from "features/MerchantSelector/hooks/useMerchantSelectorStore";
import { useMerchantNavigation } from "../../../hooks/useMerchantNavigation";
import { ErrorResponse } from "api/interfaces/api";
import { showNotification } from "@mantine/notifications";

export const useCouponCreate = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { route } = useMerchantNavigation();

  const merchantSelector = useMerchantSelectorStore();

  const selectedId = merchantSelector.useStore((state) => state.selected);

  const { mutateAsync: create, isLoading, isError } = useMutation({
    mutationFn: async (input: ICouponOrigin) => await api.coupons.create(selectedId, input),
  });

  const handleCreate = useCallback(
    async (input: ICouponOrigin) => {
      try {
        const coupon = await create({...input, merchantId: selectedId });
        navigate(`${route}/coupons/${coupon.id}`);

        await Promise.all([
          queryClient.invalidateQueries(["COUPONS_LIST", selectedId]),
          queryClient.invalidateQueries(["COUPONS_LIST"]),
          queryClient.invalidateQueries(["COUPON"]),
        ]);
        showNotification({
          autoClose: 3000,
          title: "Success!",
          message: "Coupon successfully created!",
          color: "green",
        });
      } catch (e: any) {
        const error: ErrorResponse = e.response.data;
        showNotification({
          message: error.error ?? "Something went wrong.",
          title: "Error!",
          color: "red",
          autoClose: 5000,
        });
      }
    },
    [create, navigate]
  );

  return {
    handleCreate,
    isLoading,
    isError,
  };
};
