import qs from "qs";
import { Base } from "./Base";
import { ApiResponse, ApiResponseList, PaginationParams } from "./interfaces/api";
import {AdminConstants, Constants} from "./interfaces/constants";
import { Verification, VerificationParams } from "./interfaces/verifications";
import { UserAgreement } from "./interfaces/userAgreement";

export default class Settings extends Base {
  async updateConstants(id: number, input: any) {
    const response = await this.request.patch<ApiResponse<Constants>>(
      `${this.baseUrl}/admin/constants/${id}`,
      { textValue: input }
    );
    return response.data.data;
  }

  async getAdminConstants() {
    const response = await this.request.get<ApiResponse<AdminConstants>>(
      `${this.baseUrl}/admin/constants`
    );
    return response.data.data;
  }

  async getVerifications({
    skip = 0,
    limit = 1000,
    type = "admin",
  }: PaginationParams & { type: "admin" | "user" }) {
    const query = qs.stringify({ skip, limit, type });
    const response = await this.request.get<ApiResponseList<Verification>>(
      `${this.baseUrl}/admin/verifications?${query}`
    );
    return response.data.data;
  }

  async updateVerification({ ...data }: VerificationParams) {
    const response = await this.request.patch<ApiResponse<Verification>>(
      `${this.baseUrl}/admin/verifications`,
      data
    );
    return response.data.data;
  }

  async getUserAgreements({
    skip = 0,
    limit = 1000,
    type = "admin",
  }: PaginationParams & { type: "admin" | "user" }) {
     const query = qs.stringify({ skip, limit, type });
    const response = await this.request.get<ApiResponseList<UserAgreement>>(
      `${this.baseUrl}/admin/agreements?${query}&search=merch`
    );
    return response.data.data;
  }
}
