import { useQuery } from "react-query";
import { api } from "index";

export function useEventDaysOfWeek(id?: number) {
    const {
        data: eventDaysOfWeek,
        isError,
        isLoading,
        refetch,
    } = useQuery(
        ["EVENT_DAYS_OF_WEEK"],
        () => api.events.getEventDaysOfWeek(id),
        {
            refetchOnWindowFocus: false,
            refetchIntervalInBackground: false,
            enabled: !!id,
            retry: 0,
        }
    );

    return { eventDaysOfWeek, isError, isLoading, refetch };
}
