import { api } from "index";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { ContentInput } from "api/interfaces/content";
import { useMerchantNavigation } from "../../../hooks/useMerchantNavigation";
import { ErrorResponse } from "api/interfaces/api";
import { showNotification } from "@mantine/notifications";
import { useEventCards } from "features/EventEdit/store/event-cards.store";
import { useContentCardsQuery } from "./useContentCardsQuery";

export const useContentCreate = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const cardsData = useEventCards(state => state.getFormattedData);
  const resetStore = useEventCards(state => state.resetStore);
  const { route, id: merchantId } = useMerchantNavigation();
  const { postCards } = useContentCardsQuery({ id: merchantId, contentId: undefined });
  // Event Create Handler
  const { mutateAsync: create, ...options } = useMutation(
    (input: ContentInput) => api.contents.create(input, Number(merchantId))
  );

  // Handler for forms
  const handleCreate = useCallback(
    async (input: ContentInput) => {
      try {
        const content = await create(input);
        if (content) {
          const payload = cardsData() ? cardsData() : { cards: [] };
          await postCards({ cardsData: payload as any, id: Number(merchantId), contentId: Number(content.id) });
          resetStore();
        }
        navigate(`${route}/contents/${content.id}`);
        await Promise.all([
          queryClient.invalidateQueries(["CONTENT_LIST", merchantId]),
          queryClient.invalidateQueries(["CONTENT"]),
        ]);
        showNotification({
          autoClose: 3000,
          title: "Success!",
          message: "Content successfully created!",
          color: "green",
        });
      } catch (e: any) {
        const error: ErrorResponse = e.response.data;
        showNotification({
          message: error.error ?? "Something went wrong.",
          title: "Error!",
          color: "red",
          autoClose: 5000,
        });
      }
    },
    [create, navigate]
  );

  return {
    handleCreate,
    ...options,
  };
};
