import {Button, Group, NumberInput, Stack} from "@mantine/core";
import {Controller, useForm} from "react-hook-form";
import styled from "@emotion/styled";
import {css} from "@emotion/react";
import {yupResolver} from "@hookform/resolvers/yup";
import {showNotification} from "@mantine/notifications";
import {ErrorResponse} from "../../../api/interfaces/api";
import * as yup from "yup";
import {api} from "../../../index";
import {ConstantIds} from "../../../api/interfaces/settings";
import {useEffect} from "react";
import {useAppConfigConstants} from "../../../features/AppAccess/hooks/useAppConfigConstants";

const schema = yup
    .object({
        percentage: yup
            .number()
            .positive("Amount cannot be negative or zero")
            .required("Field cannot be empty"),
    })
    .required();

export default function ReferralProgram() {
    const constantId = ConstantIds.REFERRAL;
    const {data: constants} =
        useAppConfigConstants();

    const form = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            percentage: 0,
        },
    });

    useEffect(() => {
        if (!constants) return;
        const referralData = constants!.items.filter((i) => i.id === constantId);

        form.reset({
            percentage: referralData[0].floatValue || 0.00,
        });
    }, [constants, constantId, form]);

    const handleUpdate = form.handleSubmit(async (values) => {
        try {
            await api.adminConstants.updateReferral(
                constantId,
                values.percentage
            );
            showNotification({
                autoClose: 3000,
                title: "Success!",
                message: "The referral percentage has been successfully changed",
                color: "green",
            });
        } catch (e: any) {
            const error: ErrorResponse = e.response.data;
            showNotification({
                message: error.error ?? "Something went wrong.",
                title: "Error!",
                color: "red",
                autoClose: 5000,
            });
        }
    });

    return (
        <Stack p={20} spacing={0}>
            <StyledGroup align={"flex-end"}>
                <Controller
                    name="percentage"
                    control={form.control}
                    render={({field, fieldState}) => (
                        <NumberField
                            label="Configuration for referral program"
                            variant="filled"
                            value={field.value}
                            onChange={field.onChange}
                            hideControls
                            precision={2}
                            min={0}
                            classNames={{input: "input-field"}}
                            error={fieldState.error?.message}
                        />
                    )}
                />
                <Button size="md" onClick={handleUpdate}>
                    Update
                </Button>
            </StyledGroup>
        </Stack>
    )
}

const InputCSS = css`
  display: flex;
  font-size: 14px;
  font-weight: 400;
  height: 42px;
  background: #fcfdfe;
  border: 1px solid #f0f1f7;
  color: #9fa2b4;
  line-height: 1.5;
  min-height: 42px;
  outline: none;
  padding: 10px 16px;
  width: 100%;
  transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out,
  color 0.2s ease-in-out;

  &:disabled {
    background-color: #f1f3f5;
  }

  &:hover,
  &:focus {
    color: #252733;
    border-color: #0095e5;
  }

  &::placeholder {
    color: rgb(75, 80, 109, 0.5);
  }
`;

const FieldCSS = css`
  flex: 1;

  & .input-field {
    ${InputCSS};
  }
`;

const NumberField = styled(NumberInput)`
  ${FieldCSS};
  position: relative;

  .mantine-NumberInput-error {
    position: absolute;
    left: 0;
    bottom: -19px;
  }
`;

const StyledGroup = styled(Group)`
  .mantine-NumberInput-wrapper {
    margin: 0;
  }
`;