import { useSearchParams } from "react-router-dom";
import SharedCollectibleCards from "./cards/SharedCollectibleCards";
import SharedLocationEvents from "./location-events/SharedLocationEvents";


const Sections = {
    'collectible-cards': SharedCollectibleCards,
    'location-events': SharedLocationEvents,
}

export default function TabSections() {
    const [searchParams] = useSearchParams();

    const key = (searchParams.get('tab') ?? Sections["collectible-cards"]) as keyof typeof Sections;
    if (!Sections.hasOwnProperty(key)) return null

    const Component = Sections[key]
    return <Component />
}