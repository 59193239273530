import { TransferType } from "api/interfaces/merchants";

export const TRANSFER_TYPES: TransferType[] = [
  {
    id: 2,
    name: "From User",
  },
  {
    id: 3,
    name: "Location Event User Visit",
  },
  {
    id: 4,
    name: "User Location Event Win Prize",
  },
  {
    id: 5,
    name: "User Buy Content",
  },
  {
    id: 7,
    name: "Deposit",
  },
  {
    id: 8,
    name: "Deposit for Event",
  },
  {
    id: 9,
    name: "Withdraw",
  },
];
