import styled from "@emotion/styled";
import ImageContentItem from "./components/ImageContentItem";
import { ImageContent } from "api/interfaces/imageContents";

// Props
type ComponentProps = {
  imageContents: ImageContent[]
}

// Component
export default function ImageContentList({ imageContents }: ComponentProps) {
  return (
    <Root>
      {imageContents.map(content => (
        <ImageContentItem key={content.id} imageContent={content} />
      ))}
    </Root>
  )
}

// Styling
const Root = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  background: #FFFFFF;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  overflow-y: hidden;
`


