import { Icon } from "@iconify/react"
import { Box, Button, Card, Group, Stack, Text, Tooltip } from "@mantine/core"
import Modal from "features/Modal"
import { ModalTrigger } from "features/Modal/constants"
import React, { useEffect, useMemo } from "react"
import { useParams } from "react-router-dom"
import AddWorkingDate from "./AddWorkingDate"
import { useModalStore } from "features/Modal/store/modal.store"
import { useEventWorkingDates } from "../store/event-days.store"
import WorkingDayListItem from "./WorkingDayListItem"

const DayOrder = {
    "monday": 1,
    "tuesday": 2,
    "wednesday": 3,
    "thursday": 4,
    "friday": 5,
    "saturday": 6,
    "sunday": 7
};

export default function CertainDays() {
    const { id } = useParams<{ id: string }>();
    const openModal = useModalStore(state => state.setOpen);

    const workingDates = useEventWorkingDates(state => state.workingDates);
    const addEvent = useEventWorkingDates(state => state.addEvent);

    const Event = useMemo(() => {
        const event = workingDates.find(event => event.event_id === Number(id));
        if (event) {
            event.working_days.sort((a, b) => DayOrder[a.day] - DayOrder[b.day]);
        }
        return event;
    }, [workingDates, id])

    useEffect(() => {
        if (!id || Event) return;
        addEvent(Number(id));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Event, id])

    const onOpenAdd = () => {
        openModal({ trigger: ModalTrigger.ADD_WORKING_DATE, title: "New Working Date" });
    };

    if (!id) return null;

    return (
        <React.Fragment>
            <Card radius={4} sx={{ border: "1px solid #f8f9fa" }}>
                <Card.Section inheritPadding p={24}>
                    <Group position="apart">
                        <Group spacing={8}>
                            <Text size={14} weight={500} lh={1.5}>
                                Event Working Days
                            </Text>
                            <Tooltip
                                label={`Event Working Days are the days and time that the event will be active.`}
                                width={260}
                                multiline
                                withArrow
                                position="top"
                            >
                                <Icon icon="fluent:info-16-regular" color="#339AF0" width={16} height={16} />
                            </Tooltip>
                        </Group>
                        <Group>
                            <Button variant="filled" h={28} px={12} py={4} color="dark.8" radius={4} onClick={onOpenAdd}>
                                <Text size={"14px"} weight={500} tt={"uppercase"} lh={1.5}>
                                    ADD DATE
                                </Text>
                            </Button>
                        </Group>
                    </Group>
                </Card.Section>
                <Card.Section inheritPadding px={24}>
                    <Box sx={{ height: 1, width: '100%', backgroundColor: '#f8f9fa' }} />
                </Card.Section>
                <Card.Section inheritPadding p={0} px={24}>
                    <Stack spacing={0}>

                        {Event?.working_days?.map((item, key) => (
                            <WorkingDayListItem key={key} item={item} />
                        ))}

                        {!Event?.working_days?.length && (
                            <Group p={24} align="center" position="center">
                                <Text size={"14px"} weight={400} color="gray.6" lh={1.5}>
                                    Select a date to add working hours
                                </Text>
                            </Group>
                        )}
                    </Stack>
                </Card.Section>
            </Card>

            <Modal triggers={[ModalTrigger.ADD_WORKING_DATE]}>
                <AddWorkingDate />
            </Modal>
        </React.Fragment>
    )
}