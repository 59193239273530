import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mantine/core";
import ContentLoader from "components/ContentLoader";
import PageToolbar from "components/PageToolbar";
import useMutationData from "hooks/useMutationData";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import { QuestForm } from "../../../features/Quests";
import { TQuest, TQuestTypeResponse } from "../../../features/Quests/types";
import { useQuery } from "react-query";
import { api } from "utils/api";
import { useMerchantNavigation } from "hooks/useMerchantNavigation";
import useMerchantSelectorStore from "features/MerchantSelector/hooks/useMerchantSelectorStore";
import { QuestActionsForm } from "features/Quests/components/QuestActionsForm";
import { questSchema } from "features/Quests/validation/questSchema";

export default function QuestDetailsPage() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { route } = useMerchantNavigation();
  const merchantSelector = useMerchantSelectorStore();
  const merchantId = merchantSelector.useStore((state) => state.selected);

  const form = useForm<TQuest>({
    mode: "onChange",
    resolver: yupResolver(questSchema),
  });

  const { data: quest, isLoading } = useQuery<any>({
    queryFn: async () =>
      id
        ? await api.get(`/admin/merchants/${merchantId}/quests/${id}`)
        : await api.get(`/admin/merchants/${merchantId}/quests`),
    queryKey: ["QUEST", id],
  });

  const updateRequest = useMutationData({
    url: () => `/admin/merchants/${merchantId}/quests/${id}`,
    method: "patch",
    queryKeys: {
      invalidate: [
        { queryKey: ["QUEST", id] },
        { queryKey: ["QUEST"] },
        { queryKey: ["QUEST_LIST"] },
      ],
    },
  });

  const createRequest = useMutationData({
    url: () => `/admin/merchants/${merchantId}/quests/`,
    method: "post",
    queryKeys: {
      invalidate: [
        { queryKey: ["QUEST", id] },
        { queryKey: ["QUEST"] },
        { queryKey: ["QUEST_LIST"] },
      ],
    },
  });

  const onSubmit = form.handleSubmit(async (values) => {
    const formData = {
      title: values.title,
      description: values.description,
      completionRewardId: [Number(values.completionRewardId)],
      achievementRewardId: values.achievementRewardId
        ? [Number(values.achievementRewardId)]
        : [],
      questTypeId: values.questTypeId,
      startDate: values.startDate,
      endDate: values.endDate,
      questConditions: values.questConditions,
    };

    if (id) {
      await updateRequest.mutateAsync(formData);
      navigate(`${route}/quests/${id}`);
    } else {
      const data: any = await createRequest.mutateAsync(formData);
      if (data) {
        const id = data.data?.data?.id;
        navigate(`${route}/quests/${id}`);
      }
    }
  });

  useEffect(() => {
    if (!quest || !id) return;

    form.reset({
      title: quest.data.data.title,
      description: quest.data.data.description,
      questTypeId: String(quest.data.data.questTypeId),
      achievementRewardId: !!quest.data.data.questAchievementContents?.length
        ? String(quest.data.data.questAchievementContents[0].id)
        : undefined,
      completionRewardId: !!quest.data.data.questCompletionContents?.length
        ? String(quest.data.data.questCompletionContents[0].id)
        : undefined,
      startDate: String(quest.data.data.startDate),
      endDate: String(quest.data.data.endDate),
      questConditions: quest.data.data.QuestConditions?.map((action: TQuestTypeResponse) => ({
        conditionTypeId: action.conditionTypeId,
        collectibleId: !!action.CollectibleCards?.length ? action.CollectibleCards[0].id : undefined,
        contentId: !!action.Contents?.length ? action.Contents[0].id : undefined,
        locationEventId: !!action.LocationEvents?.length ? action.LocationEvents[0].id : undefined,
      })),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quest, id]);

  if (isLoading) return <ContentLoader />;

  return (
    <FormProvider {...form}>
      <Stack p={24} spacing={24}>
        <PageToolbar label={"Go Back"} backLink={`${route}/quests`} />
        <form onSubmit={onSubmit}>
          <QuestForm />
          <QuestActionsForm />
        </form>
      </Stack>
    </FormProvider>
  );
}
