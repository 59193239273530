import styled from "@emotion/styled";
import {Icon} from "@iconify/react";
import {Grid, Group, Stack, Text} from "@mantine/core";
import {UpdateUserForm} from "api/interfaces/user";
import ContentLoader from "components/ContentLoader";
import {UserProfile} from "features/Users/components/UserProfile";
import {useUserDetails} from "features/Users/hooks/useUserDetails";
import {useUserAnalytics} from "features/Users/hooks/useUserAnalytic";
import {FC, useEffect} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {Link} from "react-router-dom";
import {useUserUpdate} from "../../../features/Users/hooks/useUserUpdate";
import AdminFeatures from "../../../features/Users/components/AdminFeatures";
import {useAuth} from "../../../features/Auth/hooks/useAuth";
import AgreementList from 'features/Users/components/AgreementList';
import AnalyticsComponent from "../../../features/AnalyticsComponent";

export const UpdateUserPage: FC = () => {
	const {user, isLoading} = useUserDetails();
	const {analytics, isAnalyticsLoading} = useUserAnalytics();
	const form = useForm<UpdateUserForm>();
	const {handleUpdate} = useUserUpdate();
	const {isPlatformAdmin} = useAuth()

	const onSubmit = async (values: UpdateUserForm) => {
		const data = {
			isPhoneVerified: values.isPhoneVerified,
			isEmailVerified: values.isPhoneVerified,
			isVerification: values.isVerification,
			isSuspend: values.isSuspend,
            balance: values.balance,
		};

		try {
			await handleUpdate(data);
		} catch (e: any) {
			console.debug(e);
		}
	};

	useEffect(() => {
		if (!user) return;
		form.reset(user);
	}, [user]);

	return (
			<FormProvider {...form}>
				<form onSubmit={form.handleSubmit(onSubmit)}>
					<Stack spacing={0}>

            <Stack p={20} spacing={0}>
              <Group position="apart">
                <Group position="left">
                  <BackLink to={`/platform/users`}>
                    <span className={"icon"}>
                      <Icon icon={"fluent:arrow-left-12-filled"}/>
                    </span>
                    <Text size="xl" weight={"bold"}>
                      Back to Users
                    </Text>
                  </BackLink>
                </Group>
              </Group>
              {!isLoading ? <UserProfile user={user!}/> : <ContentLoader/>}
            </Stack>

			{(user && isPlatformAdmin) && (
					<AdminFeatures user={user}/>
			)}

			{(user && isPlatformAdmin) && (
					<AgreementList user={user}/>
			)}

            <Stack p={20} spacing={0}>
              <Grid justify="space-between" p={20} pb={10} align="center">
                <Text weight={500} size={"xl"}>
                  User Analytics
                </Text>
              </Grid>
				<AnalyticsComponent analytics={analytics} isLoading={isAnalyticsLoading} />
			</Stack>
					</Stack>
				</form>
			</FormProvider>
	);
};

const BackLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;

  color: #2c3e50;

  & {
    transition-property: color;
    transition-duration: 250ms;
    transition-timing-function: ease-in-out;
  }

  &:hover {
    color: #0083ce;
  }

  & .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    margin-right: 8px;
  }

  & .title {
    font-size: 20px;
    font-weight: bold;
  }
`;
