import {MerchantAnalytic,} from "../../api/interfaces/analytic";
import { Paper } from "@mantine/core";
import styled from "@emotion/styled";
import { AnalyticBarChart } from "components/AnalyticBarChart";

type ComponentProps = {
  analytics?: MerchantAnalytic;
  isLoading?: boolean;
};

export default function AnalyticsComponent({
  analytics,
  isLoading,
}: ComponentProps) {
  return (
    <Root radius="md" withBorder>
        <AnalyticBarChart
          analytics={analytics}
          isLoading={isLoading}
        />
    </Root>
  );
}

const Root = styled(Paper)<any>`
  margin-top: 20px;
  overflow: hidden;
`;
