import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import Api from "./api";
import reportWebVitals from "./reportWebVitals";

import dayjs from "dayjs";
import calendar from "dayjs/plugin/calendar";

dayjs.extend(calendar)
export const api = new Api();

const rootElement = document.getElementById("root")!;

ReactDOM.createRoot(rootElement).render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
