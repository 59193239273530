import { Card, Group, Stack, Text } from "@mantine/core";
import { Fragment } from "react";
import { useParams } from "react-router-dom";

import OccasionItem from "../components/OccasionItem";
import useData from "hooks/useData";
import QueryKeys from "constants/query-keys";
import { useMerchantNavigation } from "hooks/useMerchantNavigation";
import type { TCollectableCard } from "features/CollectionCardsList/types";

export default function Contents() {
    const { id } = useParams<{ id: string }>();
    const { id: merchantId } = useMerchantNavigation();

    const { data } = useData<ApiResponse<TCollectableCard>, TCollectableCard>({
        path: `/admin/merchants/${merchantId}/collectible-cards/${id}`,
        queryKey: [QueryKeys.MERCHANT_COLLECTABLE_CARD, { cardId: Number(id) }, { merchantId: Number(merchantId) }],
        select: (data) => data.data
    });

    return (
        <Fragment>
            <Card radius={8} shadow="0px 1px 4px rgba(0, 0, 0, 0.05)">
                <Card.Section inheritPadding py={16} sx={{ borderBottom: "1px solid #f8f9fa" }}>
                    <Group position="apart">
                        <Text size={16} weight={600} lh={1.5}>
                            Linked Contents
                        </Text>
                        <Group></Group>
                    </Group>
                </Card.Section>
                <Card.Section inheritPadding p={0} pb={2}>
                    <Stack spacing={0}>
                        {data?.ContentCollectibleCards?.map((item: any) => (
                            <OccasionItem key={item.id}
                                name={item.Content.name}
                                cardId={item.collectibleCardId}
                                occasionId={item.contentId}
                                frequencyOccurrence={item.frequencyOccurrence}
                                maxAttempts={item.maxAttempts}
                                type="Contents"
                            />
                        ))}

                        {data?.ContentCollectibleCards?.length === 0 && (
                            <Group p={24} align="center" position="center">
                                <Text size={"14px"} weight={400} color="gray.6" lh={1.5}>
                                    Linked Contents list is empty
                                </Text>
                            </Group>
                        )}
                    </Stack>
                </Card.Section>
            </Card>
        </Fragment>
    );
}