import { useParams } from 'react-router-dom';
import {
	Input,
	Group,
	Image,
	Stack,
	Text,
	TextInput,
	Card,
	Textarea,
} from '@mantine/core';
import QueryKeys from 'constants/query-keys';
import Tabs from 'components/Tabs';
import useData from 'hooks/useData';
import { useMerchantNavigation } from 'hooks/useMerchantNavigation';
import type { TCollectableCard } from 'features/CollectionCardsList/types';

const tabs = [
	{ label: "Campaigns", value: "campaigns" },
	{ label: "Contents", value: "contents" },
	// { label: "Games", value: "games" }
]

const inputStyles = {
	label: {
		marginBottom: 12,
	},
	input: {
		background: '#FCFDFE',
		border: '1px solid #F1F3F5',
		borderRadius: '4px',
		"::placeholder": {
			color: '#9FA2B4'
		}
	}
}

// Component
export default function CollectionCardEdit() {
	const { id } = useParams();

	const { id: merchantId } = useMerchantNavigation();

	const { data } = useData<ApiResponse<TCollectableCard>, TCollectableCard>({
		path: `/admin/merchants/${merchantId}/collectible-cards/${id}`,
		queryKey: [QueryKeys.MERCHANT_COLLECTABLE_CARD, { cardId: Number(id) }],
		select: (data) => data.data
	});

	return (
		<Card radius={8} shadow="0px 1px 4px rgba(0, 0, 0, 0.05)">
			<Card.Section inheritPadding py={16} sx={{ borderBottom: "1px solid #f8f9fa" }}>
				<Group position="apart">
					<Text size={16} weight={600} lh={1.5}>
						Overview
					</Text>
					<Group spacing={8}></Group>
				</Group>
			</Card.Section>
			<Card.Section inheritPadding p={24}>
				<Group spacing={24} align='normal'>
					<Stack spacing={24} sx={{ flex: 1 }}>
						<TextInput
							value={data?.name}
							readOnly
							label="Name"
							placeholder="Enter card name"
							required
							styles={inputStyles}
						/>

						<Textarea
							value={data?.description}
							readOnly
							label='Description'
							placeholder='Enter card description'
							required
							styles={inputStyles}
						/>
					</Stack>
					<Stack spacing={24} h={"100%"} sx={{ flex: 1 }}>
						<Input.Wrapper label={'Preview'} style={{ flex: 1 }}>
							<Image
								styles={{ image: { objectFit: 'contain' } }}
								fit={'contain'}
								height={300}
								src={data?.imagURL}
								alt='preview picture'
								withPlaceholder
								radius={'lg'}
							/>
						</Input.Wrapper>
					</Stack>
				</Group>
			</Card.Section>
			<Card.Section inheritPadding px={12} sx={{ borderTop: "1px solid #f8f9fa" }}>
				<Tabs queryKey="tab" defaultValue={tabs[0].value} tabs={tabs} />
			</Card.Section>
		</Card>
	)
}