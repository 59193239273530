export const Preferences = {
    1: "Automotive",
    2: "Food and Bevarage",
    3: "Fashion and Apparel",
    4: "Electronics",
    5: "Home and Furniture",
    6: "Sports and Outdoor",
    7: "Health and Beauty",
    8: "Toys and Games",
    9: "Books and Literature",
    10: "Home Improvement",
    11: "Travel and Leisure",
    12: "Pet Supplies",
    13: "Music and Entertainment",
    14: "Jewelry and Accessories",
    15: "Baby and Kids Products",
    16: "Art and Craft Supplies",
    17: "Office Supplies",
} as const;

export const AgeGroups ={
    1: "16-18",
    2: "19-21",
    3: "22-24",
    4: "25-27",
    5: "28-30",
    6: "31-33",
    7: "34-36",
    8: "37-39",
    9: "40-42",
    10: "43-45",
    11: "46-48",
    12: "49-51",
    13: "52-54",
    14: "55-57",
    15: "58-60",
    16: "61+",
} as const;