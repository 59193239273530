import styled from "@emotion/styled";
import { Loader, Text } from "@mantine/core";
import { Input } from "components/Input";
import TextField from "components/TextField";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useRegister } from "../Auth/hooks/useRegister";
import { useState } from "react";

// Validation Schema
const schema = yup
  .object({
    companyName: yup.string().required("Required").trim(),

    email: yup
      .string()
      .required("Required")
      .email("Email must be a valid")
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"а-яА-ЯёЁ]+(\.[^<>()\[\]\\.,;:\s@"а-яА-ЯёЁ]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/,
        "Email must be a valid"
      )
      .lowercase()
      .trim(),
  })
  .required();

// Error Codes
const ErrorCode = {
  35: [{ field: "email", message: "Email already registered" }],
};

// Component
export default function RegisterStartForm() {
  const { handleRegisterStart } = useRegister();
  const form = useForm({ resolver: yupResolver(schema) });
  const [isInviteSend, setIsInviteSend] = useState(false);

  const onSubmit = async (data) => {
    try {
      await handleRegisterStart(data);
      setIsInviteSend(true);
    } catch (e) {
      ErrorCode[e.response.data.code]?.forEach((item) => {
        form.setError(item.field, { message: item.message });
      });
    }
  };

  return (
    <Form onSubmit={form.handleSubmit(onSubmit)}>
      <Controller
        control={form.control}
        name="email"
        render={({ field, fieldState }) => (
          <TextField label="Email" errorText={fieldState.error?.message}>
            <Input
              placeholder="Email address"
              {...field}
              disabled={isInviteSend}
            />
          </TextField>
        )}
      />

      <Controller
        control={form.control}
        name="companyName"
        render={({ field, fieldState }) => (
          <TextField label="Company Name" errorText={fieldState.error?.message}>
            <Input
              placeholder="Enter your company name"
              {...field}
              disabled={isInviteSend}
            />
          </TextField>
        )}
      />
      {!isInviteSend ? (
        <Button color={"#e50039"} hoverColor={"#b1002c"} type="submit">
          {form.formState.isSubmitting ? (
            <Loader size="xs" sx={{ stroke: "white" }} />
          ) : (
            "Send Invite"
          )}
        </Button>
      ) : (
        <Text weight={500} color={"green"} align="center">
          Invite already sent
        </Text>
      )}
    </Form>
  );
}

// Styling
const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding-top: 24px;
`;

const Button = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #fff;

  padding: 12px 16px;
  margin-top: 8px;
  margin-bottom: 8px;
  border-radius: 4px;

  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  user-select: none;
  transition: background-color 0.15s ease-in-out;

  ${({ color }) =>
    color &&
    `
        background-color: ${color};
    `}
  &:hover {
    ${({ hoverColor }) =>
      hoverColor &&
      `
            background-color: ${hoverColor};
        `}
  }
`;
