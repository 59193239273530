import styled from "@emotion/styled";
import CouponItem from "./components/CouponItem";
import { ICoupon } from "api/interfaces/coupon";

// Props
type ComponentProps = {
  coupons: ICoupon[]
}

// Component
export default function CouponsList({ coupons }: ComponentProps) {
  return (
    <Root>
      {coupons.map(coupon => (
        <CouponItem key={coupon.id} coupon={coupon} />
      ))}
    </Root>
  )
}

// Styling
const Root = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  background: #FFFFFF;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  overflow-y: hidden;
`


