import { useAuth } from "features/Auth/hooks/useAuth";
import { api } from "index";
import { useCallback, useMemo, useState } from "react";
import { useInfiniteQuery } from "react-query";

export const useMerchantAdmins = (id: number) => {
  const { isPlatformAdmin, isSuperMerchant } = useAuth();
  const [search, setSearch] = useState("");
  const { data, hasNextPage, fetchNextPage, ...rest } = useInfiniteQuery(
    ["MERCHANT_ADMINS", id, search],
    ({ pageParam = 0 }) =>
      api.admins.readAllMerchantAdmins(id, {
        search,
        skip: pageParam,
      }),
    {
      enabled: isPlatformAdmin || isSuperMerchant,
      getNextPageParam: (lastPage, allPages) => {
        if (allPages.length && 20 >= lastPage?.count) return;
        return allPages.length;
      },
    }
  );

  const admins = useMemo(() => {
    return data?.pages.map((page) => page.items).flat() || [];
  }, [data]);

  const onNextPage = useCallback(async () => {
    if (!hasNextPage) return;
    await fetchNextPage();
  }, [hasNextPage, fetchNextPage]);

  return { admins, ...rest, setSearch, search, onNextPage };
};
