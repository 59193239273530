import { useQuery } from "react-query";
import { api } from "index";
import { TextContent } from "api/interfaces/textContent";
import useMerchantSelectorStore from "features/MerchantSelector/hooks/useMerchantSelectorStore";

export function useTextContent(id?: TextContent["id"]) {
  const merchantSelector = useMerchantSelectorStore();

  const selectedId = merchantSelector.useStore((state) => state.selected);
  const {
    data: textContent,
    isError,
    isLoading,
  } = useQuery(
    ["TEXT_CONTENT", selectedId, id],
    () => api.textContents.readOne(id, Number(selectedId)),
    {
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
      enabled: !!id,
      retry: 0,
    }
  );

  return { textContent, isError, isLoading };
}
