import { Base } from './Base';
import qs from 'qs';
import {
	ApiResponse,
	ApiResponseList,
	ErrorResponse,
	OperationResponse,
	PaginationParams,
} from './interfaces/api';
import { 
    ICouponOrigin,
    ICouponResponse, 
    ICouponResponseAll,
} from './interfaces/coupon';

export default class Coupons extends Base {
	async create(merchantId: string | number, input: ICouponOrigin) {
		const response = await this.request.post<ApiResponse<ICouponOrigin>>(
			`${this.baseUrl}/admin/merchants/${merchantId}/coupons`,
			input
		);
		return response.data.data;
	}

    async getOne(merchantId: string | number, couponId: string | number) {
		const response = await this.request.get<ApiResponse<ICouponResponse["data"]>>(
			`${this.baseUrl}/admin/merchants/${merchantId}/coupons/${couponId}`
		);
		return response.data.data;
	}

	async readAll({ skip = 0, limit = 1000 }: PaginationParams, merchantId: string | number) {
		const query = qs.stringify({ skip, limit });
      
		const response = await this.request.get<ApiResponseList<ICouponResponseAll>>(
			`${this.baseUrl}/admin/merchants/${merchantId}/coupons?skip=1&limit=1?${query}`
		);
		return response.data.data;
	}

	async update(merchantId: string | number, couponId: string | number, input: ICouponOrigin) {
		const response = await this.request.patch<ApiResponse<ICouponOrigin>>(
			`${this.baseUrl}/admin/merchants/${merchantId}/coupons/${couponId}`,
			input
		);
		return response.data.data;
	}

	async delete(merchantId: string | number, couponId: string | number) {
		try {
			const response = await this.request.delete<OperationResponse>(
				`${this.baseUrl}/admin/merchants/${merchantId}/coupons/${couponId}`
			);
			return response.data.isSuccess;
		} catch (e: any) {
			const error: ErrorResponse = await e.response.data;

			return error;
		}
	}
}
