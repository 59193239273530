import {useQuery} from "react-query";
import {useMemo} from "react";
import {api} from "index";
import useMerchantSelectorStore from "features/MerchantSelector/hooks/useMerchantSelectorStore";

export default function useAdvertisementsList() {
    const merchantSelector = useMerchantSelectorStore();

    const selectedId = merchantSelector.useStore((state) => state.selected);

    const {data, ...options} = useQuery(
        ["ADVERTISEMENTS_LIST", selectedId],
        () => api.advertisements.readAll(),
        {
            staleTime: Infinity,
        }
    );

    const advertisements = useMemo(() => {
        return data?.items || [];
    }, [data]);

    return {advertisements, ...options};
}
