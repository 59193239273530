import { useQuery } from "react-query";
import { api } from "index";
import useMerchantSelectorStore from "features/MerchantSelector/hooks/useMerchantSelectorStore";

export function useCoupon(id: string | number) {
  
  const merchantSelector = useMerchantSelectorStore();

  const selectedId = merchantSelector.useStore((state) => state.selected);
  const {data: coupon, isError, isLoading} = useQuery({
    queryFn: async () => await api.coupons.getOne(selectedId, id),
    queryKey: ["COUPON", selectedId, id], 
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
    enabled: !!id,
    retry: 0,
  })

  return { coupon, isError, isLoading };
}
