import { Stack } from "@mantine/core";
import EventMainForm from "./components/EventMainForm";
import PaymentEventForm from "./components/PaymentEventForm";
import RandomRewardForm from "./components/RandomRewardForm";
import ScavengerHuntForm from "./components/ScavengerHuntForm";

export default function EventEdit() {
  return (
    <Stack spacing={"lg"}>
      <EventMainForm />
      <ScavengerHuntForm />
      <RandomRewardForm />
      <PaymentEventForm />
    </Stack>
  );
}
