import { LatLngBoundsExpression } from "leaflet";
import { useEffect } from "react";
import { useMap } from "react-leaflet";
import { useLocationStore } from "../hooks/useLocationStore";

// Component
export default function CurrentLocationObserver() {
  const { state: { current, marks } } = useLocationStore();
  const map = useMap();

  useEffect(() => {
    if (!current) return;
		
		const zoom = map.getBoundsZoom([
			[current.coords.bbox![0], current.coords.bbox![2]],
			[current.coords.bbox![1], current.coords.bbox![3]]
		]);

    map.setView({ lat: current.coords.lat, lng: current.coords.lng }, zoom, {
			animate: true,
		});
  }, [map, current]);

  useEffect(() => {
      try {
          console.log(marks)
          const bounds: LatLngBoundsExpression = marks.map((mark) => ([mark.coords.lat, mark.coords.lng]))
          console.log(bounds)
          map.fitBounds(bounds, { animate: true });
      } catch (e) {

      }

  }, [map, marks]);

  return null;
}
