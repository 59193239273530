import useMerchantSelectorStore from "features/MerchantSelector/hooks/useMerchantSelectorStore";
import {api} from "index";
import {useQuery} from "react-query";

export const useLocationAnalytics = (id?: number) => {
    const merchantSelector = useMerchantSelectorStore();
    const selected = merchantSelector.useStore((state) => state.selected);

    const { data, ...params } = useQuery(
        ["LOCATION_ANALYTIC", Number(selected), id],
        () => api.locations.getLocationAnalytics(id, Number(selected))
    );

    return {...params, analytics: data};
};
