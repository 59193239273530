import styled from "@emotion/styled";
import { Content } from "api/interfaces/content";
import ContentItem from "./components/ContentItem";

// Props
type ComponentProps = {
  contents: Content[]
}

// Component
export default function ContentList({ contents }: ComponentProps) {
  return (
    <Root>
      {contents.map(content => (
        <ContentItem key={content.id} content={content} />
      ))}
    </Root>
  )
}

// Styling
const Root = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  background: #FFFFFF;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  overflow-y: hidden;
`


