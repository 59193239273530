import { SettingsLayout } from "../../pages/platform/settings/SettingsLayout";
import { useEffect } from "react";
import { Button, Stack, Text } from "@mantine/core";
import { useUpdateConstants } from "./hooks/useFaqUpdate";
import { ConstantIds } from "../../api/interfaces/settings";
import { useServerConstants } from "../../hooks/useServerConstants";
import { Controller, useForm } from "react-hook-form";
import TextEditor from "./RichTextEditor";

export const FaqSettings = () => {
	const { FaqValue } = useServerConstants();
	const { handleUpdateConstants, submitting } = useUpdateConstants();

	const form = useForm({
		defaultValues: {
			textValue: "",
		},
	});

	useEffect(() => {
		if (!FaqValue) return;

		form.reset({
			textValue: FaqValue,
		});
	}, [FaqValue, form]);

	return (
		<SettingsLayout>
			<Controller
				name="textValue"
				control={form.control}
				render={({ field }) => (
					<Stack mt={16} style={{ gap: 16 }}>
						<Text size="lg" weight="bold">
							FAQ
						</Text>

						{/*RICH TEXT*/}
						<TextEditor value={field.value} onChange={field.onChange} />

						{/*SUBMIT*/}
						<Stack align={"flex-start"}>
							<Button
								loading={submitting}
								onClick={() =>
									handleUpdateConstants(ConstantIds.FAQ, field.value)
								}
							>
								Save
							</Button>
						</Stack>
					</Stack>
				)}
			/>
		</SettingsLayout>
	);
};
