import {
  ActionIcon,
  Button,
  createStyles,
  Group,
  Stack,
  Text,
} from "@mantine/core";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useModalStore } from "../hooks/useModalStore";
import { Icon } from "@iconify/react";
import { useMerchantNavigation } from "hooks/useMerchantNavigation";

export const VerificationModal = () => {
  const navigate = useNavigate();
  const { closeModal, state } = useModalStore();
  const { classes } = useStyles();
  const { route } = useMerchantNavigation();

  const handleRedirect = useCallback(async () => {
    navigate(`${route}/verification`);
    closeModal();
  }, [closeModal]);

  return (
    <Stack className={classes.root}>
      <Group position={"apart"} align={"center"}>
        <Group position={"left"}>
          <Text size={"md"} weight={"bold"}>
            Verification
          </Text>
        </Group>

        <Group position={"right"}>
          <ActionIcon onClick={closeModal} variant={"transparent"}>
            <Icon color={"red"} icon="ep:close" />
          </ActionIcon>
        </Group>
      </Group>

      <Stack>
        <Text
          align={"center"}
          dangerouslySetInnerHTML={{ __html: state.content! }}
        />

        <Group grow align={"center"}>
          <Button color={"red"} onClick={closeModal}>
            Close
          </Button>
          <Button onClick={handleRedirect}>Verify</Button>
        </Group>
      </Stack>
    </Stack>
  );
};

const useStyles = createStyles(() => ({
  root: {
    position: "relative",
    width: 350,
  },
}));
