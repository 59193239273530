import { useQuery } from "react-query";
import { useMemo } from "react";
import { api } from "index";
import useMerchantSelectorStore from "features/MerchantSelector/hooks/useMerchantSelectorStore";

export default function useEventList() {
  const merchantSelector = useMerchantSelectorStore();
  const selectedId = merchantSelector.useStore((state) => state.selected);

  const { data, ...options } = useQuery(
    ["EVENT_LIST", selectedId],
    () => api.events.readAll(Number(selectedId), {}),
    {
      staleTime: Infinity,
    }
  );

  const events = useMemo(() => {
    return data?.items || [];
  }, [data]);

  return { events, ...options };
}
