import { Base } from "./Base";
import { AllowedCity } from "./interfaces/allowedCity";
import {
  ApiResponse,
  ApiResponseList,
  ErrorResponse,
  OperationResponse,
  PaginationParams,
} from "./interfaces/api";
import qs from "qs";

export default class AllowedCities extends Base {
  async readAll({ skip = 0, limit = 1000 }: PaginationParams) {
    const query = qs.stringify({ skip, limit });
    const response = await this.request.get<ApiResponseList<AllowedCity>>(
      `${this.baseUrl}/admin/allowed-cities?${query}`
    );

    return response.data.data;
  }

  async create(data: Omit<AllowedCity, "id" | 'createdAt' | "updatedAt" >){
    const response = await this.request.post<ApiResponse<AllowedCity>>(`${this.baseUrl}/admin/allowed-cities`, {...data})
    return response.data.data
  }

  async remove(id: number) {
    try {
      const response = await this.request.delete<OperationResponse>(
        `${this.baseUrl}/admin/allowed-cities/${id}`
      );
      return response.data.isSuccess;
    } catch (e: any) {
      const error: ErrorResponse = await e.response.data;
      return error;
    }
  }
}
