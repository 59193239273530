import { useQuery } from "react-query";
import { api } from "index";
import { Location } from "api/interfaces/location";
import useMerchantSelectorStore from "features/MerchantSelector/hooks/useMerchantSelectorStore";

export function useLocation(id?: Location["id"]) {
  const merchantSelector = useMerchantSelectorStore();

  const selectedId = merchantSelector.useStore((state) => state.selected);
  const {
    data: location,
    isError,
    isLoading,
  } = useQuery(
    ["LOCATION", selectedId, id],
    () => api.locations.readOne(id, Number(selectedId)),
    {
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
      enabled: !!id,
    }
  );

  return { location, isError, isLoading };
}
