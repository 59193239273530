import styled from "@emotion/styled";
import { ActionIcon, Group, Text, Stack, Title } from "@mantine/core";
import { TQuest } from "../types";
import { Icon } from "@iconify/react";
import useMutationData from "hooks/useMutationData";
import { useNavigate } from "react-router-dom";
import { useMerchantNavigation } from "hooks/useMerchantNavigation";
import useMerchantSelectorStore from "features/MerchantSelector/hooks/useMerchantSelectorStore";
import { useModalStore } from "features/Modals/hooks/useModalStore";
import { showNotification } from "@mantine/notifications";

// Props
type ComponentProps = {
    quest: TQuest;
};

// Component
export default function QuestItem({ quest }: ComponentProps) {
    const navigate = useNavigate();
    const { route } = useMerchantNavigation();
    const merchantSelector = useMerchantSelectorStore();
    const merchantId = merchantSelector.useStore((state) => state.selected);
    const { setupModal, setModalStatus, closeModal } = useModalStore();

    const removeRequest = useMutationData({
        url: () => `/admin/merchants/${merchantId}/quests/${quest.id}`,
        method: "delete",
        queryKeys: {
            invalidate: [{ queryKey: ["QUEST_LIST"] }],
        },
        onSuccess: async () => {
            showNotification({
                autoClose: 3000,
                title: 'Success!',
                message: `Quest successfully removed!`,
                color: 'green',
            });
        }
    });

    const openModal = () => {
        setupModal({
            action: handleDelete,
            type: "delete",
            content:
                `Please confirm your action. <br/>Selected quest will be deleted!`,
        });
        setModalStatus(true);
    };

    const handleDelete = () => {
        removeRequest.mutate({});
        closeModal();
    };

    return (
        <Root position="apart" px={16} py={8}>
            <Stack spacing={0}>
                <Title order={5}>{quest.title}</Title>
                <Text
                    size="sm"
                    color={'dimmed'}>
                    {quest.description}
                </Text>
            </Stack>
            <Group position="right" spacing={8}>
                <ActionIcon
                    disabled={removeRequest.isLoading}
                    variant="subtle"
                    color="gray.5"
                    onClick={() => navigate(`${route}/quests/${quest.id}`)}
                    sx={(theme) => ({
                        background: "#FCFDFE",
                        border: "1px solid #F1F3F5",
                        borderRadius: "6px",
                        color: theme.colors.dark[2],
                        "& svg[data-action-icon-loader]": {
                            stroke: theme.colors.dark[2],
                        },
                    })}
                >
                    <Icon icon={"fluent:edit-16-regular"} height={14} />
                </ActionIcon>
                <ActionIcon
                    variant={"subtle"}
                    loading={removeRequest.isLoading}
                    onClick={openModal}
                    sx={(theme) => ({
                        background: "#FCFDFE",
                        border: "1px solid #F1F3F5",
                        borderRadius: "6px",
                        color: theme.colors.red[5],
                        "& svg[data-action-icon-loader]": {
                            stroke: theme.colors.red[5],
                        },
                    })}
                >
                    <Icon icon={"fluent:delete-16-regular"} height={14} />
                </ActionIcon>
            </Group>
        </Root>
    );
}

const Root = styled(Group)`
  &:not(:last-child) {
    border-bottom: 1px solid #f8f9fa;
  }
`;
