import { FC, useMemo } from "react";
import { useMerchantTransferHistory } from "../../../features/TransferHistory/hooks/useMerchantTransferHistory";
import { useParams } from "react-router-dom";
import { Button, Group, Stack, Text } from "@mantine/core";
import { TransferList } from "features/TransferHistory/components/TransfersList";
import { TRANSFER_TYPES } from "features/TransferHistory/constants";
import ContentLoader from "components/ContentLoader";

export const TransferHistoryPage: FC = () => {
  const { merchantId } = useParams();
  const { transfers, transferTypeId, setTransferTypeId, isLoading } =
    useMerchantTransferHistory(Number(merchantId));
  const controlButtons = useMemo(() => {
    return TRANSFER_TYPES.map((v, i) => (
      <Button
        disabled={transferTypeId === v.id}
        onClick={() => setTransferTypeId(v.id)}
        key={i}
      >
        {v.name}
      </Button>
    ));
  }, [TRANSFER_TYPES, transferTypeId]);
  return (
    <Stack p={20}>
      <Group position="left">
        <Text size="xl" weight="bold">
          Transfer History
        </Text>
      </Group>
      <Group spacing={10}>{controlButtons}</Group>
      {!isLoading ? <TransferList transfers={transfers} /> : <ContentLoader />}
    </Stack>
  );
};
