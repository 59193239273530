import styled from "@emotion/styled";
import { Button, Group, Text, TextInput } from "@mantine/core";
import { css } from "@emotion/react";
import { useSubscription } from "../hooks/useSubscription";
import { useSubscriptionCancel } from "features/Subscriptions/hooks/useSubscriptionCancel";
import { useSubscriptionResume } from "features/Subscriptions/hooks/useSubscriptionResume";
import {useMerchantDetails} from "../../MerchantProfile/hooks/useMerchantDetails";

export const SubscribeInformation = () => {
  const { merchant } = useMerchantDetails()
  const { expireTime, isSubscribeExpired } = useSubscription();
  const { cancelSubscribe } = useSubscriptionCancel();
  const { resumeSubscribe } = useSubscriptionResume();

  return (
    <>
      {!isSubscribeExpired ? (
        <Root>
          <Group>
            <TextField
              label="Subscription type"
              placeholder="Standard"
              variant="filled"
              disabled
              classNames={{ input: "input-field" }}
            />

            <TextField
              label="Status"
              placeholder={merchant?.stripeSubscriptionLastStatus}
              variant="filled"
              disabled
              classNames={{ input: "input-field" }}
            />
          </Group>

          <Group>
            <TextField
              label="Price"
              placeholder="100$"
              variant="filled"
              disabled
              classNames={{ input: "input-field" }}
            />

            <TextField
              label="Expire at"
              placeholder={expireTime}
              variant="filled"
              disabled
              classNames={{ input: "input-field" }}
            />
          </Group>
          <Group align={"center"}>
            {!merchant?.stripeIsSubscriptionCanceled && (
              <Button onClick={() => cancelSubscribe()} color={"red"}>
                Cancel subscription
              </Button>
            )}
            {merchant?.stripeIsSubscriptionCanceled && (
              <>
                <Text weight={500} color={"red"}>
                  Already canceled
                </Text>
                <Button
                  onClick={() => resumeSubscribe()}
                  color="green"
                >
                  Resume subscription
                </Button>
              </>
            )}
          </Group>
        </Root>
      ) : (
        <Text weight={500} color={"red"}>
          You have no active subscriptions
        </Text>
      )}
    </>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const InputCSS = css`
  display: flex;
  font-size: 14px;
  font-weight: 400;
  background: #fcfdfe;
  border: 1px solid #f0f1f7;
  color: #9fa2b4;
  line-height: 1.5;
  min-height: 42px;
  outline: none;
  padding: 10px 16px;
  width: 100%;
  transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out,
    color 0.2s ease-in-out;

  &:hover,
  &:focus {
    color: #252733;
    border-color: #0095e5;
  }

  &::placeholder {
    color: rgb(75, 80, 109, 0.5);
  }
`;

const TextField = styled(TextInput)`
  flex: 1;

  & .input-field {
    ${InputCSS};
  }
`;
