import styled from "@emotion/styled";
import { Icon } from "@iconify/react";
import {
    ActionIcon,
  Group,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { FC } from "react";

interface ListItemProps {
    reward: any;
    handleDelete: (id: string) => void;
}

export const RandomRewardItem: FC<ListItemProps> = ({ reward, handleDelete }) => {
  return (
    <Root grow position="apart">
      <Group position="apart">
            <Stack spacing={0}>
                <Title order={5}>{reward.rewardName}</Title>
                <Text color="gray" fz={14}>Reward Type: {reward.rewardType}</Text>
              </Stack>
            <ActionIcon
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.preventDefault();
                handleDelete(reward.id);
              }}
              variant="light"
              color={"red"}
              size={"lg"}
              radius={"md"}
              style={{ border: "1px solid #fbe7e7" }}
            >
                <Icon icon={"fluent:delete-24-filled"} />
            </ActionIcon>
      </Group>
    </Root>
  );
};

const Root = styled(Group)`
  flex-direction: row;
  overflow: auto;

  background-color: #ffffff;
  padding: 8px 16px;
  user-select: none;

  &:not(:last-child) {
    border-bottom: 1px solid #e9ecef;
  }

  & {
    transition-property: background-color;
    transition-duration: 250ms;
    transition-timing-function: ease-in-out;
  }

  &:hover {
    background-color: rgba(44, 62, 80, 0.05);
  }
`;