import { Button, Card, Group, Stack, Text } from "@mantine/core";
import ContentLoader from "components/ContentLoader";
import Modal from "features/Modal";
import { ModalTrigger } from "features/Modal/constants";
import { useModalStore } from "features/Modal/store/modal.store";
import { EditForm } from "features/PlatformTargetGroups/components/EditForm";
import ListItem from "features/PlatformTargetGroups/components/ListItem";
import usePlatformTargetGroups from "features/PlatformTargetGroups/hooks/useMerchantTargetGroups";
import React from "react";

// Games Page
export default function PlatformTargetGroup() {
    const { items, isLoading } = usePlatformTargetGroups()
    
    const openModal = useModalStore((state) => state.setOpen);
    
    const onOpenCreate = () => {
        openModal({ trigger: ModalTrigger.ADD_PLATFORM_TARGET_GROUP, title: "New Merchant Targeting" });
    };

    return (
        <React.Fragment>
        <Stack p={24} spacing={24}>
            <Card radius={8} shadow="0px 1px 4px rgba(0, 0, 0, 0.05)">
                <Card.Section inheritPadding py={16} sx={{ borderBottom: "1px solid #f8f9fa" }}>
                    <Group position="apart">
                        <Text size={16} weight={600} lh={1.5}>
                            Merchant Targeting
                        </Text>
                        <Group>
                            <Button type="submit" variant="filled" h={28} px={12} py={4} color="dark.8" radius={4} onClick={onOpenCreate}>
                                <Text size={"14px"} weight={500} tt={"uppercase"} lh={1.5}>
                                    ADD
                                </Text>
                            </Button>
                        </Group>
                    </Group>
                </Card.Section>
                <Card.Section inheritPadding p={0} pb={2}>
                    <Stack spacing={0}>
                        {isLoading && <ContentLoader />}

                        {items.length > 0 && items.map((item) => {
                            return <ListItem key={item.id} item={item} />
                        })}

                        {!isLoading && items.length === 0 && (
                            <Group p={24} align="center" position="center">
                                <Text size={"14px"} weight={400} color="gray.6" lh={1.5}>
                                    Merchant Targeting list is empty
                                </Text>
                            </Group>
                        )}
                    </Stack>
                </Card.Section>
            </Card>
        </Stack>

        <Modal triggers={[ModalTrigger.ADD_PLATFORM_TARGET_GROUP, ModalTrigger.EDIT_PLATFORM_TARGET_GROUP]}>
            <EditForm />
        </Modal>
        </React.Fragment>
    );
}
