import { Base } from "./Base";
import { ApiResponse, OperationResponse } from "./interfaces/api";
import {
  LoginInput,
  LoginResponse,
  RegisterEndInput,
  RegisterInput,
  RegisterInviteInput,
  RegisterResponse,
} from "./interfaces/auth";

export default class Auth extends Base {
  async login(input: LoginInput) {
    const response = await this.request.post<ApiResponse<LoginResponse>>(
      `${this.baseUrl}/public/login/admin`,
      input
    );
    return response.data.data;
  }

  async register(input: RegisterInput) {
    const response = await this.request.post<ApiResponse<RegisterResponse>>(
      `${this.baseUrl}/public/registration/merchant`,
      input
    );
    return response.data.data;
  }

  async sendRegisterInvite(input: RegisterInviteInput) {
    const response = await this.request.post<ApiResponse<OperationResponse>>(`${this.baseUrl}/public/registration/merchant/start`, input);
    return response.data.data;
  }
  async registerEnd(input: RegisterEndInput) {
    const response = await this.request.post<ApiResponse<OperationResponse>>(`${this.baseUrl}/public/registration/merchant/end`, input);
    return response.data.data;
  }
}
