import { Base } from "./Base";
import qs from "qs";
import { TextContent, TextContentInput } from "./interfaces/textContent";
import {
  ApiResponse,
  ApiResponseList,
  OperationResponse,
  PaginationParams,
} from "./interfaces/api";

export default class TextContents extends Base {
  async create(input: TextContentInput, merchantId: number) {
    const response = await this.request.post<ApiResponse<TextContent>>(
      `${this.baseUrl}/admin/merchants/${merchantId}/text-contents`,
      input
    );
    return response.data.data;
  }

  async readAll(
    merchantId: number,
    { skip = 0, limit = 1000 }: PaginationParams
  ) {
    const query = qs.stringify({ skip, limit });
    const response = await this.request.get<ApiResponseList<TextContent>>(
      `${this.baseUrl}/admin/merchants/${merchantId}/text-contents?${query}`
    );
    return response.data.data;
  }

  async readOne(id?: TextContent["id"], merchantId?: number) {
    const response = await this.request.get<ApiResponse<TextContent>>(
      `${this.baseUrl}/admin/merchants/${merchantId}/text-contents/${id}`
    );
    return response.data.data;
  }

  async update({ id, ...data }: TextContent, merchantId: number) {
    const response = await this.request.patch<ApiResponse<TextContent>>(
      `${this.baseUrl}/admin/merchants/${merchantId}/text-contents/${id}`,
      data
    );
    return response.data.data;
  }

  async remove(id?: TextContent["id"], merchantId?: number) {
    try {
      const response = await this.request.delete<OperationResponse>(
        `${this.baseUrl}/admin/merchants/${merchantId}/text-contents/${id}`
      );
      return response.data.isSuccess;
    } catch (e: any) {
      const error = await e.response.data;
      return error;
    }
  }
}
