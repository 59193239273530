import { useQuery } from "react-query";
import { api } from "index";

export const useAccount = () => {
  const { data, refetch, isLoading, isError, remove } = useQuery(["ACCOUNT"], () => api.account.me(), {retry: 0});

  return {
    data,
    refetch,
    isLoading,
    isError,
    remove,
  };
};
