export enum AdvertisementBtnTypes {
    LINK = "Link",
    LOCATION = "Location",
}

export interface Advertisement {
    id?: UniqueId,
    title: string,
    subtitle: string,
    image?: string,
    buttonName: string,
    buttonActionType: AdvertisementBtnTypes,
    buttonActionData: string,
    startDate: string,
    endDate: string,
    locationsIds: any[]
}
