import styled from "@emotion/styled";
import { Group, ActionIcon, Stack, Title } from "@mantine/core";
import { Icon } from "@iconify/react";
import { useMerchantNavigation } from "hooks/useMerchantNavigation";
import { Link } from "react-router-dom";
import { useFormContext } from "react-hook-form";

interface IData {
  label: string;
  value: string;
}

type ComponentProps = {
  index: number;
  data?: IData;
  remove: (data: any) => void;
};

export default function QuestActionItem({
  index,
  data,
  remove,
}: ComponentProps) {
  const { route } = useMerchantNavigation();

  const form = useFormContext();
  const actionConditionsId = form.getValues(
    `questConditions.${index}.conditionTypeId`
  );

  const checkCurrentRoute = (actionId: number) => {
    let route;
    if (actionId === 1) {
      route = "collectable-cards";
    }
    if (actionId === 2) {
      route = "contents";
    }
    if (actionId === 3) {
      route = "location-events";
    }

    return route;
  };

  return (
    <Root position="apart" px={16} py={8}>
      <Stack spacing={0}>
        <Title order={5}>
          {actionConditionsId === 3 ? "Visit location event: " : "Claim: "}
          {data?.label}
        </Title>
      </Stack>
      <Group position="right" spacing={8}>
        <ActionIcon
          component={Link}
          to={`${route}/${checkCurrentRoute(actionConditionsId)}/${
            data?.value
          }`}
          target={"_blank"}
          variant="light"
          size={"lg"}
          radius={"md"}
          style={{ border: "1px solid #eef0f3" }}
        >
          <Icon icon={"fluent:open-16-filled"} />
        </ActionIcon>
        <ActionIcon
          variant={"subtle"}
          onClick={() => remove(index)}
          sx={(theme) => ({
            background: "#FCFDFE",
            border: "1px solid #F1F3F5",
            borderRadius: "6px",
            color: theme.colors.red[5],
            "& svg[data-action-icon-loader]": {
              stroke: theme.colors.red[5],
            },
          })}
        >
          <Icon icon={"fluent:delete-16-regular"} height={16} />
        </ActionIcon>
      </Group>
    </Root>
  );
}

const Root = styled(Group)`
  &:not(:last-child) {
    border-bottom: 1px solid #f8f9fa;
  }
`;
