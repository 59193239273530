import { useAuthorizeWatcher } from "./hooks/useAuthWatcher";
import { useServerConstants } from "hooks/useServerConstants";

// Props
type ProviderProps = {
  publicRoutes: JSX.Element;
  privateRoutes: JSX.Element;
};

// Component
export default function AuthProvider({
  publicRoutes,
  privateRoutes,
}: ProviderProps) {
  const { isAuthorized, canRender } = useAuthorizeWatcher();

  useServerConstants();

  if (!canRender) return null;

  return isAuthorized ? privateRoutes : publicRoutes;
}
