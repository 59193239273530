import { api } from "index";
import { useMutation, useQueryClient } from "react-query";
import { useCallback } from "react";
import { LocationEventForm } from "../../../api/interfaces/locationEvents";
import { useMerchantNavigation } from "../../../hooks/useMerchantNavigation";
import { useNavigate } from "react-router-dom";
import { ErrorResponse } from "api/interfaces/api";
import { showNotification } from "@mantine/notifications";

export const useLocationEventCreate = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { id, route } = useMerchantNavigation();

  // Event Create Handler
  const { mutateAsync: create, ...options } = useMutation(
    (input: LocationEventForm) => api.locationEvents.create(input, Number(id))
  );

  // Handler for forms
  const handleCreate = useCallback(
    async (input: LocationEventForm) => {
      try {
        const locationEvent = await create(input);
        navigate(`${route}/location-events/${locationEvent.id}`);

        await Promise.all([
          queryClient.invalidateQueries(["LOCATION_EVENTS_LIST", id]),
          queryClient.invalidateQueries(["LOCATION_EVENT"]),
        ]);

        showNotification({
          autoClose: 3000,
          title: "Success!",
          message: "Location event successfully created!",
          color: "green",
        });
      } catch (e: any) {
        const error: ErrorResponse = e.response.data;
        showNotification({
          message: error.error ?? "Something went wrong.",
          title: "Error!",
          color: "red",
          autoClose: 5000,
        });
      }
    },
    [create, navigate]
  );

  return {
    handleCreate,
    ...options,
  };
};
