import { api } from "index";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { ImageContentInput } from "api/interfaces/imageContents";
import useMerchantSelectorStore from "features/MerchantSelector/hooks/useMerchantSelectorStore";
import { useMerchantNavigation } from "../../../hooks/useMerchantNavigation";
import { ErrorResponse } from "api/interfaces/api";
import { showNotification } from "@mantine/notifications";

export const useImageContentCreate = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { route } = useMerchantNavigation();

  const merchantSelector = useMerchantSelectorStore();

  const selectedId = merchantSelector.useStore((state) => state.selected);

  // Event Create Handler
  const { mutateAsync: create, ...options } = useMutation(
    (input: ImageContentInput) =>
      api.imageContents.create(input, Number(selectedId))
  );

  // Handler for forms
  const handleCreate = useCallback(
    async (input: ImageContentInput) => {
      try {
        const imageContent = await create(input);
        navigate(`${route}/image-contents/${imageContent.id}`);

        await Promise.all([
          queryClient.invalidateQueries(["IMAGE_CONTENT_LIST", selectedId]),
          queryClient.invalidateQueries(["IMAGE_CONTENT"]),
        ]);
        showNotification({
          autoClose: 3000,
          title: "Success!",
          message: "Image Content successfully created!",
          color: "green",
        });
      } catch (e: any) {
        const error: ErrorResponse = e.response.data;
        showNotification({
          message: error.error ?? "Something went wrong.",
          title: "Error!",
          color: "red",
          autoClose: 5000,
        });
      }
    },
    [create, navigate]
  );

  return {
    handleCreate,
    ...options,
  };
};
