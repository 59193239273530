import {useQuery} from "react-query";
import {api} from "../index";
import {useMemo} from "react";

export const useAdminConstants = () => {
    const { data } = useQuery(["CONSTANTS"], () => api.settings.getAdminConstants(), {
        staleTime: 0,
        refetchOnWindowFocus: true,
        refetchOnReconnect: true,
    });

    const VisitPrice = useMemo(() => {
        if(!data?.items?.length) return null
        return data.items[0].floatValue
    }, [data])

    return {VisitPrice}
}