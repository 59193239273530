import { api } from "index";
import { useMutation } from "react-query";
import { useQueryClient } from "react-query";
import { RegisterInviteInput } from "../../../../api/interfaces/auth";
import { useCallback } from "react";
import { showNotification } from "@mantine/notifications";
import { ErrorResponse } from "api/interfaces/api";

export const useMerchantAdminInvite = (merchantId: number) => {
  const queryClient = useQueryClient();
  const { mutateAsync: invite, ...params } = useMutation(
    (data: Omit<RegisterInviteInput, "companyName">) =>
      api.admins.inviteMerchantAdmin(data, merchantId)
  );

  const handleInvite = useCallback(
    async (data: Omit<RegisterInviteInput, "companyName">) => {
      try {
        await invite(data);
        await Promise.all([
          queryClient.invalidateQueries(["MERCHANT_ADMINS", merchantId]),
          queryClient.invalidateQueries(["MERCHANT_ADMINS", merchantId]),
        ]);
        showNotification({
          message: "The invitation has been sent.",
          title: "Success!",
          color: "green",
          autoClose: 3000,
        });
      } catch (e: any) {
        const error: ErrorResponse = e.response.data;
        let message: string | undefined;

        if(error.code === 35){
          message = 'Email is already in use.'
        }
        else if (typeof error.error === 'string') {
          message = error.error
        }

        showNotification({
          message: message ?? "Something went wrong.",
          title: "Error!",
          color: "red",
          autoClose: 5000,
        })

      }
    },
    [invite, queryClient]
  );

  return { handleInvite, ...params };
};
