import { useMemo } from "react";
import { Group, Card, Text, Button, Stack } from "@mantine/core";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import QuestActionItem from "./QuestActionItem";
import CreateActionsForm from "./CreateActionsForm";
import Modal from "features/Modal";
import { ModalTrigger } from "features/Modal/constants";
import { useModalStore } from "features/Modal/store/modal.store";
import { useQuestData } from "../hooks/useQuestData";
import { TQuestType } from "../types";

interface IData {
  label: string;
  value: string;
}

export const QuestActionsForm = () => {
  const form = useFormContext();
  const openModal = useModalStore((state) => state.setOpen);
  const { locationList, rewardsList, collectionCardsList } = useQuestData();

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "questConditions",
  });

  const questType = useWatch({ control: form.control, name: "questTypeId" });

  const actionsData = useMemo(() => {
    return fields.map((item: TQuestType | any) => {
      let list: IData[] | any[] = [];
      let comparisonField: number | null;
      console.log(item)

      if (item.conditionTypeId === 1) {
        list = collectionCardsList;
        comparisonField = Number(item.collectibleId);
      } else if (item.conditionTypeId === 2) {
        list = rewardsList;
        comparisonField = Number(item.contentId);
      } else if (item.conditionTypeId === 3) {
        list = locationList;
        comparisonField = Number(item.locationEventId);
      } else {
        comparisonField = null;
      }
      console.log(list)
      return list.find((i) => Number(i.value) === comparisonField) || [];
    });
  }, [fields, collectionCardsList, rewardsList, locationList]);

  return (
    <Card mt={24} radius={8} shadow="0px 1px 4px rgba(0, 0, 0, 0.05)">
      <Card.Section
        inheritPadding
        py={16}
        sx={{ borderBottom: "1px solid #f8f9fa" }}
      >
        <Group position="apart">
          <Text size={16} weight={600} lh={1.5}>
            Create action form
          </Text>
          <Group>
            <Button
              onClick={() =>
                openModal({
                  title: "Add Collectible Card",
                  trigger: ModalTrigger.ADD_QUEST_ACTION,
                })
              }
              type="button"
              variant="filled"
              h={28}
              px={12}
              py={4}
              color="dark.8"
              radius={4}
              disabled={!questType}
            >
              <Text size={"14px"} weight={500} tt={"uppercase"} lh={1.5}>
                Add action
              </Text>
            </Button>
          </Group>
        </Group>
      </Card.Section>
      <Card.Section>
        {!!actionsData &&
          actionsData.map((item, index) => (
            <QuestActionItem
              key={index}
              index={index}
              remove={remove}
              data={item}
            />
          ))}

        {actionsData.length === 0 && (
          <Stack p={24} align="center">
            {form?.formState?.errors?.questConditions && (
              <Text size={16} weight={600} lh={1.5} color="red">
                {form?.formState?.errors?.questConditions?.message?.toString()}
              </Text>
            )}
            <Text size={"14px"} weight={400} color="gray.6" lh={1.5}>
              Action list is empty
            </Text>
          </Stack>
        )}
      </Card.Section>

      <Modal triggers={[ModalTrigger.ADD_QUEST_ACTION]}>
        <CreateActionsForm create={append} />
      </Modal>
    </Card>
  );
};
