import qs from "qs";
import { Base } from "./Base";
import { AdminInput } from "./interfaces/admin";
import {
  ApiResponse,
  ApiResponseList,
  OperationResponse,
  PaginationParams,
  SearchParams,
} from "./interfaces/api";
import { IAccount } from "./interfaces/account";
import { RegisterInviteInput } from "./interfaces/auth";
import { SuspendAccountForm } from "api/interfaces/account";

export default class Admins extends Base {
  async create(input: AdminInput) {
    const response = await this.request.post<ApiResponse<IAccount>>(
      `${this.baseUrl}/admin/admins`,
      input
    );
    return response.data.data;
  }

  async readAll({ skip = 0, limit = 1000 }: PaginationParams) {
    const query = qs.stringify({ skip, limit });
    const response = await this.request.get<ApiResponseList<IAccount>>(
      `${this.baseUrl}/admin/admins?${query}`
    );
    return response.data.data;
  }

  async readOne(id?: IAccount["id"]) {
    const response = await this.request.get<ApiResponse<IAccount>>(
      `${this.baseUrl}/admin/admins/${id}`
    );
    return response.data.data;
  }

  async update({ id, ...data }: IAccount) {
    const response = await this.request.patch<ApiResponse<IAccount>>(
      `${this.baseUrl}/admin/admins/${id}`,
      data
    );
    return response.data.data;
  }

  async remove(id?: IAccount["id"]) {
    const response = await this.request.delete<OperationResponse>(
      `${this.baseUrl}/admin/admins/${id}`
    );
    return response.data.isSuccess;
  }

  async readAllMerchantAdmins(
    id: number,
    { skip = 0, limit = 1000, search = "" }: SearchParams
  ) {
    const query = qs.stringify({ skip, limit, search });
    const response = await this.request.get<ApiResponseList<IAccount>>(
      `${this.baseUrl}/admin/merchants/${id}/admins?${query}`
    );
    return response.data.data;
  }

  async readAllPlatformAdmins({
    skip = 0,
    limit = 1000,
    search = "",
  }: SearchParams) {
    const query = qs.stringify({ skip, limit, search });

    const response = await this.request.get<ApiResponseList<IAccount>>(
      `${this.baseUrl}/admin/admins?${query}`
    );

    return response.data.data;
  }

  async inviteAdmin(input: Omit<RegisterInviteInput, "companyName">) {
    const response = await this.request.post<ApiResponse<OperationResponse>>(
      `${this.baseUrl}/admin/admins`,
      input
    );

    return response.data.data;
  }

  async deletePlatformAdmin(id: number) {
    const response = await this.request.delete<ApiResponse<OperationResponse>>(
      `${this.baseUrl}/admin/admins/${id}`
    );

    return response.data.data;
  }

  async inviteMerchantAdmin(
    input: Omit<RegisterInviteInput, "companyName">,
    merchantId: number
  ) {
    const response = await this.request.post<ApiResponse<OperationResponse>>(
      `${this.baseUrl}/admin/merchants/${merchantId}/admins`,
      input
    );

    return response.data.data;
  }

  async deleteMerchantAdmin(merchantId: number, id: number) {
    const resposne = await this.request.delete<ApiResponse<OperationResponse>>(
      `${this.baseUrl}/admin/merchants/${merchantId}/admins/${id}`
    );

    return resposne.data.data;
  }

  async resetPlatformAdminPassword(id?: number) {
    if (!id) return;

    const response = await this.request.post<ApiResponse<OperationResponse>>(
      `${this.baseUrl}/admin/admins/${id}/reset`
    );

    return response.data.data;
  }

  async updatePlatformAdmin(data: SuspendAccountForm, id?: number) {
    const response = await this.request.patch<ApiResponse<IAccount>>(
      `${this.baseUrl}/admin/admins/${id}`,
      data
    );

    return response.data.data;
  }

  async resetMerchantAdminPassword(merchantId?: number, id?: number) {
    const response = await this.request.post<ApiResponse<OperationResponse>>(
      `${this.baseUrl}/admin/merchants/${merchantId}/admins/${id}/reset`
    );

    return response.data.data;
  }

  async updateMerchantAdmin(
    data: SuspendAccountForm,
    merchantId: number,
    id?: number
  ) {
    const response = await this.request.patch<ApiResponse<IAccount>>(
      `${this.baseUrl}/admin/merchants/${merchantId}/admins/${id}`,
      data
    );

    return response.data.data;
  }
}
