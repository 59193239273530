import { api } from "index";
import { useQuery } from "react-query";
import {useParams} from "react-router-dom";

export const useUserDetails = () => {
  const { id } = useParams();
  const { data, ...rest } = useQuery(["USER", Number(id)], () => api.users.readOne(Number(id)));

  return { user: data, ...rest };
};
