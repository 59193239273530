import { api } from "index";
import { useQuery } from "react-query";

export const useAppConfigConstants = () => {
  const { data, isLoading } = useQuery(
    ["ADMIN_CONSTANTS_LIST"],
    () => api.adminConstants.readAll(),
    {
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
      retry: 0,
    }
  );

  return { data, isLoading };
};
