import { Icon } from "@iconify/react";
import { Group, Text, createStyles } from "@mantine/core";
import { Link } from "react-router-dom";

type PageToolbarProps = {
    label: string;
    backLink: string;
    children?: React.ReactNode;
}

export default function PageToolbar({ label, backLink, children }: PageToolbarProps) {
  const { classes } = useStyles();

    return (
        <Group position="apart" spacing={24}>
            <Group position="left">
                <Link className={classes.link} to={backLink}>
                    <span className={"icon"}>
                        <Icon icon="eva:arrow-back-outline" />
                    </span>
                    <Text className="title" lh={1.5}>
                        {label}
                    </Text>
                </Link>
            </Group>
            <Group position="right">
                {children}
            </Group>
        </Group>
    )
}

const useStyles = createStyles((theme) => ({
  link: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    color: theme.colors.gray[5],

    '&': {
      transitionProperty: 'color',
      transitionDuration: '250ms',
      transitionTimingFunction: 'ease',
    },

    '&:hover': {
      color: theme.colors.dark[7],
    },

    '& .icon': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 20,
      marginRight: 6,
    },

    '& .title': {
      fontSize: 16,
      fontWeight: 500,
    },
  }

}))