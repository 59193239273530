import { api } from "index";
import { useMutation, useQueryClient } from "react-query";
import { useCallback } from "react";
import { EventWorkDays } from "api/interfaces/events";
import { useMerchantNavigation } from "../../../hooks/useMerchantNavigation";
import { showNotification } from "@mantine/notifications";
import { ErrorResponse } from "api/interfaces/api";

export const useEventCreateWorkDays = () => {
    const queryClient = useQueryClient();
    const { id: merchantId } = useMerchantNavigation();

    // Event Create Handler
    const { mutateAsync: create, ...options } = useMutation((input: EventWorkDays) =>
        api.events.createWorkDays(input)
    );

    // Handler for forms
    const handleCreateWorkDays = useCallback(
        async (input: EventWorkDays) => {
            try {
                await create(input);

                await Promise.all([
                    queryClient.invalidateQueries(["EVENT_LIST", merchantId]),
                    queryClient.invalidateQueries(["EVENT"]),
                    queryClient.invalidateQueries(["EVENT_DAYS_OF_WEEK"]),
                ]);
                showNotification({
                    autoClose: 3000,
                    title: "Success!",
                    message: "Event working days added successfully",
                    color: "green",
                });
            } catch (e: any) {
                const error: ErrorResponse = e.response.data;
                showNotification({
                    message: error.error ?? "Something went wrong.",
                    title: "Error!",
                    color: "red",
                    autoClose: 5000,
                });
            }
        },
        [create]
    );

    return {
        handleCreateWorkDays,
        ...options,
    };
};
