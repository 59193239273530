import styled from "@emotion/styled";
import {Loader} from "@mantine/core";
import {Input} from "components/Input";
import TextField from "components/TextField";
import {useForm, Controller} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {useRegister} from "../Auth/hooks/useRegister";
import { PasswordField } from 'components/PasswordInput';

// Validation Schema
const schema = yup
    .object({
        password: yup.string().matches(/^[A-Za-z0-9]*$/, "The password must contain only latin characters and digits").required("Required"),
    })
    .required();

// Error Codes
const ErrorCode = {
    35: [{field: "password", message: "Email already registered"}],
};

// Component
export default function RegisterEndForm({token, companyName}) {
    const {handleRegisterEnd} = useRegister();
    const form = useForm({resolver: yupResolver(schema)});

    const onSubmit = async (data) => {
        try {
            const input = data;
            input.companyName = companyName;
            input.jwt = token;
            await handleRegisterEnd(data);
        } catch (e) {
            ErrorCode[e.response.data.code]?.forEach((item) => {
                form.setError(item.field, {message: item.message});
            });
        }
    };

    return (
        <Form onSubmit={form.handleSubmit(onSubmit)}>
            <Controller control={form.control} name="password" render={({field, fieldState}) => (
                <TextField label="Set Password" errorText={fieldState.error?.message}>
                    <PasswordField placeholder="Password" {...field} />
                </TextField>
            )}/>

            <Button color={"#e50039"} hoverColor={"#b1002c"} type="submit">
                {form.formState.isSubmitting
                    ? <Loader size="xs" sx={{stroke: "white"}}/>
                    : "Save password"
                }
            </Button>
        </Form>
    );
}

// Styling
const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding-top: 24px;
`;

const Button = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #fff;

  padding: 12px 16px;
  margin-top: 8px;
  margin-bottom: 8px;
  border-radius: 4px;

  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  user-select: none;
  transition: background-color 0.15s ease-in-out;

  ${({color}) =>
          color &&
          `
        background-color: ${color};
    `}
  &:hover {
    ${({hoverColor}) =>
            hoverColor &&
            `
            background-color: ${hoverColor};
        `}
  }
`;
