import { api } from '../utils/api';
import { UseQueryOptions, useQuery } from 'react-query';

export default function useData<T, TSelectReturn>({ queryKey, path, params = {}, select, onSuccess, enabled = true }: Pick<TPaginatedOptions<UseQueryOptions<T, unknown, TSelectReturn>['select']>, 'path' | 'queryKey' | 'params' | "select" | "onSuccess" | "enabled">) {
    const fetchData = async () => {
        const response = await api.get(`${path}`, { params })
        return response.data;
    }

    return useQuery<T, unknown, TSelectReturn>({
        queryKey: [...queryKey, { ...params }],
        queryFn: fetchData,
        staleTime: Infinity,
        select,
        onSuccess,
        enabled
    });

}
