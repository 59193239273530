import {useCallback} from "react";
import {api} from "index";
import tokenStorage from "helpers/tokenStorage";
import {useAuthStore} from "./useAuthStore";
import {useAuth} from "./useAuth";
import {LocalStorage} from "../../../helpers/localStorage";
import useMerchantSelectorStore from "../../MerchantSelector/hooks/useMerchantSelectorStore";

export function useLogin() {
    const {logIn} = useAuthStore();
    const {checkAuth} = useAuth();
    const {Select} = useMerchantSelectorStore()

    const handleLogin = useCallback(
        async (values) => {
            const response = await api.auth.login(values);

            console.log("Token", response.data);
            tokenStorage.setToken(response.token);

            const user = await checkAuth();
            console.log("[SIGN_IN]", response);

            Select(String(user.Merchant?.id))

            logIn(user);
        },
        [logIn, checkAuth]
    );

    return {
        handleLogin,
    };
}
