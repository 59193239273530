import { Group, Loader } from "@mantine/core";

// Component
export default function ContentLoader() {
	return (
		<Group align="center" position="center" p={24}>
			<Loader size={24} color="teal.7" />
		</Group>
	)
}

