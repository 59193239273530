import styled from "@emotion/styled";
import AdvertisementsItem from "./components/AdvertisementsItem";
import {Advertisement} from "../../api/interfaces/advertisements";

// Props
type ComponentProps = {
  advertisements: Advertisement[]
}

// Component
export default function AdvertisementsList({ advertisements }: ComponentProps) {
  return (
    <Root>
      {advertisements.map(content => (
        <AdvertisementsItem key={content.id} advertisements={content} />
      ))}
    </Root>
  )
}

// Styling
const Root = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  background: #FFFFFF;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  overflow-y: hidden;
`


