import { useAuth } from "features/Auth/hooks/useAuth";
import { api } from "index";
import { useMemo } from "react";
import { useQuery } from "react-query";

export function useAdmins() {
  const { isPlatformAdmin } = useAuth()
	const { data, refetch, ...options } = useQuery(["ADMIN_LIST"], () => api.admins.readAll({}), {
    staleTime: Infinity,
    enabled: isPlatformAdmin
	});

	const admins = useMemo(() => {
    return data?.items || [];
  }, [data]);

  return { admins, ...options }
}
