import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Group, NumberInput, Select, Stack, Text } from "@mantine/core";
import { TCollectableCard } from "features/CollectionCardsList/types";
import { useModalStore } from "features/Modal/store/modal.store";
import usePaginatedData from "hooks/usePaginatedData";
import { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import * as yup from 'yup';
import { useSharedCards } from "../../hooks/useSharedCards";
import QueryKeys from "constants/query-keys";

type FormState = {
	collectible_card_ids: number;
	maxAttempts: number;
	frequencyOccurrence: number;
};

type EditPayload = {
	card: TCollectableCard;
	maxAttempts: number;
	frequencyOccurrence: number;
};

export default function AddCardModal() {
	const { id, merchantId } = useParams<{ id: string, merchantId: string }>();

	const close = useModalStore(state => state.setClose);
	const payload = useModalStore(state => state.payload as EditPayload);

	const { addCard, addCardLoading, data, updateCard, updateCardLoading } = useSharedCards(merchantId!, id!);

	const { items: cards } = usePaginatedData<TCollectableCard>({
		path: `/admin/merchants/${merchantId}/collectible-cards`,
		defaultLimit: 1000,
		queryKey: [QueryKeys.MERCHANT_COLLECTABLE_CARDS, { merchantId: merchantId }],
	});

	// Form Implementation
	const form = useForm<FormState>({
		mode: 'onChange',
		resolver: yupResolver(yup.object({
			collectible_card_ids: yup.number().required(),
			maxAttempts: yup.number().required(),
			frequencyOccurrence: yup.number().required(),
		})),
		defaultValues: payload && {
			collectible_card_ids: payload.card.id,
			maxAttempts: payload.maxAttempts,
			frequencyOccurrence: payload.frequencyOccurrence,
		}
	});

	const availableCards = useMemo(() => {
		if (!data) return [];
		const ids = data.map((item: any) => item.CollectibleCard.id);
		return cards
			.filter(card => !ids.includes(card.id))
			.map(item => ({
				label: item.name,
				value: String(item.id),
			}));
	}, [cards]);

	const onShare = async () => {
		if (!id || !merchantId) return;
		const data = {
			cardId: Number(form.getValues().collectible_card_ids),
			maxAttempts: Number(form.getValues().maxAttempts),
			frequencyOccurrence: Number(form.getValues().frequencyOccurrence),
		};
		try {
			if (payload) {
				await updateCard({ gameId: id, merchantId: merchantId, cardSettings: data });
			} else {
				await addCard({ gameId: id, merchantId: merchantId, cardSettings: data });
			}
			close();
		} catch (e) {
			console.error(e);
		}
	};

	return (
		<form onSubmit={form.handleSubmit(onShare)}>
			<Stack px={24} pb={24} spacing={0}>
				<Stack spacing={8}>
					<Controller
						name='collectible_card_ids'
						control={form.control}
						render={({ field }) => (
							<Select {...field}
								readOnly={!!payload}
								data={availableCards}
								placeholder={payload ? payload.card.name : 'Select collectible cards'}
								nothingFound={'Not found'}
								variant={'filled'}
								clearable
								searchable
								value={String(field.value)}
								onChange={field.onChange}
								withinPortal
								clearButtonProps={{
									'aria-label': 'Clear input',
								}}
								styles={{
									value: {
										backgroundColor: '#F1F3F5',
										border: '1px solid #F1F3F5',
										borderRadius: '3px',
										color: '#868E96',
										fontSize: '12px',
										fontWeight: 400,
									},
									defaultValueRemove: {
										color: '#868E96',
										width: 18,
										height: 18,
										minHeight: 18,
										minWidth: 18,
									},
									label: {
										marginBottom: 12,
									},
									input: {
										background: '#FCFDFE',
										border: '1px solid #F1F3F5',
										borderRadius: '4px',
										paddingTop: 3,
										paddingBottom: 3,
										"::placeholder": {
											color: '#9FA2B4'
										}
									}
								} as any}
							/>
						)}
					/>

					<Controller
						name='maxAttempts'
						control={form.control}
						render={({ field }) => (
							<NumberInput {...field}
								label="Max attempts"
								hideControls
								value={field.value}
								onChange={field.onChange}
								withAsterisk
							/>
						)}

					/>

					<Controller
						name='frequencyOccurrence'
						control={form.control}
						render={({ field }) => (
							<NumberInput {...field}
								label="Frequency Occurrence"
								hideControls
								min={0}
								max={1}
								precision={2}
								value={field.value}
								onChange={field.onChange}
								withAsterisk
							/>
						)}
					/>
				</Stack>

				<Group grow align={'center'} mt={24}>
					<Button type='submit' loading={payload ? updateCardLoading : addCardLoading} variant="filled" h={36} px={12} py={4} color="teal.7" radius={4} >
						<Text size={"14px"} weight={500} tt={'uppercase'} lh={1.5}>SUBMIT</Text>
					</Button>
				</Group>
			</Stack>
		</form>
	)
}