import { Modal as ModalBase, Group, Text, ActionIcon } from "@mantine/core";
import { useModalStore } from "./store/modal.store";
import { useEffect } from "react";
import { Icon } from "@iconify/react";
import { ModalRootProps } from "@mantine/core/lib/Modal/ModalRoot/ModalRoot";

type ModalProps = {
    children: React.ReactNode[] | React.ReactNode;
    triggers: (string | number)[];
} & Omit<ModalRootProps, 'opened' | 'onClose' | 'onTransitionEnd'>;

function ClearState() {
    const open = useModalStore((state) => state.open);
    const reset = useModalStore((state) => state.reset);

    useEffect(() => {
        return () => {
            !open && reset();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    return null;
}

export default function Modal({ children, triggers, ...rest }: ModalProps) {
    const open = useModalStore((state) => state.open);
    const title = useModalStore((state) => state.title);
    const trigger = useModalStore((state) => state.trigger);
    const setClose = useModalStore((state) => state.setClose);

    const opened = open && triggers.includes(trigger);

    return (
        <ModalBase.Root title={"str"} keepMounted={false} opened={opened} centered={true} onClose={setClose} {...rest}>
            <ModalBase.Overlay opacity={0.75} />
            <ModalBase.Content radius={'sm'} miw={420}>
                <ClearState />
                <ModalBase.Header p={0}>
                    <Group position={'apart'} align={'center'} px={24} py={24} w={"100%"}>
                        <Group position={'left'} spacing={4}>
                            <Text size={'md'} weight={'bold'} color='dark.8'>
                                {title}
                            </Text>
                        </Group>

                        <Group position={'right'}>
                            <ActionIcon onClick={setClose} variant={'transparent'}>
                                <Icon icon={'carbon:close'} height={24} />
                            </ActionIcon>
                        </Group>
                    </Group>
                </ModalBase.Header>
                <ModalBase.Body p={0}>{children}</ModalBase.Body>
            </ModalBase.Content>
        </ModalBase.Root>
    );
}
