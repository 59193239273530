import { useCallback } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { api } from "index";
import { TextContent } from "api/interfaces/textContent";
import { useModalStore } from "../../Modals/hooks/useModalStore";
import { useMerchantNavigation } from "../../../hooks/useMerchantNavigation";
import { showNotification } from "@mantine/notifications";

export const useTextContentDelete = (id?: TextContent["id"]) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { closeModal, setupModal, setModalStatus } = useModalStore();
  const { route, id: merchantId } = useMerchantNavigation();

  // Remove Event
  const { mutateAsync, ...options } = useMutation(() =>
    api.textContents.remove(id, Number(merchantId))
  );

  const handleDelete = useCallback(async () => {
    const onDelete = async () => {
      closeModal();
      const res = await mutateAsync();
      if (typeof res === "object" && res.code === 100) {
        console.log("setup modal");
        setupModal({
          type: "error",
          content: `You can't delete Image Content. While it's in use.`,
        });
        setModalStatus(true);
      } else if (typeof res === "object") {
        showNotification({
          message: res.message ?? "Something went wrong.",
          title: "Error!",
          color: "red",
          autoClose: 5000,
        });
      } else {
        closeModal();
        showNotification({
          autoClose: 3000,
          title: "Success!",
          message: "Text Content successfully deleted!",
          color: "green",
        });
        navigate(`${route}/text-contents`);

        await Promise.all([
          queryClient.invalidateQueries(["TEXT_CONTENT_LIST", merchantId]),
          queryClient.invalidateQueries(["TEXT_CONTENT"]),
        ]);
      }
    };
    setupModal({
      action: onDelete,
      type: "delete",
      content:
        "Please confirm your action. <br/>Selected Text Content will be deleted!",
    });
    setModalStatus(true);
  }, [mutateAsync, navigate, id]);

  return {
    handleDelete,
    ...options,
  };
};
