import { Base } from "./Base";

export default class Games extends Base {

    async getSharedGames(merchantId: number | string) {
        const response = await this.request.get(
            `${this.baseUrl}/admin/merchants/${merchantId}/games`,
        );
        return response.data.data;
    }

    async getSharedMerchants(gameId: number | string) {
        const response = await this.request.get(
            `${this.baseUrl}/admin/merchants/games/${gameId}`,
        );
        return response.data.data;
    }

    async shareGame(gameId: number | string, merchantIds: number[]) {
        const response = await this.request.post(
            `${this.baseUrl}/admin/games/share/${gameId}`,
            { merchantIds: merchantIds }
        );
        return response.data.isSuccess;
    }

    async removeMerchant(gameId: number | string, merchantId: number | string) {
        const response = await this.request.delete(
            `${this.baseUrl}/admin/games/${gameId}/share/${merchantId}`,
        );
        return response.data.data;
    }

    async getGameDetail(gameId: number | string, merchantId: number | string) {
        const response = await this.request.get(
            `${this.baseUrl}/admin/merchants/${merchantId}/games/${gameId}`,
        );
       
        return response.data.data;
    }

    async getCards(merchantId: string | number, gameId: string | number) {
        const response = await this.request.get(`${this.baseUrl}/admin/merchants/${merchantId}/games/${gameId}/collectible-cards`);

        return response.data.data;
    }

    async addCard(merchantId: string | number, gameId: string | number, cardSettings: any) {
        const response = await this.request.post(`${this.baseUrl}/admin/merchants/${merchantId}/games/${gameId}/collectible-cards`, cardSettings);

        return response.data.data;
    }

    async removeCard(merchantId: string | number, gameId: string | number, cardId: number | string) {
        const response = await this.request.delete(`${this.baseUrl}/admin/merchants/${merchantId}/games/${gameId}/collectible-cards/${cardId}`);

        return response.data.data;
    }

    async updateCardSettings(merchantId: string | number, gameId: string | number, cardSettings: any) {
        const response = await this.request.patch(`${this.baseUrl}/admin/merchants/${merchantId}/games/${gameId}/collectible-cards`, cardSettings);

        return response.data.data;
    }

    async getEvents(merchantId: string | number, gameId: string | number) {
        const response = await this.request.get(`${this.baseUrl}/admin/merchants/${merchantId}/games/${gameId}/location-events`);

        return response.data.data;
    }

    async addEvent(merchantId: string | number, gameId: string | number, cardSettings: any) {
        const response = await this.request.post(`${this.baseUrl}/admin/merchants/${merchantId}/games/${gameId}/location-events`, cardSettings);

        return response.data.data;
    }

    async removeEvent(merchantId: string | number, gameId: string | number, locationEventId: number | string) {
        const response = await this.request.delete(`${this.baseUrl}/admin/merchants/${merchantId}/games/${gameId}/location-events/${locationEventId}`);
        
        return response.data.data;
    }

    async updateEventSettings(merchantId: string | number, gameId: string | number, cardSettings: any) {
        const response = await this.request.patch(`${this.baseUrl}/admin/merchants/${merchantId}/games/${gameId}/location-events`, cardSettings);
        
        return response.data.data;
    }

}
