import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

type TMerchnantDay = {
    dayId: number,
    startTime: string,
    endTime: string,
};

type TMerchantDates = {
    merchantId: string | number | undefined,
    workingDays: TMerchnantDay[];
};

interface IWorkingDaysStore {
    workingDays: TMerchantDates[],
    merchantDates: TMerchnantDay[],
    saveDate: () => void,
    setDate: (merchantId: string | number | undefined) => void,
    addNewDate: (dayId: string | number | undefined, startTime: string, endTime: string) => void;
    removeDate: (dayId: string | number | undefined) => void,
};

const init = {
    workingDays: localStorage.getItem('mechantWorkingDays') ? JSON.parse(localStorage.getItem('mechantWorkingDays')!) : [],
};

export const useMerchantWorkingDays = create<IWorkingDaysStore>()(
    devtools(
        (set, get) => ({
            ...init,
            merchantDates: [],
            saveDate: () => {
                const merchantId = localStorage.getItem("merchant-selector");
                if (!merchantId) return;
                const filteredDates = get().workingDays.filter((merchantDate: TMerchantDates) => merchantDate.merchantId != merchantId);
                set(get().workingDays = [...filteredDates, { merchantId: merchantId, workingDays: get().merchantDates }] as any);
                localStorage.setItem("mechantWorkingDays", JSON.stringify(get().workingDays) as any);
            },
            setDate: (merchantId: string | number | undefined) => {
                if (!merchantId) return;
                const merchantDates = get().workingDays.filter((data: any) => data.merchantId == merchantId);
                if (merchantDates.length) {
                    set(get().merchantDates = [...merchantDates[0]?.workingDays] as any);
                } else {
                    set(get().merchantDates = [] as any);
                }
            },
            addNewDate: (dayId: string | number | undefined, startTime: string, endTime: string) => {
                if (!dayId) return;
                const newDateData: TMerchnantDay = {
                    dayId: Number(dayId),
                    startTime: startTime,
                    endTime: endTime,
                };
                set(get().merchantDates = [...get().merchantDates, newDateData] as any);
            },
            removeDate: (dayId: string | number | undefined) => {
                if (!dayId) return;
                const newDays = get().merchantDates.filter((day: TMerchnantDay) => day.dayId != dayId);
                set(get().merchantDates = [...newDays] as any);
            },
        })
    )
)