import { Button, Group, Stack, Text, Select, NumberInput } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import QueryKeys from "constants/query-keys";
import type { TCollectableCard } from "features/CollectionCardsList/types";
import { useModalStore } from "features/Modal/store/modal.store";
import { useMerchantNavigation } from "hooks/useMerchantNavigation";
import usePaginatedData from "hooks/usePaginatedData";
import { useMemo, useState } from "react";
import { useEventCards } from "features/EventEdit/store/event-cards.store";

export default function AddModalWithSettings() {
    const { id: merchantId } = useMerchantNavigation();
    const [selectedCard, setSelectedCard] = useState<any[] | null>(null);
    const [frequency, setFrequency] = useState<number | undefined>(undefined);
    const [maxAttempts, setMaxAttempts] = useState<number | undefined>(undefined);
    
    const addCard = useEventCards(state => state.addCard);
    const { items: allCards } = usePaginatedData<TCollectableCard>({
        path: `/admin/merchants/${merchantId}/collectible-cards`,
        defaultLimit: 1000,
        queryKey: [QueryKeys.MERCHANT_COLLECTABLE_CARDS],
    });

    const close = useModalStore(state => state.setClose);

    const availableItems = useMemo(() => {
        return allCards.map(item => ({
            label: item.name,
            value: String(item.id),
        }));
    }, [allCards]);


    const hanldeSelectCard = (value: any) => {
        const cardName = allCards.filter((card: any) => card.id == value)[0];
        setSelectedCard([value, cardName.name]);
    };

    const handleAddCard = () => {
        if (!selectedCard || !frequency || !maxAttempts) {
            showNotification({
                autoClose: 3000,
                title: "Warning!",
                message: "Please fill all card fields",
                color: "orange",
            });
            return;
        }
        const newCard = {
            id: selectedCard[0],
            name: selectedCard[1],
            frequencyOccurrence: frequency,
            maxAttempts: maxAttempts,
        }
        addCard(newCard);
        setSelectedCard(null);
        close();
    }

    return (
        <form>
            <Stack px={24} pb={24} spacing={0}>
                <Stack spacing={8}>
                    <Select
                        label="Card"
                        placeholder="Pick one"
                        searchable
                        nothingFound="No options"
                        withinPortal
                        withAsterisk
                        data={availableItems}
                        value={selectedCard ? selectedCard[0] : undefined}
                        onChange={hanldeSelectCard}
                    />

                    <NumberInput
                        label="Frequency Occurrence"
                        hideControls
                        min={0}
                        max={1}
                        precision={2}
                        value={frequency ? frequency : undefined}
                        onChange={(value: number) => setFrequency(value)}
                        withAsterisk
                    />

                    <NumberInput
                        label="Max attempts"
                        hideControls
                        value={maxAttempts ? maxAttempts : undefined}
                        onChange={(value: number) => setMaxAttempts(value)}
                        withAsterisk
                    />
                </Stack>
                <Group grow align={'center'} mt={24}>
                    <Button onClick={handleAddCard} variant="filled" h={36} px={12} py={4} color="teal.7" radius={4} >
                        <Text size={"14px"} weight={500} tt={'uppercase'} lh={1.5}>SUBMIT</Text>
                    </Button>
                </Group>
            </Stack>
        </form>
    )
}