import {ActionIcon, Button, createStyles, Group, Stack, Text} from "@mantine/core";
import {Icon} from '@iconify/react';
import {useModalStore} from "../hooks/useModalStore";

export const DeleteModal = () => {
    const {state, closeModal} = useModalStore()
    const {classes} = useStyles()

    return (
        <Stack className={classes.root}>
            <Group position={'apart'} align={'center'}>
                <Group position={"left"}>
                    <Text size={'md'} weight={'bold'}>
                        Delete confirmation
                    </Text>
                </Group>
                <Group position={'right'}>
                    <ActionIcon onClick={closeModal} variant={'transparent'}>
                        <Icon color={'red'} icon="ep:close"/>
                    </ActionIcon>
                </Group>
            </Group>

            <Stack>
                <Text align={"center"} dangerouslySetInnerHTML={{__html: state.content!}}/>

                <Button style={{height: 36}} loading={state.loading} onClick={state.action} variant={'filled'}
                        color={"primary"}>
                    Confirm
                </Button>
            </Stack>
        </Stack>
    )
}

const useStyles = createStyles(() => ({
    root: {
        position: 'relative',
        width: 350
    }
}))
