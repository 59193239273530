import { Base } from "./Base";
import QueryString from "qs"
import { MapPlace } from "./interfaces/openStreet";
import { LocationCoords } from "features/LocationsMap/store";

export default class OpenStreet extends Base {
  config = {
		baseURL: 'https://nominatim.openstreetmap.org',
		headers: {
			'accept-language': 'en-US;q=0.9,en;q=0.8'
		}
  };

  async searchByName(searchText?: string) {
		const params = {
			q: searchText,
			limit: 50,
			format: 'json',
			addressdetails: 1,
			polygon_geojson: 0
		}

		const qs = QueryString.stringify(params)
    
    const response = await this.request.get<MapPlace[]>(`/search?${qs}`, this.config);
    return response.data;
  }

	async searchByCoords(props?: LocationCoords) {
		if (!props) return

		const { lat, lng: lon } = props

		const params = {
			lat,
			lon,
			format: 'json',
			addressdetails: 1,
			polygon_geojson: 0
		}

		const qs = QueryString.stringify(params)
    
    const response = await this.request.get<MapPlace>(`/reverse?${qs}`, this.config);
		if (response.data.error) throw response.data.error

    return response.data;
	}
}
