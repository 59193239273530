import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

type TEventLimit = {
    eventId: number,
    rpuLimit: number | undefined,
    timeGaps: number | undefined,
};

type TGetLimit = Omit<TEventLimit, "eventId">;

interface IEventLimits {
    eventLimits: TEventLimit[],
    setLimit: (eventId: number, rpuLimit: number | undefined, timeGaps: number | undefined) => void,
    getLimit: (eventId: string | number | undefined) => TGetLimit,
};

const InitialState = {
    eventLimits: localStorage.getItem("eventLimits") ? JSON.parse(localStorage.getItem('eventLimits')!) : [],
};

export const useEventLimits = create<IEventLimits>()(
    devtools(
        (set, get) => ({
            ...InitialState,
            setLimit: (eventId: number, rpuLimit: number | undefined, timeGaps: number | undefined) => {
                const limit = get().eventLimits.filter((eventLimit: TEventLimit) => eventLimit.eventId == eventId);
                if (limit.length > 0) {
                    set(get().eventLimits.map((eventLimit: TEventLimit) => {
                        if (eventLimit.eventId == eventId) {
                            if (rpuLimit) eventLimit.rpuLimit = rpuLimit;
                            if (timeGaps) eventLimit.timeGaps = timeGaps;
                        }
                    }) as any);
                } else {
                    set(get().eventLimits = [...get().eventLimits, { eventId, rpuLimit, timeGaps }] as any);
                }
                localStorage.setItem("eventLimits", JSON.stringify(get().eventLimits));
            },
            getLimit: (eventId: string | number | undefined) => {
                if (!eventId) {
                    return {
                        rpuLimit: undefined,
                        timeGaps: undefined,
                    };
                }
                const limit = get().eventLimits.filter((eventLimit: TEventLimit) => eventLimit.eventId == eventId);
                return {
                    rpuLimit: limit[0]?.rpuLimit,
                    timeGaps: limit[0]?.timeGaps,
                };;
            }
        }),
    )
)