import { api } from "index";
import { useCallback } from "react";
import {useMutation, useQueryClient} from "react-query";
import {useMerchantDetails} from "../../MerchantProfile/hooks/useMerchantDetails";
import {useAccount} from "../../Auth/hooks/useAccount";
import {wait} from "../../../helpers/wait";

export const useSubscriptionResume = () => {
  const queryClient = useQueryClient()
  const { merchant } = useMerchantDetails();
  const { data: user } = useAccount()
  
  const { mutateAsync: resumeSubscription, ...options } = useMutation(() => api.merchants.resumeSubscribe(merchant?.id));

  const resumeSubscribe = useCallback(async () => {
    try {
      const res = await resumeSubscription();
      if (!res?.isSuccess) return
  
      await wait(500)
  
      const queries = [
        ["MERCHANT", merchant?.id],
      ]
  
      if (user?.Merchant?.id === merchant?.id) {
        queries.push(["ACCOUNT"])
      }
  
      await Promise.all(queries.map(keys => queryClient.invalidateQueries(keys)))
    } catch (e: any) {
      console.debug(e);
    }
  }, [resumeSubscription, merchant, user]);

  return { ...options, resumeSubscribe };
};
