import { SubscriptionModal } from "./components/SubscriptionModal";
import { useModalStore } from "./hooks/useModalStore";
import { DeleteModal } from "./components/DeleteModal";
import { Modal } from "@mantine/core";
import { ErrorModal } from "./components/ErrorModal";
import { VerificationModal } from "./components/VerificationModal";
import { FeedbackModal } from './components/FeedbackModal';

export const ModalContainer = () => {
  const { state, closeModal } = useModalStore();

  return (
    <Modal
      opened={state.status}
      onClose={closeModal}
      withCloseButton={false}
      overlayProps={{opacity: 0.5}}
      transitionProps={{ transition: "slide-down", duration: 400 }}
      closeOnClickOutside={false}
      padding={30}
      centered
      size={"auto"}
    >
      {state.type === "subscription" && <SubscriptionModal />}
      {state.type === "delete" && <DeleteModal />}
      {state.type === "error" && <ErrorModal />}
      {state.type === "verification" && <VerificationModal />}
      {state.type === "feedback" && <FeedbackModal />}
    </Modal>
  );
};
