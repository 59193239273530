import styled from "@emotion/styled";
import { Group, Stack, Text } from "@mantine/core";
import PasswordForm from "features/ProfileEdit/components/PasswordForm";

// Page
export default function ProfileUpdate() {
  return (
    <Stack>
      <Stack spacing={0} style={{ padding: 20 }}>
        <Group position="apart">
          <Group position="left">
            <Text size="xl" weight="bold">
              Manage Password
            </Text>
          </Group>
        </Group>

        <Card>
          <PasswordForm />
        </Card>
      </Stack>
    </Stack>
  );
}

// Styling
const Card = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  padding: 20px;
  overflow-y: hidden;
  gap: 20px;
  margin-top: 20px;
`;
