import { useCallback, useEffect } from "react";
import styled from "@emotion/styled";
import { Icon } from "@iconify/react";
import { Link, useParams } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { Button, Group, Stack, Text } from "@mantine/core";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation } from "features/LocationEdit/hooks/useLocation";
import { useLocationUpdate } from "features/LocationEdit/hooks/useLocationUpdate";
import { useLocationDelete } from "features/LocationEdit/hooks/useLocationDelete";
import LocationEdit from "features/LocationEdit";
import ContentLoader from "components/ContentLoader";
import { Country } from "api/interfaces/constants";
import { IAccount } from "api/interfaces/account";
import { useAdmins } from "features/Admins/useAdmins";
import { LocationInput } from "api/interfaces/location";
import useContentList from "features/ContentList/hooks/useContentList";
import { LocationAnalyticsDashboard } from "features/LocationEdit/LocationAnalyticsDashboard";
import {useMerchantNavigation} from "../../../hooks/useMerchantNavigation";
import { useLocationStore } from 'features/LocationsMap/hooks/useLocationStore';
import { useLocationCreate } from 'features/LocationEdit/hooks/useLocationCreate';

// Validation Schema
const schema = yup
  .object({
    name: yup
      .string()
      .max(200, `Name is too long. Maximum length is 200 chars.`)
      .lowercase()
      .trim()
      .required("Required"),
    longitude: yup.string().required("Required"),
    latitude: yup.string().required("Required"),
    radiusMeters: yup.number().max(300, `Maximum radius 300 m.`).required("Required"),
    countryId: yup.number().required("Required"),
    // adminId: yup.number().required("Required"),
    city: yup.string().required("Required").trim(),
    address: yup.string().required("Required").trim(),
  })
  .required();

// Form State
interface FormState {
  name: string;
  longitude: number;
  latitude: number;
  radiusMeters: number;
  isEnabled: boolean;
  countryId: Country["id"];
  adminId?: IAccount["id"];
  city: string;
  address: string;
}

// Page
export default function LocationUpdate() {
  const { id } = useParams();
  const { isLoading } = useAdmins();
  const { isLoading: contentLoading } = useContentList();
  const { location } = useLocation(Number(id));
  const { handleUpdate } = useLocationUpdate(Number(id));
  const { handleDelete } = useLocationDelete(Number(id));
  const { route } = useMerchantNavigation()
  const { handleCreate } = useLocationCreate();
  const { setLocationPossibility, setTempLocationData, setMapPath, state: { tempLocationData } } = useLocationStore();
  
  const form = useForm<FormState>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: tempLocationData?.title || "",
      latitude: tempLocationData?.coords.lat || 0,
      longitude: tempLocationData?.coords.lng || 0,
      radiusMeters: tempLocationData?.coords.radius || 0,
      isEnabled: true,
      countryId: tempLocationData?.id || 1,
      city: tempLocationData?.city || "",
      address: tempLocationData?.address || "",
    },
  });

  const onSubmit = useCallback(
    async (input: FormState) => {
      const data = {} as LocationInput;
      try {
        Object.assign(data, {
          name: input.name,
          latitude: input.latitude,
          longitude: input.longitude,
          radiusMeters: input.radiusMeters,
          isEnabled: input.isEnabled,
          countryId: input.countryId,
          city: input.city,
          address: input.address,
        });

        if(!location) {
          await handleCreate({ ...data }).then(() => form.reset());
          setTempLocationData({
            title: '',
            coords: {
              lat: 0,
              lng: 0,
            },
          });
          setMapPath(false);
          setLocationPossibility(false);
        } else {
          await handleUpdate({ ...data }).then(() => form.reset());
        }
        console.log("UPDATED QR CONTENT");
      } catch (e) {
        console.log(e);
      }
    },
    [location, handleUpdate, handleCreate, form, setTempLocationData]
  );

  // Update Form Data
  useEffect(() => {
    if (!location) return;

    form.reset({
      name: location.name,
      latitude: location.latitude,
      longitude: location.longitude,
      radiusMeters: location.radiusMeters,
      isEnabled: location.isEnabled,
      countryId: location.countryId,
      city: location.city,
      address: location.address,
    });
  }, [location, form]);

  if ((id && !location) || isLoading || contentLoading)
    return <ContentLoader />;

  return (
    <FormProvider {...form}>
      <Root onSubmit={form.handleSubmit(onSubmit)}>
        <Stack spacing={0}>
          <Group position="apart">
            <Group position="left">
              <BackLink to={`${route}/locations`}>
                <span className={"icon"}>
                  <Icon icon={"fluent:arrow-left-12-filled"} />
                </span>
                <Text size="xl" weight={"bold"}>
                  Back to Locations
                </Text>
              </BackLink>
            </Group>
            <Group position="right">
              {!!location && (
                <Button
                  leftIcon={<Icon icon={"fluent:delete-24-filled"} height={18} />}
                  onClick={handleDelete}
                  radius={"sm"}
                  color={"red"}
                  variant={"filled"}
                >
                  Delete
                </Button>
              )}
              {!location && (
                <Button
                  leftIcon={<Icon icon={"fluent:delete-24-filled"} height={18} />}
                  component={Link}
                  to={`${route}/locations`}
                  radius={"sm"}
                  color={"red"}
                  variant={"filled"}
                >
                  Cancel
                </Button>
              )}

              <Button
                type="submit"
                leftIcon={<Icon icon={"fluent:save-24-filled"} height={18} />}
                radius={"sm"}
                color={"teal"}
                variant={"filled"}
                loading={form.formState.isSubmitting}
              >
                {location ? "Update" : "Create"}
              </Button>
            </Group>
          </Group>

          <LocationEdit />

          {id && <LocationAnalyticsDashboard />}
        </Stack>
      </Root>
    </FormProvider>
  );
}

// Styling
const Root = styled.form`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const BackLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;

  color: #2c3e50;

  & {
    transition-property: color;
    transition-duration: 250ms;
    transition-timing-function: ease-in-out;
  }

  &:hover {
    color: #0083ce;
  }

  & .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    margin-right: 8px;
  }

  & .title {
    font-size: 20px;
    font-weight: bold;
  }
`;
