import { css } from "@emotion/react";
import styled from "@emotion/styled";
import {
  Avatar,
  Button,
  Center,
  Grid,
  Group,
  NumberInput,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import { User } from "api/interfaces/user";
import PhoneInput from "components/PhoneInput";
import { FC, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useAuth } from "../../Auth/hooks/useAuth";
import { useUserUpdate } from "../hooks/useUserUpdate";


interface UserProfileProps {
  user: User;
}
const schema = yup
    .object({
      balance: yup.number(),
    })
    .required();

export const UserProfile: FC<UserProfileProps> = ({ user }) => {
  const {isPlatformAdmin} = useAuth()
  const {handleUpdate} = useUserUpdate();

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      balance: 0,
    },
    mode: "onChange",
  });

  const onSubmit = form.handleSubmit(async (input: any) => {
    await handleUpdate(input);
  });

  useEffect(() => {
    if (!user) return;
    form.reset({
      balance: user.balance
    });
  }, [user, form]);

  return (
    <Root>
      <Grid p={10} justify="space-between">
        <Text size="xl" weight={"bold"}>
          User Profile
        </Text>
       
      </Grid>
      <Group grow position={"apart"}>
        <Stack>
          <TextField
            label="Name"
            variant="filled"
            disabled
            classNames={{ input: "input-field" }}
            value={[user.firstName, user.lastName].join(" ")}
          />
          <TextField
            label="Email"
            variant="filled"
            disabled
            classNames={{ input: "input-field" }}
            value={user.email}
          />
          <PhoneInput value={user.phone} disabled onChange={() => {}} />
            <Group align={'flex-end'} noWrap>
              <Controller
                  name="balance"
                  control={form.control}
                  render={({field}) => (
                      <NumberInput
                          style={{width:"100%"}}
                          label="Balance"
                          variant="filled"
                          hideControls
                          disabled={!isPlatformAdmin}
                          classNames={{ input: "input-field" }}
                          {...field}
                      />
                  )}
              />
              {isPlatformAdmin && <Button loading={form.formState.isSubmitting} onClick={onSubmit}>Update balance</Button>}
            </Group>
        </Stack>
        <Stack>
          <Center style={{ pointerEvents: "none", padding: 5 }}>
            <Stack spacing={0}>
              <Avatar
                radius={200}
                size={200}
                src={user.profilePicture}
                alt="preview picture"
              />
            </Stack>
          </Center>
        </Stack>
      </Group>
    </Root>
  );
};

const Root = styled(Stack)`
  margin-top: 20px;
  background: #ffffff;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  padding: 20px;
  overflow-y: hidden;
  gap: 20px;
`;

const InputCSS = css`
  display: flex;
  font-size: 14px;
  font-weight: 400;
  background: #fcfdfe;
  border: 1px solid #f0f1f7;
  color: #9fa2b4;
  line-height: 1.5;
  min-height: 42px;
  outline: none;
  padding: 10px 16px;
  width: 100%;
  transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out,
    color 0.2s ease-in-out;

  &:hover,
  &:focus {
    color: #252733;
    border-color: #0095e5;
  }

  &::placeholder {
    color: rgb(75, 80, 109, 0.5);
  }
`;

const TextField = styled(TextInput)`
  flex: 1;

  & .input-field {
    ${InputCSS};
  }
`;
