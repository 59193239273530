import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Group, NumberInput, Select, Stack, Text } from "@mantine/core";
import { useModalStore } from "features/Modal/store/modal.store";
import { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import * as yup from 'yup';
import { useShareLocationEvents } from "features/MerchantGameDetails/hooks/useSharedLocationEvents";
import { useQuery } from "react-query"; 
import { api } from "utils/api";

type FormState = {
	locationEventId: number;
	maxAttempts: number;
	frequencyOccurrence: number;
};

type LocationEventPayload = {
	id: number, 
	name: string,
};

type EditPayload = {
	locationEvent: LocationEventPayload;
	maxAttempts: number;
	frequencyOccurrence: number;
};

export default function AddLocationEventModal() {
	const { id, merchantId } = useParams<{ id: string, merchantId: string }>();

	const close = useModalStore(state => state.setClose);
	const payload = useModalStore(state => state.payload as EditPayload)
	
	const { addEvent, addEventLoading, data, updateEvent, updateEventLoading } = useShareLocationEvents(merchantId!, id!);

	const { data: eventsData } = useQuery({
		queryFn: async () => await api.get(`/admin/location-events/merchants/${merchantId}`),
		queryKey: [`LOCATION_EVENTS`, { merchantId: merchantId }]
	});

	// Form Implementation
	const form = useForm<FormState>({
		mode: 'onChange',
		resolver: yupResolver(yup.object({
			locationEventId: yup.number().required(),
			maxAttempts: yup.number().required(),
			frequencyOccurrence: yup.number().required(),
		})),
		defaultValues: payload && {
			locationEventId: payload.locationEvent.id,
			maxAttempts: payload.maxAttempts,
			frequencyOccurrence: payload.frequencyOccurrence,
		}
	});

	const availableCards = useMemo(() => {
		if (!data || !eventsData) return [];
		const ids = data.map((item: any) => item.LocationEvent.id);
		return eventsData.data.data
			.filter((event: LocationEventPayload) => !ids.includes(event.id))
			.map((item: LocationEventPayload) => ({
				label: item.name,
				value: String(item.id),
			}));
	}, [eventsData]);

	const onShare = async () => {
		if (!id || !merchantId) return;
		const data = {
			locationEventId: Number(form.getValues().locationEventId),
			maxAttempts: Number(form.getValues().maxAttempts),
			frequencyOccurrence: Number(form.getValues().frequencyOccurrence),
		};
		try {
			if (payload) {
				await updateEvent({ gameId: id, merchantId: merchantId, locationEventSettings: data });
			} else {
				await addEvent({ gameId: id, merchantId: merchantId, locationEventSettings: data });
			}
			close();
		} catch (e) {
			console.error(e);
		}
	};

	return (
		<form onSubmit={form.handleSubmit(onShare)}>
			<Stack px={24} pb={24} spacing={0}>
				<Stack spacing={8}>
					<Controller
						name='locationEventId'
						control={form.control}
						render={({ field }) => (
							<Select {...field}
								readOnly={!!payload}
								data={availableCards}
								placeholder={payload ? payload.locationEvent.name : 'Select location events'}
								nothingFound={'Not found'}
								variant={'filled'}
								clearable
								searchable
								value={String(field.value)}
								onChange={field.onChange}
								withinPortal
								clearButtonProps={{
									'aria-label': 'Clear input',
								}}
								styles={{
									value: {
										backgroundColor: '#F1F3F5',
										border: '1px solid #F1F3F5',
										borderRadius: '3px',
										color: '#868E96',
										fontSize: '12px',
										fontWeight: 400,
									},
									defaultValueRemove: {
										color: '#868E96',
										width: 18,
										height: 18,
										minHeight: 18,
										minWidth: 18,
									},
									label: {
										marginBottom: 12,
									},
									input: {
										background: '#FCFDFE',
										border: '1px solid #F1F3F5',
										borderRadius: '4px',
										paddingTop: 3,
										paddingBottom: 3,
										"::placeholder": {
											color: '#9FA2B4'
										}
									}
								} as any}
							/>
						)}
					/>

					<Controller
						name='maxAttempts'
						control={form.control}
						render={({ field }) => (
							<NumberInput {...field}
								label="Max attempts"
								hideControls
								value={field.value}
								onChange={field.onChange}
								withAsterisk
							/>
						)}

					/>

					<Controller
						name='frequencyOccurrence'
						control={form.control}
						render={({ field }) => (
							<NumberInput {...field}
								label="Frequency Occurrence"
								hideControls
								min={0}
								max={1}
								precision={2}
								value={field.value}
								onChange={field.onChange}
								withAsterisk
							/>
						)}
					/>
				</Stack>

				<Group grow align={'center'} mt={24}>
					<Button type='submit' loading={payload ? updateEventLoading : addEventLoading} variant="filled" h={36} px={12} py={4} color="teal.7" radius={4} >
						<Text size={"14px"} weight={500} tt={'uppercase'} lh={1.5}>SUBMIT</Text>
					</Button>
				</Group>
			</Stack>
		</form>
	)
}