import { Button, Card, Group, Stack, Text } from "@mantine/core";
import Modal from "features/Modal";
import { ModalTrigger } from "features/Modal/constants";
import { Fragment, useMemo } from "react";
import AddLocationEventModal from "./AddLocationEventModal";
import { useModalStore } from "features/Modal/store/modal.store";
import { useParams } from "react-router-dom";

import ListItem from "./ListItem";

import { useShareLocationEvents } from "features/MerchantGameDetails/hooks/useSharedLocationEvents";
import ContentLoader from "components/ContentLoader";

type LocationEventData = {
    id: number;
    name: string;
}

type CardWithSettings = {
    maxAttempts: number;
    frequencyOccurrence: number;
    LocationEvent: LocationEventData;
}

export default function SharedLocationEvents() {
    const { id, merchantId } = useParams<{ id: string, merchantId: string }>();

    const openModal = useModalStore(state => state.setOpen);
    const { data, isLoading } = useShareLocationEvents(merchantId!, id!);

    const events = useMemo(() => {
        if (!data) return;
        return data;
    }, [data]);

    return (
        <Fragment>
            <Card radius={8} shadow="0px 1px 4px rgba(0, 0, 0, 0.05)">
                <Card.Section inheritPadding py={16} sx={{ borderBottom: "1px solid #f8f9fa" }}>
                    <Group position="apart">
                        <Text size={16} weight={600} lh={1.5}>
                            Location Events
                        </Text>
                        <Group>
                            <Button type="submit" variant="filled" h={28} px={12} py={4} color="dark.8" radius={4} onClick={() => openModal({
                                title: 'Add Location Event',
                                trigger: ModalTrigger.ADD_LOCATION_EVENTS,
                            })}>
                                <Text size={"14px"} weight={500} tt={'uppercase'} lh={1.5}>ADD LOCATION EVENTS</Text>
                            </Button>
                        </Group>
                    </Group>
                </Card.Section>
                <Card.Section inheritPadding p={0} pb={2}>
                    <Stack spacing={0}>
                        {isLoading && <ContentLoader />}

                        {!isLoading && events?.map((item: CardWithSettings, index: number) => (
                            <ListItem
                                key={index}
                                maxAttempts={item.maxAttempts}
                                frequencyOccurrence={item.frequencyOccurrence}
                                locationEvent={item.LocationEvent}
                            />
                        ))}

                        {!isLoading && events?.length === 0 && (
                            <Group p={24} align="center" position="center">
                                <Text size={"14px"} weight={400} color="gray.6" lh={1.5}>Location Event list is empty</Text>
                            </Group>
                        )}
                    </Stack>
                </Card.Section>
            </Card>

            <Modal triggers={[ModalTrigger.ADD_LOCATION_EVENTS]}>
                <AddLocationEventModal />
            </Modal>

        </Fragment>
    )
}