import qs from "qs";
import { Base } from "./Base";
import {
  ApiResponse,
  ApiResponseList,
  OperationResponse,
  SearchParams,
} from "./interfaces/api";
import { UpdateUserForm, User } from "./interfaces/user";

export default class Users extends Base {
  async readAll({ skip = 0, limit = 1000, search = "" }: SearchParams) {
    const query = qs.stringify({ skip, limit, search });

    const response = await this.request.get<ApiResponseList<User>>(
      `${this.baseUrl}/admin/users?${query}`
    );

    return response.data.data;
  }

  async readOne(id?: User["id"]) {
    const response = await this.request.get<ApiResponse<User>>(
      `${this.baseUrl}/admin/users/${id}`
    );

    return response.data.data;
  }

  async updateUser(data: UpdateUserForm, id?: User["id"]) {
    const response = await this.request.patch<ApiResponse<User>>(
      `${this.baseUrl}/admin/users/${id}`,
      data
    );
    return response.data.data;
  }

  async userResetPassword(id: User["id"]) {
    const response = await this.request.post<ApiResponse<OperationResponse>>(
      `${this.baseUrl}/admin/users/${id}/reset`
    );
    return response.data.data;
  }

  async deleteUser(id?: User["id"]) {
    const response = await this.request.delete<ApiResponse<OperationResponse>>(
      `${this.baseUrl}/admin/users/${id}`
    );

    return response.data.data;
  }
}
