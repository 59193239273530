import { api } from "index";
import { useMutation, useQueryClient } from "react-query";
import { useCallback } from "react";
import { TextContent } from "api/interfaces/textContent";
import useMerchantSelectorStore from "features/MerchantSelector/hooks/useMerchantSelectorStore";
import { ErrorResponse } from "api/interfaces/api";
import { showNotification } from "@mantine/notifications";

export const useTextContentUpdate = (id?: TextContent["id"]) => {
  const queryClient = useQueryClient();

  const merchantSelector = useMerchantSelectorStore();

  const selectedId = merchantSelector.useStore((state) => state.selected);

  // Event Update Handler
  const { mutateAsync: update, ...options } = useMutation(
    (input: TextContent) => api.textContents.update(input, Number(selectedId))
  );

  // Handler for forms
  const handleUpdate = useCallback(
    async (input: TextContent) => {
      if (!id) return;

      try {
        await update({ ...input, id });

        await Promise.all([
          queryClient.invalidateQueries(["TEXT_CONTENT_LIST", selectedId]),
          queryClient.invalidateQueries(["TEXT_CONTENT"]),
        ]);

        showNotification({
          autoClose: 3000,
          title: "Success!",
          message: "Text Content successfully updated!",
          color: "green",
        });
      } catch (e: any) {
        const error: ErrorResponse = e.response.data;
        showNotification({
          message: error.error ?? "Something went wrong.",
          title: "Error!",
          color: "red",
          autoClose: 5000,
        });
      }
    },
    [id, update]
  );

  return {
    handleUpdate,
    ...options,
  };
};
