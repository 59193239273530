import { useEventCards } from "../store/event-cards.store";
import { Group, Stack, Title, Text, ActionIcon } from "@mantine/core";
import { Icon } from "@iconify/react";
import styled from "@emotion/styled";
import { useParams } from "react-router-dom";
import { showNotification } from "@mantine/notifications";
import { useModalStore } from "features/Modals/hooks/useModalStore";
import useMutationData from "hooks/useMutationData";
import QueryKeys from "constants/query-keys";

export default function CollectibleCard({ id: cardId, name, frequencyOccurrence, maxAttempts, settingsId, type }: TStorageCollectibleCard) {
    const onDelete = useEventCards(state => state.removeCard);
    const { merchantId, id } = useParams();
    const { setupModal, setModalStatus, closeModal } = useModalStore();

    const deleteCard = useMutationData({
        url: () => `/admin/merchants/${merchantId}/${type}/${id}/collectible-cards/${settingsId}`,
        method: 'delete',
        queryKeys: {
            invalidate: [
                { queryKey: [QueryKeys.CARDS_FOR_CONTENT] },
                { queryKey: [QueryKeys.CARDS_FOR_EVENT] },
            ],
        },
        onSuccess: (): any => {
            showNotification({
                autoClose: 3000,
                title: "Success!",
                message: "Card deleted",
                color: "green",
            });
        },
    });

    const handleDelete = async () => {
        if (id) {
            try {
                onDelete(Number(cardId));
                deleteCard.mutate({});
            } catch (e) {
                showNotification({
                    autoClose: 3000,
                    title: "Something gone wrong!",
                    message: `${e}`,
                    color: "red",
                });
            }
        } else {
            onDelete(Number(cardId));
        }
        closeModal();
    }

    const openModal = () => {
        setupModal({
            action: handleDelete,
            type: "delete",
            content:
                "Please confirm your action. <br/>Selected card will be deleted!",
        });
        setModalStatus(true);
    }

    return (
        <Root grow>
            <Group position="left">
                <Stack spacing={0}>
                    <Title order={5} style={{ textTransform: 'capitalize' }}>{name}</Title>
                    <Text size="sm" color={'dimmed'}>
                        Frequency occurrence: {frequencyOccurrence * 100}% <br />
                        Max Attempts: {maxAttempts}
                    </Text>
                </Stack>
            </Group>

            <Group position="right" spacing={'lg'}>
                <Group position="right" spacing={'xs'}>
                    <ActionIcon onClick={openModal} variant="light" color={'red'} size={'lg'} radius={'md'} style={{ border: "1px solid #fbe7e7" }}>
                        <Icon icon={"fluent:dismiss-12-filled"} />
                    </ActionIcon>
                </Group>
            </Group>
        </Root>
    );
}

// Styling
const Root = styled(Group)`
      flex-direction: row;
    
      background-color: #ffffff;
      padding: 8px 16px;
      user-select: none;
    
      &:not(:last-child) {
        border-bottom: 1px solid #e9ecef;
      }
    
      & {
        transition-property: background-color;
        transition-duration: 250ms;
        transition-timing-function: ease-in-out;
      }
    
      &:hover {
        background-color: rgba(44, 62, 80, 0.05);
      }
    `;
