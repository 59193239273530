import { Group, Paper, Stack, Textarea, TextInput } from "@mantine/core";
import { Controller, useFormContext } from "react-hook-form";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

// Component
export default function TextContentEdit() {
  const { control } = useFormContext();

  return (
    <Root radius={"md"} withBorder p={"lg"}>
      <Stack spacing={"lg"}>
        {/* Name */}
        <Group position="left" grow spacing={"lg"}>
          <Controller
            name="name"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                label="Name"
                placeholder="Enter content name"
                variant="filled"
                error={fieldState.error?.message}
                required
                classNames={{ input: "input-field" }}
                {...field}
              />
            )}
          />
        </Group>

        {/* Text */}
        <Group position="left" grow>
          <Controller
            name="text"
            control={control}
            render={({ field }) => (
              <TextareaField
                label="Text"
                placeholder="Enter text content"
                variant="filled"
                autosize
                required
                classNames={{ input: "input-field" }}
                {...field}
              />
            )}
          />
        </Group>
      </Stack>
    </Root>
  );
}

// CSS Helper
const InputCSS = css`
  display: flex;
  font-size: 14px;
  font-weight: 400;
  background: #fcfdfe;
  border: 1px solid #f0f1f7;
  color: #9fa2b4;
  line-height: 1.5;
  min-height: 42px;
  outline: none;
  padding: 10px 16px;
  width: 100%;
  transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out,
    color 0.2s ease-in-out;

  &:hover,
  &:focus {
    color: #252733;
    border-color: #0095e5;
  }

  &::placeholder {
    color: rgb(75, 80, 109, 0.5);
  }
`;

const FieldCSS = css`
  flex: 1;

  & .input-field {
    ${InputCSS};
  }
`;
// Styling
const Root = styled(Paper)<any>`
  margin-top: 20px;
  overflow: hidden;
`;

const TextField = styled(TextInput)`
  ${FieldCSS};
  position: relative;
  .mantine-TextInput-error {
    position: absolute;
    left: 0;
    bottom: -24px;
  }
`;

const TextareaField = styled(Textarea)`
  ${FieldCSS};
`;
