import { Base } from "./Base";
import { ApiResponse } from "./interfaces/api";
import {
  UpdatePasswordForm,
  IAccount,
} from "./interfaces/account";

export default class Account extends Base {
  async me() {
    const response = await this.request.get<ApiResponse<IAccount>>(
      `${this.baseUrl}/admin/whoami`
    );
    return response.data.data;
  }
  
  async updatePassword(form: UpdatePasswordForm) {
    const response = await this.request.patch<ApiResponse<any>>(
      `${this.baseUrl}/admin/whoami/password`,
      form
    );

    return response.data.data;
  }
}
