import { useQuery, useMutation, useQueryClient } from "react-query";
import { api } from "index";

type DeleteProps = {
    gameId: string | number,
    merchantId: string | number
};

type ShareProps = {
    gameId: string | number,
    merchantIds: number[],
};

export const useMerchantsForGame = (gameId: number | string) => {
  
    const client = useQueryClient();

    const { data, isLoading } = useQuery({
        queryFn: async () => await api.games.getSharedMerchants(gameId),
        queryKey: [`MERCHANT_SHARED_GAMES_${gameId}`],
    });

    const { mutateAsync: removeMerchant, isLoading: deleteLoading } = useMutation({
        mutationFn: async ({ gameId, merchantId }: DeleteProps) => await api.games.removeMerchant(gameId, merchantId),
        onSuccess: async () => {
            await client.invalidateQueries([`MERCHANT_SHARED_GAMES_${gameId}`]);
        }
    });

    const { mutateAsync: addMerchants, isLoading: updateLoading } = useMutation({
        mutationFn: async ({ gameId, merchantIds }: ShareProps) => await api.games.shareGame(gameId, merchantIds),
        onSuccess: async () => {
            await client.invalidateQueries([`MERCHANT_SHARED_GAMES_${gameId}`]);
        }
    });


    return {
        data,
        isLoading,
        deleteLoading,
        updateLoading,
        removeMerchant,
        addMerchants,
    }
}