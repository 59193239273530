import styled from "@emotion/styled";
import { Group, Select, Text, Button, Stack } from "@mantine/core";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { css } from "@emotion/react";
import { useEffect, useMemo } from "react";
import { useModalStore } from "features/Modal/store/modal.store";
import { useQuestConditionTypes } from "../hooks/useQuestConditionTypes";
import { useQuestData } from "../hooks/useQuestData";

export default function CreateActionsForm({ create }: any) {
  const setClose = useModalStore((state) => state.setClose);
  const conditionTypesData = useQuestConditionTypes();

  const { locationList, rewardsList, collectionCardsList } = useQuestData();

  const form = useFormContext();

  const questType = form.getValues("questTypeId");

  const filteredConditionTypesData = useMemo(() => {
    if (Number(questType) === 2) {
      return conditionTypesData.filter(
        (item) => item.value === 1 || item.value === 2
      );
    } else if (Number(questType) === 3) {
      return conditionTypesData.filter((item) => item.value === 3);
    }
    return conditionTypesData;
  }, [questType, conditionTypesData]);

  const conditionType = useWatch({
    control: form.control,
    name: "conditionTypeId",
  });

  const handleCreate = () => {
    const conditionTypeId = form.getValues("conditionTypeId");
    const collectibleId =
      conditionType === 1 ? form.getValues("collectibleId") : null;
    const contentId = conditionType === 2 ? form.getValues("contentId") : null;
    const locationEventId =
      conditionType === 3 ? form.getValues("locationEventId") : null;

    create({ conditionTypeId, collectibleId, contentId, locationEventId });
    setClose();
  };

  useEffect(() => {
    return () => {
      form.setValue("conditionTypeId", null);
    };
  }, [form]);

  return (
    <Stack px={24} pb={24} spacing={0} mih={320} justify="space-between">
      <Stack spacing={10}>
        <Controller
          name="conditionTypeId"
          control={form.control}
          render={({ field }) => (
            <SelectField
              {...field}
              label="Quest condition type"
              placeholder={
                filteredConditionTypesData.length > 0
                  ? filteredConditionTypesData.filter(
                      (data) => data.value == field.value
                    )[0]?.label
                  : "Select action"
              }
              variant="filled"
              data={filteredConditionTypesData as any}
              onChange={(value) => {
                field.onChange(value);
              }}
              withAsterisk
              required
            />
          )}
        />

        {conditionType === 1 && (
          <Controller
            name="collectibleId"
            control={form.control}
            render={({ field }) => (
              <SelectField
                {...field}
                label="Quest condition"
                placeholder="Select collectible card"
                variant="filled"
                data={collectionCardsList}
                onChange={(value) => {
                  field.onChange(value);
                }}
                withAsterisk
                required
              />
            )}
          />
        )}

        {conditionType === 2 && (
          <Controller
            name="contentId"
            control={form.control}
            render={({ field }) => (
              <SelectField
                {...field}
                label="Quest condition"
                placeholder="Select reward"
                variant="filled"
                data={rewardsList}
                onChange={(value) => {
                  field.onChange(value);
                }}
                withAsterisk
                required
              />
            )}
          />
        )}

        {conditionType === 3 && (
          <Controller
            name="locationEventId"
            control={form.control}
            render={({ field }) => (
              <SelectField
                {...field}
                label="Quest condition"
                placeholder="Select location"
                variant="filled"
                data={locationList}
                onChange={(value) => {
                  field.onChange(value);
                }}
                withAsterisk
                required
              />
            )}
          />
        )}
      </Stack>
      <Group grow align={"center"} mt={24}>
        <Button
          onClick={handleCreate}
          variant="filled"
          type="button"
          h={28}
          px={12}
          py={4}
          color="dark.8"
          radius={4}
        >
          <Text size={"14px"} weight={500} tt={"uppercase"} lh={1.5}>
            Create action
          </Text>
        </Button>
      </Group>
    </Stack>
  );
}

const InputCSS = css`
  display: flex;
  font-size: 14px;
  font-weight: 400;
  background: #fcfdfe;
  color: #9fa2b4;
  line-height: 1.5;
  min-height: 42px;
  outline: none;
  padding: 10px 16px;
  width: 100%;
  transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out,
    color 0.2s ease-in-out;

  &:hover,
  &:focus {
    color: #252733;
    border-color: #0095e5;
  }

  &::placeholder {
    color: rgb(75, 80, 109, 0.5);
  }
`;

const FieldCSS = css`
  flex: 1;

  & .input-field {
    ${InputCSS};
  }
`;

const SelectField = styled(Select)`
  ${FieldCSS};

  & .input-field {
    border: 1px solid ${({ error }) => (error ? "#f03e3e" : "#f0f1f7")};
  }
`;
