import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import ContentLoader from "components/ContentLoader";
import PageToolbar from "components/PageToolbar";
import QueryKeys from "constants/query-keys";
import PlatformCollectionCardsEdit from "features/PlatformCollectionCardsEdit";
import { TCollectableCard } from "features/PlatformCollectionCardsList/types";
import TabSections from "features/PlatformCollectionCardsEdit/TabSections";
import useData from "hooks/useData";
import useMutationData from "hooks/useMutationData";
import { api } from "index";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from 'yup';


interface FormState {
    type: 'update' | 'create';
    name: string;
    description: string;
    imagURL: string;
    image?: File;
}

export default function PlatformCollectableCardEditPage() {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    
    const { data: card, isLoading } = useData<ApiResponse<TCollectableCard>, TCollectableCard>({
        path: `/admin/collectible-cards/${id}`,
        queryKey: [QueryKeys.PLATFORM_COLLECTABLE_CARD, { cardId: Number(id) }],
        select: (data) => data.data,
        enabled: !!id,
    });

    const form = useForm<FormState>({
        mode: 'onChange',
        resolver: yupResolver(yup.object({
            type: yup.string().oneOf(['update', 'create']),
            name: yup.string().max(32, `Name is too long. Maximum length is 32 chars.`).trim().required('Required'),
            description: yup.string().trim().required('Required'),
            imagURL: yup.string().trim(),
            image: yup.mixed().when(['type', 'imageURL'], {
                is: (type: string, imageURL: string) => type === 'create' && !imageURL,
                then: yup.mixed().required('Required'),
                otherwise: yup.mixed(),
            }),
        })),
        defaultValues: {
            type: id ? 'update' : 'create',
            name: '',
            imagURL: '',
            description: '',
        },
    });

    useEffect(() => {
        form.reset({
            type: card?.id ? 'update' : 'create',
            name: card?.name ?? '',
            description: card?.description ?? '',
            imagURL: card?.imagURL ?? '',
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [card])

    const createRequest = useMutationData({
        url: () => `/admin/collectible-cards`,
        method: 'post',
        queryKeys: {
            invalidate: [
                { queryKey: [QueryKeys.PLATFORM_COLLECTABLE_CARDS] },
            ],
        },
        onSuccess: async () => {
            showNotification({
                autoClose: 3000,
                title: 'Success!',
                message: 'Collectible card successfully created!',
                color: 'green',
            });
        }
    })

    const updateRequest = useMutationData({
        url: () => `/admin/collectible-cards/${id}`,
        method: 'patch',
        queryKeys: {
            invalidate: [
                { queryKey: [QueryKeys.PLATFORM_COLLECTABLE_CARDS] },
                { queryKey: [QueryKeys.PLATFORM_COLLECTABLE_CARD, { cardId: Number(id) }] },
                { queryKey: [QueryKeys.MERCHANT_COLLECTABLE_CARDS] },
                { queryKey: [QueryKeys.MERCHANT_COLLECTABLE_CARD, { cardId: Number(id) }] },
            ],
        },
        onSuccess: async () => {
            showNotification({
                autoClose: 3000,
                title: 'Success!',
                message: 'Collectible card successfully updated!',
                color: 'green',
            });
        }
    })

    const onSubmit = form.handleSubmit(async (input: any) => {
        let data = {
            name: input.name,
            description: input.description,
            imagURL: input.imagURL,
        } as TCollectableCard;

        try {
            // IMAGE CONTENT
            if (input.image) {
                const response = await api.files.uploadImage(input.image);

                data = {
                    ...data,
                    name: input.name,
                    description: input.description,
                    imagURL: response.url,
                };


                if (!id) {
                    const res = await createRequest.mutateAsync(data) as any;
                    navigate(`/platform/collectable-cards/${res.data.data.id}`)
                }
            }

            if (id) {
                data = {
                    ...data,
                    id: Number(id),
                };


                await updateRequest.mutateAsync(data);
            }
        } catch (e: any) {
            console.log('error', e);
        } finally {
            form.setValue('image', undefined);
        }
    });

    if (isLoading) return <ContentLoader />

    return (
        <FormProvider {...form}>
            <Stack p={24} spacing={24}>
                <PageToolbar label={"Go Back"} backLink={`/platform/collectable-cards`} />

                <form onSubmit={onSubmit}>
                    <PlatformCollectionCardsEdit />
                </form>

                {!!id && (
                    <TabSections />
                )}
            </Stack>
        </FormProvider>
    )
}