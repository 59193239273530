import { Icon } from "@iconify/react";
import { Button, Card, Group, Modal, Text, Tooltip } from "@mantine/core";
import { RandomRewardList } from "features/RandomReward/RandomRewardList";
import { CreateRewardModal } from "features/RandomReward/components/CreateRewardModal";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useEventRandomReward } from "../store/event-random-reward.store";

export default function RandomRewardForm() {
    const { id: campaignId, merchantId } = useParams();
    const params = useParams();
    const [createModalStatus, setCreateModalStatus] = useState<boolean>(false);
    const campaignRewards = useEventRandomReward(state => state.campaignRewards);
    const getCampaignRewards = useEventRandomReward(state => state.getCampaignRewards);
    const addReward = useEventRandomReward(state => state.addReward);
    const deleteReward = useEventRandomReward(state => state.deleteReward);
    
    useEffect(() => {
        getCampaignRewards(campaignId);
    }, [params]);
        
    const handleCreate = (reward: any) => {
        addReward(reward, campaignId);
    }

    if (!merchantId) return null;

	return (
        <>
            <Card radius={8} withBorder style={{ overflow: "visible" }}>
                <Card.Section inheritPadding withBorder py={16}>
                    <Group position="apart">
                        <Group position="left">
                            <Text size="lg" weight={700}>
                                Random Reward
                            </Text>

                            <Tooltip multiline width={220} withArrow label="When a user is added, they can receive either a random reward or a manually selected reward.">
                                <Icon icon="material-symbols:info-outline" color="#f55200" />
                            </Tooltip>
                        </Group>
                        <Group>
                            <Button
                                leftIcon={<Icon icon={"fluent:add-12-filled"} height={18} />}
                                radius={"sm"}
                                variant={"filled"}
                                sx={{ maxWidth: 300 }}
                                onClick={() => setCreateModalStatus(true)}
                            >
                                Add Reward
                            </Button>
                        </Group>
                    </Group>
                </Card.Section>
                <Card.Section inheritPadding pb={16}>
                    <RandomRewardList handleDelete={deleteReward} rewards={campaignRewards} />
                </Card.Section>
            </Card>
            <Modal
                onClose={() => setCreateModalStatus(false)}
                opened={createModalStatus}
                withCloseButton={false}
                overlayProps={{opacity: 0.5}}
                transitionProps={{ transition: "slide-down", duration: 400 }}
                closeOnClickOutside={false}
                padding={30}
                centered
                size={"md"}
            >
                <CreateRewardModal
                    handleCreate={handleCreate}
                    close={() => setCreateModalStatus(false)}
                />
            </Modal>
        </>
	);
}
