import {useMerchantDetails} from "./useMerchantDetails";
import {useMutation, useQueryClient} from "react-query";
import {api} from "../../../index";
import {useAccount} from "../../Auth/hooks/useAccount";

export default function useMerchantAgreement() {
	const { data: user } = useAccount()
	const { merchant } = useMerchantDetails();
	const queryClient = useQueryClient()
	
	const { mutateAsync, ...options } = useMutation(async () => {
		if (!merchant) return
		
		const res = await api.merchants.acceptAgreement(merchant?.id)
		if (!res?.isSuccess) return
		
		const queries = [
			["MERCHANT", merchant?.id],
		]
		
		if (user?.Merchant?.id === merchant?.id) {
			queries.push(["ACCOUNT"])
		}
		
		await Promise.all(queries.map(keys => queryClient.invalidateQueries(keys)))
	});
	
	return {
		accept: mutateAsync,
		...options
	}
}
