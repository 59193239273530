import styled from "@emotion/styled";
import { Paper, PaperProps } from "@mantine/core";
import RegisterEndForm from "features/RegisterForm/RegisterEndForm";
import RegisterStartForm from "features/RegisterForm/RegisterStartForm";
import { Link, useSearchParams } from "react-router-dom";

// Page
export default function RegisterPage() {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const companyName = searchParams.get("companyName");

  return (
    <RegisterCard withBorder radius={"md"} p={"xl"}>
      <BrandLogo>
        <span className={"name"}>QUBI</span>
        <span className={"type"}>CMS</span>
      </BrandLogo>

      {!token ? (
        <RegisterStartForm />
      ) : (
        <RegisterEndForm token={token} companyName={companyName} />
      )}

      <GroupLinks>
        <AdditionalInfo>
          <span>
            Already have an account? <Link to="/auth/sign-in">Sign in</Link>
          </span>
        </AdditionalInfo>
      </GroupLinks>
    </RegisterCard>
  );
}

// Styling
const RegisterCard = styled(Paper)<PaperProps>`
  max-width: 360px;
  width: 100%;
  position: relative;
  word-wrap: break-word;
`;

const BrandLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;

  & > * {
    font-family: "Fredoka One", serif;
    font-size: 28px;
  }

  & .name {
    color: #00a0ff;
  }

  & .type {
    color: #2c3e50;
  }
`;

const GroupLinks = styled.div`
  display: flex;
  flex-direction: column;
`;

const AdditionalInfo = styled.div`
  margin-top: 12px;
  margin-bottom: 16px;

  text-align: center;
  line-height: 18px;
  font-size: 14px;
  color: #666892;

  & span {
    display: flex;
    gap: 4px;
    justify-content: center;
  }

  & span > a {
    color: #666892;
    text-decoration: underline;
  }

  & > a:hover {
    color: #0095e5;
  }
`;
