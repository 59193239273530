import { useQuery } from "react-query"
import { api } from "index" 

export default function useMerchantGames(merchant_id: string | number) {

    const { data, isLoading } = useQuery({
        queryFn: async () => await api.games.getSharedGames(merchant_id),
        queryKey: [`MERCHANT_SHARED_GAMES_${merchant_id}`]
    }); 

    return {
        data,
        isLoading,
    }
}