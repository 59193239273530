import { useQuery } from "react-query";
import { api } from "index";
import { Event } from "api/interfaces/events";
import useMerchantSelectorStore from "features/MerchantSelector/hooks/useMerchantSelectorStore";

export function useLocationEvent(id?: Event["id"]) {
  const merchantSelector = useMerchantSelectorStore();

  const selectedId = merchantSelector.useStore((state) => state.selected);
  const {
    data: locationEvent,
    isError,
    isLoading,
    refetch,
  } = useQuery(
    ["LOCATION_EVENT", selectedId, id],
    () => api.locationEvents.readOne(id, Number(selectedId)),
    {
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
      enabled: !!id,
      retry: 0,
    }
  );

  return { locationEvent, isError, isLoading, refetch };
}
