import { useAuth } from "features/Auth/hooks/useAuth";
import { api } from "index";
import { useCallback, useMemo, useState } from "react";
import { useInfiniteQuery } from "react-query";

export const usePlatformAdmins = () => {
  const { isSuperAdmin } = useAuth();
  const [search, setSearch] = useState("");
  const { data, hasNextPage, fetchNextPage, ...rest } = useInfiniteQuery(
    ["PLATFORM_ADMINS", search],
    ({ pageParam = 0 }) =>
      api.admins.readAllPlatformAdmins({
        search,
        skip: pageParam,
      }),
    {
      enabled: isSuperAdmin,
      getNextPageParam: (lastPage, allPages) => {
        if (allPages.length && 20 >= lastPage?.count) return;
        return allPages.length;
      },
    }
  );

  const admins = useMemo(() => {
    return data?.pages.map((page) => page.items).flat() || [];
  }, [data]);

  const onNextPage = useCallback(async () => {
    if (!hasNextPage) return;
    await fetchNextPage();
  }, [hasNextPage, fetchNextPage]);

  return { admins, ...rest, setSearch, search, onNextPage };
};
