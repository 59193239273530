import { showNotification } from "@mantine/notifications";
import { ErrorResponse } from "api/interfaces/api";
import { AdminConstant } from "api/interfaces/constants";
import { api } from "index";
import { useCallback } from "react";
import { useMutation, useQueryClient } from "react-query";

export const useAppConfigConstantsUpdate = () => {
  const queryClient = useQueryClient();

  const { mutateAsync: update, ...options } = useMutation(
    (data: AdminConstant) => api.adminConstants.update(data),
    {
      onSuccess: async () => {
        await Promise.all([
          queryClient.invalidateQueries(["ADMIN_CONSTANTS_LIST"]),
          queryClient.invalidateQueries(["ADMIN_CONSTANTS_LIST"]),
        ]);

        showNotification({
          autoClose: 3000,
          title: "Success!",
          message: "Access constants successfully updated!",
          color: "green",
        });
      },
      onError: (e: any) => {
        const error: ErrorResponse = e.response.data;
        showNotification({
          message: error.error ?? "Something went wrong.",
          title: "Error!",
          color: "red",
          autoClose: 5000,
        });
      },
    }
  );
  const handleUpdate = useCallback(
    async ({ id, ...data }: AdminConstant) => {
      if (!id) return;
      await update({ ...data, id });
    },
    [update]
  );

  return { handleUpdate, ...options };
};
