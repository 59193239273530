import { Base } from "./Base";
import qs from "qs";

import {
  ApiResponse,
  ApiResponseList,
  OperationResponse,
  PaginationParams,
  SearchParams,
} from "./interfaces/api";
import {
  Merchant,
  MerchantTransfer,
  MerchantUpdateParams,
  MerchantVerifyParams,
  MerchantVerifyResponse,
} from "./interfaces/merchants";

export default class Merchants extends Base {
  async readAll({ skip = 0, limit = 1000, search = "" }: SearchParams) {
    const query = qs.stringify({ skip, limit, search });
    const response = await this.request.get<ApiResponseList<Merchant>>(
      `${this.baseUrl}/admin/merchants?${query}`
    );
    return response.data.data;
  }

  async readOne(id?: Merchant["id"] | string) {
    if (!id) return;

    const response = await this.request.get<ApiResponse<Merchant>>(
      `${this.baseUrl}/admin/merchants/${id}`
    );
    return response.data.data;
  }

  async update(id?: Merchant["id"], input = {} as MerchantUpdateParams) {
    if (!id) return;

    const response = await this.request.patch<ApiResponse<Merchant>>(
      `${this.baseUrl}/admin/merchants/${id}`,
      input
    );
    return response.data.data;
  }

  async verify(id?: Merchant["id"], input = {} as MerchantVerifyParams) {
    if (!id) return;

    const response = await this.request.post<
      ApiResponse<MerchantVerifyResponse>
    >(`${this.baseUrl}/admin/merchants/${id}/veriffs`, input);
    return response.data.data;
  }

  async subscribe(id?: Merchant["id"]) {
    if (!id) return;

    const query = qs.stringify({
      successUrl: window.location.href,
      cancelUrl: window.location.href,
    });

    const response = await this.request.post<
      ApiResponse<MerchantVerifyResponse>
    >(`${this.baseUrl}/admin/merchants/${id}/subscription?${query}`);
    return response.data.data;
  }

  async cancelSubscribe(id?: Merchant["id"]) {
    if (!id) return;

    const response = await this.request.delete<ApiResponse<unknown>>(
      `${this.baseUrl}/admin/merchants/${id}/subscription`
    );
    return response.data;
  }

  async resumeSubscribe(id?: Merchant["id"]) {
    if (!id) return;

    const response = await this.request.patch<ApiResponse<unknown>>(
      `${this.baseUrl}/admin/merchants/${id}/subscription`
    );
    return response.data;
  }

  async acceptAgreement(id?: Merchant["id"]) {
    if (!id) return;

    const response = await this.request.post<ApiResponse<unknown>>(
      `${this.baseUrl}/admin/merchants/${id}/accept-agreement`
    );
    return response.data;
  }

  async deleteMerchant(id?: Merchant["id"]) {
    if (!id) return;

    const response = await this.request.delete<ApiResponse<OperationResponse>>(
      `${this.baseUrl}/admin/merchants/${id}`
    );

    return response.data.data;
  }

  async getTransferHistory(
    merchantId: Merchant["id"],
    transferTypeId: number,
    { skip = 0, limit = 1000 }: PaginationParams
  ) {
    if (!merchantId) return;

    const query = qs.stringify({ skip, limit, transferTypeId });
    const response = await this.request.get<ApiResponseList<MerchantTransfer>>(
      `${this.baseUrl}/admin/merchants/${merchantId}/transfers?${query}`
    );

    return response.data.data;
  }
}
