import {useCallback, useState} from "react";
import {api} from "../../../index";

export const useTemporaryQr = () => {
    const [qr, setQr] = useState("")

    const handleGenerateQr = useCallback(async(id?: string) => {
        const data = {
            type: 'content',
            contentId: id || ''
        }
        const {url} = await api.files.createTemporaryQr({text: JSON.stringify(data)})
        setQr(url)
    }, [qr, setQr])

    return {
        qr,
        handleGenerateQr
    }
}