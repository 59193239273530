import { Group, Stack, Text } from "@mantine/core";
import { usePlatformAnalytics } from "../../../features/PlatformAnalytics/hooks/usePlatformAnalytics";
import AnalyticsComponent from "../../../features/AnalyticsComponent";

export default function PlatformAnalyticsPage() {
  const { analytics, isLoading } = usePlatformAnalytics();

  return (
    <Stack p={"lg"} spacing={0}>
      <Group position="apart">
        <Group position="left">
          <Text size="xl" weight="bold">
            Platfrom Analytics
          </Text>
        </Group>
      </Group>
        <AnalyticsComponent analytics={analytics} isLoading={isLoading} />
    </Stack>
  );
}
