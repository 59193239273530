import {useMerchantAnalytics} from "../../../features/MerchantProfile/hooks/useMerchantAnalytics";
import { Group, Stack, Text} from "@mantine/core";
import AnalyticsComponent from "../../../features/AnalyticsComponent";


export default function MerchantAnalyticsPage() {
	const { analytics, isLoading } = useMerchantAnalytics()
	
	return (
		<Stack p={"lg"} spacing={0}>
			<Group position="apart">
				<Group position="left">
					<Text size="xl" weight="bold">
						Merchant Analytics
					</Text>
				</Group>
			</Group>
			
			<AnalyticsComponent analytics={analytics} isLoading={isLoading} />
		</Stack>
	)
}
