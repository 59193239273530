import {Group, Stack, Button} from "@mantine/core";
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from 'yup'
import { api } from "index";
import { showNotification } from '@mantine/notifications';
import { Icon } from '@iconify/react';
import { PasswordField } from 'components/PasswordInput';

export default function PasswordForm() {

	const form = useForm({
		resolver: yupResolver(yup.object({
			oldPassword: yup.string()
				.matches(
					/^[A-Za-z0-9]*$/,
					'The password must contain only latin characters and digits'
				)
				.required('Required')
				.trim(),
			newPassword: yup.string()
				.matches(
					/^[A-Za-z0-9]*$/,
					'The password must contain only latin characters and digits'
				)
				.required('Required')
				.trim(),
			confirmPassword: yup.string()
				.required("Required")
				.oneOf([yup.ref('newPassword'), null], 'Passwords must match')
				.trim(),
		})),
		defaultValues: {
			oldPassword: "",
			newPassword: "",
			confirmPassword: ""
		}
	})

	const onSubmit = form.handleSubmit(async (values) => {
		try {

			await api.account.updatePassword({
				newPassword: values.newPassword,
				oldPassword: values.oldPassword
			})
			showNotification({
				message: 'Password updated.',
				title: 'Success!',
				color: 'green',
				icon: <Icon icon={"fluent:checkmark-12-filled"} color={"#fff"} height={24}/>,
				autoClose: 3000,
			})
		} catch {
			form.setError("oldPassword", { message: 'Invalid current password' })
		}
	})

	return (
		<Stack>

			<Group grow>
				<Stack>
					<Controller name="oldPassword" control={form.control} render={({field, fieldState}) => (
						<PasswordField
							label="Current Password"
							placeholder="Enter your current password"
							variant="filled"
							required
							error={fieldState.error?.message}
							classNames={{input: "input-field"}}
							{...field}
						/>
					)} />

					<Controller name="newPassword" control={form.control} render={({field, fieldState}) => (
						<PasswordField
							label="Password"
							placeholder="Enter new password"
							variant="filled"
							required
							error={fieldState.error?.message}
							classNames={{input: "input-field"}}
							{...field}
						/>
					)} />
			
					<Controller name="confirmPassword" control={form.control} render={({field, fieldState}) => (
						<PasswordField
							label="Confirm password"
							placeholder="Enter confirm new password"
							variant="filled"
							required
							error={fieldState.error?.message}
							classNames={{input: "input-field"}}
							{...field}
						/>
					)} />
				</Stack>

				<Stack />

			</Group>

			<Group sx={{position: 'relative'}}>
				<Button onClick={onSubmit} loading={form.formState.isSubmitting} disabled={form.formState.isSubmitting}>Save</Button>
            </Group>

		</Stack>
	)
}
