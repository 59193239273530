import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import ContentLoader from "components/ContentLoader";
import { Button, Group, Stack, Text } from "@mantine/core";
import useAdvertisementsList from "../../../features/AdvertisementsList/hooks/useAdvertisementsList";
import AdvertisementsList from "../../../features/AdvertisementsList";

// Page
export default function Advertisements() {
  const { advertisements, isLoading } = useAdvertisementsList();
  
  return (
    <Stack p={"lg"} spacing={0}>
      <Group position="apart">
        <Group position="left">
          <Text size="xl" weight="bold">
            Advertisements
          </Text>
        </Group>
        <Group position="right">
          <Button
            component={Link}
            to={`/platform/advertisements/create`}
            leftIcon={<Icon icon={"fluent:add-12-filled"} height={18} />}
            radius={"sm"}
            color={"blue"}
            variant={"filled"}
          >
            Create
          </Button>
        </Group>
      </Group>
      
      {!isLoading ? (
        <AdvertisementsList advertisements={advertisements} />
      ) : (
        <ContentLoader />
      )}
    </Stack>
  );
}
