import styled from "@emotion/styled";
import {Button, Group, Paper, Stack, Text, Title} from "@mantine/core";
import {Location} from "api/interfaces/location";
import {useEffect, useRef, useState} from "react";
import {Circle, FeatureGroup, LayerGroup, MapContainer, Marker, Popup, TileLayer,} from "react-leaflet";
import CurrentLocationObserver from "./observers/CurrentLocationObserver";
import {useLocationStore} from "./hooks/useLocationStore";
import CreateObserver from "./observers/CreateObserver";
import MarkerSelectObserver from "./observers/MarkerSelectObserver";
import {LocationMark} from "./store";
import {Icon} from "leaflet";
import useIdentifyLocation from "./hooks/useIdentifyLocation";

// Props
interface ComponentProps {
	locations: Location[];
}

// Component
export default function LocationsMap({locations}: ComponentProps) {
	const currentMarkerRef = useRef<any>();
	const {
		state: {marks, current, searchCoords, default: defaultCoords, setLocationFlag},
		setMarks,
	} = useLocationStore();
	const [selectedMark, setSelectedMark] = useState<LocationMark | undefined>();
	const [createOpened, setCreateOpened] = useState(false);
	const {setCoordsLocation} = useIdentifyLocation();
	
	useEffect(() => {
		if (!locations.length) return;
		
		const marks = locations.map((item) => ({
			id: item.id,
			title: item.name,
			coords: {
				lat: item.latitude,
				lng: item.longitude,
				radius: item.radiusMeters,
			},
		}));
		
		setMarks(marks);
	}, [locations, setMarks]);
	
	useEffect(() => {
		if (!currentMarkerRef.current) return;
		currentMarkerRef.current.openPopup();
	}, [current]);
	
	const currentMarkIcon = new Icon({
		iconUrl: "https://api.iconify.design/fluent/location-28-filled.svg?color=%23e50039",
		iconSize: [38, 38],
	});
	
	const markIcon = new Icon({
		iconUrl: "https://api.iconify.design/fluent/location-28-filled.svg?color=%2300a0ff",
		iconSize: [38, 38],
	});
	
	return (
			<Root radius={"md"} withBorder>
				<Map center={defaultCoords} zoom={6} scrollWheelZoom={true}>
					<TileLayer
							attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
							url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
							noWrap={true}
					/>
					
					{/* Marks */}
					{marks.length && (
							<LayerGroup>
								{marks.map((mark) => (
										<Circle
												key={mark.id}
												center={{lat: mark.coords.lat, lng: mark.coords.lng}}
												radius={mark.coords.radius}
												pathOptions={{
													opacity: 0.3,
													fillOpacity: 0.1,
													fillColor: "#00a0ff",
													color: "#00a0ff",
												}}
										>
											<Marker icon={markIcon} position={{lat: mark.coords.lat, lng: mark.coords.lng}}>
												<Popup>
													<Stack spacing={"lg"} style={{minWidth: 260}}>
														<Group>
															<Title order={5}>{mark.title}</Title>
														</Group>
														<Group position="right">
															{!setLocationFlag &&
                                <Button
                                  variant="light"
                                  color={"teal"}
                                  // Написано не через ссылку потому что вызывает ошибку
                                  onClick={() => setSelectedMark(mark)}
                                >
                                  <Text size={"sm"} weight={500}>Edit</Text>
                                </Button>
															}
														</Group>
													</Stack>
												</Popup>
											</Marker>
										</Circle>
								))}
							</LayerGroup>
					)}
					
					{searchCoords && current && setLocationFlag && (
							<FeatureGroup>
								<Marker
										icon={currentMarkIcon}
										ref={currentMarkerRef}
										position={{
											lat: searchCoords.lat,
											lng: searchCoords.lng,
										}}
								>
									<Popup>
										<Stack spacing={"lg"} style={{minWidth: 260}}>
											<Group>
												<Title order={5}>{current.title}</Title>
											</Group>
											<Group position="right">
												<Button
														variant="light"
														color={"blue"}
														onClick={() => {
															setCoordsLocation({
																lat: searchCoords.lat,
																lng: searchCoords.lng,
															})
															setCreateOpened(true)
														}}
												>
													<Text size={"sm"} weight={500}>
														Use location
													</Text>
												</Button>
											</Group>
										</Stack>
									</Popup>
								</Marker>
							</FeatureGroup>
					)}
					
					{/* MAP OBSERVERS */}
					<MarkerSelectObserver marker={selectedMark}/>
					<CreateObserver
							opened={createOpened}
							onClose={() => setCreateOpened(false)}
					/>
					<CurrentLocationObserver/>
				</Map>
			</Root>
	);
}

// Styling
const Map = styled(MapContainer)`
  width: 100%;
  height: 100%;
  z-index: 100;
`;

// Styling
const Root = styled(Paper)<any>`
  margin-top: 20px;
  overflow: hidden;
  flex: 1;
`;
