import styled from "@emotion/styled";
import { Icon } from "@iconify/react";
import { Button, Checkbox, Group, Paper } from "@mantine/core";
import { FC } from "react";
import { Controller } from "react-hook-form";
import { useFormContext } from "react-hook-form";

export const UpdateAppAccessConfigForm: FC = () => {
  const { control, formState } = useFormContext();
  return (
    <Root radius="md" withBorder p="lg">
      <Group spacing={30}>
        <Controller
          control={control}
          name="byInvite"
          render={({ field }) => (
            <Checkbox
              checked={field.value}
              {...field}
              label="Access by Invite"
            />
          )}
        />
        <Controller
          control={control}
          name="byAllowedCity"
          render={({ field }) => (
            <Checkbox
              checked={field.value}
              {...field}
              label="Access by allowed city"
            />
          )}
        />
        <Button
          type="submit"
          leftIcon={<Icon icon={"fluent:save-24-filled"} height={18} />}
          radius={"sm"}
          color={"teal"}
          variant={"filled"}
          loading={formState.isSubmitting}
        >
          Update
        </Button>
      </Group>
    </Root>
  );
};

const Root = styled(Paper)<any>`
  margin-top: 20px;
  overflow: hidden;
`;
