import { Icon } from "@iconify/react";
import {Link} from "react-router-dom";
import ContentLoader from "components/ContentLoader";
import { Button, Group, Stack, Text } from "@mantine/core";
import LocationEventsList from "../../../features/LocationEventsList";
import useLocationEvents from "../../../features/LocationEventsList/hooks/useLocationEvents";
import {useMerchantNavigation} from "../../../hooks/useMerchantNavigation";

// Page
export default function LocationEvents() {
  const { locationEvents, isLoading } = useLocationEvents();
  const { route } = useMerchantNavigation()

  return (
    <Stack p={"lg"} spacing={0}>
      <Group position="apart">
        <Group position="left">
          <Text size="xl" weight="bold">
            Location events
          </Text>
        </Group>
        <Group position="right">
          <Button
            component={Link}
            to={`${route}/location-events/create`}
            leftIcon={<Icon icon={"fluent:add-12-filled"} height={18} />}
            radius={"sm"}
            color={"blue"}
            variant={"filled"}
          >
            Create
          </Button>
        </Group>
      </Group>

      {!isLoading ? (
        <LocationEventsList locationEvents={locationEvents} />
      ) : (
        <ContentLoader />
      )}
    </Stack>
  );
}
