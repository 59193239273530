import { api } from "index";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { LocationInput } from "api/interfaces/location";
import { useMerchantNavigation } from "../../../hooks/useMerchantNavigation";
import { ErrorResponse } from "api/interfaces/api";
import { showNotification } from "@mantine/notifications";

export const useLocationCreate = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { id: merchantId, route } = useMerchantNavigation();

  // Event Create Handler
  const { mutateAsync: create, ...options } = useMutation(
    (input: LocationInput) => api.locations.create(input, Number(merchantId))
  );

  // Handler for forms
  const handleCreate = useCallback(
    async (input: LocationInput) => {
      try {
        const location = await create(input);
        navigate(`${route}/locations/${location.id}`);

        await Promise.all([
          queryClient.invalidateQueries(["LOCATION_LIST", merchantId]),
          queryClient.invalidateQueries(["LOCATION"]),
        ]);
        showNotification({
          autoClose: 3000,
          title: "Success!",
          message: "Location successfully created!",
          color: "green",
        });
      } catch (e: any) {
        const error: ErrorResponse = e.response.data;
        showNotification({
          message: error.error ?? "Something went wrong.",
          title: "Error!",
          color: "red",
          autoClose: 5000,
        });
      }
    },
    [create, navigate]
  );

  return {
    handleCreate,
    ...options,
  };
};
