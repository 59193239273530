import {useMerchantDelete} from "../hooks/useMerchantDelete";
import {Button, Group, Stack, Text} from "@mantine/core";
import {Icon} from "@iconify/react";
import {Merchant} from "../../../api/interfaces/merchants";
import styled from "@emotion/styled";
import MerchantManageForm from "./MerchantManageForm";

type ComponentProps = {
	merchant: Merchant;
}

export default function AdminFeatures({}: ComponentProps) {
	const {handleMerchantDelete, isLoading: isDeleteLoading} = useMerchantDelete();
	
	return (
			<Stack p={"lg"} spacing={0}>
				<Group position="apart">
					<Group position="left">
						<Text size="xl" weight="bold">
							Admin Control
						</Text>
					</Group>
					<Group position="right" />
				</Group>
				
				<Root>
					<Group grow position={"apart"} align={"flex-start"}>
						
							
							{/* CHANGE MERCHANT ADMIN FIELDS */}
							<MerchantManageForm />
							
						
							{/* DELETE FEATURE */}
							<Group position={"right"}>
								<Button
										leftIcon={<Icon icon={"fluent:delete-24-filled"} height={18}/>}
										radius={"sm"}
										color={"red"}
										variant={"filled"}
										onClick={() => handleMerchantDelete()}
										loading={isDeleteLoading}
								>
									Delete Account
								</Button>
							</Group>
						
					</Group>
				</Root>
			</Stack>
	)
}

const Root = styled(Stack)`
  margin-top: 20px;
  background: #ffffff;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  padding: 20px;
  overflow-y: hidden;
  gap: 20px;
`;
