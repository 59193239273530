export const QueryKeys = {
    PLATFORM_COLLECTABLE_CARDS: "PLATFORM_COLLECTABLE_CARDS",
    PLATFORM_COLLECTABLE_CARD: "PLATFORM_COLLECTABLE_CARD",
    PLATFORM_COLLECTABLE_CARD_MERCHANTS: "PLATFORM_COLLECTABLE_CARD_MERCHANTS",
    MERCHANT_COLLECTABLE_CARDS: "MERCHANT_COLLECTABLE_CARDS",
    MERCHANT_COLLECTABLE_CARD: "MERCHANT_COLLECTABLE_CARD",
    MERCHANT_COLLECTABLE_CARD_CAMPAIGNS: "MERCHANT_COLLECTABLE_CARD_CAMPAIGNS",
    MERCHANT_COLLECTABLE_CARD_CONTENTS: "MERCHANT_COLLECTABLE_CARD_CONTENTS",
    MERCHANT_COLLECTABLE_CARDS_BY_CONTENT: "MERCHANT_COLLECTABLE_CARDS_BY_CONTENT",
    MERCHANT_COLLECTABLE_CARDS_BY_CAMPAIGNS: "MERCHANT_COLLECTABLE_CARDS_BY_CAMPAIGNS",
    CARDS_FOR_EVENT: "CARDS_FOR_EVENT",
    CARDS_FOR_CONTENT: "CARDS_FOR_CONTENT",
} as const;

export default QueryKeys;