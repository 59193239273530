import { Base } from "./Base";
import {
  ApiResponse,
  ApiResponseList,
  ErrorResponse,
  OperationResponse,
  PaginationParams,
} from "./interfaces/api";
import qs from "qs";
import { AppInvite } from "./interfaces/appInvite";

export default class AppInvites extends Base {
  async readAll({ skip = 0, limit = 1000 }: PaginationParams) {
    const query = qs.stringify({ skip, limit });
    const response = await this.request.get<ApiResponseList<AppInvite>>(
      `${this.baseUrl}/admin/invites?${query}`
    );

    return response.data.data;
  }

  async remove(id: number) {
    try {
      const response = await this.request.delete<OperationResponse>(
        `${this.baseUrl}/admin/invites/${id}`
      );
      return response.data.isSuccess;
    } catch (e: any) {
      const error: ErrorResponse = await e.response.data;
      return error;
    }
  }

  async create(data: Omit<AppInvite, "id" | "createdAt" | "updatedAt">) {
    const response = await this.request.post<ApiResponse<AppInvite>>(
      `${this.baseUrl}/admin/invites`,
      { ...data }
    );

    return response.data.data;
  }
}
