import { Group } from "@mantine/core";
import { FC } from "react";
import ContentLoader from "./ContentLoader";
import {MerchantAnalytic} from "../api/interfaces/analytic";

interface AnalyticBarChartProps {
  analytics?: MerchantAnalytic;
  isLoading?: boolean;
}
export const AnalyticBarChart: FC<AnalyticBarChartProps> = ({analytics , isLoading}) => {
  if (isLoading) return <ContentLoader />;
  return (
    <Group position="apart" grow>
        <iframe
            src={`${analytics?.iframeUrl}`}
            frameBorder="0"
            width="800"
            height="600"
            allowTransparency
        ></iframe>
    </Group>
  );
};
