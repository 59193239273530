import { useQuery, useMutation, useQueryClient } from "react-query";
import QueryKeys from "constants/query-keys";
import { api } from "utils/api";
import { showNotification } from "@mantine/notifications";
import { useEventCards } from "features/EventEdit/store/event-cards.store";
import { useParams } from "react-router-dom";

type ContentCardsQueryProps = {
    id: number | string,
    contentId: number | string | undefined,
};

type CardsData = {
    cardId: number | string,
    frequencyOccurrence: number | string,
    maxAttempts: number | string,
};

type PostData = {
    cardsData: CardsData[] | [] | undefined;
    id: string | number,
    contentId: string | number
};

type UseContentCardsProps = {
    contentCards: any[] | undefined,
    isLoading: boolean,
    postCards: ({ }: PostData) => Promise<void>;
};

export const useContentCardsQuery = ({ id, contentId }: ContentCardsQueryProps): UseContentCardsProps => {
    const client = useQueryClient();
    const resetStore = useEventCards(state => state.resetStore);
    const { merchantId } = useParams();

    const _postCards = async (cardsData: CardsData[] | undefined, id: number | string, contentId: number | string) => {
        const response = await api.post(`/admin/merchants/${id}/contents/${contentId}/collectible-cards`, cardsData);
        return { response, contentId };
    };

    const { data, isLoading } = useQuery({
        queryFn: async () => contentId ? await api.get(`/admin/merchants/${id}/contents/${contentId}/collectible-cards`) : undefined,
        queryKey: [QueryKeys.CARDS_FOR_CONTENT, { contentId }],
    });

    const { mutate: postCards }: any = useMutation({
        mutationFn: async ({ cardsData, id, contentId }: PostData) => await _postCards(cardsData, id, contentId),
        onSuccess: async (data: any) => {
            console.log(`Success data`, data);
            showNotification({
                autoClose: 3000,
                title: "Success!",
                message: "Cards were added!",
                color: "green",
            });
            await Promise.all([
                client.invalidateQueries([QueryKeys.CARDS_FOR_CONTENT, { contentId: data.contentId }]),
                data
                    .response
                    .data
                    .data
                    .map(async (item: any) => await client.invalidateQueries([QueryKeys.MERCHANT_COLLECTABLE_CARD,
                    { cardId: Number(item.collectibleCardId) },
                    { merchantId: Number(merchantId) }])),
            ]);
        },
        onError: (error) => {
            resetStore();
            showNotification({
                autoClose: 3000,
                title: "Error!",
                message: `${error}`,
                color: "red",
            });
        }
    });

    return {
        contentCards: data ? data.data.data.items : undefined,
        isLoading,
        postCards,
    }
}

