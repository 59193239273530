import {useParams} from "react-router-dom";
import {useQuery} from "react-query";
import {api} from "../../../index";

export function useMerchantAnalytics() {
		const pageParams = useParams()
		const { data, ...rest } = useQuery(["MERCHANT_ANALYTICS", Number(pageParams.merchantId)], () => api.analytics.merchant(pageParams.merchantId))
		
		return {
			analytics: data,
			...rest
		}
	
	
}
