import { showNotification } from "@mantine/notifications";
import { AllowedCity } from "api/interfaces/allowedCity";
import { ErrorResponse } from "api/interfaces/api";
import { api } from "index";
import { useCallback } from "react";
import { useMutation, useQueryClient } from "react-query";

export const useAllowedCityCreate = () => {
  const queryClient = useQueryClient();

  const { mutateAsync: create, ...options } = useMutation(
    (data: Omit<AllowedCity, "id" | "createdAt" | "updatedAt">) =>
      api.allowedCities.create(data),
    {
      onSuccess: async () => {
        await Promise.all([
          queryClient.invalidateQueries(["ALLOWED_CITIES_LIST"]),
          queryClient.invalidateQueries(["ALLOWED_CITIES_LIST"]),
        ]);
        showNotification({
          autoClose: 3000,
          title: "Success!",
          message: "City successfully created!",
          color: "green",
        });
      },
      onError(e: any) {
        const error: ErrorResponse = e.response.data;
        showNotification({
          message: error.error ?? "Something went wrong.",
          title: "Error!",
          color: "red",
          autoClose: 5000,
        });
      },
    }
  );

  const handleCreate = useCallback(
    async (data: Omit<AllowedCity, "id" | "createdAt" | "updatedAt">) => {
      await create(data);
    },
    [create]
  );

  return { handleCreate, ...options };
};
