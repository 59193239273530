import { useCallback } from "react";
import { api } from "index";
import { RegisterEndInput, RegisterInviteInput } from "api/interfaces/auth";
import { useNavigate } from "react-router-dom";

export function useRegister() {
  const navigate = useNavigate();

  const handleRegisterStart = useCallback(
    async (values: RegisterInviteInput) => {
      await api.auth.sendRegisterInvite(values);
    },
    []
  );

  const handleRegisterEnd = useCallback(
    async (values: RegisterEndInput) => {
      console.log("Token", values.token);
      await api.auth.registerEnd(values);
      navigate("/sign-in");
    },
    [navigate]
  );

  return {
    handleRegisterStart,
    handleRegisterEnd,
  };
}
