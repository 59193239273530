import { useQuery, useMutation, useQueryClient } from "react-query";
import QueryKeys from "constants/query-keys";
import { api } from "utils/api";
import { showNotification } from "@mantine/notifications";
import { useEventCards } from "../store/event-cards.store";
import { useParams } from "react-router-dom";

type EventCardsQueryProps = {
    id: number | string,
    eventId: number | string | undefined,
};

type CardsData = {
    cardId: number | string,
    frequencyOccurrence: number | string,
    maxAttempts: number | string,
};

type PostData = {
    cardsData: CardsData[] | [] | undefined;
    id: string | number,
    eventId: string | number
};

type UseEventCardsProps = {
    eventCards: any[] | undefined,
    isLoading: boolean,
    postCards: ({ }: PostData) => Promise<void>;
};

export const useEventCardsQuery = ({ id, eventId }: EventCardsQueryProps): UseEventCardsProps => {
    const client = useQueryClient();
    const resetStore = useEventCards(state => state.resetStore);
    const { merchantId } = useParams();

    const _postCards = async (cardsData: CardsData[] | undefined, id: number | string, eventId: number | string) => {
        const response = await api.post(`/admin/merchants/${id}/events/${eventId}/collectible-cards`, cardsData);
        return { response, eventId };
    };

    const { data, isLoading } = useQuery({
        queryFn: async () => eventId ? await api.get(`/admin/merchants/${id}/events/${eventId}/collectible-cards`) : undefined,
        queryKey: [QueryKeys.CARDS_FOR_EVENT, { eventId }],
    });

    const { mutate: postCards }: any = useMutation({
        mutationFn: async ({ cardsData, id, eventId }: PostData) => await _postCards(cardsData, id, eventId),
        onSuccess: async (data: any) => {
            console.log(`Success data`, data);
            // showNotification({
            //     autoClose: 3000,
            //     title: "Success!",
            //     message: "Cards were added!",
            //     color: "green",
            // });
            await Promise.all([
                client.invalidateQueries([QueryKeys.CARDS_FOR_EVENT, { eventId: data.eventId }]),
                data
                    .response
                    .data
                    .data
                    .map(async (item: any) => await client.invalidateQueries([QueryKeys.MERCHANT_COLLECTABLE_CARD,
                    { cardId: Number(item.collectibleCardId) },
                    { merchantId: Number(merchantId) },
                    ])),
            ]);
        },
        onError: (error) => {
            resetStore();
            showNotification({
                autoClose: 3000,
                title: "Error!",
                message: `${error}`,
                color: "red",
            });
        }
    });

    return {
        eventCards: data ? data.data.data.items : undefined,
        isLoading,
        postCards,
    }
}

