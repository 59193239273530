interface NavigationItem {
  title: string;
  to: (id?: number | string) => string;
  icon: string;
  section: "content" | "company" | "platform" | "account"
}

export const navigation: NavigationItem[] = [
  {
    "title": "Campaigns",
    "to": id => `/merchant/${id}/campaigns`,
    "icon": "fluent:calendar-ltr-24-regular",
    "section": "content"
  },
  {
    "title": "Location Events",
    "to": id => `/merchant/${id}/location-events`,
    "icon": "fa6-solid:map-location",
    "section": "content"
  },
  {
    "title": "Locations",
    "to": id => `/merchant/${id}/locations`,
    "icon": "fluent:globe-location-24-regular",
    "section": "content"
  },
  {
    "title": "Contents",
    "to": id => `/merchant/${id}/contents`,
    "icon": "fluent:document-bullet-list-multiple-24-regular",
    "section": "content"
  },
  {
    "title": "Text Contents",
    "to": id => `/merchant/${id}/text-contents`,
    "icon": "fluent:code-text-edit-20-filled",
    "section": "content"
  },
  {
    "title": "Image Contents",
    "to": id => `/merchant/${id}/image-contents`,
    "icon": "fluent:image-multiple-24-regular",
    "section": "content"
  },
  {
    "title": "Coupons",
    "to": id => `/merchant/${id}/coupons`,
    "icon": "ri:coupon-3-line",
    "section": "content"
  },
  {
    "title": "Collectible cards",
    "to": id => `/merchant/${id}/collectable-cards`,
    "icon": "streamline:cards",
    "section": "content"
  },
  {
    "title": "Quests",
    "to": id => `/merchant/${id}/quests`,
    "icon": "fluent-mdl2:questionnaire",
    "section": "content"
  },
  {
    "title": "Content Targeting",
    "to": id => `/merchant/${id}/content-targets`,
    "icon": "fluent:target-24-regular",
    "section": "content"
  },

  {
    "title": "Account",
    "to": () => "/account",
    "icon": "fluent:person-arrow-left-24-regular",
    "section": "account"
  },
]
