import type { Preferences } from 'mock/target-group';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';


export type ExtendedMerchant = {
    id: number;
    preference?: keyof typeof Preferences;
}


interface IExtendedMerchantStore {
    merchants: ExtendedMerchant[];

    sync: (ids: number[]) => void;

    rewrite: (merchant: ExtendedMerchant) => void;
    remove: (id: number) => void;
}

const InitialState: Pick<IExtendedMerchantStore, 'merchants'> = {
    merchants: localStorage.getItem('EXTENDED_MERCHANTS') ? JSON.parse(localStorage.getItem('EXTENDED_MERCHANTS')!) : [],
};

export const useExtendedMerchantStore = create<IExtendedMerchantStore>()(
    devtools(
        (set, get) => ({
            ...InitialState,

            rewrite: (merchant) => {
                const merchants = get().merchants;

                if (!merchants.some((item) => item.id === merchant.id)) {
                    merchants.push(merchant);
                }

                const updatedMerchants = merchants.map((item) => {
                    if (item.id !== merchant.id) return item;
                    return merchant;
                });


                localStorage.setItem('EXTENDED_MERCHANTS', JSON.stringify(updatedMerchants));
                set({ merchants: updatedMerchants });
            },

            remove: (id) => {
                const merchants = get().merchants;

                const updatedMerchants = merchants.filter((item) => item.id !== id);

                localStorage.setItem('EXTENDED_MERCHANTS', JSON.stringify(updatedMerchants));
                set({ merchants: updatedMerchants });
            },

            sync: (ids) => {
                const local_saved = localStorage.getItem('EXTENDED_MERCHANTS');
                if (!local_saved) {
                    for (const id of ids) {
                        get().rewrite({ id });
                    }
                    return
                }

                const local_merchants = JSON.parse(local_saved);

                // if local storage do not have merchant in store, remove it from local storage
                for (const merchant of local_merchants) {
                    if (!ids.includes(merchant.id)) {
                        get().remove(merchant.id);
                    }
                }

                // if store do not have merchant in local storage, add it to store
                for (const id of ids) {
                    if (!local_merchants.some((merchant: ExtendedMerchant) => merchant.id === id)) {
                        get().rewrite({ id });
                    }
                }
            },
        }),
        { name: 'Extended Merchants' },
    ),
);
