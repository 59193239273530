import { useNavigate } from "react-router-dom";
import useMerchantSelectorStore from "../features/MerchantSelector/hooks/useMerchantSelectorStore";
import { useMerchantTargetStore } from "store/merchant-target.store";
import { useEffect } from "react";

export function useMerchantNavigation() {
	const navigate = useNavigate();
	const { useStore } = useMerchantSelectorStore();
	const merchantId = useStore(state => state.selected);

	const addMerchant = useMerchantTargetStore((state) => state.addMerchant);

	useEffect(() => {
		addMerchant(Number(merchantId));

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [merchantId]);

	const route = `/merchant/${merchantId}`

	return {
		id: merchantId,
		route,
		navigate: (path: string) => {
			const pathRoute = path.startsWith("/")
				? path
				: `/${path}`

			return navigate(`${route}${pathRoute}`)
		}
	}
}
