import styled from "@emotion/styled";
import { ActionIcon, Group, Text } from "@mantine/core";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { TGame } from "features/PlatformGames/types";
import { useMerchantNavigation } from "hooks/useMerchantNavigation";

// Props
type ComponentProps = {
    game: TGame;
};

// Component
export default function ListItem({ game }: ComponentProps) {
    const navigate = useNavigate();
    const { route } = useMerchantNavigation();

    return (
        <Root position="apart" px={16} py={8}>
            <Group position="left" align={"center"} spacing={8}>
                <Text size={14} weight={500} lh={1.5} tt={"capitalize"}>{game?.name}</Text>
            </Group>

            <Group position="right" spacing={8}>
                <ActionIcon
                    variant="subtle"
                    color="gray.5"
                    onClick={() => navigate(`${route}/games/${game?.id}`)}
                    sx={(theme) => ({
                        background: "#FCFDFE",
                        border: "1px solid #F1F3F5",
                        borderRadius: "6px",
                        color: theme.colors.dark[2],
                        "& svg[data-action-icon-loader]": {
                            stroke: theme.colors.dark[2],
                        },
                    })}
                >
                    <Icon icon={"octicon:link-external-16"} height={14} />
                </ActionIcon>
            </Group>
        </Root>
    );
}

const Root = styled(Group)`
  &:not(:last-child) {
    border-bottom: 1px solid #f8f9fa;
  }
`;
