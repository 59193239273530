import { Base } from "./Base";
// import qs from "qs";

import {
	ApiResponse,
	// ApiResponseList,
	// PaginationParams,
} from "./interfaces/api";
import {Merchant} from "./interfaces/merchants";
import {RefillResponse} from "./interfaces/balance";

export default class Balance extends Base {
	async refill(id?: Merchant["id"]) {
		if (!id) return
		const successUrl = window.location.href;
		const cancelUrl = window.location.href;
		
		const response = await this.request.post<ApiResponse<RefillResponse>>(`${this.baseUrl}/admin/merchants/${id}/balance`, {
			cancelUrl,
			successUrl
		});
		
		return response.data.data;
	}
}
