import { Base } from "./Base";
import qs from "qs";
import { ImageContent, ImageContentInput } from "./interfaces/imageContents";
import {
  ApiResponse,
  ApiResponseList,
  ErrorResponse,
  OperationResponse,
  PaginationParams,
} from "./interfaces/api";

export default class ImageContents extends Base {
  async create(input: ImageContentInput, merchantId?: number) {
    const response = await this.request.post<ApiResponse<ImageContent>>(
      `${this.baseUrl}/admin/merchants/${merchantId}/image-contents`,
      input
    );
    return response.data.data;
  }

  async readAll(
    merchantId: number,
    { skip = 0, limit = 1000 }: PaginationParams
  ) {
    const query = qs.stringify({ skip, limit });
    const response = await this.request.get<ApiResponseList<ImageContent>>(
      `${this.baseUrl}/admin/merchants/${merchantId}/image-contents?${query}`
    );
    return response.data.data;
  }

  async readOne(id?: ImageContent["id"], merchantId?: number) {
    const response = await this.request.get<ApiResponse<ImageContent>>(
      `${this.baseUrl}/admin/merchants/${merchantId}/image-contents/${id}`
    );
    return response.data.data;
  }

  async update({ id, ...data }: ImageContent, merchantId?: number) {
    const response = await this.request.patch<ApiResponse<ImageContent>>(
      `${this.baseUrl}/admin/merchants/${merchantId}/image-contents/${id}`,
      data
    );
    return response.data.data;
  }

  async remove(id: ImageContent["id"], merchantId: UniqueId) {
    try {
      const response = await this.request.delete<OperationResponse>(
        `${this.baseUrl}/admin/merchants/${merchantId}/image-contents/${id}`
      );
      return response.data.isSuccess;
    } catch (e: any) {
      const error: ErrorResponse = await e.response.data;

      return error;
    }
  }
}
