import styled from "@emotion/styled";
import { ActionIcon, Group, Stack, Text } from "@mantine/core";
import { Icon } from "@iconify/react";
import { useMerchantTargetStore, type MerchantTargetGroupContent } from "store/merchant-target.store";
import type { Content } from "api/interfaces/content";
import { Preferences, AgeGroups } from "mock/target-group";
import { useMerchantNavigation } from "hooks/useMerchantNavigation";
import { useState } from "react";
import { useModalStore } from "features/Modal/store/modal.store";
import { ModalTrigger } from "features/Modal/constants";

// Props
type ComponentProps = {
    item: Content & {
        targetGroup: MerchantTargetGroupContent;
    };
};

// Component
export default function ListItem({ item }: ComponentProps) {
    const [loading, setLoading] = useState(false);

    const openModal = useModalStore((state) => state.setOpen);
    
    const onOpenEdit = () => {
        openModal({ trigger: ModalTrigger.EDIT_MERCHANT_TARGET_GROUP, title: "Edit Content Targeting", payload: item.targetGroup });
    };

    const { id } = useMerchantNavigation();
    const removeMerchantContent = useMerchantTargetStore(state => state.removeMerchantContent);

    const preferences = item.targetGroup.preferences.map((i) => Preferences[i as keyof typeof Preferences])
    const ageGroups = item.targetGroup.age_groups.map((i) => AgeGroups[i as keyof typeof AgeGroups])

    const onRemove = () => {
        setLoading(true);
        const timeout = setTimeout(() => {
            setLoading(false);
            removeMerchantContent(Number(id), item.targetGroup.content_id);
            clearTimeout(timeout);
        }, 1000);
    }

    return (
        <Root position="apart" px={16} py={8}>
            <Group grow position="left" align={"center"} spacing={8} sx={{ flex: 1 }}>
                <Stack sx={{ flex: 1 }}>
                    <Text size={14} weight={500} lh={1.5} tt={"capitalize"}>{item.name}</Text>
                </Stack>

                <Stack spacing={8}>
                    <Text size={12} weight={400} color="gray.6" lh={1.5}>
                        Age Groups: <Text span color="dark.8">{ageGroups.join(', ')}</Text>
                    </Text>
                    <Text size={12} weight={400} color="gray.6" lh={1.5}>
                        Preferences: <Text span color="dark.8">{preferences.join(', ')}</Text>
                    </Text>
                </Stack>
            </Group>

            <Group position="right" spacing={8}>
                <ActionIcon
                    variant="subtle"
                    color="gray.5"
                    onClick={onOpenEdit}
                    sx={(theme) => ({
                        background: "#FCFDFE",
                        border: "1px solid #F1F3F5",
                        borderRadius: "6px",
                        color: theme.colors.dark[2],
                        "& svg[data-action-icon-loader]": {
                            stroke: theme.colors.dark[2],
                        },
                    })}
                >
                    <Icon icon={"fluent:pen-16-regular"} height={14} />
                </ActionIcon>
                <ActionIcon
                    variant="subtle"
                    color="red.5"
                    loading={loading}
                    onClick={onRemove}
                    sx={(theme) => ({
                        background: "#FCFDFE",
                        border: "1px solid #F1F3F5",
                        borderRadius: "6px",
                        color: theme.colors.red[5],
                        "& svg[data-action-icon-loader]": {
                            stroke: theme.colors.red[5],
                        },
                    })}
                >
                    <Icon icon={"fluent:delete-16-regular"} height={14} />
                </ActionIcon>
            </Group>
        </Root>
    );
}

const Root = styled(Group)`
  &:not(:last-child) {
    border-bottom: 1px solid #f8f9fa;
  }
`;
