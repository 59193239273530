import { Button, Group, Stack, Text } from "@mantine/core";
import styled from "@emotion/styled";
import React from "react";
import { Link, useLocation } from "react-router-dom";

export const SettingsLayout: React.FC<any> = ({ children }) => {
  const { pathname } = useLocation();

  return (
    <Stack p={"lg"} spacing={0}>
      <Group style={{ justifyContent: "space-between" }}>
        <Text size="xl" weight="bold">
          Settings
        </Text>

        <Navigation>
          <Link to={"/platform/settings/faq"}>
            <Button disabled={pathname === "/platform/settings/faq"}>
              FAQ settings
            </Button>
          </Link>
          <Link to={"/platform/settings/terms-of-use"}>
            <Button disabled={pathname === "/platform/settings/terms-of-use"}>
              Terms of use app
            </Button>
          </Link>
          <Link to={"/platform/settings/feedback"}>
            <Button disabled={pathname === "/platform/settings/feedback"}>
              Feedback
            </Button>
          </Link>

          <Link to={"/platform/settings/payment"}>
            <Button disabled={pathname === "/platform/settings/payment"}>
              Payment
            </Button>
          </Link>
          <Link to={"/platform/settings/fee"}>
            <Button disabled={pathname === "/platform/settings/fee"}>
              Fee
            </Button>
          </Link>
        </Navigation>
      </Group>

      {children}
    </Stack>
  );
};

export const Navigation = styled.div({
  display: "flex",
  alignItems: "center",
  background: "#fff",
  padding: 16,
  border: `1px solid #e9ecef`,
  borderRadius: 8,
  gap: 16,
});
