import {useAccount} from "features/Auth/hooks/useAccount";
import {useCallback, useMemo} from "react";
import {useAuthStore} from "./useAuthStore";
import TokenStorage from "helpers/tokenStorage";
import {UserRoles} from "../../../config";
import useMerchantSelectorStore from "../../MerchantSelector/hooks/useMerchantSelectorStore";
import dayjs from "dayjs";

export const useAuth = () => {
  const { data: user, remove, refetch, isError } = useAccount();
  const { state, logOut, logIn } = useAuthStore();
  const merchantSelector = useMerchantSelectorStore()

  const hasPermissions = useCallback((roleIds: UserRoles[]) => {
    if (!user) return false
    return roleIds.includes(user?.Role.id)
  }, [user])

  const isAuthorized = useMemo(() => {
    return Boolean(state.loggedIn && !!state.user && !!user);
  }, [state.loggedIn, state.user, user]);

  const logout = useCallback(() => {
    console.log("logout");
    TokenStorage.removeToken();
    remove();
    logOut();
    merchantSelector.Select(null)
  }, [remove, logOut, merchantSelector.Select]);

  const checkAuth = useCallback(async () => {
    return new Promise(async(resolve, reject) => {
      try {
        const token = TokenStorage.getToken();
        console.log("checking auth token", token);
        if (!token) return reject('NO AUTH TOKEN')

        const { data: authUser } = await refetch();

        if (!authUser) return reject('NO USER DATA')

        logIn(authUser);
        return resolve(authUser);
      } catch (e) {
        logout();
        reject(e)
      }
    })

  }, [refetch, logIn, logout]);

  const isSubscribeExpired = useMemo(() => {
    if (!user?.Merchant) return true;

    return !dayjs(user?.Merchant.stripeSubscriptionAccessExpire).isAfter(new Date(), "second");
  }, [user]);

  const isSubscriptionCancelled = useMemo(() => {
    if (!user?.Merchant) return true;

    return user?.Merchant.stripeIsSubscriptionCanceled;
  }, [user]);

  const isMerchantSubscribed = useMemo(() => {
    return hasPermissions([UserRoles.SUPER_MERCHANT, UserRoles.MERCHANT]) && !isSubscribeExpired && !isSubscriptionCancelled
  }, [isSubscribeExpired, isSubscriptionCancelled])

  return {
    user,
    isAuthorized,
    hasPermissions,
    isSuperAdmin: hasPermissions([UserRoles.SUPER_ADMIN]),
    isQubiAdmin: hasPermissions([UserRoles.QUBI_ADMIN]),
    isSuperMerchant: hasPermissions([UserRoles.SUPER_MERCHANT]),
    isMerchant: hasPermissions([UserRoles.MERCHANT]),
    isPlatformMerchant: hasPermissions([UserRoles.SUPER_MERCHANT, UserRoles.MERCHANT]),
    isPlatformAdmin: hasPermissions([UserRoles.SUPER_ADMIN, UserRoles.QUBI_ADMIN]),
    isMerchantSubscribed,
    checkAuth,
    isError,
    logout,
  };
};
