import { Card, Group, Stack, Text } from '@mantine/core';
import { useMerchantNavigation } from '../../../hooks/useMerchantNavigation';
import usePaginatedData from 'hooks/usePaginatedData';
import { TCollectableCard } from 'features/CollectionCardsList/types';
import ListItem from 'features/CollectionCardsList/components/ListItem';
import ContentLoader from 'components/ContentLoader';
import QueryKeys from 'constants/query-keys';

// Page
export default function CollectableCards() {
	const { id } = useMerchantNavigation();

    const { items, isLoading } = usePaginatedData<TCollectableCard>({
        path: `/admin/merchants/${id}/collectible-cards`,
        defaultLimit: 1000,
        queryKey: [QueryKeys.MERCHANT_COLLECTABLE_CARDS],
    });

	return (
		<Stack p={24} spacing={24}>
			<Card radius={8} shadow="0px 1px 4px rgba(0, 0, 0, 0.05)">
                <Card.Section inheritPadding py={16} sx={{ borderBottom: "1px solid #f8f9fa" }}>
                    <Group position="apart">
                        <Text size={16} weight={600} lh={1.5}>
                            Collectible Cards
                        </Text>
                        <Group></Group>
                    </Group>
                </Card.Section>
                <Card.Section inheritPadding p={0} pb={2}>
                    <Stack spacing={0}>
                        {isLoading && <ContentLoader />}

                        {items.length > 0 && items.map((item) => {
                            return <ListItem card={item} key={item.id} />
                        })}

                        {!isLoading && items.length === 0 && (
                            <Group p={24} align="center" position="center">
                                <Text size={"14px"} weight={400} color="gray.6" lh={1.5}>
                                    Admin not sharing with you collectible cards
                                </Text>
                            </Group>
                        )}
                    </Stack>
                </Card.Section>
            </Card>
		</Stack>
	);
}
