import styled from "@emotion/styled";
import { MerchantTransfer } from "api/interfaces/merchants";
import { FC, useMemo } from "react";
import { TransferItem } from "./TransferItem";
import { Stack, Text } from "@mantine/core";

interface TransferListProps {
  transfers?: MerchantTransfer[];
}

export const TransferList: FC<TransferListProps> = ({ transfers = [] }) => {
  console.log("🚀 ~ file: TransfersList.tsx:10 ~ transfers", transfers);

  const items = useMemo(() => {
    if (!transfers?.length) {
      return (
        <Stack justify={"center"} sx={{ height: "100%" }}>
          <Text align="center" size="sm" color={"dimmed"}>
            Not found...
          </Text>
        </Stack>
      );
    }

    return transfers.map((transfer, i) => (
      <TransferItem transfer={transfer} key={i} />
    ));
  }, [transfers]);

  return <Root>{items}</Root>;
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  background: #ffffff;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  overflow-y: hidden;
`;
