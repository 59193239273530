import { useQuery, useMutation, useQueryClient } from "react-query";
import { api } from "index";

type CardSettings = {
    cardId: number,
    frequencyOccurrence: number,
    maxAttempts: number
};

type AddCardProps = {
    gameId: number | string;
    merchantId: number | string;
    cardSettings: CardSettings;
};

type RemoveCardProps = {
    gameId: number | string;
    merchantId: number | string;
    cardId: number | string;
};

export const useSharedCards = (merchantId: number | string, gameId: number | string) => {
    const client = useQueryClient();

    const { data, isLoading } = useQuery({
        queryFn: async () => await api.games.getCards(merchantId, gameId),
        queryKey: [`MERCHANT_GAME_CARDS`, { merchantId: merchantId }, { gameId: gameId }],
    });

    const { mutateAsync: removeCard, isLoading: removeCardLoading } = useMutation({
        mutationFn: async ({ gameId, merchantId, cardId }: RemoveCardProps) => await api.games.removeCard(merchantId, gameId, cardId),
        onSuccess: async () => {
            await client.invalidateQueries( [`MERCHANT_GAME_CARDS`, { merchantId: merchantId }, { gameId: gameId }]);
        }
    });

    const { mutateAsync: addCard, isLoading: addCardLoading } = useMutation({
        mutationFn: async ({ gameId, merchantId, cardSettings }: AddCardProps) => await api.games.addCard(merchantId, gameId, cardSettings),
        onSuccess: async () => {
            await client.invalidateQueries( [`MERCHANT_GAME_CARDS`, { merchantId: merchantId }, { gameId: gameId }]);
        }
    });


    const { mutateAsync: updateCard, isLoading: updateCardLoading } = useMutation({
        mutationFn: async ({ gameId, merchantId, cardSettings }: AddCardProps) => await api.games.updateCardSettings(merchantId, gameId, cardSettings),
        onSuccess: async () => {
            await client.invalidateQueries( [`MERCHANT_GAME_CARDS`, { merchantId: merchantId }, { gameId: gameId }]);
        }
    });


    return {
        data,
        isLoading,
        addCard,
        addCardLoading,
        removeCard,
        removeCardLoading,
        updateCard,
        updateCardLoading,
    }
}