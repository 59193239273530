import { useCallback, useEffect } from "react";
import styled from "@emotion/styled";
import { Icon } from "@iconify/react";
import { Link, useParams } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { Button, Group, Stack, Text } from "@mantine/core";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTextContent } from "features/TextContentEdit/hooks/useTextContent";
import { useTextContentCreate } from "features/TextContentEdit/hooks/useTextContentCreate";
import { useTextContentUpdate } from "features/TextContentEdit/hooks/useTextContentUpdate";
import { useTextContentDelete } from "features/TextContentEdit/hooks/useTextContentDelete";
import TextContentEdit from "features/TextContentEdit";
import ContentLoader from "components/ContentLoader";
import { useAdmins } from "features/Admins/useAdmins";
import { useMerchantNavigation } from "../../../hooks/useMerchantNavigation";

// Validation Schema
const schema = yup
  .object({
    name: yup
      .string()
      .max(32, `Name is too long. Maximum length is 32 chars.`)
      .lowercase()
      .trim()
      .required("Required"),
    text: yup.string().required("Required").trim(),
  })
  .required();

// Page
export default function TextContentUpdate() {
  const { id } = useParams();
  const { isLoading } = useAdmins();
  const { textContent } = useTextContent(Number(id));
  const { handleCreate } = useTextContentCreate();
  const { handleUpdate } = useTextContentUpdate(Number(id));
  const { handleDelete } = useTextContentDelete(Number(id));
  const { route } = useMerchantNavigation();

  const form = useForm({
    defaultValues: {
      name: textContent?.name || "",
      text: textContent?.text || "",
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = useCallback(
    async (input: any) => {
      console.log(input);
      if (id) {
        await handleUpdate(input);
      } else {
        await handleCreate(input).then(() => form.reset());
      }
    },
    [id, form, handleCreate, handleUpdate]
  );

  // Update Form Data
  useEffect(() => {
    if (!textContent) return;
    form.reset(textContent);
  }, [textContent, form]);

  if ((id && !textContent) || isLoading) return <ContentLoader />;

  return (
    <FormProvider {...form}>
      <Root onSubmit={form.handleSubmit(onSubmit)}>
        <Stack spacing={0}>
          <Group position="apart">
            <Group position="left">
              <BackLink to={`${route}/text-contents`}>
                <span className={"icon"}>
                  <Icon icon={"fluent:arrow-left-12-filled"} />
                </span>
                <Text size="xl" weight={"bold"}>
                  Back to Text Contents
                </Text>
              </BackLink>
            </Group>
            <Group position="right">
              {!!textContent && (
                <Button
                  leftIcon={
                    <Icon icon={"fluent:delete-24-filled"} height={18} />
                  }
                  onClick={handleDelete}
                  radius={"sm"}
                  color={"red"}
                  variant={"filled"}
                >
                  Delete
                </Button>
              )}
              {!textContent && (
                <Button
                  leftIcon={
                    <Icon icon={"fluent:delete-24-filled"} height={18} />
                  }
                  component={Link}
                  to={`${route}/text-contents`}
                  radius={"sm"}
                  color={"red"}
                  variant={"filled"}
                >
                  Cancel
                </Button>
              )}

              <Button
                type="submit"
                leftIcon={<Icon icon={"fluent:save-24-filled"} height={18} />}
                radius={"sm"}
                color={"teal"}
                variant={"filled"}
                loading={form.formState.isSubmitting}
              >
                {textContent ? "Update" : "Create"}
              </Button>
            </Group>
          </Group>

          <TextContentEdit />
        </Stack>
      </Root>
    </FormProvider>
  );
}

// Styling
const Root = styled.form`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const BackLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;

  color: #2c3e50;

  & {
    transition-property: color;
    transition-duration: 250ms;
    transition-timing-function: ease-in-out;
  }

  &:hover {
    color: #0083ce;
  }

  & .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    margin-right: 8px;
  }
`;
