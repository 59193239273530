import {ActionIcon, Button, createStyles, Group, Stack, Text} from "@mantine/core";
import {useModalStore} from "../hooks/useModalStore";
import {Icon} from "@iconify/react";

export const ErrorModal = () => {
    const {closeModal, state} = useModalStore();
    const {classes} = useStyles()

    return (
        <Stack className={classes.root}>
            <Group position={'apart'} align={'center'}>
                <Group position={"left"}>
                    <Text size={'md'} weight={'bold'}>
                        Error
                    </Text>
                </Group>

                <Group position={'right'}>
                    <ActionIcon onClick={closeModal} variant={'transparent'}>
                        <Icon color={'red'} icon="ep:close"/>
                    </ActionIcon>
                </Group>
            </Group>

            <Stack>
                <Text align={"center"} dangerouslySetInnerHTML={{__html: state.content!}}/>

                <Group grow align={"center"}>
                    <Button color={"red"} onClick={closeModal}>Close</Button>
                </Group>
            </Stack>
        </Stack>
    );
};

const useStyles = createStyles(() => ({
    root: {
        position: 'relative',
        width: 350
    }
}))
