export class LocalStorage {
	// На момент разработки будем хранить токен в local storage браузера
	private storage: Storage;
	private storageKey: string;
	
	constructor(key: string) {
		this.storageKey = key
		this.storage = window.localStorage;
	}
	
	static key(key: string) {
		return new LocalStorage(key)
	}
	
	get() {
		const value = this.storage.getItem(this.storageKey);
		if (!value || value === "undefined") return null

		return value
	}
	
	set(value: any) {
		this.storage.setItem(this.storageKey, value);
	}
	
	remove() {
		this.storage.removeItem(this.storageKey);
	}
}
