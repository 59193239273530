import { FC } from "react";
import styled from "@emotion/styled";
import { Box } from "@mantine/core";
import ContentLoader from "components/ContentLoader";
import { MerchantAdminItemWrapper } from "./components/MerchantAdminItemWrapper";
import { IAccount } from "api/interfaces/account";

interface MerchantAdminsListProps {
  merchantId: UniqueId;
  admins?: IAccount[];
  isLoading: boolean;
}

export const MerchantAdminsList: FC<MerchantAdminsListProps> = ({
  merchantId,
  admins = [],
  isLoading,
}) => {
  return (
    <Box>
      {!isLoading ? (
        <Root>
          {admins.map((admin, i) => (
            <MerchantAdminItemWrapper
              user={admin}
              key={i}
              merchantId={merchantId}
            />
          ))}
        </Root>
      ) : (
        <ContentLoader />
      )}
    </Box>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  background: #ffffff;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  overflow-y: hidden;
`;
