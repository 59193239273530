import {useCallback, useMemo, useState} from "react";
import { useInfiniteQuery } from "react-query"
import {api} from "../../../index";
import {useAuth} from "../../Auth/hooks/useAuth";

export function useSearchMerchant() {
    const { isPlatformAdmin } = useAuth()
    const [search, setSearch] = useState("");
    const {data, hasNextPage, fetchNextPage, ...options} = useInfiniteQuery(['SEARCH_MERCHANTS', search],
        ({pageParam = 0}) => api.merchants.readAll({
            search,
            skip: pageParam,
            limit: 20,
        }), {
            enabled: isPlatformAdmin,
            getNextPageParam: (lastPage, allPages) => {
                if (allPages.length && 20 >= lastPage?.count) return;
                return allPages.length;
            },
        },
    );

    const merchants = useMemo(() => {
        return data?.pages.map(page => page.items).flat() || [];
    }, [data]);

    const onNextPage = useCallback(async () => {
        if (!hasNextPage) return;
        await fetchNextPage();
    }, [hasNextPage, fetchNextPage]);

    return {
        search,
        setSearch,
        merchants,
        onNextPage,
        ...options
    }
}
