import styled from "@emotion/styled";
import {Link} from "react-router-dom";
import {Icon} from "@iconify/react"
import {ActionIcon, Group, Loader, Stack, Text, Title} from "@mantine/core";
import {TextContent} from 'api/interfaces/textContent';
import {useTextContentDelete} from 'features/TextContentEdit/hooks/useTextContentDelete';
import {useMerchantNavigation} from "../../../hooks/useMerchantNavigation";

// Props
type ComponentProps = {
    textContent: TextContent
}

// Component
export default function TextContentItem({textContent}: ComponentProps) {
    const {handleDelete, isLoading} = useTextContentDelete(textContent.id)
    const {route} = useMerchantNavigation()

    return (
        <Link to={`${route}/text-contents/${textContent.id}`}>


            <Root position="apart" spacing={'lg'}>
                <Group position="left" grow>
                    <Stack spacing={0}>
                        <Title order={5}>{textContent.name}</Title>
                        <Text size="sm" color={'dimmed'}>
                            {textContent.text.slice(0, 50)}...
                        </Text>
                    </Stack>
                </Group>

                <Group position="right" spacing={'xs'}>
                    <ActionIcon variant="light" color={'teal'} size={'lg'} radius={'md'}
                                style={{border: "1px solid #cef3e8"}}>
                        <Icon icon={"fluent:edit-24-filled"} color={'teal'}/>
                    </ActionIcon>
                    <ActionIcon onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                        e.preventDefault();
                        handleDelete()
                    }} variant="light" color={'red'} size={'lg'} radius={'md'}
                                style={{border: "1px solid #fbe7e7"}}>
                        {isLoading
                            ? <Loader size="xs" sx={{stroke: "red"}}/>
                            : <Icon icon={"fluent:delete-24-filled"}/>
                        }
                    </ActionIcon>
                </Group>
            </Root>
        </Link>
    )
}

// Styling
const Root = styled(Group)`
  flex-direction: row;

  background-color: #ffffff;
  padding: 8px 16px;
  user-select: none;

  &:not(:last-child) {
    border-bottom: 1px solid #e9ecef;
  }

  & {
    transition-property: background-color;
    transition-duration: 250ms;
    transition-timing-function: ease-in-out;
  }

  &:hover {
    background-color: rgba(44, 62, 80, 0.05);
  }
`;
