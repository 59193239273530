import { useQuery } from "react-query";
import { useMemo } from "react";
import { api } from "index";
import useMerchantSelectorStore from "features/MerchantSelector/hooks/useMerchantSelectorStore";

export default function useTextContentList() {
  const merchantSelector = useMerchantSelectorStore();

  const selectedId = merchantSelector.useStore((state) => state.selected);
  const { data, ...options } = useQuery(
    ["TEXT_CONTENT_LIST", selectedId],
    () => api.textContents.readAll(Number(selectedId), {}),
    {
      staleTime: Infinity,
    }
  );

  const textContents = useMemo(() => {
    return data?.items || [];
  }, [data]);

  return { textContents, ...options };
}
