import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "store";
import slice, { UserInterfaceState } from "../store";

export const useUserInterfaceStore = () => {
  const state = useSelector((state: AppState) => state.userInterface);
  const dispatch = useDispatch();

  const toggleInterface = useCallback(() => {
    dispatch(slice.actions.toggleAll());
  }, [dispatch]);

  const toggleElement = useCallback((key: keyof UserInterfaceState) => {
    dispatch(slice.actions.toggleElement(key));
  }, [dispatch]);

  return {
    state,
		toggleElement,
		toggleInterface
  };
};
