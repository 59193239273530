import axios from "axios";
import TokenStorage from "helpers/tokenStorage";

import Auth from "./Auth";
import Files from "./Files";
import Events from "./Events";
import Account from "./Account";
import Contents from "./Contents";
import Locations from "./Locations";
import TextContents from "./TextContents";
import ImageContents from "./ImageContents";
import Admins from "./Admin";
import OpenStreet from "./OpenStreet";
import LocationEvents from "./LocationEvents";
import Settings from "./Settings";
import Constants from "./Constants";
import AdminConstants from "./AdminConstants";
import AppInvites from "./AppInvites";
import AllowedCities from "./AlllowedCities";
import Merchants from "./Merchants";
import Balance from "./Balance";
import Analytics from "./Analytics";
import Users from "./Users";
import Advertisements from "./Advertisements";
import CollectionCards from './CollectionCards';
import Games from "./Games";
import Coupons from "./Coupons";

export default class Api {
  baseUrl = "/api/v1";
  auth: Auth;
  files: Files;
  admins: Admins;
  events: Events;
  account: Account;
  contents: Contents;
  locations: Locations;
  openStreet: OpenStreet;
  textContents: TextContents;
  imageContents: ImageContents;
  locationEvents: LocationEvents;
  settings: Settings;
  constants: Constants;
  adminConstants: AdminConstants;
  appInvites: AppInvites;
  allowedCities: AllowedCities;
  merchants: Merchants;
  balance: Balance;
  analytics: Analytics;
  users: Users;
  advertisements: Advertisements;
  collectionCards: CollectionCards;
  games: Games;
  coupons: Coupons;

  constructor() {
    this.auth = new Auth(this.request, this.baseUrl);
    this.files = new Files(this.request, this.baseUrl);
    this.admins = new Admins(this.request, this.baseUrl);
    this.events = new Events(this.request, this.baseUrl);
    this.account = new Account(this.request, this.baseUrl);
    this.contents = new Contents(this.request, this.baseUrl);
    this.locations = new Locations(this.request, this.baseUrl);
    this.openStreet = new OpenStreet(this.request, this.baseUrl);
    this.textContents = new TextContents(this.request, this.baseUrl);
    this.imageContents = new ImageContents(this.request, this.baseUrl);
    this.locationEvents = new LocationEvents(this.request, this.baseUrl);
    this.settings = new Settings(this.request, this.baseUrl);
    this.constants = new Constants(this.request, this.baseUrl);
    this.adminConstants = new AdminConstants(this.request, this.baseUrl);
    this.appInvites = new AppInvites(this.request, this.baseUrl);
    this.allowedCities = new AllowedCities(this.request, this.baseUrl);
    this.users = new Users(this.request, this.baseUrl);
    // NEW API
    this.merchants = new Merchants(this.request, this.baseUrl);
    this.balance = new Balance(this.request, this.baseUrl);
    this.analytics = new Analytics(this.request, this.baseUrl);
    this.advertisements = new Advertisements(this.request, this.baseUrl)
    this.collectionCards = new CollectionCards(this.request, this.baseUrl);
    this.games = new Games(this.request, this.baseUrl);
    this.coupons = new Coupons(this.request, this.baseUrl);
  }

  // инстанс запросов через который буду выполятся все запросы на бекенд
  get request() {
    const instance = axios.create();

    instance.interceptors.request.use((config) => {
      // TODO: get token from storage
      const token = TokenStorage.getToken();

      if (token) {
        (config.headers as any).common["Authorization"] = `Bearer ${token}`;
      }

      return config;
    });

    return instance;
  }
}
