import { useMemo } from "react";
import type { Content } from "api/interfaces/content";
import usePaginatedData from "hooks/usePaginatedData";
import { useMerchantTargetStore } from "store/merchant-target.store";

export default function useMerchantTargetGroups(merchant_id: string | number) {
    const sync = useMerchantTargetStore(state => state.syncMerchantContents);
    const targetGroup = useMerchantTargetStore(state => state.targets.find(({ merchant_id: id }) => id === Number(merchant_id))!);

    const { items: contents, ...options } = usePaginatedData<Content>({
        path: `/admin/merchants/${merchant_id}/contents`,
        defaultLimit: 1000,
        queryKey: ["CONTENT_LIST", merchant_id],
        onSuccess(data) {
            if (merchant_id) {
                sync(Number(merchant_id), data.items?.map((item: any) => item.id) ?? []);
            }
        },
    });

    const items = useMemo(() => {
        const existsTargetGroups = targetGroup?.target_contents
            .filter((target) => contents.some(content => target.content_id === content.id)) ?? []

        return existsTargetGroups.map((content) => {
            const item = contents.find(({ id }) => id === content.content_id)!;
            return { ...item, targetGroup: content }
        });
    }, [contents, targetGroup?.target_contents]);

    return {
        items,
        ...options,
    }
}