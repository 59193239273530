import { css } from "@emotion/react";
import styled from "@emotion/styled";
import {
  CSSObject,
  Group,
  Paper,
  Select,
  Stack,
  Switch,
  TextInput,
} from "@mantine/core";
import { useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import useLocationList from "../../LocationList/hooks/useLocationList";
import useEventList from "../../EventList/hooks/useEventList";

// Component
export default function LocationEventEditForm() {
  const { control } = useFormContext();
  const { events } = useEventList();
  const { locations } = useLocationList();

  const eventList = useMemo(() => {
    return events.map((event) => ({
      value: String(event.id),
      label: event.name,
    }));
  }, [events]);

  const locationList = useMemo(() => {
    return locations.map((content) => ({
      value: String(content.id),
      label: content.name,
    }));
  }, [locations]);

  return (
    <Root radius="md" withBorder p="lg">
      <Group grow spacing={"lg"} align={"flex-start"}>
        <Stack spacing="lg">
          {/* Name */}
          <Stack>
            <Controller
              name="name"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  label="Name"
                  placeholder="Enter location event name"
                  variant="filled"
                  error={fieldState.error?.message}
                  required
                  classNames={{ input: "input-field" }}
                  {...field}
                />
              )}
            />
          </Stack>

          {/* Text Content */}
          <Stack>
            <Controller
              name="locationId"
              control={control}
              render={({ field }) => (
                <SelectField
                  label="Location"
                  placeholder="Select location"
                  required
                  variant="filled"
                  searchable
                  data={locationList}
                  classNames={{ input: "input-field" }}
                  {...field}
                  value={String(field.value)}
                  onChange={(value) => field.onChange(Number(value))}
                />
              )}
            />
          </Stack>

          {/* isGiftForPurchase */}
        </Stack>
        <Stack spacing="lg">
          {/* Content Type */}
          <Stack>
            <Controller
              name="eventId"
              control={control}
              render={({ field }) => (
                <SelectField
                  label="Campaign"
                  placeholder="Select event"
                  required
                  variant="filled"
                  searchable
                  data={eventList}
                  classNames={{ input: "input-field" }}
                  {...field}
                  value={String(field.value)}
                  onChange={(value) => field.onChange(Number(value))}
                />
              )}
            />
          </Stack>
        </Stack>
      </Group>
      <Group mt={20} spacing={30}>
        <Stack>
          <Controller
            name="isGiftForPurchase"
            control={control}
            render={({ field }) => (
              <Switch
                label="Use Gifts For Purchase"
                styles={SwitcherStyles}
                checked={field.value}
                onChange={field.onChange}
              />
            )}
          />
        </Stack>
        <Stack>
          <Controller
            name="isSubsequentReward"
            control={control}
            render={({ field }) => (
              <Switch
                label="Subsequent Reward"
                styles={SwitcherStyles}
                checked={field.value}
                onChange={field.onChange}
              />
            )}
          />
        </Stack>
        <Stack>
          <Controller
            name="isInitialReward"
            control={control}
            render={({ field }) => (
              <Switch
                label="Initial Reward"
                styles={SwitcherStyles}
                checked={field.value}
                onChange={field.onChange}
              />
            )}
          />
        </Stack>
      </Group>
    </Root>
  );
}

const SwitcherStyles: Record<"input" | "label" | "root" | "body", CSSObject> = {
  root: {
    flexDirection: "column",
    alignItems: "flex-start",
    cursor: 'pointer',
  },
  label: {
    paddingLeft: 0,
    order: 1,
    marginBottom: 4,
    fontSize: 14,
    fontWeight: 500,
    color: "#212529",
    wordBreak: "break-word",
    cursor: "default",
  },
  input: {
    order: 2,
    cursor: "pointer",
  },
  body: {
    gap: 6
  }
};

const InputCSS = css`
  display: flex;
  font-size: 14px;
  font-weight: 400;
  background: #fcfdfe;
  color: #9fa2b4;
  line-height: 1.5;
  min-height: 42px;
  outline: none;
  padding: 10px 16px;
  width: 100%;
  transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out,
    color 0.2s ease-in-out;

  &:hover,
  &:focus {
    color: #252733;
    border-color: #0095e5;
  }

  &::placeholder {
    color: rgb(75, 80, 109, 0.5);
  }
`;

const FieldCSS = css`
  flex: 1;

  & .input-field {
    ${InputCSS};
  }
`;
// Styling
const Root = styled(Paper)<any>`
  margin-top: 20px;
  overflow: hidden;
`;

const TextField = styled<any>(TextInput)`
  ${FieldCSS};
  position: relative;
  .mantine-TextInput-error {
    position: absolute;
    left: 0;
    bottom: -24px;
  }
  & .input-field {
    border: 1px solid ${({ error }) => (error ? "#f03e3e" : "#f0f1f7")};
  }
`;

const SelectField = styled(Select)`
  ${FieldCSS};

  & .input-field {
    border: 1px solid ${({ error }) => (error ? "#f03e3e" : "#f0f1f7")};
  }
`;
