import styled from "@emotion/styled";
import {Link} from "react-router-dom";
import {Icon} from "@iconify/react";
import {ActionIcon, Avatar, Group, Loader, Stack, Text, Title} from "@mantine/core";
import {ImageContent} from "api/interfaces/imageContents";
import {useImageContentDelete} from "features/ImageContentEdit/hooks/useImageContentDelete";
import {useMerchantNavigation} from "../../../hooks/useMerchantNavigation";

// Props
type ComponentProps = {
    imageContent: ImageContent;
};

// Component
export default function ImageContentItem({imageContent}: ComponentProps) {
    const {handleDelete, isLoading} = useImageContentDelete(imageContent.id);
    const {route} = useMerchantNavigation()

    return (
        <Link to={`${route}/image-contents/${imageContent.id}`}>
            <Root grow position="apart">
                <Group position='left'>
                    <Stack>
                        <Avatar size={42} radius={"xl"} src={imageContent.url} alt={imageContent.name}/>
                    </Stack>
                    <Stack spacing={0}>
                        <Title order={5}>{imageContent.name}</Title>
                        <Text size="sm" color={'dimmed'}>
                            {imageContent.ImageContentType.name}
                        </Text>
                    </Stack>
                </Group>

                <Group position="right" spacing={'xs'}>
                    <ActionIcon variant="light" color={'teal'} size={'lg'} radius={'md'}
                                style={{border: "1px solid #cef3e8"}}>
                        <Icon icon={"fluent:edit-24-filled"} color={'teal'}/>
                    </ActionIcon>
                    <ActionIcon onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                        e.preventDefault();
                        handleDelete()
                    }} variant="light" color={'red'} size={'lg'} radius={'md'} style={{border: "1px solid #fbe7e7"}}>
                        {isLoading
                            ? <Loader size="xs" sx={{stroke: "red"}}/>
                            : <Icon icon={"fluent:delete-24-filled"}/>
                        }
                    </ActionIcon>
                </Group>
            </Root>
        </Link>
    );
}

// Styling
const Root = styled(Group)`
  flex-direction: row;

  background-color: #ffffff;
  padding: 8px 16px;
  user-select: none;

  &:not(:last-child) {
    border-bottom: 1px solid #e9ecef;
  }

  & {
    transition-property: background-color;
    transition-duration: 250ms;
    transition-timing-function: ease-in-out;
  }

  &:hover {
    background-color: rgba(44, 62, 80, 0.05);
  }
`;
