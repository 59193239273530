import { SettingsLayout } from "pages/platform/settings/SettingsLayout";
import { Card, Stack, Button, NumberInput, Group } from "@mantine/core";
import { Icon } from "@iconify/react";
import { showNotification } from "@mantine/notifications";
import { useState, useEffect, FC } from "react";

const updateTransaction = new Event("updTransaction");

export const FeeSettings: FC = () => {
    const [p2p, setP2p] = useState<number | undefined>(undefined);
    const [credit, setCredit] = useState<number | undefined>(undefined);
    const [loadingP, setLoadingP] = useState<boolean>(false);
    const [loadingC, setLoadingC] = useState<boolean>(false);

    const handlePlanSave = (type: string) => {
        if (type == "credit card" && !credit) {
            localStorage.removeItem("Credit_card_transaction");
        }
        if (type == "p2p" && !p2p) {
            localStorage.removeItem("P2P_transaction");
        }
        type === "p2p" ? setLoadingP(true) : setLoadingC(true);
     
        const timeout = setTimeout(() => {
            showNotification({
                title: "Success!",
                message: `${type} fee updated`,
                color: "green",
                autoClose: 3000,
            });

            type === "p2p" ? setLoadingP(false) : setLoadingC(false);

            if (type == "p2p" && p2p) localStorage.setItem("P2P_transaction", String(p2p));
            else if (credit) localStorage.setItem("Credit_card_transaction", String(credit));
            window.dispatchEvent(updateTransaction);
            clearTimeout(timeout);
        }, 300);
    }

    useEffect(() => {
        const pTp = localStorage.getItem("P2P_transaction");
        if (pTp) setP2p(Number(pTp));
        const creditCard = localStorage.getItem("Credit_card_transaction");
        if (creditCard) setCredit(Number(creditCard));
    }, []);

    return (
        <SettingsLayout>
            <Card mt={20}>
                <Group spacing={30}>
                    <Stack>
                        <NumberInput
                            label="Merchant p2p fee"
                            placeholder="%"
                            hideControls
                            precision={2}
                            min={0.01}
                            max={100}
                            icon={<Icon icon={"ri:p2p-fill"} />}
                            value={p2p ? p2p : undefined}
                            onChange={(value: number) => setP2p(value)}
                            formatter={(value) => `${value}%`}
                        />
                        <Button
                            leftIcon={<Icon icon={"fluent:save-24-filled"} height={18} />}
                            radius={"sm"}
                            sx={{ width: "fit-content" }}
                            color={"teal"}
                            variant={"filled"}
                            onClick={() => handlePlanSave("p2p")}
                            loading={loadingP}
                        >
                            Update
                        </Button>
                    </Stack>
                    <Stack>
                        <NumberInput
                            label="Merchant credit card fee"
                            placeholder="%"
                            hideControls
                            precision={2}
                            min={0.01}
                            max={100}
                            icon={<Icon icon={"f7:creditcard"} />}
                            value={credit ? credit : undefined}
                            onChange={(value: number) => setCredit(value)}
                            formatter={(value) => `${value}%`}
                        />
                        <Group>
                            <Button
                                leftIcon={
                                    <Icon icon={"fluent:save-24-filled"} height={18} />
                                }
                                radius={"sm"}
                                sx={{ width: "fit-content" }}
                                color={"teal"}
                                variant={"filled"}
                                onClick={() => handlePlanSave("credit card")}
                                loading={loadingC}
                            >
                                Update
                            </Button>
                        </Group>
                    </Stack>
                </Group>
            </Card>
        </SettingsLayout>
    );
}
