import { api } from "index";
import { useMutation, useQueryClient } from "react-query";
import { useCallback } from "react";
import { Location, LocationInput } from "api/interfaces/location";
import useMerchantSelectorStore from "features/MerchantSelector/hooks/useMerchantSelectorStore";
import { showNotification } from "@mantine/notifications";
import { ErrorResponse } from "api/interfaces/api";

export const useLocationUpdate = (id?: Location["id"]) => {
  const queryClient = useQueryClient();

  const merchantSelector = useMerchantSelectorStore();

  const selectedId = merchantSelector.useStore((state) => state.selected);

  // Event Update Handler
  const { mutateAsync: update, ...options } = useMutation(
    (values: LocationInput) => api.locations.update(values, Number(selectedId))
  );

  // Handler for forms
  const handleUpdate = useCallback(
    async (input: LocationInput) => {
      if (!id) return;

      try {
        await update({ ...input, id });
        await Promise.all([
          queryClient.invalidateQueries(["LOCATION_LIST"]),
          queryClient.invalidateQueries(["LOCATION"]),
        ]);
        showNotification({
          autoClose: 3000,
          title: "Success!",
          message: "Location successfully updated!",
          color: "green",
        });
      } catch (e: any) {
        const error: ErrorResponse = e.response.data;
        showNotification({
          message: error.error ?? "Something went wrong.",
          title: "Error!",
          color: "red",
          autoClose: 5000,
        });
      }
    },
    [id, update]
  );

  return {
    handleUpdate,
    ...options,
  };
};
