import styled from "@emotion/styled";
import { Container, Stack } from "@mantine/core";
import { Link, useParams } from "react-router-dom";

const ReferralPage = () => {
  const { userId } = useParams<{ userId: string }>();

  return (
    <Container px={25} size={1520}>
      <Stack mb={58} mt={58} align="center">
        <BrandLogo to={"/startup"}>
          <span className={"name"}>QUBI</span>
          <span className={"type"}>CMS</span>
        </BrandLogo>

        <Title>
          Hello, if you haven't been redirected to the application, please click
          on the link provided below.
        </Title>
        <CustomLink
          href={`qubiapp://openScreen?screen=welcome-screen&userId=${userId}`}
        >
          Invitation link
        </CustomLink>
      </Stack>
    </Container>
  );
};

export default ReferralPage;

const BrandLogo = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  height: 60px;

  & > * {
    font-family: "Fredoka One", serif;
    font-size: 28px;
  }

  & .name {
    color: rgb(18, 184, 134);
  }

  & .type {
    color: #2c3e50;
  }
`;

const Title = styled.div`
  font-family: Roboto;
  -webkit-tap-highlight-color: transparent;
  color: inherit;
  font-size: inherit;
  line-height: 1.55;
  -webkit-text-decoration: none;
  text-decoration: none;
  font-size: 1.25rem;
  line-height: 2.7em;
  font-weight: 400;
  color: #102839;
  text-align: center;
  margin-bottom: 0;

  @media (max-width: 700px) {
    line-height: 1.2em;
  }
`;

const CustomLink = styled.a`
  margin-top: 0;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.25rem;
  text-align: center;
  color: #102839;
  -webkit-text-decoration: underline;
  text-decoration: underline;
`;
