import { useQuery } from "react-query";
import { api } from "index";
import { ImageContent } from "api/interfaces/imageContents";
import useMerchantSelectorStore from "features/MerchantSelector/hooks/useMerchantSelectorStore";

export function useImageContent(id?: ImageContent["id"]) {
  const merchantSelector = useMerchantSelectorStore();

  const selectedId = merchantSelector.useStore((state) => state.selected);
  const {
    data: imageContent,
    isError,
    isLoading,
  } = useQuery(
    ["IMAGE_CONTENT", selectedId, id],
    () => api.imageContents.readOne(id, Number(selectedId)),
    {
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
      enabled: !!id,
      retry: 0,
    }
  );

  return { imageContent, isError, isLoading };
}
