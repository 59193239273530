import { useQuery } from "react-query";
import { useMemo } from "react";
import { api } from "index";
import useMerchantSelectorStore from "features/MerchantSelector/hooks/useMerchantSelectorStore";

export default function useContentList() {
  const merchantSelector = useMerchantSelectorStore();

  const selectedId = merchantSelector.useStore((state) => state.selected);
  const { data, ...options } = useQuery(
    ["CONTENT_LIST", selectedId],
    () => api.contents.readAll(Number(selectedId), {}),
    {
      staleTime: Infinity,
    }
  );

  const contents = useMemo(() => {
    return data?.items || [];
  }, [data]);

  return { contents, ...options };
}
