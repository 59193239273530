import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAccount } from "api/interfaces/account";
import TokenStorage from "helpers/tokenStorage";

// Auth Types
export interface AuthState {
  loggedIn: boolean;
  user?: IAccount;
}

// Initial State
const initialState: AuthState = {
  loggedIn: Boolean(TokenStorage.getToken()),
  user: undefined,
};

// Auth Store
export default createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (_, action: PayloadAction<IAccount>) => ({
      user: action.payload,
      loggedIn: true,
    }),
    logout: () => ({
      user: undefined,
      loggedIn: false,
    }),
  },
});
