import {
  Button,
  Group,
  NumberInput,
  Paper,
  Stack,
  Text,
  TextInput, Tooltip,
} from "@mantine/core";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useEvent } from "../hooks/useEvent";
import { useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { api } from "index";
import useMerchantSelectorStore from "features/MerchantSelector/hooks/useMerchantSelectorStore";
import { useModalStore } from "features/Modals/hooks/useModalStore";
import { useMemo } from "react";
import dayjs from "dayjs";
import { useMerchantDetails } from "../../MerchantProfile/hooks/useMerchantDetails";
import { useAuth } from "../../Auth/hooks/useAuth";
import { showNotification } from "@mantine/notifications";
import { ErrorResponse } from "api/interfaces/api";
import {Icon} from "@iconify/react";

const schema = yup
  .object({
    depositEvent: yup
      .number()
      .positive("Amount cannot be negative or zero")
      .required("Field cannot be empty"),
  })
  .required();

// Component
export default function PaymentEventForm() {
  const { id } = useParams();
  const { event: LocationEvent, refetch } = useEvent(Number(id));
  const { setModalStatus, setupModal } = useModalStore();
  const { merchant } = useMerchantDetails();
  const { user } = useAuth();

  const isEventEnd = useMemo(() => {
    if (!LocationEvent) return true;
    return !dayjs(LocationEvent.endDate).isAfter(new Date(), "second");
  }, [LocationEvent]);

  const merchantSelector = useMerchantSelectorStore();

  const selectedId = merchantSelector.useStore((state) => state.selected);

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      depositEvent: 0,
    },
  });

  const handleDeposit = form.handleSubmit(async (values) => {
    if (!LocationEvent) return;

    if (merchant!.balance < values.depositEvent && merchant?.isVerification) {
      setupModal({
        type: "error",
        content: "Not enough funds to replenish",
      });
      setModalStatus(true);
      return;
    } else if (!merchant?.isVerification) {
      setupModal({
        type: "verification",
        content: "Please wait until the administrator confirms your account",
      });
      setModalStatus(true);
      return;
    }

    try {
      await api.events.depositEvent(
        LocationEvent.id,
        values.depositEvent,
        Number(selectedId)
      );
      showNotification({
        autoClose: 3000,
        title: "Success!",
        message: "The balance has been successfully replenished",
        color: "green",
      });
      await refetch();
    } catch (e: any) {
      const error: ErrorResponse = e.response.data;
      showNotification({
        message: error.error ?? "Something went wrong.",
        title: "Error!",
        color: "red",
        autoClose: 5000,
      });
    }
  });

  const handleWithdraw = async () => {
    if (!LocationEvent) return;

    if (!merchant?.isVerification) {
      setupModal({
        type: "verification",
        content: "Please wait until the administrator confirms your account",
      });
      setModalStatus(true);
      return;
    }

    try {
      await api.events.withdrawEvent(LocationEvent.id, Number(selectedId));
      showNotification({
        autoClose: 3000,
        title: "Success!",
        message: "Balance successfully withdrawn",
        color: "green",
      });
    } catch (e: any) {
      const error: ErrorResponse = e.response.data;
      showNotification({
        message: error.error ?? "Something went wrong.",
        title: "Error!",
        color: "red",
        autoClose: 5000,
      });
    }
    await refetch();
  };

  if (!LocationEvent) return null;

  return (
    <Root radius="md" withBorder p="lg">
      <Stack spacing={0}>
        {/* Header */}
        <Group grow pb={20} style={{ borderBottom: "1px solid #e9ecef" }}>
          <Group position="left">
            <Text size="md" weight="bold">
              Payment information
            </Text>

            <Tooltip multiline width={220} withArrow label="Make sure you deposit enough money for your purposes. (Location event won't be depicted in mobile applications if you have not enough money to pay fee).">
              <Icon icon="material-symbols:info-outline" color="#f55200" />
            </Tooltip>
          </Group>
        </Group>

        {/*Content */}
        <Stack mt={20}>
          <Group grow style={{ gap: 20 }} spacing={0}>
            <Stack>
              <NumberField
                label="Total visits"
                variant="filled"
                value={LocationEvent.totalVisits}
                hideControls
                disabled
                classNames={{ input: "input-field" }}
              />
            </Stack>
            <Stack>
              <NumberField
                label="Left visits"
                variant="filled"
                value={LocationEvent.leftVisits}
                hideControls
                disabled
                classNames={{ input: "input-field" }}
              />
            </Stack>
          </Group>

          <Group grow style={{ gap: 20 }} spacing={0}>
            <Stack>
              <TextInput
                label="Total deposit (Qubi coins)"
                variant="filled"
                value={LocationEvent.totalDeposit.toFixed(3)}
                disabled
                classNames={{ input: "input-field" }}
              />
            </Stack>
            <Stack>
              <TextInput
                label="Left deposit (Qubi coins)"
                variant="filled"
                value={LocationEvent.leftDeposit.toFixed(3)}
                disabled
                classNames={{ input: "input-field" }}
              />
            </Stack>
          </Group>

          {user?.Role.id !== 4 && (
            <StyledGroup align={"flex-end"}>
              <Controller
                name="depositEvent"
                control={form.control}
                render={({ field, fieldState }) => (
                  <NumberField
                    label="Deposit event (Qubi coins)"
                    variant="filled"
                    value={field.value}
                    onChange={field.onChange}
                    hideControls
                    precision={2}
                    min={0}
                    classNames={{ input: "input-field" }}
                    error={fieldState.error?.message}
                  />
                )}
              />
              <Button size="md" onClick={handleDeposit}>
                Refill balance
              </Button>
            </StyledGroup>
          )}
          {user?.Role.id !== 4 && isEventEnd && (
            <Button
              color={"blue"}
              disabled={!(LocationEvent.leftDeposit > 0)}
              onClick={() => handleWithdraw()}
              sx={{ width: "fit-content" }}
            >
              Withdraw
            </Button>
          )}
        </Stack>
      </Stack>
    </Root>
  );
}

const InputCSS = css`
  display: flex;
  font-size: 14px;
  font-weight: 400;
  height: 42px;
  background: #fcfdfe;
  border: 1px solid #f0f1f7;
  color: #9fa2b4;
  line-height: 1.5;
  min-height: 42px;
  outline: none;
  padding: 10px 16px;
  width: 100%;
  transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out,
    color 0.2s ease-in-out;

  &:disabled {
    background-color: #f1f3f5;
  }

  &:hover,
  &:focus {
    color: #252733;
    border-color: #0095e5;
  }

  &::placeholder {
    color: rgb(75, 80, 109, 0.5);
  }
`;

const FieldCSS = css`
  flex: 1;

  & .input-field {
    ${InputCSS};
  }
`;
// Styling
const Root = styled(Paper)<any>`
  overflow: hidden;
`;

const NumberField = styled(NumberInput)`
  ${FieldCSS};
  position: relative;
  .mantine-NumberInput-error {
    position: absolute;
    left: 0;
    bottom: -19px;
  }
`;

const StyledGroup = styled(Group)`
  .mantine-NumberInput-wrapper {
    margin: 0;
  }
`;
