import {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "store";
import modalSlice, {ModalSlice} from "../store/modalSlice";

interface setupModalProps {
    action?: any;
    type: ModalSlice['type'];
    content: string
}

export const useModalStore = () => {
    const state = useSelector((state: AppState) => state.modal);
    const dispatch = useDispatch()

    const setContent = useCallback((payload?: string) => {
        dispatch(modalSlice.actions.setModalContent(payload))
    }, [dispatch])

    const setModalStatus = useCallback((status: boolean) => {
        dispatch(modalSlice.actions.setStatus(status))
    }, [dispatch])

    const changeModalType = useCallback((type: ModalSlice['type']) => {
        dispatch(modalSlice.actions.changeModalType(type))
    }, [dispatch])

    const setModalAction = useCallback((action: any) => {
        dispatch(modalSlice.actions.changeModalAction(action))
    }, [dispatch])

    const clearModalStore = useCallback(() => {
        setModalAction(undefined)
        changeModalType(undefined)
        setContent(undefined)
        setActionLoading(false)
    }, [])

    const closeModal = useCallback(() => {
        setModalStatus(false)
        const timer = setTimeout(() => {
            clearTimeout(timer)
            clearModalStore()
        }, 300)
    }, [clearModalStore, setModalStatus])

    const setupModal = useCallback(({type, content, action}: setupModalProps) => {
        setModalAction(action)
        changeModalType(type)
        setContent(content)
    }, [setModalAction, changeModalType, setContent])

    const setActionLoading = useCallback((value: boolean) => {
        dispatch(modalSlice.actions.changeLoading(value))
    }, [dispatch])

    return {
        state,
        setContent,
        setModalStatus,
        changeModalType,
        setModalAction,
        clearModalStore,
        closeModal,
        setupModal,
        setActionLoading,
        opened: state.status
    }
}
