import { useModalStore } from "features/Modals/hooks/useModalStore";
import { api } from "index";
import { useMutation, useQueryClient } from "react-query";
import { useCallback } from "react";
import { showNotification } from "@mantine/notifications";

export const useAppInviteDelete = () => {
  const queryClient = useQueryClient();
  const { setupModal, setModalStatus, closeModal } = useModalStore();

  const { mutateAsync, ...options } = useMutation(
    (id: number) => api.appInvites.remove(id),
    {
      onSuccess: async () => {
        await Promise.all([
          queryClient.invalidateQueries(["APP_INVITES_LIST"]),
          queryClient.invalidateQueries(["APP_INVITES_LIST"]),
        ]);
      },
    }
  );

  const handleDelete = useCallback(
    (id: number) => {
      const onDelete = async () => {
        closeModal();
        const res = await mutateAsync(id);
        if (typeof res === "object") {
          showNotification({
            message: res.error ?? "Something went wrong.",
            title: "Error!",
            color: "red",
            autoClose: 5000,
          });
        } else {
          closeModal();
          showNotification({
            autoClose: 3000,
            title: "Success!",
            message: "Invite Code successfully deleted!",
            color: "green",
          });
        }
      };

      setupModal({
        action: onDelete,
        type: "delete",
        content:
          "Please confirm your action. <br/>Selected Invite Event will be deleted!",
      });

      setModalStatus(true);
    },
    [mutateAsync]
  );

  return { handleDelete, ...options };
};
