import { FC, useMemo } from "react";
import styled from "@emotion/styled";
import { Box, Modal } from "@mantine/core";
import ContentLoader from "components/ContentLoader";
import { InviteAdminModal } from "./components/InviteAdminModal";
import { usePlatformAdminInvite } from "./hooks/platform/usePlatformAdminInvite";
import { IAccount } from "api/interfaces/account";
import { PlatformAdminItemWrapper } from "./components/PlatformAdminItemWrapper";

interface ComponentProps {
  inviteStatus: boolean;
  closeInvite: (val: boolean) => void;
  admins?: IAccount[];
  isLoading: boolean;
}

export const PlatformAdminsList: FC<ComponentProps> = ({
  inviteStatus,
  closeInvite,
  admins = [],
  isLoading,
}) => {
  const { handleInvite, isLoading: isInviteLoading } = usePlatformAdminInvite();

  const memoList = useMemo(() => {
    return admins.map((admin, i) => (
      <PlatformAdminItemWrapper user={admin} key={i} />
    ));
  }, [admins]);

  return (
    <Box>
      {!isLoading ? <Root>{memoList}</Root> : <ContentLoader />}
      <Modal
        onClose={() => closeInvite(false)}
        opened={inviteStatus}
        withCloseButton={false}
        overlayProps={{opacity: 0.5}}
        transitionProps={{ transition: "slide-down", duration: 400 }}
        closeOnClickOutside={false}
        padding={30}
        centered
        size={"auto"}
      >
        <InviteAdminModal
          handleInvite={handleInvite}
          isLoading={isInviteLoading}
          close={() => closeInvite(false)}
        />
      </Modal>
    </Box>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  background: #ffffff;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  overflow-y: hidden;
`;
