import useMerchantSelectorStore from "features/MerchantSelector/hooks/useMerchantSelectorStore";
import { api } from "index";
import { useQuery } from "react-query";

export const useEventAnalytics = (id?: number) => {
  const merchantSelector = useMerchantSelectorStore();

  const selected = merchantSelector.useStore((state) => state.selected);
  const { data, ...params } = useQuery(
    ["EVENT_ANALYTIC", id, Number(selected)],
    () =>
      api.events.getEventAnalytic(id, Number(selected))
  );

  return { ...params, analytics: data};
};
