import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { nanoid } from '@reduxjs/toolkit';

type TReward = {
    rewardType: string,
    rewardName: string,
    contentId: number,
    rewardsQuantity: number,
    extraChance: number,
    userId: number,
    campaignId: string | undefined,
    id: string,
};

interface IRewardStore {
    rewards: TReward[],
    campaignRewards: TReward[] | [];
    addReward: (reward: TReward, campaignId: string | undefined) => void,
    getCampaignRewards: (campaignId: string | undefined) => void,
    saveRewards: (campaignId: string) => void,
    deleteReward: (id: string) => void,
    resetCampaignRewards: () => void,
};

const init = {
    rewards: localStorage.getItem("rewards") ? JSON.parse(localStorage.getItem("rewards")!) : [],
    campaignRewards: [],
};

export const useEventRandomReward = create<IRewardStore>()(
    devtools(
        (set, get) => ({
            ...init,
            addReward: (reward: any, campaignId: string | undefined,) => {
                reward.campaignId = campaignId;
                reward.id = nanoid();
                set(get().campaignRewards = [...get().campaignRewards, reward] as any);
            },
            getCampaignRewards: (campaignId: string | undefined) => {
                if (!campaignId) {
                    get().resetCampaignRewards();
                    return;
                };
                const filteredRewards = get().rewards.filter((reward: TReward) => reward.campaignId == campaignId);
                set(get().campaignRewards = [...filteredRewards] as any);
            },
            saveRewards: (campaignId: string) => {
                const isId = get().rewards.filter((reward: TReward) => reward.campaignId == campaignId);
                const rewardsWithId = get().campaignRewards.map((reward: TReward) => { return { ...reward, campaignId } });
                set(get().campaignRewards = [...rewardsWithId] as any);
                if (isId.length > 0) {
                    const filteredRewards = get().rewards.filter((reward: TReward) => reward.campaignId != campaignId);
                    set(get().rewards = [...filteredRewards, ...get().campaignRewards] as any);
                    localStorage.setItem("rewards", JSON.stringify(get().rewards));
                } else {
                    set(get().rewards = [...get().rewards, ...get().campaignRewards] as any);
                    localStorage.setItem("rewards", JSON.stringify(get().rewards));
                }


            },
            deleteReward: (id: string) => {
                const filteredRewards = get().campaignRewards.filter((reward: TReward) => reward.id != id);
                set(get().campaignRewards = [...filteredRewards] as any);
            },
            resetCampaignRewards: () => { 
                set(get().campaignRewards = [] as any);
            },
        })
    )
);

