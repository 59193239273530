import { api } from "index";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { ErrorResponse } from "api/interfaces/api";
import { showNotification } from "@mantine/notifications";
import {Advertisement} from "../../../api/interfaces/advertisements";

export const useAdvertisementsCreate = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  // Event Create Handler
  const { mutateAsync: create, ...options } = useMutation(
    (input: Advertisement) =>
      api.advertisements.create(input)
  );

  // Handler for forms
  const handleCreate = useCallback(
    async (input: Advertisement) => {
      console.log(input)
      try {
        const advertisements = await create(input);
        navigate(`/platform/advertisements/${advertisements.id}`);

        await Promise.all([
          queryClient.invalidateQueries(["ADVERTISEMENTS_LIST"]),
          queryClient.invalidateQueries(["ADVERTISEMENTS"]),
        ]);
        showNotification({
          autoClose: 3000,
          title: "Success!",
          message: "Image Content successfully created!",
          color: "green",
        });
      } catch (e: any) {
        const error: ErrorResponse = e.response.data;
        showNotification({
          message: error.error ?? "Something went wrong.",
          title: "Error!",
          color: "red",
          autoClose: 5000,
        });
      }
    },
    [create, navigate]
  );

  return {
    handleCreate,
    ...options,
  };
};
