import * as yup from "yup";

export const questSchema = yup.object({
  title: yup.string().required("Title is required"),
  description: yup.string().required("Description is required"),
  questTypeId: yup.mixed().required("Quest type ID is required"),
  startDate: yup
    .date()
    .required("Start Date is required")
    .typeError("Invalid Date"),
  endDate: yup
    .date()
    .required("End Date is required")
    .typeError("Invalid Date"),
  achievementRewardId: yup.mixed(),
  completionRewardId: yup
    .mixed()
    .required("Quest Completion Content is required"),
  questConditions: yup
    .array()
    .of(
      yup.object({
        conditionTypeId: yup
          .number()
          .required("Action Conditions ID is required"),
        collectibleId: yup.number().when("conditionTypeId", {
          is: 1,
          then: yup.number().required("Collectible ID is required"),
          otherwise: yup.number().nullable(),
        }),
        contentId: yup.number().when("conditionTypeId", {
          is: 2,
          then: yup.number().required("Reward ID is required"),
          otherwise: yup.number().nullable(),
        }),
        locationEventId: yup.number().when("conditionTypeId", {
          is: 3,
          then: yup.number().required("Location Event ID is required"),
          otherwise: yup.number().nullable(),
        }),
      })
    )
    .min(1, "At least one action is required")
    .required("Actions are required"),
});
