import { Base } from "./Base";
import { ApiResponse, PaginationParams } from "./interfaces/api";
import { Constants as ConstantsType } from "./interfaces/constants";
import qs from "qs";

export default class Constants extends Base {
  async readAll({ skip = 0, limit = 1000 }: PaginationParams) {
    const query = qs.stringify({ skip, limit });
    const response = await this.request.get<ApiResponse<ConstantsType>>(
      `${this.baseUrl}/public/constants?${query}`
    );
    return response.data.data;
  }
}
