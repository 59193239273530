import { useSearchParams } from "react-router-dom";
import Campaigns from "./containers/Campaigns";
import Contents from "./containers/Contents";


const Sections = {
    'campaigns': Campaigns,
    'contents': Contents,
}

export default function TabSections() {
    const [searchParams] = useSearchParams();

    const key = (searchParams.get('tab') ?? "merchants") as keyof typeof Sections;
    if (!Sections.hasOwnProperty(key)) return null

    const Component = Sections[key]
    return <Component />
}