import packageInfo from "../../package.json";
import { Group, Text } from "@mantine/core";
import styled from "@emotion/styled";
import { appConfig } from "config";

export const AppVersion = () => {
  console.info("APP VERSION:", `v${packageInfo.version}`)
	if (!appConfig.showVersion) return null

  return (
    <Root>
      <Text size="sm" weight={500} >
        Version: v{packageInfo.version}
      </Text>
    </Root>
  );
};

const Root = styled(Group)({
  position: "absolute",
  left: 0,
  bottom: 0,
  backgroundColor: "#fbc02d",
  borderTopRightRadius: 8,
  zIndex: 9999,
  padding: "2px 8px",
});
