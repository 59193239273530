import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mantine/core";
import ContentLoader from "components/ContentLoader";
import PageToolbar from "components/PageToolbar";
import { TGame } from "features/PlatformGames/types";
import PlatformGameDetails from "features/PlatformGameDetails";
import TabSections from "features/PlatformGameDetails/components/TabSections";
import useData from "hooks/useData";
import useMutationData from "hooks/useMutationData";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useSharingGameStore } from "store/game-sharing.store";
import * as yup from "yup";

export default function GameDetailsPage() {
    const { id } = useParams<{ id: string }>();

    const addGame = useSharingGameStore(state => state.addGame);

    const form = useForm<Pick<TGame, 'name'>>({
        mode: 'onChange',
        resolver: yupResolver(yup.object({
            name: yup.string().required(),
        })),
        defaultValues: {
            name: '',
        },
    });

    const { data: game, isLoading } = useData<ApiResponseList<TGame>, TGame | undefined>({
        path: `/admin/games/`,
        queryKey: ["GAME", id],
        select: (data) => data.data.items?.find((item) => item.id === Number(id)),
        onSuccess(data) {
            addGame(data.id)
        }
    });

    const updateRequest = useMutationData({
        url: () => `/admin/games/${id}`,
        method: 'patch',
        queryKeys: {
            invalidate: [
                { queryKey: ["GAME", id] },
                { queryKey: ["GAME_LIST"] }
            ],
        }
    })

    const onSubmit = form.handleSubmit(async (values) => {
        await updateRequest.mutateAsync(values)
    })

    useEffect(() => {
        if (!game) return
        form.reset({
            name: game.name,
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [game])

    if (isLoading) return <ContentLoader />

    return (
        <FormProvider {...form}>
            <Stack p={24} spacing={24}>
                <PageToolbar label={"Go Back"} backLink={"/platform/games"} />

                <form onSubmit={onSubmit}>
                    <PlatformGameDetails />
                </form>

                <TabSections />

            </Stack>
        </FormProvider>
    )
}