import { Icon } from "@iconify/react";
import { Button, Group, Input, Stack, Text } from "@mantine/core";
import { PlatformAdminsList } from "features/PlatformAdmins/PlatformAdminsList";
import { usePlatformAdmins } from "features/PlatformAdmins/hooks/platform/usePlatformAdmins";
import { useState } from "react";


export const PlatformAdmins = () => {
  const [inviteModalStatus, setInviteModalStatus] = useState<boolean>(false);
  const { admins, isLoading, search, setSearch } = usePlatformAdmins();
  return (
    <Stack p={20} spacing={0}>
      <Group mb={20} position="apart">
        <Group position="left">
          <Text size="xl" weight="bold">
            Platform admins
          </Text>
        </Group>

        <Group position="right">
          <Button color="yellow" onClick={() => setInviteModalStatus(true)}>
            Invite Admin
          </Button>
        </Group>
      </Group>

      <Input
        sx={{ width: "30%", minWidth: 250 }}
        placeholder="Search..."
        icon={<Icon icon={"ic:baseline-search"} />}
        value={search}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setSearch(e.target.value)
        }
      />
      
      <PlatformAdminsList
        admins={admins}
        isLoading={isLoading}
        inviteStatus={inviteModalStatus}
        closeInvite={setInviteModalStatus}
      />
      
    </Stack>
  );
};
