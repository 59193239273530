import {Button, Group, Paper, Select, Stack, Text, Tooltip} from "@mantine/core";
import {Icon} from "@iconify/react";
import {useController, useFormContext} from "react-hook-form";
import {Content} from "../../../api/interfaces/content";
import {useCallback, useMemo, useState} from "react";
import {css} from "@emotion/css";
import styled from "@emotion/styled";
import ContentItem from "./ContentItem";
import useContentList from "features/ContentList/hooks/useContentList";

// Component
export default function ContentsInitialsSelect() {
    const {contents} = useContentList();
    const {control} = useFormContext();
    const {field} = useController({name: "contentsInitials", control});
    const [selectId, setSelectId] = useState<Content["id"] | undefined | null>(
        undefined
    );

    const contentList: Content[] = useMemo(() => {
        return (field.value || [])
            .map((id: Content["id"]) => {
                const content = contents.find((item) => item.id === id);
                if (!content) return undefined;

                return content;
            })
            .filter((item: Content | undefined) => !!item);
    }, [contents, field.value]);

    const contentsData = useMemo(() => {
        return contents
            .filter((i) => !field.value.includes(i.id))
            .map((content) => ({
                value: String(content.id),
                label: content.name,
            }));
    }, [contents, field]);

    const onRemove = useCallback(
        (inputId: Content["id"]) => {
            if (!field.value.includes(inputId)) return;

            const values = field.value.filter((id: Content["id"]) => inputId !== id);
            field.onChange(values);
        },
        [field]
    );

    const onAdd = useCallback(() => {
        if (!selectId) return;
        field.onChange([Number(selectId), ...field.value]);
        console.debug(field.value);
        setSelectId(undefined);
    }, [field, selectId]);

    return (
        <Paper withBorder radius={"md"} style={{overflow: "hidden"}}>
            <Stack spacing={0}>
                {/* Header */}
                <Group grow p={"lg"} style={{borderBottom: "1px solid #e9ecef"}}>
                    <Group position="left">
                        <Text size="md" weight="bold">
                            First visit rewards
                        </Text>


                        <Tooltip multiline width={220} withArrow
                                 label="These rewards are awarded when user visits location for the first time.">
                            <Icon icon="material-symbols:info-outline"/>
                        </Tooltip>
                    </Group>
                    <Group position="right" spacing={16}>
                        <SelectField
                            placeholder="Pick one"
                            searchable
                            nothingFound="No options"
                            data={contentsData}
                            classNames={{input: "input-field"}}
                            onChange={(id) => setSelectId(Number(id))}
                            value={String(selectId)}
                            sx={{width: "100%", maxWidth: 300}}
                        />
                        <Button
                            leftIcon={<Icon icon={"fluent:add-12-filled"} height={18}/>}
                            radius={"sm"}
                            color={"dark"}
                            variant={"filled"}
                            onClick={onAdd}
                            disabled={!selectId}
                            sx={{ maxWidth: 300}}
                        >
                            Add Gift
                        </Button>
                    </Group>
                </Group>

                {/* Content */}
                <Stack spacing={0}>
                    {!contentList.length && (
                        <Group grow p={"lg"}>
                            <Text size="sm" color={"dimmed"}>
                                Not found...
                            </Text>
                        </Group>
                    )}
                    {contentList.map((content) => (
                        <ContentItem
                            key={content.id}
                            content={content}
                            onRemove={() => onRemove(content.id)}
                        />
                    ))}
                </Stack>
            </Stack>
        </Paper>
    );
}

// CSS Helper
const InputCSS = css`
  background: #fcfdfe;
  border: 1px solid #f0f1f7;
  color: #9fa2b4;
  outline: none;
  transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out,
  color 0.2s ease-in-out;

  &:hover,
  &:focus {
    color: #252733;
    border-color: #0095e5;
  }

  &::placeholder {
    color: rgb(75, 80, 109, 0.5);
  }
`;

const SelectField = styled(Select)`
  flex: 1;

  & .input-field {
    ${InputCSS};
  }
`;
