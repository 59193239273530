import styled from "@emotion/styled";
import { Outlet } from "react-router-dom";

// Component
export default function AuthLayout() {
  return (
    <CenterRoot>
      <Outlet />
    </CenterRoot>
  );
}

// Styling
const CenterRoot = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
`;
