import ContentLoader from "components/ContentLoader";
import { ReactNode } from "react";

// Props
interface ComponentProps {
	isLoading?: boolean;
	children?: ReactNode;
}

export default function SpotlightWrapper({ isLoading, children }: ComponentProps) {
	
	if (isLoading) return (
		<ContentLoader />
	)

	return (
		<div style={{width:"100%", maxHeight:"520px", overflow:"auto"}}>
			{children}
		</div>
	)
}
