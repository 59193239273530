import { useMemo } from "react";
import usePaginatedData from "hooks/usePaginatedData";
import { usePlatformTargetStore } from "store/platform-target.store";
import type { Merchant } from "api/interfaces/merchants";

export default function usePlatformTargetGroups() {
    const targets = usePlatformTargetStore(state => state.targets);

    const { items: merchants, ...options } = usePaginatedData<Merchant>({
        path: `/admin/merchants`,
        defaultLimit: 1000,
        queryKey: ["EXTENDED_MERCHANT_LIST"],
    });

    const items = useMemo(() => {
        const list = targets?.filter((target) => merchants.some(merchant => target.merchant_id === merchant.id)) ?? []

        return list.map((content) => {
            const item = merchants.find(({ id }) => id === content.merchant_id)!;
            return { ...item, targetGroup: content }
        });
    }, [merchants, targets]);

    return {
        items,
        ...options,
    }
}