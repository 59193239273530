import {useMerchantDetails} from "../hooks/useMerchantDetails";
import {useMerchantProfileUpdate} from "../hooks/useMerchantProfileUpdate";
import {Controller, useForm} from "react-hook-form";
import {useEffect} from "react";
import {Merchant} from "../../../api/interfaces/merchants";
import {Button, Checkbox, Group, Stack} from "@mantine/core";

export default function MerchantManageForm() {
	const {merchant} = useMerchantDetails();
	const {handleUpdate} = useMerchantProfileUpdate();
	
	const form = useForm<Merchant>({
		defaultValues: {
			isSuspend: merchant?.isSuspend || false,
			isVerification: merchant?.isVerification || false
		},
	});
	
	const onSubmit = form.handleSubmit(async (input: any) => {
		await handleUpdate(input);
	});
	
	useEffect(() => {
		if (!merchant) return;
		form.reset(merchant);
	}, [merchant]);
	
	return (
			<Stack spacing={40}>
				
				<Group>
					<Controller control={form.control} name="isVerification" render={({field, fieldState}) => (
						<Checkbox
								checked={field.value}
								onChange={(e) => field.onChange(e.target.checked)}
								error={fieldState.error?.message}
								label="KYC Verification"
						/>
				)}/>
					
					<Controller control={form.control} name="isSuspend" render={({field, fieldState}) => (
							<Checkbox
									checked={field.value}
									onChange={(e) => field.onChange(e.target.checked)}
									error={fieldState.error?.message}
									label="Suspend"
							/>
					)}/>`
				</Group>
				
				<Group>
					{form.formState.isDirty && (
						<Button radius={"sm"} color={"lime"} variant={"filled"}
					        onClick={onSubmit}
					        disabled={form.formState.isSubmitting}
					        loading={form.formState.isSubmitting}
						>
							Save Changes
						</Button>
					)}
				</Group>
			
			</Stack>
	)
}
