import styled from "@emotion/styled";
import { Location } from "api/interfaces/location";
import LocationItem from "./components/LocationItem";

// Props
type ComponentProps = {
  locations: Location[]
}

// Component
export default function LocationList({ locations }: ComponentProps) {
  return (
    <Root>
      {locations.map(location => (
        <LocationItem key={location.id} location={location} />
      ))}
    </Root>
  )
}

// Styling
const Root = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  background: #FFFFFF;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  overflow-y: hidden;
`
