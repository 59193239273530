import {useCallback, useState} from "react";
import {api} from "../../../index";
import { showNotification } from "@mantine/notifications";
import { ErrorResponse } from "api/interfaces/api";

export const useUpdateConstants = () => {
const [submitting, setSubmitting] = useState(false)


  const handleUpdateConstants = useCallback(async(id:number, value: string) => {
    setSubmitting(true)
    try{
      await api.settings.updateConstants(id, value)
      showNotification({
        autoClose: 3000,
        title: "Success!",
        message: "Successfully updated!",
        color: "green",
      });
    }
    catch(e: any){
      const error: ErrorResponse = e.response.data;
      showNotification({
        message: error.error ?? "Something went wrong.",
        title: "Error!",
        color: "red",
        autoClose: 5000,
      });
    }
    setSubmitting(false)
  }, [setSubmitting])

  return {
    handleUpdateConstants,
    submitting
  }
}