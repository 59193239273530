import styled from "@emotion/styled";
import { Grid, Paper, Stack, Title } from "@mantine/core";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { useLocationEventAnalytic } from "../hooks/useLocationEventAnalytic";
import AnalyticsComponent from "../../AnalyticsComponent";

export const LocationEventAnalyticDashboard: FC = () => {
  const { id } = useParams();

  const { analytics, isLoading} = useLocationEventAnalytic(Number(id));
  return (
    <Root radius="md" withBorder p="lg">
      <Stack p={10} spacing={20}>
        <Grid justify="space-between" pb={10} align="center">
          <Title order={5}>Location Event Analytics</Title>
        </Grid>

        <AnalyticsComponent analytics={analytics} isLoading={isLoading} />
      </Stack>
    </Root>
  );
};

const Root = styled(Paper)<any>`
  margin-top: 20px;
  overflow: hidden;
  max-width: calc(100vw - 320px);
`;
