import { useQuery } from "react-query";
import { useMemo } from "react";
import { api } from "index";
import useMerchantSelectorStore from "features/MerchantSelector/hooks/useMerchantSelectorStore";

export default function useCouponsList() {
  const merchantSelector = useMerchantSelectorStore();

  const selectedId = merchantSelector.useStore((state) => state.selected);

  const { data, isLoading, isError } = useQuery({
    queryFn: async () => await api.coupons.readAll({}, selectedId),
    queryKey: ["COUPONS_LIST", selectedId],
    staleTime: Infinity,
  });

  const coupons = useMemo(() => {
    return data?.items || [];
  }, [data, isLoading]);

  return {
    coupons, 
    isLoading,
    isError
  };
}
