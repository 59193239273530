import { Base } from './Base';
import qs from 'qs';
import {
	ApiResponse,
	ApiResponseList,
	ErrorResponse,
	OperationResponse,
	PaginationParams,
} from './interfaces/api';
import { TCollectableCard } from 'features/CollectionCardsList/types';

export default class CollectionCards extends Base {
	async create(input: TCollectableCard) {
		const response = await this.request.post<ApiResponse<TCollectableCard>>(
			`${this.baseUrl}/admin/collectible-cards`,
			input
		);
		return response.data.data;
	}

	async readAll({ skip = 0, limit = 1000 }: PaginationParams) {
		const query = qs.stringify({ skip, limit });
		const response = await this.request.get<ApiResponseList<TCollectableCard>>(
			`${this.baseUrl}/admin/collectible-cards?${query}`
		);
		return response.data.data;
	}

	async update({ id, ...data }: TCollectableCard) {
		const response = await this.request.patch<ApiResponse<TCollectableCard>>(
			`${this.baseUrl}/admin/collectible-cards/${id}`,
			data
		);
		return response.data.data;
	}

	async remove(id?: TCollectableCard['id']) {
		try {
			const response = await this.request.delete<OperationResponse>(
				`${this.baseUrl}/admin/collectible-cards/${id}`
			);
			return response.data.isSuccess;
		} catch (e: any) {
			const error: ErrorResponse = await e.response.data;

			return error;
		}
	}
}
