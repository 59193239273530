import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface IEventCards {
    cards: TEventCardSelect[],
    addCard: (card: TStorageCollectibleCard) => TEventCardSelect[],
    removeCard: (cardId: number) => void,
    getFormattedData: () => TPostCardsData | undefined,
    resetStore: () => void,
}

export const useEventCards = create<IEventCards>()(
    devtools(
        (set, get) => ({
            cards: [],
            addCard: (card: TStorageCollectibleCard) => {
                set(get().cards = get().cards.filter((selectedCard: TStorageCollectibleCard) => selectedCard.id != card.id) as any);
                set(get().cards = [...get().cards, card] as any);
                return get().cards;
            },
            removeCard: (cardId: number) => {
                const filteredList: TEventCardSelect[] = get().cards.filter((card: TStorageCollectibleCard) => card.id != cardId)
                set(get().cards = filteredList as any)
            },
            getFormattedData: () => {
                if (get().cards.length === 0) return undefined;
                const formattedData: TStorageCollectibleCard[] = get().cards.map((card: TStorageCollectibleCard) => {
                    return {
                        cardId: card.id,
                        frequencyOccurrence: card.frequencyOccurrence,
                        maxAttempts: card.maxAttempts,
                    }
                });
                const data = {
                    cards: formattedData,
                };
                return data;
            },
            resetStore: () => {
                set(get().cards = [] as any);
            },
        })
    )
)