import { useCallback } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { api } from "index";
import { Event } from "api/interfaces/events";
import { useModalStore } from "../../Modals/hooks/useModalStore";
import { useMerchantNavigation } from "../../../hooks/useMerchantNavigation";
import { useAuth } from "features/Auth/hooks/useAuth";
import { showNotification } from "@mantine/notifications";

export const useEventDelete = (event?: Event) => {
  const queryClient = useQueryClient();
  const { route, id: merchantId } = useMerchantNavigation();
  const navigate = useNavigate();
  const { closeModal, setModalStatus, setupModal } = useModalStore();
  const { isMerchant } = useAuth();
  // Remove Event
  const { mutateAsync, ...options } = useMutation(() =>
    api.events.remove(event?.id, Number(merchantId))
  );

  const handleDelete = useCallback(async () => {
    if (!event) return;
    const onDelete = async () => {
      closeModal();

      if (event.leftDeposit > 0) {
        await api.events.withdrawEvent(event.id, Number(merchantId));
      }
      const res = await mutateAsync();

      if (typeof res === "object") {
        showNotification({
          message: res.error ?? "Something went wrong.",
          title: "Error!",
          color: "red",
          autoClose: 5000,
        });
      } else {
        closeModal();
        navigate(`${route}/campaigns`);

        await Promise.all([
          queryClient.invalidateQueries(["EVENT_LIST", merchantId]),
          queryClient.invalidateQueries(["EVENT", event.id]),
          queryClient.invalidateQueries(["LOCATION_EVENTS_LIST", merchantId]),
          queryClient.invalidateQueries(["LOCATION_EVENT"]),
        ]);

        showNotification({
          autoClose: 3000,
          title: "Success!",
          message: "The Event was successfully deleted",
          color: "green",
        });
      }
    };

    if (event.leftDeposit > 0 && isMerchant) {
      showNotification({
        autoClose: 5000,
        title: "Error!",
        message: "You cannot delete a campaign that still has funds!",
        color: "red",
      });

      return;
    }

    let message =
      event.leftDeposit > 0
        ? "Please confirm your action. <br/>Selected Campaign will be deleted! <br/> All funds will be returned to the Merchant balance."
        : "Please confirm your action. <br/>Selected Campaign will be deleted!";

    setupModal({
      action: onDelete,
      type: "delete",
      content: message,
    });

    setModalStatus(true);
  }, [mutateAsync, navigate, event, isMerchant]);

  return {
    handleDelete,
    ...options,
  };
};
