import styled from "@emotion/styled";

// Styling
export const Input = styled.input`
  display: flex;
  font-size: 14px;
  font-weight: 400;
  background: #fcfdfe;
  border: 1px solid #f0f1f7;
  color: #9fa2b4;
  line-height: 1.5;
  min-height: 42px;
  outline: none;
  padding: 10px 16px;
  width: 100%;
  transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out, color 0.2s ease-in-out;

  ${({ radius }) => `
        border-radius: ${radius || `4px`};
    `}
  &:hover,
  &:focus {
    color: #252733;
    border-color: #0095e5;
  }

  &::placeholder {
    color: rgb(75, 80, 109, 0.5);
  }
`;
