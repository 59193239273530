import { Group, Stack, Text, Title } from "@mantine/core";
import {PlanCard} from "../../../features/About/components/PlanCard";
import {SubscribeInformation} from "../../../features/Subscriptions/components/SubscribeInformation";
import styled from "@emotion/styled";

export default function SubscribePage() {
  return (
    <Stack px={"lg"} py={40} spacing={40}>
      {/* About Text */}
      <Stack mt={"lg"} spacing={"lg"}>
        <Group position="center">
          <Title order={3} style={{ fontFamily: "Poppins" }}>About Qubi</Title>
        </Group>
        <Group position="center">
          <Text size={"sm"} style={{ maxWidth: "80%", fontFamily: "Poppins" }}>
            Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet
            sint. Velit officia consequat duis enim velit mollit. Exercitation
            veniam consequat sunt nostrud amet. Amet minim mollit non deserunt
            ullamco est sit aliqua dolor do amet sint. Velit officia consequat
            duis enim velit mollit. Exercitation veniam consequat sunt nostrud
            amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do
            amet sint. Velit officia consequat duis enim velit mollit.
            Exercitation veniam consequat sunt nostrud amet. Amet minim mollit
            non deserunt ullamco est sit aliqua dolor do amet sint. Velit
            officia consequat duis enim velit mollit. Exercitation veniam
            consequat sunt nostrud amet.
          </Text>
        </Group>
      </Stack>

      {/* Plans */}
      <Stack mt={"lg"} spacing={"lg"}>
        <Group position="center">
          <Title order={3} style={{ fontFamily: "Poppins" }}>Plans</Title>
        </Group>

        <PlanCard/>
      </Stack>
  
      <Stack spacing={0} style={{padding: 20}}>
        <Group position="apart">
          <Group position="left">
            <Text size="xl" weight="bold">
              Subscription
            </Text>
          </Group>
        </Group>
    
        <Card>
          <SubscribeInformation />
        </Card>
      </Stack>
    </Stack>
  );
}


const Card = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  padding: 20px;
  overflow-y: hidden;
  gap: 20px;
  margin-top: 20px;
`;
