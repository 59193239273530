import { useCallback, useMemo } from "react";
import { api } from "../../../index";
import dayjs from "dayjs";
import { useAuth } from "../../Auth/hooks/useAuth";
import { useModalStore } from "features/Modals/hooks/useModalStore";
import { ErrorResponse } from "../../../api/interfaces/api";
import {useMerchantDetails} from "../../MerchantProfile/hooks/useMerchantDetails";

export const useSubscription = () => {
  const { merchant } = useMerchantDetails();
  const { isPlatformMerchant } = useAuth();
  const { setModalStatus, setupModal } = useModalStore();

  const handleBuySubscription = useCallback(async () => {
      try {
        const result = await api.merchants.subscribe(merchant?.id);

        window.open(result?.url);
      } catch (e: any) {
        const parsedError: ErrorResponse = await e.response.data;

        setModalStatus(true);

        if (parsedError.code === 29) {
          setupModal({
            type: "subscription",
            content:
              "Sorry, but you must pass all the verification, before buying subscriptions",
          });
          return;
        }

        if (typeof parsedError.error === "string") {
          setupModal({
            type: "error",
            content: parsedError.error,
          });
          return;
        }
      }
    },
    [setModalStatus, merchant]
  );

  const isSubscribeExpired = useMemo(() => {
    if (!merchant) return true;

    return !dayjs(merchant.stripeSubscriptionAccessExpire).isAfter(new Date(), "second");
  }, [merchant]);

  const isSubscriptionCancelled = useMemo(() => {
    if (!merchant) return true;

    return merchant.stripeIsSubscriptionCanceled;
  }, [merchant]);

  const isSubscribed = useMemo(() => {
    return isPlatformMerchant && !isSubscribeExpired && !isSubscriptionCancelled
  }, [isPlatformMerchant, isSubscribeExpired, isSubscriptionCancelled])

  const expireTime = useMemo(() => {
    if (!merchant) return "";
    return dayjs(merchant.stripeSubscriptionAccessExpire).format("DD MMMM, YYYY");
  }, [merchant]);

  return {
    handleBuySubscription,
    isSubscribeExpired,
    expireTime,
    isSubscriptionCancelled,
    isSubscribed
  };
};
