import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IAccount } from "api/interfaces/account";
import { AppState } from "store";
import authSlice from "../store";

export const useAuthStore = () => {
  const state = useSelector((state: AppState) => state.auth);
  const dispatch = useDispatch();

  const logIn = useCallback((user: IAccount) => {
    dispatch(authSlice.actions.login(user));
  }, [dispatch]);

  const logOut = useCallback(() => {
    dispatch(authSlice.actions.logout());
  }, [dispatch]);

  return {
    state,
    logIn,
    logOut,
  };
};
