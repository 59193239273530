import { useQuery } from "react-query";
import { api } from "index";
import { useMemo } from "react";

export default function useMerchantGame(merchant_id: string | number, game_id: string | number) {

  const { data, isLoading } = useQuery({
    queryFn: async () => await api.games.getGameDetail(game_id, merchant_id),
    queryKey: [`MERCHANT_SHARED_GAMES_${merchant_id}_${game_id}`],
  });

  const game = useMemo(() => {
    if (!data) return;
    return data.Game;
  }, [data, merchant_id, game_id]);

  return {
    game,
    isLoading
  }
}
