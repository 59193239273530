import { api } from "index";
import { useMutation } from "react-query";
import { useCallback } from "react";
import { useModalStore } from "features/Modals/hooks/useModalStore";
import { useParams } from "react-router-dom";
import { showNotification } from "@mantine/notifications";
import { ErrorResponse } from "api/interfaces/api";

export const useUserPasswordReset = () => {
  const { id } = useParams();
  const { mutateAsync: resetPassword, ...rest } = useMutation(
    () => api.users.userResetPassword(Number(id)),
    {
      onSuccess: () => {
        showNotification({
          autoClose: 3000,
          title: "Success!",
          message: "Password successfully reset!",
          color: "green",
        });
      },
      onError: (e: any) => {
        const error: ErrorResponse = e.response.data;
        showNotification({
          message: error.error ?? "Something went wrong.",
          title: "Error!",
          color: "red",
          autoClose: 5000,
        });
      },
    }
  );
  const { closeModal, setupModal, setModalStatus } = useModalStore();

  const handleResetPassword = useCallback(() => {
    if (!id) return;
    const onReset = async () => {
      closeModal();
      await resetPassword();
    };

    setupModal({
      type: "delete",
      content: "Please confirm your action. <br/>Password will be reset",
      action: onReset,
    });
    setModalStatus(true);
  }, [resetPassword]);

  return { ...rest, handleResetPassword };
};
