import axios from 'axios';
import tokenStorage from 'helpers/tokenStorage';

export const api = axios.create({
    baseURL: `/api/v1`,
});

api.interceptors.request.use((config) => {
    const token = tokenStorage.getToken();

    if (token && config.headers) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
});

api.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response.status === 401) {
            tokenStorage.removeToken();
            if (error.config.headers['Authorization']) window.location.href = '/login';
        }
        return Promise.reject(error);
    },
);
