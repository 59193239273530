import { IAccount, SuspendAccountForm } from "api/interfaces/account";
import { useMerchantAdminDelete } from "../hooks/merchant/useMerchantAdminDelete";
import { FC } from "react";
import { ListItem } from "./ListItem";
import { FormProvider, useForm } from "react-hook-form";
import { useMerchantAdminPasswordReset } from "../hooks/merchant/useMerchantAdminPasswordReset";
import { useMerchantAdminUpdate } from "../hooks/merchant/useMerchantAdminUpdate";

interface Props {
  user: IAccount;
  merchantId: number;
}

export const MerchantAdminItemWrapper: FC<Props> = ({ user, merchantId }) => {
  const { handleDelete, isLoading: isDeleteLoading } =
    useMerchantAdminDelete(merchantId);

  const { handlePasswordReset } = useMerchantAdminPasswordReset(merchantId);
  const { handleUpdate } = useMerchantAdminUpdate(merchantId);
  const form = useForm<SuspendAccountForm>({
    defaultValues: {
      isSuspend: user.isSuspend,
    },
  });
  const onSubmit = async (values: SuspendAccountForm) => {
    await handleUpdate(values, user.id);
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <ListItem
          user={user}
          handleDelete={() => handleDelete(user.id)}
          handleReset={() => handlePasswordReset(user.id)}
          isLoading={isDeleteLoading}
          superAdmin={user.roleId === 3}
        />
      </form>
    </FormProvider>
  );
};
