import { api } from '../utils/api';
import { InvalidateQueryFilters, useMutation, useQueryClient } from 'react-query';

export type MutationType = 'post' | 'patch' | 'delete' | 'put';

const MutationMethodByType = {
    post: 'post',
    patch: 'patch',
    delete: 'delete',
    put: 'put',
};

type TMutationProps<T> = {
    url: (variables: T) => string;
    searchParams?: Record<string, unknown>;
    method?: MutationType;
    queryKeys: {
        invalidate?: InvalidateQueryFilters[];
        remove?: QueryKey[];
    };
    onSuccess?: () => Promise<void>;
};

export default function useMutationData<T>({ url, method: type = 'post', onSuccess, queryKeys, searchParams }: TMutationProps<T>) {
    const queryClient = useQueryClient();
    return useMutation<unknown, unknown, T>({
        mutationFn: (variables) => {
            const method = MutationMethodByType[type] as 'post' | 'patch' | 'delete' | 'put';
            if (method === 'delete') return api.delete(url(variables), { params: searchParams });
            return api[method](url(variables), variables, { params: searchParams });
        },
        onSuccess: async () => {
            if (queryKeys.invalidate && queryKeys.invalidate.length > 0) {
                await Promise.allSettled(queryKeys.invalidate.map((key) => queryClient.invalidateQueries(key)));
            }

            if (queryKeys.remove && queryKeys.remove.length > 0) {
                await Promise.allSettled(queryKeys.remove.map((key) => queryClient.removeQueries(key)));
            }

            if (onSuccess) await onSuccess();
        },
    });
}
