import styled from "@emotion/styled";
import { ActionIcon, Group, Stack, Text } from "@mantine/core";
import { Icon } from "@iconify/react";
import { useParams } from "react-router-dom";
import { TWorkingDay, useEventWorkingDates } from "../store/event-days.store";
import { useState } from "react";

// Props
type ComponentProps = {
    item: TWorkingDay;
};

// Component
export default function WorkingDayListItem({ item }: ComponentProps) {
    const { id } = useParams<{ id: string }>();
    const [loading, setLoading] = useState(false);

    const removeWorkingDay = useEventWorkingDates(state => state.removeWorkingDay);

    const onRemove = () => {
        setLoading(true);
        const timeout = setTimeout(() => {
            removeWorkingDay(Number(id), item.day);
            setLoading(false);
            clearTimeout(timeout);
        }, 1000);
    }

    return (
        <Root position="apart" px={0} py={8}>
            <Group position="left" align={"center"} spacing={8}>
                <Stack spacing={2}>
                    <Text size={14} weight={500} lh={1.5} tt={"capitalize"}>{item.day}</Text>
                    <Text size={12} weight={400} lh={1.5} color="dark.2">
                        {timeFormat(item.start)} - {timeFormat(item.end)}
                    </Text>
                </Stack>
            </Group>

            <Group position="right" spacing={8}>
                <ActionIcon
                    variant={"subtle"}
                    onClick={() => onRemove()}
                    loading={loading}
                    sx={(theme) => ({
                        background: "#FCFDFE",
                        border: "1px solid #F1F3F5",
                        borderRadius: "6px",
                        color: theme.colors.red[5],
                        "& svg[data-action-icon-loader]": {
                            stroke: theme.colors.red[5],
                        },
                    })}
                >
                    <Icon icon={"fluent:delete-16-regular"} height={14} />
                </ActionIcon>
            </Group>
        </Root>
    );
}

const Root = styled(Group)`
  &:not(:last-child) {
    border-bottom: 1px solid #f8f9fa;
  }
`;

function timeFormat(time: string) {
    if (time.includes("AM") || time.includes("PM")) return time;

    const [hours, minutes] = time.split(":");
    const ampm = Number(hours) >= 12 ? "PM" : "AM";
    const hours12 = Number(hours) % 12 || 12;

    const formattedHours = hours12 < 10 ? `0${hours12}` : hours12;

    return `${formattedHours}:${minutes} ${ampm}`;
}