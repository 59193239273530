import styled from "@emotion/styled";
import { Event } from "api/interfaces/events";
import EventItem from "./components/EventItem";

// Props
type ComponentProps = {
  events: Event[];
};

// Component
export default function EventList({ events }: ComponentProps) {
  return (
    <Root>
      {events.map((event) => (
        <EventItem key={event.id} event={event} />
      ))}
    </Root>
  );
}

// Styling
const Root = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  background: #ffffff;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  overflow-y: hidden;
`;
