import { useAppConfigConstants } from "features/AppAccess/hooks/useAppConfigConstants";
import { useAppConfigConstantsUpdate } from "features/AppAccess/hooks/useAppConfigConstantsUpdate";
import { SettingsLayout } from "pages/platform/settings/SettingsLayout";
import { FC, useEffect } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import ContentLoader from "components/ContentLoader";
import { Button, Card, Group, Stack, TextInput } from "@mantine/core";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Icon } from "@iconify/react";


const schema = yup.object({
  email: yup.string().email().required(),
  clickupForm: yup.string().url().required(),
});

interface FormState {
  email: string;
  clickupForm: string;
}
// constant id=8 - clickup form link, id=9 -  contact email
type Constants = 8 | 9;

export const FeedbackSettings: FC = () => {
  const { data, isLoading } = useAppConfigConstants();
  const form = useForm<FormState>({ resolver: yupResolver(schema) });
  const { handleUpdate } = useAppConfigConstantsUpdate();

  const onSubmit = async (values: FormState, id: Constants) => {
    const contactEmail = getConfigValue(9);
    const clickupForm = getConfigValue(8);

    if (!contactEmail || !clickupForm) return;

    if (id === 9) {
      contactEmail!.textValue = values.email;
      await handleUpdate(contactEmail);
      return;
    }

    if (id === 8) {
      clickupForm.textValue = values.clickupForm;
      await handleUpdate(clickupForm);
      return;
    }
  };

  const getConfigValue = (id: Constants) => {
    return data?.items.filter((val) => val.id === id)[0];
  };

  useEffect(() => {
    if (!data || !data.items.length) return;

    form.setValue("email", getConfigValue(9)!.textValue);
    form.setValue("clickupForm", getConfigValue(8)!.textValue);
  }, [data]);

  return (
    <SettingsLayout>
      {!isLoading ? (
        <form>
          <Card mt={20}>
            <Group spacing={30}>
              <Stack>
                <Controller
                  control={form.control}
                  name="email"
                  render={({ field, fieldState }) => (
                    <TextField
                      label="Contact Email"
                      placeholder="Email"
                      {...field}
                      error={fieldState.error?.message}
                    />
                  )}
                />
                <Button
                  onClick={form.handleSubmit((e) => onSubmit(e, 9))}
                  leftIcon={<Icon icon={"fluent:save-24-filled"} height={18} />}
                  radius={"sm"}
                  sx={{ width: "fit-content" }}
                  color={"teal"}
                  variant={"filled"}
                  loading={form.formState.isSubmitting}
                >
                  Update
                </Button>
              </Stack>
              <Stack>
                <Controller
                  control={form.control}
                  name="clickupForm"
                  render={({ field, fieldState }) => (
                    <TextField
                      label="Contact Clickup Form"
                      placeholder="Link"
                      {...field}
                      error={fieldState.error?.message}
                    />
                  )}
                />
                <Group >
                  <Button
                    onClick={form.handleSubmit((e) => onSubmit(e, 8))}
                    leftIcon={
                      <Icon icon={"fluent:save-24-filled"} height={18} />
                    }
                    radius={"sm"}
                    sx={{ width: "fit-content" }}
                    color={"teal"}
                    variant={"filled"}
                    loading={form.formState.isSubmitting}
                  >
                    Update
                  </Button>
                </Group>
              </Stack>
            </Group>
          </Card>
        </form>
      ) : (
        <ContentLoader />
      )}
    </SettingsLayout>
  );
};

const InputCSS = css`
  display: flex;
  font-size: 14px;
  font-weight: 400;
  background: #fcfdfe;
  color: #9fa2b4;
  line-height: 1.5;
  min-height: 42px;
  outline: none;
  padding: 10px 16px;
  width: 100%;
  transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out,
    color 0.2s ease-in-out;

  &:hover,
  &:focus {
    color: #252733;
    border-color: #0095e5;
  }

  &::placeholder {
    color: rgb(75, 80, 109, 0.5);
  }
`;

const FieldCSS = css`
  flex: 1;

  & .input-field {
    ${InputCSS};
  }
`;
// Styling

const TextField = styled<any>(TextInput)`
  ${FieldCSS};

  & .input-field {
    border: 1px solid ${({ error }) => (error ? "#f03e3e" : "#f0f1f7")};
  }

  & input {
    width: 250px;
  }
`;
