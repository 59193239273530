import { useCallback } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { api } from "index";
import { ImageContent } from "api/interfaces/imageContents";
import { useModalStore } from "../../Modals/hooks/useModalStore";
import { useMerchantNavigation } from "../../../hooks/useMerchantNavigation";
import { showNotification } from "@mantine/notifications";

export const useImageContentDelete = (id: ImageContent["id"]) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { setupModal, setModalStatus, closeModal } = useModalStore();

  const { id: merchantId, route } = useMerchantNavigation();

  // Remove Event
  const { mutateAsync, ...options } = useMutation(() =>
    api.imageContents.remove(id, Number(merchantId))
  );

  const handleDelete = useCallback(async () => {
    if (!id) return;
    const onDelete = async () => {
      closeModal();
      const res = await mutateAsync();
      if (typeof res === "object") {
        showNotification({
          message: (res as any).message ?? res.error ?? "Something went wrong.",
          title: "Error!",
          color: "red",
          autoClose: 5000,
        });
      } else {
        closeModal();
        showNotification({
          autoClose: 3000,
          title: "Success!",
          message: "Image Content successfully deleted!",
          color: "green",
        });
        navigate(`${route}/image-contents`);

        await Promise.all([
          queryClient.invalidateQueries(["IMAGE_CONTENT_LIST", merchantId]),
          queryClient.invalidateQueries(["IMAGE_CONTENT"]),
        ]);
      }
    };
    setupModal({
      action: onDelete,
      type: "delete",
      content:
        "Please confirm your action. <br/>Selected Image Content will be deleted!",
    });
    setModalStatus(true);
  }, [mutateAsync, navigate, id, setupModal, setModalStatus]);

  return {
    handleDelete,
    ...options,
  };
};
