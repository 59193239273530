import styled from "@emotion/styled";
import { Icon } from "@iconify/react";
import { Group, ActionIcon, Text, Stack } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import QueryKeys from "constants/query-keys";
import useMutationData from "hooks/useMutationData";
import { useNavigate, useParams } from "react-router-dom";
import { useModalStore } from "features/Modals/hooks/useModalStore";

type RelatedProps = {
    name: string,
    cardId: number | string,
    occasionId: number | string,
    type: string,
    frequencyOccurrence: number | string,
    maxAttempts: number | string,
};

export default function OccasionItem({ name, cardId, occasionId, type, frequencyOccurrence, maxAttempts }: RelatedProps) {
    const { merchantId } = useParams();
    const { setupModal, setModalStatus, closeModal } = useModalStore();
    const navigate = useNavigate();

    const unshareMutation = useMutationData({
        url: () => `/admin/merchants/${merchantId}/${type.toLowerCase()}/${occasionId}`,
        method: 'delete',
        queryKeys: {
            invalidate: [
                { queryKey: [QueryKeys.MERCHANT_COLLECTABLE_CARD, { cardId: Number(cardId) }, { merchantId: Number(merchantId) }] },
                { queryKey: type == "Events" ? ["EVENT_LIST", merchantId] : ["CONTENT_LIST", merchantId] },
            ],
        },
        onSuccess: async () => {
            showNotification({
                autoClose: 3000,
                title: 'Success!',
                message: `${type.slice(0, -1)} successfully removed!`,
                color: 'green',
            });
        }
    });

    const openModal = () => {
        setupModal({
            action: handleDeleteOccasion,
            type: "delete",
            content:
                `Please confirm your action. <br/>Selected ${type.slice(0, -1).toLowerCase()} will be deleted!`,
        });
        setModalStatus(true);
    };

    const handleDeleteOccasion = () => {
        unshareMutation.mutate({});
        closeModal();
    };

    return (
        <ListItem position='apart' px={16} py={8}>
            <Group grow position="left" align={"center"} spacing={8} sx={{ flex: 1 }}>
                <Stack sx={{ flex: 1 }}>
                    <Text size={14} weight={500} lh={1.5} tt={"capitalize"}>{name}</Text>
                </Stack>
                <Stack spacing={8}>
                    <Text size={12} weight={400} color="gray.6" lh={1.5}>
                        Frequency occurrence: <Text span color="dark.8">{Number(frequencyOccurrence) * 100}%</Text>
                    </Text>
                    <Text size={12} weight={400} color="gray.6" lh={1.5}>
                        Max attempts: <Text span color="dark.8">{maxAttempts}</Text>
                    </Text>
                </Stack>
            </Group>
            <Group position="right" spacing={8}>
                <ActionIcon
                    variant="subtle"
                    color="gray.5"
                    onClick={() => navigate(`/merchant/${merchantId}/${type == "Events" ? "campaigns" : "contents"}/${occasionId}`)}
                    sx={(theme) => ({
                        background: "#FCFDFE",
                        border: "1px solid #F1F3F5",
                        borderRadius: "6px",
                        color: theme.colors.dark[2],
                        "& svg[data-action-icon-loader]": {
                            stroke: theme.colors.dark[2],
                        },
                    })}
                >
                    <Icon icon={"fluent:edit-16-regular"} height={14} />
                </ActionIcon>
                <ActionIcon
                    variant="subtle"
                    color="red.5"
                    loading={unshareMutation.isLoading}
                    onClick={openModal}
                    sx={(theme) => ({
                        background: "#FCFDFE",
                        border: "1px solid #F1F3F5",
                        borderRadius: "6px",
                        color: theme.colors.red[5],
                        "& svg[data-action-icon-loader]": {
                            stroke: theme.colors.red[5],
                        },
                    })}
                >
                    <Icon icon={"fluent:delete-16-regular"} height={14} />
                </ActionIcon>
            </Group>
        </ListItem>
    )
}

const ListItem = styled(Group)`
    &:not(:last-child) {
        border-bottom: 1px solid #f8f9fa;
    }
`;
