const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export default function humanizeTime(time, timeZone = 0) {
  const initialDate = new Date(time).getTime();
  const date = new Date(initialDate + timeZone * 3600000);
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();

  const minutesFormatted = minutes === 0 ? "" : `:${minutes}`;
  const ampm = hours >= 12 ? "pm" : "am";
  return `${days[date.getUTCDay()]} ${hours}${minutesFormatted}${ampm}`;
}
