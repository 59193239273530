import { Icon } from "@iconify/react";
import { Group, Input, Stack, Text } from "@mantine/core";
import { UsersList } from "features/Users/components/UsersList";
import { useUsersList } from "features/Users/hooks/useUsersList";
import { FC } from "react";

export const UsersPage: FC = () => {
  const { users, isLoading, search, setSearch } = useUsersList();

  return (
    <Stack p={20} spacing={15}>
      <Group position="apart">
        <Group position="left">
          <Text size="xl" weight="bold">
            Users
          </Text>
        </Group>
      </Group>
      <Input
        sx={{ width: "30%", minWidth: 250 }}
        placeholder="Search..."
        icon={<Icon icon={"ic:baseline-search"} />}
        value={search}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setSearch(e.target.value)
        }
      />
      <UsersList users={users} isLoading={isLoading} />
    </Stack>
  );
};
