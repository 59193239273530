import { Group, Stack } from "@mantine/core";
import ContentSelect from "./components/ContentSelect";
import GiftSelect from "./components/GiftSelect";
import LocationEventEditForm from "./components/LocationEventEditForm";
import SubsequentsSelect from "./components/SubsequentsSelect";
import ContentsInitialsSelect from "./components/ContentsInitialsSelect";
import GameSelect from "./components/GameSelect";

export default function LocationEventEdit() {
  return (
    <Stack spacing={"lg"}>
      <LocationEventEditForm />

        <Group grow position="apart" align={"flex-start"}>
            <GameSelect />
        </Group>

        <Group grow position="apart" align={"flex-start"}>
        <Stack>
          <ContentSelect />
        </Stack>
        <Stack>
          <GiftSelect />
        </Stack>
      </Group>
      <Group grow position="apart">
        <Stack>
          <SubsequentsSelect />
        </Stack>
        <Stack>
          <ContentsInitialsSelect />
        </Stack>
      </Group>
    </Stack>
  );
}
