import {useCallback} from "react";
import {api} from "../../../index";
import {useMerchantDetails} from "./useMerchantDetails";

export const useMerchantBalance = () => {
  const { merchant } = useMerchantDetails()
  
  const handleRefill = useCallback(async () => {
    const result = await api.balance.refill(merchant?.id)

    window.open(result?.url)
  }, [merchant])

  return {
    handleRefill
  }
}
