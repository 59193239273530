// Component
import { useAuth } from "features/Auth/hooks/useAuth";
import styled from "@emotion/styled";
import { Badge, Button, Group } from "@mantine/core";
import { useAppConfigConstants } from "features/AppAccess/hooks/useAppConfigConstants";
import { useCallback, useEffect, useState } from "react";
import MerchantSelector from "../MerchantSelector";
import { useModalStore } from "features/Modals/hooks/useModalStore";
import { Tooltip, Text } from "@mantine/core";

export default function Header() {
  const { user, isSuperAdmin } = useAuth();
  const { isLoading } = useAppConfigConstants();
  const { setModalStatus, setupModal } = useModalStore();
  const internalBalance = user?.internalBalance?.toFixed(3);
  const [p2p, setP2p] = useState<any>(undefined);
  const [card, setCard] = useState<any>(undefined);

  const openFeedbackModal = useCallback(() => {
    setupModal({
      type: "feedback",
      content: "You can contact us via form or email.",
    });
    setModalStatus(true);
  }, [setupModal, setModalStatus]);

  const updateTransaction = () => {
    const pTp = localStorage.getItem("P2P_transaction");
    const creditCard = localStorage.getItem("Credit_card_transaction");
    setP2p(pTp);
    setCard(creditCard);

    window.removeEventListener("updTransaction", updateTransaction);
  }

  window.addEventListener("updTransaction", updateTransaction);

  useEffect(() => {
    const pTp = localStorage.getItem("P2P_transaction");
    const creditCard = localStorage.getItem("Credit_card_transaction");
    setP2p(pTp);
    setCard(creditCard);
  }, []);

  return (
    <Root>
      <Group spacing={8}>
        <Button
          variant={"filled"}
          color={"violet"}
          onClick={openFeedbackModal}
          size="sm"
          loading={isLoading}
        >
          FEEDBACK
        </Button>
      </Group>

      <Group mx={"auto"}>
        <MerchantSelector />
      </Group>
      <Group spacing={10} noWrap>
        {p2p &&
          <Tooltip label="Fee percent for p2p transactions"
            color="#3c78d8"
            withArrow
            position="bottom-end"
            offset={10}
          >
            <Text weight="bold" c={"#3c78d8"} style={{ cursor: "default" }}>p2p: {p2p}%</Text>
          </Tooltip>
        }
        {card &&
          <Tooltip label="Fee percent for credit card transactions"
            color="#0aac00"
            withArrow
            position="bottom-start"
            offset={10}
          >
            <Text weight="bold" c={"#0aac00"} style={{ cursor: "default" }}>Credit card: {card}%</Text>
          </Tooltip>
        }
        {isSuperAdmin && (
          <Badge size="xl" radius={"sm"}>
            Platform Balance: {internalBalance}
          </Badge>
        )}
        <Badge size="xl" variant="dot" color={"lime"} radius={"sm"}>
          {user?.Role.name}
        </Badge>
      </Group>
    </Root>
  );
}

// Styling
const Root = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 60px;
  min-height: 60px;
  background-color: #ffffff;
  border-bottom: 1px solid #e9ecef;
  padding: 8px 20px;
`;
