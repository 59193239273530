import {Icon} from "@iconify/react"
import {Link} from "react-router-dom";
import ContentLoader from "components/ContentLoader";
import useContentList from "features/ContentList/hooks/useContentList";
import ContentList from "features/ContentList";
import { Button, Group, Stack, Text } from "@mantine/core";
import {useMerchantNavigation} from "../../../hooks/useMerchantNavigation";

// Page
export default function Contents() {
  const { contents, isLoading } = useContentList()
  const { route } = useMerchantNavigation()

  return (
    <Stack p={'lg'} spacing={0}>

      <Group position='apart'>
      <Group position="left">
          <Text size="xl" weight="bold">
            Contents
          </Text>
        </Group>
        <Group position="right">
          <Button
            component={Link}
            to={`${route}/contents/create`}
            leftIcon={<Icon icon={"fluent:add-12-filled"} height={18} />}
            radius={"sm"}
            color={"blue"}
            variant={"filled"}
          >
            Create
          </Button>
        </Group>
      </Group>

      {!isLoading
        ? <ContentList contents={contents}/>
        : <ContentLoader />
      }
    
    </Stack>
  );
}
