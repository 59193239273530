import useMerchantSelectorStore from "./hooks/useMerchantSelectorStore";
import { Group, Select, SelectItem } from "@mantine/core";
import { useSearchMerchant } from "./hooks/useSearchMerchant";
import { Icon } from "@iconify/react";
import { useAuth } from "../Auth/hooks/useAuth";
import { useCallback, useEffect, useMemo } from "react";
import { useMerchantDetails } from "../MerchantProfile/hooks/useMerchantDetails";

import { useNavigate } from "react-router-dom";

export default function MerchantSelector() {
    const store = useMerchantSelectorStore();
    const { isPlatformMerchant, isPlatformAdmin, user } = useAuth();
    const { merchants, setSearch, search } = useSearchMerchant();
    const { merchant, isLoading } = useMerchantDetails()
    const navigate = useNavigate()

    const selected = store.useStore(state => state.selected)

    const list: SelectItem[] = useMemo(() => {
        if (!user) return []

        if (isPlatformMerchant && user.Merchant) return [{
            label: user.Merchant.name,
            value: String(user.Merchant.id)
        }]

        return merchants.map(item => ({
            label: item.name,
            value: String(item.id)
        }))
    }, [user, merchants])

    useEffect(() => {
        if (isLoading) return

        if (!isPlatformMerchant || !user?.Merchant) return
        store.Select(String(merchant?.id || user.Merchant.id))
    }, [isPlatformMerchant, user?.Merchant, merchant])

    const onSelect = useCallback((id: string | null) => {
        if (!id) return

        const merchant = merchants.find(item => item.id === Number(id))!;
        store.Select(String(merchant.id), false)
        navigate(`/merchant/${merchant.id}/profile`)
    }, [merchants, navigate])

    return (
        <Group>
            <Select
                data={list}
                placeholder={isPlatformMerchant ? user?.Merchant?.name : "Select merchant"}
                searchable={isPlatformAdmin}
                nothingFound={"Not found"}
                maxDropdownHeight={500}
                icon={<Icon icon={"fa6-solid:hashtag"} />}
                onSearchChange={setSearch}
                onChange={onSelect}
                variant={"filled"}
                searchValue={search}
                readOnly={isPlatformMerchant}
                clearable={isPlatformAdmin}
                defaultValue={String(selected)}
                styles={{
                    root: { border: "2px solid #12b886", borderRadius: "6px" },
                    input: { border: "0px" },
                }}
            />
        </Group>
    )
}
