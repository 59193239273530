import { useDebouncedValue } from "@mantine/hooks";
import { api } from "index";
import { useMemo } from "react";
import { useQuery } from "react-query";
import { useLocationStore } from "./useLocationStore";

export default function useSearchLocation() {
	const { state: { searchText }, setSearchLocation } = useLocationStore()
	const [debounced] = useDebouncedValue(searchText, 500)
	const { data, ...options } = useQuery(['LOCATIONS_BY_TEXT', debounced], () => api.openStreet.searchByName(debounced), { 
		staleTime: Infinity,
		enabled: Boolean(debounced) 
	})

	const locations = useMemo(() => {
		return data || []
	}, [data])

	return {
		locations,
		searchText,
		setSearchLocation,
		...options
	}
}
