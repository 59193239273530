import { FC } from "react";
import { ListItem } from "./ListItem";
import { IAccount, SuspendAccountForm } from "api/interfaces/account";
import { usePlatformAdminDelete } from "../hooks/platform/usePlatformAdminDelete";
import { usePlatformAdminPasswordReset } from "../hooks/platform/usePlatformAdminResetPassword";
import { FormProvider, useForm } from "react-hook-form";
import { usePlatformAdminUpdate } from "../hooks/platform/usePlatformAdminUpdate";

interface Props {
  user: IAccount;
}

export const PlatformAdminItemWrapper: FC<Props> = ({ user }) => {
  const { handleDelete, isLoading: isDeleteLoading } = usePlatformAdminDelete();
  const { handlePasswordReset, isLoading: isResetLoading } =
    usePlatformAdminPasswordReset();
  const { handleUpdate } = usePlatformAdminUpdate(user.id);
  const form = useForm<SuspendAccountForm>({
    defaultValues: {
      isSuspend: user.isSuspend,
    },
  });
  const onSubmit = async (values: SuspendAccountForm) => {
    await handleUpdate(values);
  };
  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <ListItem
          user={user}
          handleDelete={() => handleDelete(user.id)}
          handleReset={() => handlePasswordReset(user.id)}
          isLoading={isDeleteLoading || isResetLoading}
        />
      </form>
    </FormProvider>
  );
};
