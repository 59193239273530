import { Box, Stack, Title } from "@mantine/core";
import { FC } from "react";

export const StartupPage: FC = () => {
  return (
    <Box p={20}>
      <Stack>
        <Title>Welcome to QUBI CMS</Title>
        <Title order={4}>
          To continue, if you want to continue as merchant please select required merchant account in the header ▲
        </Title>
      </Stack>
    </Box>
  );
};
