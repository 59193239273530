import {Button, Group, Stack, Text} from "@mantine/core";
import {useMerchantDetails} from "../../../features/MerchantProfile/hooks/useMerchantDetails";
import dayjs from "dayjs"
import useMerchantAgreement from "../../../features/MerchantProfile/hooks/useMerchantAgreement";

export default function MerchantAgreementPage() {
	const { merchant } = useMerchantDetails()
	const { accept, isLoading } = useMerchantAgreement()

	const acceptDate = dayjs(merchant?.agreementAcceptDate).format("MMMM D, YYYY h:mm A");
	
	return (
			<Stack p={"lg"} spacing={0}>
				<Group position="apart">
					<Group position="left">
						<Text size="xl" weight="bold">
							User Agreement
						</Text>
					</Group>
					<Group position="right"/>
				</Group>
				
				<Group>
					{merchant?.isAgreementAccepted && (
							<Text>{acceptDate}</Text>
					)}
					{!merchant?.isAgreementAccepted && (
							<Button variant={"filled"} color={"dark"} onClick={() => accept()} disabled={isLoading} loading={isLoading}>Accept</Button>
					)}
				</Group>
			</Stack>
	)
}
