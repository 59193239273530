import { useCallback } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { api } from "index";
import { useModalStore } from "../../Modals/hooks/useModalStore";
import { useMerchantNavigation } from "../../../hooks/useMerchantNavigation";
import { showNotification } from "@mantine/notifications";
import { ErrorResponse } from "api/interfaces/api";

export const useCouponDelete = (id: number | string) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { setupModal, setModalStatus, closeModal } = useModalStore();

  const { id: merchantId, route } = useMerchantNavigation();

  const { mutateAsync: deleteCoupon, isLoading, isError } = useMutation({
    mutationFn: async () => await api.coupons.delete(merchantId, id),
    onSuccess: async () => {
      closeModal();
      showNotification({
        autoClose: 3000,
        title: "Success!",
        message: "Coupon successfully deleted!",
        color: "green",
      });
      navigate(`${route}/coupons`);

      await Promise.all([
        queryClient.invalidateQueries(["COUPONS_LIST", merchantId]),
        queryClient.invalidateQueries(["COUPONS_LIST"]),
        queryClient.invalidateQueries(["COUPON"]),
      ]);
    },
    onError: (e: ErrorResponse) => {
      showNotification({
        message: e.error ?? "Something went wrong.",
        title: "Error!",
        color: "red",
        autoClose: 5000,
      });
    }
  });

  const handleDelete = useCallback(async () => {
    if (!id) return;
    const onDelete = async () => {
      closeModal();
      await deleteCoupon();
    };
    setupModal({
      action: onDelete,
      type: "delete",
      content:
        "Please confirm your action. <br/>Selected Coupon will be deleted!",
    });
    setModalStatus(true);
  }, [deleteCoupon, navigate, id, setupModal, setModalStatus]);

  return {
    handleDelete,
    isLoading,
    isError,
  };
};
