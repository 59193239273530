import { useQuery, useMutation, useQueryClient } from "react-query";
import { api } from "index";

type LocationEventSettings = {
    locationEventId: number,
    frequencyOccurrence: number,
    maxAttempts: number
};

type AddLocationEventProps = {
    gameId: number | string;
    merchantId: number | string;
    locationEventSettings: LocationEventSettings;
};

type RemoveLocationEventProps = {
    gameId: number | string;
    merchantId: number | string;
    locationEventId: number | string;
};

export const useShareLocationEvents = (merchantId: number | string, gameId: number | string) => {
    const client = useQueryClient();

    const { data, isLoading } = useQuery({
        queryFn: async () => await api.games.getEvents(merchantId, gameId),
        queryKey: [`MERCHANT_GAME_EVENTS`, { merchantId: merchantId }, { gameId: gameId }],
    });

    const { mutateAsync: removeEvent, isLoading: removeEventLoading } = useMutation({
        mutationFn: async ({ gameId, merchantId, locationEventId }: RemoveLocationEventProps) =>
            await api.games.removeEvent(merchantId, gameId, locationEventId),
        onSuccess: async () => {
            await client.invalidateQueries([`MERCHANT_GAME_EVENTS`, { merchantId: merchantId }, { gameId: gameId }]);
        }
    });

    const { mutateAsync: addEvent, isLoading: addEventLoading } = useMutation({
        mutationFn: async ({ gameId, merchantId, locationEventSettings }: AddLocationEventProps) =>
            await api.games.addEvent(merchantId, gameId, locationEventSettings),
        onSuccess: async () => {
            await client.invalidateQueries([`MERCHANT_GAME_EVENTS`, { merchantId: merchantId }, { gameId: gameId }]);
        }
    });


    const { mutateAsync: updateEvent, isLoading: updateEventLoading} = useMutation({
        mutationFn: async ({ gameId, merchantId, locationEventSettings }: AddLocationEventProps) =>
            await api.games.updateEventSettings(merchantId, gameId, locationEventSettings),
        onSuccess: async (locationEventSettings) => {
            await Promise.all(
                [
                    client.invalidateQueries([`MERCHANT_GAME_EVENTS`, { merchantId: merchantId }, { gameId: gameId }]),
                    client.invalidateQueries(["LOCATION_EVENT", merchantId, locationEventSettings.locationEventId ]),
                    client.invalidateQueries(["LOCATION_EVENTS_LIST", merchantId]),
                    client.invalidateQueries(["LOCATION_EVENT", locationEventSettings.locationEventId]),
                ]
            );
        }
    });


    return {
        data,
        isLoading,
        removeEvent,
        removeEventLoading,
        addEvent,
        addEventLoading,
        updateEvent,
        updateEventLoading,
    }
}