import {useCallback, useEffect, useState} from "react";
import { RichTextEditor, Link } from "@mantine/tiptap";
import { useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import TextAlign from "@tiptap/extension-text-align";
import styled from "@emotion/styled";
import Image from "@tiptap/extension-image";
import {ActionIcon, Flex, Popover, TextInput, Title} from "@mantine/core";
import {IconPhoto, IconPhotoPlus} from "@tabler/icons-react"
// import Highlight from "@tiptap/extension-highlight";
// import Underline from "@tiptap/extension-underline";
// import Superscript from "@tiptap/extension-superscript";
// import SubScript from "@tiptap/extension-subscript";

interface IProps {
	value: string;
	onChange: (...event: any[]) => void;
}

const TextEditor = ({ value, onChange }: IProps) => {
	const [isInited, setIsInited] = useState(false);
	const [imgLink, setImgLink] = useState("");
	const [opened, setOpened] = useState(false);

	const editor = useEditor({
		extensions: [
			StarterKit,
			Link,
			TextAlign.configure({ types: ["heading", "paragraph"] }),
			Image
			// Underline,
			// Superscript,
			// SubScript,
			// Highlight,
		],
		content: value,
		onUpdate: ({ editor }) => {
			onChange(editor.getHTML());
		},
	});

	useEffect(() => {
		if (isInited || !value || value === "") return;

		editor?.commands.setContent(value);

		setIsInited(true);
	}, [value, editor, isInited]);


	const addImage = useCallback(() => {
		if (!!imgLink) {
			editor?.chain().focus().setImage({ src: imgLink }).run()

			setImgLink("")
			setOpened(false)
		}
	}, [editor,imgLink])

	return (
		<>
			<CustomTextEditor editor={editor}>
				<RichTextEditor.Toolbar sticky>
					<RichTextEditor.ControlsGroup>
						<RichTextEditor.Bold />
						<RichTextEditor.Italic />
						<RichTextEditor.Strikethrough />
						<RichTextEditor.ClearFormatting />
						<RichTextEditor.Code />
					</RichTextEditor.ControlsGroup>

					<RichTextEditor.ControlsGroup>
						<RichTextEditor.H1 />
						<RichTextEditor.H2 />
						<RichTextEditor.H3 />
						<RichTextEditor.H4 />
					</RichTextEditor.ControlsGroup>

					<RichTextEditor.ControlsGroup>
						<RichTextEditor.Blockquote />
						<RichTextEditor.Hr />
						<RichTextEditor.BulletList />
						<RichTextEditor.OrderedList />
					</RichTextEditor.ControlsGroup>

					<RichTextEditor.ControlsGroup>
						<RichTextEditor.Link />
						<RichTextEditor.Unlink />
					</RichTextEditor.ControlsGroup>

					<RichTextEditor.ControlsGroup>
						<RichTextEditor.AlignLeft />
						<RichTextEditor.AlignCenter />
						<RichTextEditor.AlignJustify />
						<RichTextEditor.AlignRight />
					</RichTextEditor.ControlsGroup>

					<RichTextEditor.ControlsGroup>
						<Popover opened={opened} onChange={setOpened} trapFocus position="bottom" withArrow shadow="md">
							<Popover.Target>
								<CustomButton onClick={() => setOpened((o) => !o)}>
									<IconPhoto size={16} stroke={1.5} />
								</CustomButton>

							</Popover.Target>
							<Popover.Dropdown sx={(theme) => ({ background: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white })}>
								<Title order={6}>Image link</Title>
								<Flex align={"center"}>
									<TextInput placeholder="Link" size="xs" value={imgLink} onChange={(event) => setImgLink(event.currentTarget.value)} />
									<ActionIcon variant="filled" color="teal" onClick={addImage}>
										<IconPhotoPlus size={16} stroke={2} />
									</ActionIcon>
								</Flex>

							</Popover.Dropdown>
						</Popover>
					</RichTextEditor.ControlsGroup>
				</RichTextEditor.Toolbar>

				<RichTextEditor.Content />
			</CustomTextEditor>
		</>
	);
};

export default TextEditor;

const CustomTextEditor = styled(RichTextEditor)`
	&.mantine-RichTextEditor-root {
		max-height: 410px;
		min-height: 200px;
		overflow: scroll;
	}

	ul {
		list-style: disc;
	}

	ol {
		list-style: decimal;
	}
`;

const CustomButton = styled.button`
	min-width: 1.625rem;
	height: 1.625rem;
	
	display: flex;
	justify-content: center;
	align-items: center;

	box-sizing: border-box;
	background-color: #fff;

	border-radius: 0.25rem;
	border: 0.0625rem solid #ced4da;

	cursor: pointer;
`;
