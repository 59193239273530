import * as yup from "yup";
import styled from "@emotion/styled";
import { Input } from "components/Input";
import TextField from "components/TextField";
import { useForm } from "react-hook-form";
import { Loader } from "@mantine/core";

import { useLogin } from "../Auth/hooks/useLogin";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback } from "react";
import { PasswordField } from 'components/PasswordInput';

// Validation Schema
const schema = yup
  .object({
    login: yup.string().required("Required").lowercase().trim(),
    password: yup.string().matches(/^[A-Za-z0-9]*$/, "The password must contain only latin characters and digits").required("Required"),
  })
  .required();

// Error Codes
const ErrorCode = {
  404: [{ field: "login", message: "User not found" }],
  401: [{ field: "password", message: "Password is incorrect" }],
};

// Component
export default function LoginForm() {
  const { handleLogin } = useLogin();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    setError,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      login: "",
      password: "",
    },
  });

  const onSubmit = useCallback(async (data) => {
    try {
      await handleLogin(data);
      reset();
    } catch (e) {
      ErrorCode[e.response.status]?.forEach((item) => {
        setError(item.field, { message: item.message });
      });
    }
  }, [setError, handleLogin, reset]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <TextField label="Login" errorText={errors.login?.message}>
        <Input placeholder="Email" {...register("login")} />
      </TextField>
      <TextField label="Password" errorText={errors.password?.message}>
        <PasswordField
          placeholder="Password"
          {...register("password")}
        />
      </TextField>

      <Button color={"#0095e5"} hoverColor={"#007bbd"} type="submit">
        {isSubmitting ? (
          <Loader size="xs" sx={{ stroke: "white" }} />
        ) : (
          "Sign in"
        )}
      </Button>
    </Form>
  );
}

// Styling
const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding-top: 24px;
`;

const Button = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #fff;

  padding: 12px 16px;
  margin-top: 8px;
  margin-bottom: 8px;
  border-radius: 4px;

  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  user-select: none;
  transition: background-color 0.15s ease-in-out;

  ${({ color }) =>
    color &&
    `
        background-color: ${color};
    `}
  &:hover {
    ${({ hoverColor }) =>
      hoverColor &&
      `
            background-color: ${hoverColor};
        `}
  }
`;
