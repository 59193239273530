import { Base } from "./Base";
import { ApiResponse } from "./interfaces/api";
import {QrInput, QrResponse, TemporaryQrInput, UploadImageResponse} from "./interfaces/files";

export default class Files extends Base {
  config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  async uploadImage(file: File) {
    const formData = new FormData()
    formData.append('file', file)

    const response = await this.request.post<ApiResponse<UploadImageResponse>>(`${this.baseUrl}/admin/files`, formData, this.config);
    return response.data.data;
  }

  async createQr(input: QrInput) {
    const response = await this.request.post<ApiResponse<QrResponse>>(`${this.baseUrl}/admin/files/qr`, input);
    return response.data.data;
  }

  async createTemporaryQr (input: TemporaryQrInput) {
    const response = await this.request.post<ApiResponse<QrResponse>>(`${this.baseUrl}/admin/qr`, input)
    return response.data.data
  }
}
