import { api } from "index";
import { useMutation, useQueryClient } from "react-query";
import { useCallback } from "react";
import { useMerchantDetails } from "./useMerchantDetails";
import { MerchantUpdateParams } from "../../../api/interfaces/merchants";
import { ErrorResponse } from "api/interfaces/api";
import { showNotification } from "@mantine/notifications";

export const useMerchantProfileUpdate = () => {
  const { merchant } = useMerchantDetails();
  const queryClient = useQueryClient();

  // Event Update Handler
  const { mutateAsync: update, ...options } = useMutation(
    (input: MerchantUpdateParams) => api.merchants.update(merchant?.id, input)
  );

  // Handler for forms
  const handleUpdate = useCallback(
    async (input: MerchantUpdateParams) => {
      if (typeof input.logo === "object") {
        const res = await api.files.uploadImage(input.logo);
        input.logo = res.url;
      }

      try {
        await update({ ...input });

        await Promise.all([
          queryClient.invalidateQueries(["MERCHANT", merchant?.id]),
        ]);
        showNotification({
          autoClose: 3000,
          title: "Success!",
          message: "Profile successfully updated!",
          color: "green",
        });
      } catch (e: any) {
        const error: ErrorResponse = e.response.data;
        showNotification({
          message: error.error ?? "Something went wrong.",
          title: "Error!",
          color: "red",
          autoClose: 5000,
        });
      }
    },
    [update]
  );

  return {
    handleUpdate,
    ...options,
  };
};
