import { Icon } from "@iconify/react";
import { SpotlightProvider } from "@mantine/spotlight";
import SpotlightWrapper from "./SpotlightWrapper";
import { PropsWithChildren, useMemo } from "react";
import useSearchLocation from "../hooks/useSearchLocation";
import { useLocationStore } from "../hooks/useLocationStore";
import useIdentifyLocation from "../hooks/useIdentifyLocation";

export default function FinderProvider({ children, onTrigger}: PropsWithChildren<{ onTrigger?: () => void }>) {
  const { locations, setSearchLocation } = useSearchLocation();
  const { setCoordsLocation } = useIdentifyLocation();
  const { setCurrentLocation } = useLocationStore()

  const actions = useMemo(() => locations.map((item: any) => ({
		title: item.display_name,
		icon: <Icon icon={"fluent:location-16-filled"} />,
		onTrigger: () => {
      onTrigger && onTrigger()

      setTimeout(() => {
        setCurrentLocation({
          title: item.display_name,
          coords: {
            lat: item.lat,
            lng: item.lon,
            bbox: item.boundingbox
          }
        })
        setCoordsLocation({
          lat: item.lat,
          lng: item.lon,
          bbox: item.boundingbox
        })
      }, 200)
    },
	})), [locations, setCurrentLocation, onTrigger]);

  return (
    <SpotlightProvider
      limit={Infinity}
      actions={actions}
      searchIcon={<Icon icon={"fluent:search-12-filled"} height={20} />}
      filter={(_, actions) => actions}
      onQueryChange={(text) => setSearchLocation(text)}
      actionsWrapperComponent={(props) => (
        <SpotlightWrapper isLoading={false} {...props} />
      )}
      searchPlaceholder="Search..."
      cleanQueryOnClose={true}
      zIndex={1000}
    >
      {children}
    </SpotlightProvider>
  );
}
