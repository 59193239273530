import styled from "@emotion/styled";
import { Icon } from "@iconify/react";
import {
  ActionIcon,
  Button,
  Checkbox,
  Group,
  Loader,
  Title,
} from "@mantine/core";
import { IAccount, SuspendAccountForm } from "api/interfaces/account";
import { useAuth } from "features/Auth/hooks/useAuth";
import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";

interface ListItemProps {
  user: IAccount;
  handleDelete: () => void;
  isLoading: boolean;
  handleReset: () => void;
  superAdmin?: boolean;
}

export const ListItem: FC<ListItemProps> = ({
  user,
  handleDelete,
  isLoading,
  handleReset,
  superAdmin,
}) => {
  const { control, formState } = useFormContext<SuspendAccountForm>();
  const { isSuperAdmin } = useAuth();
  return (
    <Root grow position="apart" className={superAdmin ? "superAdmin" : ""}>
      <Group position="left">
        <Title order={5}>{user.email}</Title>
      </Group>
      <Group position="right" align="center">
        <Controller
          control={control}
          name="isSuspend"
          render={({ field, fieldState }) => (
            <Checkbox
              checked={field.value}
              error={fieldState.error?.message}
              onChange={(e) => field.onChange(e.target.checked)}
              label="Suspend"
            />
          )}
        />

        <Button
          type="submit"
          leftIcon={<Icon icon={"fluent:save-24-filled"} height={18} />}
          radius={"sm"}
          color={"teal"}
          variant={"filled"}
          loading={formState.isSubmitting}
        >
          Update
        </Button>

        {isSuperAdmin && (
          <Button
            leftIcon={<Icon icon={"mdi:lock-reset"} height={18} />}
            radius={"sm"}
            color={superAdmin ? "orange.7" : "yellow"}
            variant={"filled"}
            onClick={() => handleReset()}
            loading={isLoading}
          >
            Password Reset
          </Button>
        )}

        <ActionIcon
          onClick={handleDelete}
          variant="light"
          color={"red"}
          size={"lg"}
          radius={"md"}
          style={{ border: "1px solid #fbe7e7" }}
        >
          {isLoading ? (
            <Loader size="xs" sx={{ stroke: "red" }} />
          ) : (
            <Icon icon={"fluent:delete-24-filled"} />
          )}
        </ActionIcon>
      </Group>
    </Root>
  );
};

const Root = styled(Group)`
  flex-direction: row;
  overflow: auto;

  background-color: #ffffff;
  padding: 8px 16px;
  user-select: none;

  &:not(:last-child) {
    border-bottom: 1px solid #e9ecef;
  }

  & {
    transition-property: background-color;
    transition-duration: 250ms;
    transition-timing-function: ease-in-out;
  }

  &:hover {
    background-color: rgba(44, 62, 80, 0.05);
  }
`;
