import { Outlet } from "react-router-dom";
import Navbar from "features/Navbar";
import styled from "@emotion/styled";
import Header from "features/Header";
import { useUserInterfaceStore } from "features/UI/hooks/useUIStore";
import usePaginatedData from "hooks/usePaginatedData";
import { Merchant } from "api/interfaces/merchants";
import { useExtendedMerchantStore } from "store/extended-merchant.store";
import { usePlatformTargetStore } from "store/platform-target.store";

// Layout
export default function DashboardLayout() {
    const { state } = useUserInterfaceStore()

    const sync = useExtendedMerchantStore(state => state.sync);
    const platformTargetSync = usePlatformTargetStore(state => state.sync);

    usePaginatedData<Merchant>({
        path: "/admin/merchants",
        defaultLimit: 1000,
        queryKey: ["EXTENDED_MERCHANT_LIST"],
        onSuccess: (data) => {
            const ids = data.items?.map((item: any) => item.id) ?? []
            sync(ids)
            platformTargetSync(ids)
        },
    })

    return (
        <DashboardRoot>
            {state.sidebar && (
                <Navbar />
            )}

            <AppContainer>
                {state.header && (
                    <Header />
                )}
                <PageContainer headerSize={state.header ? 60 : 0}>
                    <Outlet />
                </PageContainer>
            </AppContainer>
        </DashboardRoot>
    );
}

// Styling
const DashboardRoot = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  flex: 1;
`;

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 100%;
`

const PageContainer = styled.div<{ headerSize: number }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  max-height: ${({ headerSize }) => `calc(100vh - ${headerSize}px)`};
`;
