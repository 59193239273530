import { useQuery } from "react-query";
import { api } from "index";
import { Content } from "api/interfaces/content";
import useMerchantSelectorStore from "features/MerchantSelector/hooks/useMerchantSelectorStore";

export function useContent(id?: Content["id"]) {
  const merchantSelector = useMerchantSelectorStore();

  const selectedId = merchantSelector.useStore((state) => state.selected);
  const {
    data: content,
    isError,
    isLoading,
  } = useQuery(
    ["CONTENT", selectedId, id],
    () => api.contents.readOne(id, Number(selectedId)),
    {
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
      enabled: !!id,
      retry: 0,
    }
  );

  return { content, isError, isLoading };
}
