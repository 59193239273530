import { Stack } from "@mantine/core";
import ContentLoader from "components/ContentLoader";
import PageToolbar from "components/PageToolbar";
import QueryKeys from "constants/query-keys";
import CollectionCardEdit from "features/CollectionCardEdit";
import { TCollectableCard } from "features/CollectionCardsList/types";
import TabSections from "features/CollectionCardEdit/TabSections";
import useData from "hooks/useData";
import { useMerchantNavigation } from "hooks/useMerchantNavigation";
import { useParams } from "react-router-dom";


export default function MerchantCollectableCardEditPage() {
    const { id } = useParams<{ id: string }>();
    const { route, id: merchantId } = useMerchantNavigation();

    const { isLoading } = useData<ApiResponse<TCollectableCard>, TCollectableCard>({
        path: `/admin/merchants/${merchantId}/collectible-cards/${id}`,
        queryKey: [QueryKeys.MERCHANT_COLLECTABLE_CARD, { cardId: Number(id) }],
        select: (data) => data.data,
        enabled: !!id
    });

    if (isLoading) return <ContentLoader />

    return (
        <Stack p={24} spacing={24}>
            <PageToolbar label={"Go Back"} backLink={`${route}/collectable-cards`} />

            <CollectionCardEdit />
            <TabSections />
        </Stack>
    )
}