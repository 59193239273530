import {
    ActionIcon,
    Button,
    createStyles,
    Group,
    Stack,
    Text,
  } from "@mantine/core";
  import { useCallback } from "react";
  import { useModalStore } from "../hooks/useModalStore";
  import { Icon } from "@iconify/react";
  import { useAppConfigConstants } from 'features/AppAccess/hooks/useAppConfigConstants';

  type LinksId = 8 | 9;
  
  export const FeedbackModal = () => {
    const { closeModal, state } = useModalStore();
    const { classes } = useStyles();
    const {data: constants, isLoading} = useAppConfigConstants();
    const getLinkFromConstant = useCallback(
        (id: LinksId) => constants?.items.filter((val) => val.id === id)[0].textValue,
        [constants]
    );  
  console.log(getLinkFromConstant(8))
  console.log(getLinkFromConstant(9))
    return (
      <Stack className={classes.root}>
        <Group position={"apart"} align={"center"}>
          <Group position={"left"}>
            <Text size={"md"} weight={"bold"}>
              Feedback
            </Text>
          </Group>
  
          <Group position={"right"}>
            <ActionIcon onClick={closeModal} variant={"transparent"}>
              <Icon color={"red"} icon="ep:close" />
            </ActionIcon>
          </Group>
        </Group>
  
        <Stack>
          <Text
            align={"center"}
            dangerouslySetInnerHTML={{ __html: state.content! }}
          />
  
          <Group grow align={"center"}>
            <Button
              component="a"
              variant={'filled'}
              color={'violet'}
              target="_blank"
              href={getLinkFromConstant(8)}
              size="sm"
              loading={isLoading}
            >
              ClickUp Form
            </Button>
            <Button
              component="a"
              href={`mailto:${getLinkFromConstant(9)}`}
              size="sm"
              variant={'filled'}
              color={'dark'}
              target="_blank"
              loading={isLoading}
            >
              Email
            </Button>
          </Group>
        </Stack>
      </Stack>
    );
  };
  
  const useStyles = createStyles(() => ({
    root: {
      position: "relative",
      width: 350,
    },
  }));
  