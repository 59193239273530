import styled from "@emotion/styled";
import {Link} from "react-router-dom";
import {Icon} from "@iconify/react";
import {ActionIcon, Group, Loader, Stack, Text, Title} from "@mantine/core";
import {useLocationDelete} from "features/LocationEdit/hooks/useLocationDelete";
import {Location} from "api/interfaces/location";
import {useMerchantNavigation} from "../../../hooks/useMerchantNavigation";

// Props
type ComponentProps = {
    location: Location;
};

// Component
export default function LocationItem({location}: ComponentProps) {
    const {handleDelete, isLoading} = useLocationDelete(location.id);
    const {route} = useMerchantNavigation()

    return (
        <Link to={`${route}/locations/${location.id}`}>


            <Root grow>
                <Group position="left">
                    <Stack spacing={0}>
                        <Title order={5}>{location.name}</Title>
                        <Text size="sm" color={'dimmed'}>
                            {location.Country.name}
                        </Text>
                    </Stack>
                </Group>

                <Group position="right" spacing={'lg'}>
                    <Group position="right">
                        <Text color={'dimmed'} size={'sm'}>{location.isEnabled ? "Enabled" : "Disabled"}</Text>
                    </Group>

                    <Group position="right" spacing={'xs'}>
                        <ActionIcon variant="light" color={'teal'} size={'lg'} radius={'md'}
                                    style={{border: "1px solid #cef3e8"}}>
                            <Icon icon={"fluent:edit-24-filled"} color={'teal'}/>
                        </ActionIcon>
                        <ActionIcon onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                            e.preventDefault();
                            handleDelete()
                        }} variant="light" color={'red'} size={'lg'} radius={'md'}
                                    style={{border: "1px solid #fbe7e7"}}>
                            {isLoading
                                ? <Loader size="xs" sx={{stroke: "red"}}/>
                                : <Icon icon={"fluent:delete-24-filled"}/>
                            }
                        </ActionIcon>
                    </Group>
                </Group>
            </Root>
        </Link>
    );
}

// Styling
const Root = styled(Group)`
  flex-direction: row;

  background-color: #ffffff;
  padding: 8px 16px;
  user-select: none;

  &:not(:last-child) {
    border-bottom: 1px solid #e9ecef;
  }

  & {
    transition-property: background-color;
    transition-duration: 250ms;
    transition-timing-function: ease-in-out;
  }

  &:hover {
    background-color: rgba(44, 62, 80, 0.05);
  }
`;
