import { showNotification } from "@mantine/notifications";
import { ErrorResponse } from "api/interfaces/api";
import { useModalStore } from "features/Modals/hooks/useModalStore";
import { api } from "index";
import { useCallback } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

export const useUserDelete = () => {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { closeModal, setupModal, setModalStatus } = useModalStore();
  const { mutateAsync: deleteUser, ...rest } = useMutation(
    () => api.users.deleteUser(Number(id)),
    {
      onSuccess: async () => {
        showNotification({
          autoClose: 3000,
          title: "Success!",
          message: "User successfully deleted!",
          color: "green",
        });

        navigate("/platform/users");

        await Promise.all([
            queryClient.invalidateQueries(["USERS_LIST"]),
        ]);
        },
      onError: (e: any) => {
        const error: ErrorResponse = e.response.data;
        showNotification({
          message: error.error ?? "Something went wrong.",
          title: "Error!",
          color: "red",
          autoClose: 5000,
        });
      },
    }
  );

  const handleDelete = useCallback(() => {
    const onDelete = async () => {
      closeModal();
      await deleteUser();
    };

    setupModal({
      type: "delete",
      content:
        "Please confirm your action. <br/>Selected User will be deleted!",
      action: onDelete,
    });
    setModalStatus(true);
  }, [queryClient, deleteUser, navigate, id]);

  return { ...rest, handleDelete };
};
