import {useParams} from "react-router-dom";
import {useQuery} from "react-query";
import {api} from "../../../index";

export function useMerchantDetails() {
	const pageParams = useParams()
	
	const { data, ...rest } = useQuery(["MERCHANT", Number(pageParams.merchantId)], () => api.merchants.readOne(pageParams.merchantId))
	
	return {
		merchant: data,
		...rest
	}
}
