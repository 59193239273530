import { useQuery } from "react-query";
import { api } from "index";

export function useAllDaysOfWeek() {
    const {
        data: allDaysOfWeek,
        isError,
        isLoading,
        refetch,
    } = useQuery(
        ["ALL_DAYS_OF_WEEK"],
        () => api.events.getAllDaysOfWeek(),
        {
            refetchOnWindowFocus: false,
            refetchIntervalInBackground: false,
            retry: 0,
        }
    );

    return { allDaysOfWeek, isError, isLoading, refetch };
}
