import { Button, Group, Stack, Text } from "@mantine/core";
import styled from "@emotion/styled";
import { Icon } from "@iconify/react";
import { FormProvider, useForm } from "react-hook-form";
import { useCallback, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useLocation } from "react-router-dom";
import * as yup from "yup";
import { useMerchantProfileUpdate } from "features/MerchantProfile/hooks/useMerchantProfileUpdate";
import { useMerchantDetails } from "features/MerchantProfile/hooks/useMerchantDetails";
import MerchantProfileEdit from "features/MerchantProfile";
import { useAuth } from "features/Auth/hooks/useAuth";
import AdminFeatures from "features/MerchantProfile/components/AdminFeatures";
import { useMerchantWorkingDays } from "features/MerchantProfile/store/merchant-working-days.store";

const schema = yup
  .object({
    name: yup.string().required("Required").trim(),
    description: yup.string().required("Required").min(4).trim(),
    phone: yup.string()
      .matches(/^$|^\d{7,}$/, "Phone is too short. Minimum length is 7 chars."),
    email: yup
      .string()
      .email("Email must be a valid")
      .nullable()
      .matches(
        /^$|^(([^<>()\[\]\\.,;:\s@"а-яА-ЯёЁ]+(\.[^<>()\[\]\\.,;:\s@"а-яА-ЯёЁ]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/,
        "Email must be a valid"
      )
      .lowercase()
      .trim(),
    address: yup
      .string()
      .trim()
      .matches(/^[#.\da-zA-Z\s,-]+$/, {
        message: "Enter correct address",
        excludeEmptyString: true,
      }),
    website: yup.string()
      .trim()
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        "Enter correct website!"),
    countryId: yup.number(),
  })
  .required();

export function CompanyProfile() {
  const { merchant } = useMerchantDetails();
  const { handleUpdate } = useMerchantProfileUpdate();
  const { hash } = useLocation();
  const { isPlatformAdmin } = useAuth();
  const saveDate = useMerchantWorkingDays(state => state.saveDate);
  // const [p2p, setP2p] = useState<string | undefined>(undefined);
  // const [card, setCard] = useState<string | undefined>(undefined);

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      description: "",
      email: "",
      phone: "",
      address: "",
      website: "",
      countryId: "233",
      workDays: [],
      workDaysTimes: [],
      ...merchant,
    },
    mode: "onChange",
  });

  const onSubmit = useCallback(
    async (input: any) => {
      await handleUpdate(input);
      saveDate();
    },
    [handleUpdate]
  );

  useEffect(() => {
    if (!merchant) return;

    form.reset({
      ...merchant,
      name: merchant.name,
      description: merchant.description,
      email: merchant.email,
      phone: merchant.phone,
      address: merchant.address,
      website: merchant.website,
      countryId: String(merchant.countryId),
      balance: Number(merchant.balance.toFixed(3)),
      workDays: [],
      workDaysTimes: []
    });
  }, [merchant, form]);

  useEffect(() => {
    const el = hash && document.getElementById(hash.substr(1));
    if (!el) return;

    el.scrollIntoView({ behavior: "smooth" });
  }, [hash]);

  // useEffect(() => {
  //   const pTp = localStorage.getItem("P2P_transaction");
  //   const creditCard = localStorage.getItem("Credit_card_transaction");
  //   console.log(pTp, creditCard);
  //   if (pTp) setP2p(pTp);
  //   if (creditCard) setCard(creditCard);
  // }, [])

  return (
    <Stack spacing={0}>
      <FormProvider {...form}>
        <Form onSubmit={form.handleSubmit(onSubmit)}>
          <Stack spacing={0}>
            <Group position="apart">
              <Group position="left">
                <Text size="xl" weight="bold">
                  Company Details
                </Text>
              </Group>
              {/* <Group position="center">
                {p2p &&
                  <Tooltip label="Fee percent for p2p transactions"
                    color="#3c78d8"
                    withArrow
                    position="bottom-end"
                  >
                    <Text weight="bold" c={"#3c78d8"} style={{ cursor: "default" }}>P2p: {p2p}%</Text>
                  </Tooltip>
                }
                {card &&
                  <Tooltip label="Fee percent for credit card transactions"
                    color="#0aac00"
                    withArrow
                    position="bottom-start"
                  >
                    <Text weight="bold" c={"#0aac00"} style={{ cursor: "default" }}>Credit card: {card}%</Text>
                  </Tooltip>
                }
              </Group> */}
              <Group position="right">
                <Button
                  type="submit"
                  leftIcon={<Icon icon={"fluent:save-24-filled"} height={18} />}
                  radius={"sm"}
                  color={"teal"}
                  variant={"filled"}
                  loading={form.formState.isSubmitting}
                >
                  Update
                </Button>
              </Group>
            </Group>
          </Stack>

          <MerchantProfileEdit />
        </Form>
      </FormProvider>

      {merchant && isPlatformAdmin && <AdminFeatures merchant={merchant} />}
    </Stack>
  );
}

const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;
