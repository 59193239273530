import { Base } from "./Base";
import { ApiResponse, ApiResponseList } from "./interfaces/api";
import { AdminConstant } from "./interfaces/constants";

export default class AdminConstants extends Base {
  async readAll() {
    const response = await this.request.get<ApiResponseList<AdminConstant>>(
      `${this.baseUrl}/admin/constants`
    );
    return response.data.data;
  }

  async update({ id, ...data }: AdminConstant) {
    const response = await this.request.patch<ApiResponse<AdminConstant>>(
      `${this.baseUrl}/admin/constants/${id}`,
      data
    );
    return response.data.data;
  }

  async updateReferral(id: number, input: AdminConstant["floatValue"]) {
    const response = await this.request.patch<ApiResponse<AdminConstant>>(
      `${this.baseUrl}/admin/constants/${id}`,
        { floatValue: input }
    );
    return response.data.data;
  }
}
