import { combineReducers, configureStore } from "@reduxjs/toolkit";

// Slices
import authSlice from "features/Auth/store";
import locationSlice from "features/LocationsMap/store";
import userInterfaceSlice from "features/UI/store";
import modalSlice from "features/Modals/store/modalSlice";
import merchantSelector from "features/MerchantSelector/store"

const reducer = combineReducers({
  auth: authSlice.reducer,
  location: locationSlice.reducer,
  userInterface: userInterfaceSlice.reducer,
  modal: modalSlice.reducer,
  merchantSelector: merchantSelector.reducer
});

export default configureStore({
  reducer,
  devTools: {
    actionCreators: {
      ...authSlice.actions,
      ...locationSlice.actions,
      ...userInterfaceSlice.actions,
      ...modalSlice.actions,
      ...merchantSelector.actions
    }
  }
});

export type AppState = ReturnType<typeof reducer>;
