import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Types
export interface ModalSlice {
  type?: "subscription" | "delete" | "error" | "verification" | "feedback";
  content?: string;
  status: boolean;
  action?: any;
  loading: boolean;
}

// Initial State
const initialState: ModalSlice = {
  type: undefined,
  content: undefined,
  status: false,
  loading: false,
};

// Auth Store
export default createSlice({
  name: "modals",
  initialState,
  reducers: {
    setModalContent: (state, action: PayloadAction<string | undefined>) => {
      state.content = action.payload;
    },
    setStatus: (state, action: PayloadAction<boolean>) => {
      state.status = action.payload;
    },
    changeModalType: (state, action: PayloadAction<ModalSlice["type"]>) => {
      state.type = action.payload;
    },
    changeModalAction: (state, action: PayloadAction<ModalSlice["action"]>) => {
      state.action = action.payload;
    },
    changeLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});
