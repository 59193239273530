import { useMemo } from "react";
import { useRewards } from "../hooks/useRewards";
import useLocationEvents from "features/LocationEventsList/hooks/useLocationEvents";
import usePaginatedData from "hooks/usePaginatedData";
import { TCollectableCard } from "features/CollectionCardsList/types";
import QueryKeys from "constants/query-keys";
import { useParams } from "react-router-dom";

export const useQuestData = () => {
  const { merchantId } = useParams();
  const { rewards } = useRewards();
  const { locationEvents } = useLocationEvents();

  const { items: collectionCards } = usePaginatedData<TCollectableCard>({
    path: `/admin/merchants/${merchantId}/collectible-cards`,
    defaultLimit: 1000,
    queryKey: [QueryKeys.MERCHANT_COLLECTABLE_CARDS],
  });

  const locationList = useMemo(() => {
    return locationEvents.map((location) => ({
      value: String(location.id),
      label: location.name,
    }));
  }, [locationEvents]);

  const rewardsList = useMemo(() => {
    return rewards.map((reward) => ({
      value: String(reward.value),
      label: reward.label,
    }));
  }, [rewards]);

  const collectionCardsList = useMemo(() => {
    return collectionCards.map((card) => ({
      value: String(card.id),
      label: card.name,
    }));
  }, [collectionCards]);

  return {
    locationList,
    rewardsList,
    collectionCardsList,
  };
};