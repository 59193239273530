import { api } from "index"
import { useQuery } from "react-query"
import useMerchantSelectorStore from "features/MerchantSelector/hooks/useMerchantSelectorStore";
import { useMemo } from "react";

export const useRewards = () => {
    const merchantSelector = useMerchantSelectorStore();
    const selectedId = merchantSelector.useStore((state) => state.selected);
    
    const { data, isLoading, isError } = useQuery({
        queryFn: async () => await api.contents.readAll(Number(selectedId), { skip: 0, limit: 1000 }),
    });

    const rewards = useMemo(() => {
        if (!data) return [];
        return data.items.map((item) => {
            return {
                label: item.name,
                value: item.id,
            }
        })
    }, [data, isLoading]);

    return {
        rewards,
        isLoading,
        isError,
    };
}