import { FC, useMemo } from "react";
import styled from "@emotion/styled";
import { Box, Stack, Text } from "@mantine/core";
import { RandomRewardItem } from "./components/RandomRewardItem";

interface RandomRewardsListProps {
    rewards: any[];
    handleDelete: (id: string) => void;
}

export const RandomRewardList: FC<RandomRewardsListProps> = ({ rewards = [], handleDelete }) => {

    const items = useMemo(() => {
        if (!rewards?.length) {
          return (
            <Stack justify={"center"} sx={{ height: "100%" }}>
              <Text align="center" size="sm" color={"dimmed"}>
                Not found...
              </Text>
            </Stack>
          );
        }
    
        return rewards.map((reward, i) => (
          <RandomRewardItem handleDelete={handleDelete} reward={reward} key={i} />
        ));
      }, [rewards]);

    return (
        <Box>
            <Root>
                {items}
            </Root>
        </Box>
    );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 35px;
  background: #ffffff;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  overflow-y: hidden;
`;