import { api } from "index";
import { useQuery } from "react-query";

export const useAppInvites = () => {
  const { data, isLoading } = useQuery(
    ["APP_INVITES_LIST"],
    () => api.appInvites.readAll({}),
    {
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
      retry: 0,
    }
  );

  return { data, isLoading };
};
