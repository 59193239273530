import { showNotification } from "@mantine/notifications";
import { ErrorResponse } from "api/interfaces/api";
import { useModalStore } from "features/Modals/hooks/useModalStore";
import { api } from "index";
import { useCallback } from "react";
import { useMutation } from "react-query";

export const useMerchantAdminPasswordReset = (merchantId: number) => {
  const { mutateAsync: resetPassword, ...rest } = useMutation(
    (id: number) => api.admins.resetMerchantAdminPassword(merchantId, id),
    {
      onSuccess: () => {
        showNotification({
          autoClose: 3000,
          title: "Success!",
          message: "Password successfully reset!",
          color: "green",
        });
      },
      onError: (e: any) => {
        const error: ErrorResponse = e.response.data;
        showNotification({
          message: error.error ?? "Something went wrong.",
          title: "Error!",
          color: "red",
          autoClose: 5000,
        });
      },
    }
  );
  const { closeModal, setupModal, setModalStatus } = useModalStore();

  const handlePasswordReset = useCallback(
    async (id: number) => {
      if (!id) return;
      const onReset = async () => {
        closeModal();
        await resetPassword(id);
      };

      setupModal({
        type: "delete",
        content: "Please confirm your action. <br/>Password will be reset",
        action: onReset,
      });
      setModalStatus(true);
    },
    [resetPassword]
  );

  return { ...rest, handlePasswordReset };
};
