import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {LocalStorage} from "../../../helpers/localStorage";

export interface MerchantSelectorState {
    selected?: null | string;
}

// Initial State
const initialState: MerchantSelectorState = {
    selected: LocalStorage.key('merchant-selector').get()
};

// Auth Store
export default createSlice({
    name: "merchantSelector",
    initialState,
    reducers: {
        select: (state, action: PayloadAction<string | null>) => ({
            ...state,
            selected: action.payload
        })
    }
});
