// import styled from "@emotion/styled";
import TextContentItem from "./components/TextContentItem";
import { TextContent } from 'api/interfaces/textContent';
import { Paper } from "@mantine/core";
import styled from "@emotion/styled";

// Props
type ComponentProps = {
  textContents: TextContent[]
}

// Component
export default function TextContentList({ textContents }: ComponentProps) {
  return (
    <Root radius={"md"} withBorder>
      {textContents.map(content => (
        <TextContentItem key={content.id} textContent={content} />
      ))}
    </Root>
  )
}

// Styling
const Root = styled(Paper)<any>`
  margin-top: 20px;
  overflow: hidden;
`


