import { showNotification } from "@mantine/notifications";
import { IAccount, SuspendAccountForm } from "api/interfaces/account";
import { ErrorResponse } from "api/interfaces/api";
import { api } from "index";
import { useCallback } from "react";
import { useMutation, useQueryClient } from "react-query";

export const usePlatformAdminUpdate = (id: IAccount["id"]) => {
  const queryClient = useQueryClient();
  const { mutateAsync: updateAdmin, ...rest } = useMutation(
    (data: SuspendAccountForm) => api.admins.updatePlatformAdmin(data, id)
  );

  const handleUpdate = useCallback(
    async (data: SuspendAccountForm) => {
      try {
        await updateAdmin(data);
        await Promise.all([queryClient.invalidateQueries(["PLATFORM_ADMINS"])]);
        showNotification({
          autoClose: 3000,
          title: "Success!",
          message: "Admin successfully updated!",
          color: "green",
        });
      } catch (e: any) {
        const error: ErrorResponse = e.response.data;
        showNotification({
          message: error.error ?? "Something went wrong.",
          title: "Error!",
          color: "red",
          autoClose: 5000,
        });
      }
    },
    [updateAdmin]
  );

  return { ...rest, handleUpdate };
};
