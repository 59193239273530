import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import AuthLayout from "features/Layouts/AuthLayout";

import Login from "pages/auth/Login";
import RegisterPage from "pages/auth/Register";
import ReferralPage from "pages/referral";

export default function PublicRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Outlet />}>
        <Route index element={<Navigate replace to="/auth/sign-in" />} />
        <Route path="auth" element={<AuthLayout />}>
          <Route index element={<Navigate replace to="/auth/sign-in" />} />
          <Route path="sign-in" element={<Login />} />
          <Route path="sign-up" element={<RegisterPage />} />
          {/*<Route path="forgot-password" element={<Forgot />} />*/}
        </Route>
        <Route path="referral/:userId" element={<ReferralPage />} />
        <Route path="*" element={<Navigate replace to="/auth/sign-in" />} />
      </Route>
    </Routes>
  );
}
