import styled from "@emotion/styled";
import { Icon } from "@iconify/react";
import { Group, Avatar, ActionIcon, Text, Stack } from "@mantine/core";
import { TCollectableCard } from "features/CollectionCardsList/types";
import { useParams } from "react-router-dom";
import { useSharedCards } from "../../hooks/useSharedCards";
import { useModalStore } from "features/Modal/store/modal.store";
import { ModalTrigger } from "features/Modal/constants";

type SharedItemProps = {
    maxAttempts: number;
    frequencyOccurrence: number;
    card: TCollectableCard;
}

export default function ListItem({ card, maxAttempts, frequencyOccurrence }: SharedItemProps) {
    const { id, merchantId } = useParams<{ id: string, merchantId: string }>();
  
    const { removeCard, removeCardLoading } = useSharedCards(merchantId!, id!);
    const openModal = useModalStore((state) => state.setOpen);
    const onOpenEdit = () => {
        openModal({
            title: 'Add Collectible Card',
            trigger: ModalTrigger.ADD_CARDS,
            payload: { card, maxAttempts, frequencyOccurrence },
        });
    };
    return (
        <Root position='apart' px={16} py={8} grow>
            <Group position='left' align={'center'} spacing={8} noWrap style={{ overflowX: "hidden" }}>
                {card.imagURL
                    ? <Avatar size="sm" radius={'xl'} src={card.imagURL} alt="it's me" />
                    : <Avatar size="sm" radius={'xl'} color='blue'>{card.name[0].toUpperCase()}</Avatar>
                }
                <Stack spacing={2}>
                    <Text size={'sm'} weight={500}>{card.name}</Text>
                    <Text size={'xs'} color={'gray'}>{card.description}</Text>
                </Stack>
            </Group>
            <Stack spacing={8}>
                <Text size={12} weight={400} color="gray.6" lh={1.5}>
                    Max attempts: <Text span color="dark.8">{maxAttempts}</Text>
                </Text>
                <Text size={12} weight={400} color="gray.6" lh={1.5}>
                    Frequency occurrence: <Text span color="dark.8">{frequencyOccurrence * 100}%</Text>
                </Text>
            </Stack>
            <Group position='right' spacing={8}>
                <ActionIcon
                    variant="subtle"
                    color="gray.5"
                    onClick={onOpenEdit}
                    sx={(theme) => ({
                        background: "#FCFDFE",
                        border: "1px solid #F1F3F5",
                        borderRadius: "6px",
                        color: theme.colors.dark[2],
                        "& svg[data-action-icon-loader]": {
                            stroke: theme.colors.dark[2],
                        },
                    })}
                >
                    <Icon icon={"fluent:pen-16-regular"} height={14} />
                </ActionIcon>
                <ActionIcon
                    variant={'subtle'}
                    onClick={() => removeCard({ gameId: id, merchantId: merchantId, cardId: card.id } as any)}
                    loading={removeCardLoading}
                    sx={(theme) => ({
                        background: '#FCFDFE',
                        border: '1px solid #F1F3F5',
                        borderRadius: '6px',
                        color: theme.colors.red[5],
                        "& svg[data-action-icon-loader]": {
                            stroke: theme.colors.red[5],
                        }
                    })}
                >
                    <Icon icon={'fluent:delete-16-regular'} height={14} />
                </ActionIcon>
            </Group>
        </Root>
    )
}

const Root = styled(Group)`
    &:not(:last-child) {
        border-bottom: 1px solid #f8f9fa;
    }
`