import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Types
export type UserInterfaceState = {
	header: boolean;
	sidebar: boolean;
}

// Initial State
const initialState: UserInterfaceState = {
  header: true,
	sidebar: true
};

// Auth Store
export default createSlice({
  name: "user-interface",
  initialState,
  reducers: {
    toggleAll: (state) => ({
			header: !state.header,
			sidebar: !state.sidebar
		}),
		toggleElement: (state, action: PayloadAction<keyof UserInterfaceState>) => ({
			...state,
			[action.payload]: !state[action.payload]
		}),
  },
});
