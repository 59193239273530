// import { Button, Group, Modal, Stack, Text, Title } from "@mantine/core";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LocationMark } from "../store";
import {useMerchantNavigation} from "../../../hooks/useMerchantNavigation";

// Props
interface ComponentProps {
  marker?: LocationMark;
}

// Component
export default function MarkerSelectObserver({ marker }: ComponentProps) {
  const navigate = useNavigate()
  const { route } = useMerchantNavigation()
  
  // Написан этот перехватчик потому что вызов из компонента вызывает ошибку
  useEffect(() => {
    if (!marker) return
    navigate(`${route}/locations/${marker.id}`)
  }, [marker, navigate])

  return null
}
