import { showNotification } from "@mantine/notifications";
import { SuspendAccountForm } from "api/interfaces/account";
import { ErrorResponse } from "api/interfaces/api";
import { api } from "index";
import { useCallback } from "react";
import { useMutation, useQueryClient } from "react-query";

type MutationParams = {
  data: SuspendAccountForm;
  id: number;
};

export const useMerchantAdminUpdate = (merchantId: number) => {
  const queryClient = useQueryClient();
  const { mutateAsync: updateAdmin, ...rest } = useMutation(
    ({ data, id }: MutationParams) =>
      api.admins.updateMerchantAdmin(data, merchantId, id)
  );

  const handleUpdate = useCallback(
    async (data: SuspendAccountForm, id: number) => {
      try {
        await updateAdmin({ data, id });
        await Promise.all([
          queryClient.invalidateQueries(["MERCHANT_ADMINS", merchantId]),
        ]);

        showNotification({
          autoClose: 3000,
          title: "Success!",
          message: "Admin successfully updated!",
          color: "green",
        });
      } catch (e: any) {
        const error: ErrorResponse = e.response.data;
        showNotification({
          message: error.error ?? "Something went wrong.",
          title: "Error!",
          color: "red",
          autoClose: 5000,
        });
      }
    },
    [updateAdmin]
  );

  return { ...rest, handleUpdate };
};
