import { Icon } from "@iconify/react";
import { Box, Button, Grid, Input, Modal, Text } from "@mantine/core";
import { MerchantAdminsList } from "features/PlatformAdmins/MerchantAdminsList";
import { InviteAdminModal } from "features/PlatformAdmins/components/InviteAdminModal";
import { useMerchantAdminInvite } from "features/PlatformAdmins/hooks/merchant/useMerchantAdminInvite";
import { useMerchantAdmins } from "features/PlatformAdmins/hooks/merchant/useMerchantAdmins";
import { FC, useState } from "react";
import { useParams } from "react-router-dom";

export const MerchantAdmins: FC = () => {
  const { merchantId } = useParams();
  const [inviteModalStatus, setInviteModalStatus] = useState<boolean>(false);
  const { handleInvite, isLoading: isInviteLoading } = useMerchantAdminInvite(
    Number(merchantId)
  );

  const { admins, search, setSearch, isLoading } = useMerchantAdmins(
    Number(merchantId)
  );

  if (!merchantId) return null;

  return (
    <Box p={20}>
      <Grid justify="space-between" px={10} mt={10}>
        <Text size="xl" weight="bold">
          Merchant Admins
        </Text>
        <Button color="yellow" onClick={() => setInviteModalStatus(true)}>
          Invite Admin
        </Button>
      </Grid>
      <Input
        py={10}
        sx={{ width: "30%", minWidth: 250 }}
        placeholder="Search..."
        icon={<Icon icon={"ic:baseline-search"} />}
        value={search}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setSearch(e.target.value)
        }
      />
      <MerchantAdminsList
        admins={admins}
        isLoading={isLoading}
        merchantId={Number(merchantId)}
      />
      <Modal
        onClose={() => setInviteModalStatus(false)}
        opened={inviteModalStatus}
        withCloseButton={false}
        overlayProps={{opacity: 0.5}}
        transitionProps={{ transition: "slide-down", duration: 400 }}
        closeOnClickOutside={false}
        padding={30}
        centered
        size={"auto"}
      >
        <InviteAdminModal
          handleInvite={handleInvite}
          isLoading={isInviteLoading}
          close={() => setInviteModalStatus(false)}
        />
      </Modal>
    </Box>
  );
};
