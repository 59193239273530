import { Group, Stack, Text } from '@mantine/core';
import styled from '@emotion/styled';
import { User } from '../../../api/interfaces/user';
import dayjs from "dayjs";

type ComponentProps = {
	user: User;
};

export default function AgreementList({ user }: ComponentProps) {
	const acceptDate = dayjs(user?.agreementAcceptDate).format("MMMM D, YYYY h:mm A");
	return (
		<Stack p={'lg'} spacing={0}>
			<Group position='apart'>
				<Group position='left'>
					<Text size='xl' weight='bold'>
						Agreement
					</Text>
				</Group>
			</Group>
			<Root>
				<Group grow position={'apart'} align={'flex-start'}>
					<Group position={'center'}>
						{user.isAgreementAccepted ? (
							<Text size='md' weight='bold'>
								Agreement Accepted at {acceptDate}
							</Text>
						) : (
							<Text size='md' weight='bold'>
								Agreement not accepted
							</Text>
						)}
					</Group>
				</Group>
			</Root>
		</Stack>
	);
}

const Root = styled(Stack)`
	margin-top: 20px;
	background: #ffffff;
	border: 1px solid #e9ecef;
	border-radius: 8px;
	padding: 20px;
	overflow-y: hidden;
	gap: 20px;
`;
