import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import ContentLoader from "components/ContentLoader";
import useImageContentList from "features/ImageContentList/hooks/useImageContentList";
import ImageContentList from "features/ImageContentList";
import { Button, Group, Stack, Text } from "@mantine/core";
import {useMerchantNavigation} from "../../../hooks/useMerchantNavigation";

// Page
export default function ImageContents() {
  const { imageContents, isLoading } = useImageContentList();
  const { route } = useMerchantNavigation()
  
  return (
    <Stack p={"lg"} spacing={0}>
      <Group position="apart">
        <Group position="left">
          <Text size="xl" weight="bold">
            Image Contents
          </Text>
        </Group>
        <Group position="right">
          <Button
            component={Link}
            to={`${route}/image-contents/create`}
            leftIcon={<Icon icon={"fluent:add-12-filled"} height={18} />}
            radius={"sm"}
            color={"blue"}
            variant={"filled"}
          >
            Create
          </Button>
        </Group>
      </Group>

      {!isLoading ? (
        <ImageContentList imageContents={imageContents} />
      ) : (
        <ContentLoader />
      )}
    </Stack>
  );
}
