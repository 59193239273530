import styled from "@emotion/styled";
import { Box, Stack, Text } from "@mantine/core";
import { User } from "api/interfaces/user";
import { FC, useMemo } from "react";
import { UserItem } from "./UserItem";
import ContentLoader from "components/ContentLoader";

interface UsersListProps {
  users?: User[];
  isLoading: boolean;
}

export const UsersList: FC<UsersListProps> = ({ users = [], isLoading }) => {
  const usersList = useMemo(() => {
    if (users.length === 0)
      return (
        <Stack justify={"center"} sx={{ height: "100%" }}>
          <Text align="center" size="sm" color={"dimmed"}>
            Not found...
          </Text>
        </Stack>
      );
    return users.map((user, i) => <UserItem user={user} key={i} />);
  }, [users]);
  return (
    <Box>
      <Root>{!isLoading ? usersList : <ContentLoader />}</Root>
    </Box>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  background: #ffffff;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  overflow-y: hidden;
`;
