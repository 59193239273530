import { ActionIcon, Group, Stack, Text, Title } from "@mantine/core";
import { FC } from "react";
import styled from "@emotion/styled";
import { Icon } from "@iconify/react";
import dayjs from "dayjs";
import { AllowedCity } from "api/interfaces/allowedCity";

interface AllowedCityProps {
  city: AllowedCity;
  onRemove: (id: number) => void;
}

const formatDate = (date: string) => {
  return dayjs(date).format("DD-MM-YY");
};

export const AllowedCityItem: FC<AllowedCityProps> = ({ city, onRemove }) => {
  return (
    <Root grow>
      <Group position="left">
        <Stack spacing={0}>
          <Title order={5}>
            {city.name}
          </Title>
          <Text size="sm" color={"dimmed"}>
            created: {formatDate(city.createdAt)}
          </Text>
        </Stack>
      </Group>

      <Group position="right" spacing={"lg"}>
        <Group position="right" spacing={"xs"}>
          <ActionIcon
            variant="light"
            color={"red"}
            size={"lg"}
            radius={"md"}
            onClick={() => onRemove(city.id)}
            style={{ border: "1px solid #fbe7e7" }}
          >
            <Icon icon={"fluent:dismiss-12-filled"} />
          </ActionIcon>
        </Group>
      </Group>
    </Root>
  );
};

const Root = styled(Group)`
  flex-direction: row;

  background-color: #ffffff;
  padding: 8px 16px;
  user-select: none;

  &:not(:last-child) {
    border-bottom: 1px solid #e9ecef;
  }

  & {
    transition-property: background-color;
    transition-duration: 250ms;
    transition-timing-function: ease-in-out;
  }

  &:hover {
    background-color: rgba(44, 62, 80, 0.05);
  }
`;
