import merchantSelector, {MerchantSelectorState} from "../store";
import { useDispatch, useSelector as useReduxSelector} from "react-redux";
import {AppState} from "../../../store";
import {useCallback} from "react";
import {LocalStorage} from "../../../helpers/localStorage";


type ExtendState<S> = S & {
    select: (id?: UniqueId) => void
}

export default function useMerchantSelectorStore() {
    const dispatch = useDispatch()

    const Select = useCallback((id: string | null, clearStorage = true) => {
        if (id) LocalStorage.key("merchant-selector").set(id)
        else if (clearStorage) LocalStorage.key("merchant-selector").remove()
        
        dispatch(merchantSelector.actions.select(id))
    }, [dispatch])
    function useStore<Selected extends unknown>(selector?: (state: MerchantSelectorState) => Selected) {

        return useReduxSelector((state: AppState) => selector ? selector(state.merchantSelector): state.merchantSelector) as ExtendState<Selected>
    }

    return {
        useStore,
        Select
    }
}

