import { Button, Card, Group, Stack, Text } from "@mantine/core";
import Modal from "features/Modal";
import { ModalTrigger } from "features/Modal/constants";
import { Fragment, useMemo } from "react";
import ShareModal from "./ShareModal";
import { useModalStore } from "features/Modal/store/modal.store";
import { useParams } from "react-router-dom";
import { useMerchantsForGame } from "../hooks/useMerchantsForGame";
import ContentLoader from "components/ContentLoader";
import SharedItem from "./SharedItem";

export default function SharedMerchants() {
    const { id } = useParams<{ id: string }>();
    const openModal = useModalStore(state => state.setOpen);
    const { data, isLoading }: any = useMerchantsForGame(id!);

    const sharedMerchants = useMemo(() => {
        if (!data) return;
        return data.map((item: any) => item.Merchant);
    }, [id, data]);
    
    return (
        <Fragment>
            <Card radius={8} shadow="0px 1px 4px rgba(0, 0, 0, 0.05)">
                <Card.Section inheritPadding py={16} sx={{ borderBottom: "1px solid #f8f9fa" }}>
                    <Group position="apart">
                        <Text size={16} weight={600} lh={1.5}>
                            Shared with merchants
                        </Text>
                        <Group>
                            <Button type="submit" variant="filled" h={28} px={12} py={4} color="dark.8" radius={4} onClick={() => openModal({
                                title: 'Share Game',
                                trigger: ModalTrigger.SHARE_GAME,
                            })}>
                                <Text size={"14px"} weight={500} tt={'uppercase'} lh={1.5}>SHARE</Text>
                            </Button>
                        </Group>
                    </Group>
                </Card.Section>
                <Card.Section inheritPadding p={0} pb={2}>
                    <Stack spacing={0}>
                        {isLoading && <ContentLoader />}

                        {!isLoading && sharedMerchants?.map((item: any, index: number) => (
                            <SharedItem key={index} merchant={item} />
                        ))}

                        {!isLoading && sharedMerchants?.length === 0 && (
                            <Group p={24} align="center" position="center">
                                <Text size={"14px"} weight={400} color="gray.6" lh={1.5}>Merchant list is empty</Text>
                            </Group>
                        )}
                    </Stack>
                </Card.Section>
            </Card>

            <Modal triggers={[ModalTrigger.SHARE_GAME]}>
                <ShareModal />
            </Modal>

        </Fragment>
    )
}