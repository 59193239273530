import { Link, NavLink } from "react-router-dom";
import { Navbar as NavbarBase, ScrollArea, Stack, Text } from "@mantine/core";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { useAuth } from "../Auth/hooks/useAuth";
import { Icon } from "@iconify/react/dist/iconify";
import { navigation } from "./data/navigation";
import { useMerchantNavigation } from "../../hooks/useMerchantNavigation";
import { useMemo } from "react";

// Component
export default function Navbar() {
  const { logout } = useAuth();
  const {
    isSuperAdmin,
    isSuperMerchant,
    isPlatformAdmin,
    isPlatformMerchant,
    isMerchantSubscribed,
  } = useAuth();
  const { route, id: merchantId } = useMerchantNavigation();
  const isMerchantSelected = useMemo(() => {
    if (merchantId !== "undefined" && !!merchantId) return true;

    return false;
  }, [merchantId]);

  const contentList = navigation.filter((item) => item.section === "content");
  const accountList = navigation.filter((item) => item.section === "account");

  return (
    <NavbarRoot>
      <NavbarBase.Section>
        <BrandLogo to={"/startup"}>
          <span className={"name"}>QUBI</span>
          <span className={"type"}>CMS</span>
        </BrandLogo>
      </NavbarBase.Section>

      <NavbarBase.Section grow component={ScrollArea}>
        <Stack spacing={0}>
          {!!(
            (isPlatformAdmin && isMerchantSelected) ||
            (isPlatformMerchant && isMerchantSubscribed)
          ) && (
            <Stack p={"lg"} spacing={0}>
              <Text
                fw={"Bold"}
                tt={"uppercase"}
                c={"dark"}
                opacity={0.35}
                size={14}
                pl={4}
              >
                CONTENT
              </Text>
              {contentList.map((item) => (
                <NavItemLink to={item.to(merchantId)} key={item.title}>
                  {!!item.icon && (
                    <span className={"icon"}>
                      <Icon icon={item.icon} />
                    </span>
                  )}
                  <span className={"title"}>{item.title}</span>
                </NavItemLink>
              ))}
            </Stack>
          )}

          {isMerchantSelected && (
            <Stack p={"lg"} spacing={0}>
              <Text
                fw={"Bold"}
                tt={"uppercase"}
                c={"dark"}
                opacity={0.35}
                size={14}
                pl={4}
              >
                COMPANY
              </Text>

              <NavItemLink to={`${route}/profile`}>
                <span className={"icon"}>
                  <Icon icon={"mingcute:profile-line"} />
                </span>
                <span className={"title"}>Company Profile</span>
              </NavItemLink>

              {((isSuperMerchant && isMerchantSubscribed) ||
                isPlatformAdmin) && (
                <NavItemLink to={`${route}/admins`}>
                  <span className={"icon"}>
                    <Icon icon={"fluent-mdl2:workforce-management"} />
                  </span>
                  <span className={"title"}>Merchant Admins</span>
                </NavItemLink>
              )}
{/* 
              {((isSuperMerchant && isMerchantSubscribed) || isPlatformAdmin) && (
                <NavItemLink to={`${route}/verification`}>
                  <span className={"icon"}>
                    <Icon icon={"fluent:checkmark-starburst-24-regular"} />
                  </span>
                  <span className={"title"}>Verifications</span>
                </NavItemLink>
              )} */}

              {(isSuperMerchant || isPlatformAdmin) && (
                <NavItemLink to={`${route}/subscribe`}>
                  <span className={"icon"}>
                    <Icon icon={"fluent:payment-28-regular"} />
                  </span>
                  <span className={"title"}>Subscription</span>
                </NavItemLink>
              )}

              {(isSuperMerchant || isPlatformAdmin) && (
                <NavItemLink to={`${route}/transfer-history`}>
                  <span className={"icon"}>
                    <Icon icon={"fluent:money-calculator-24-regular"} />
                  </span>
                  <span className={"title"}>Transfer History</span>
                </NavItemLink>
              )}

              <NavItemLink to={`${route}/user-agreement`}>
                <span className={"icon"}>
                  <Icon icon={"fluent:document-table-checkmark-24-regular"} />
                </span>
                <span className={"title"}>User Agreement</span>
              </NavItemLink>

              <NavItemLink to={`${route}/games`}>
                <span className={"icon"}>
                  <Icon icon={"fluent:textbox-settings-24-regular"} />
                </span>
                <span className={"title"}>Games</span>
              </NavItemLink>

              <NavItemLink to={`${route}/analytics`}>
                <span className={"icon"}>
                  <Icon icon={"fluent:data-trending-24-regular"} />
                </span>
                <span className={"title"}>Analytics</span>
              </NavItemLink>
            </Stack>
          )}

          {isPlatformAdmin && (
            <Stack p={"lg"} spacing={0}>
              <Text
                fw={"Bold"}
                tt={"uppercase"}
                c={"dark"}
                opacity={0.35}
                size={14}
                pl={4}
              >
                PLATFORM
              </Text>
              {!isSuperAdmin && (
                <NavItemLink to={"/platform/advertisements"}>
                  <span className={"icon"}>
                    <Icon icon={"mdi:advertisements"} />
                  </span>
                  <span className={"title"}>Advertisements</span>
                </NavItemLink>
              )}

              <NavItemLink to={"/platform/app-access"}>
                <span className={"icon"}>
                  <Icon icon={"fluent:phone-lock-24-regular"} />
                </span>
                <span className={"title"}>App Access</span>
              </NavItemLink>

              <NavItemLink to={"/platform/referral-program"}>
                <span className={"icon"}>
                  <Icon icon={"mdi:invite"} />
                </span>
                <span className={"title"}>Referral Program</span>
              </NavItemLink>

              <NavItemLink to={`/platform/users`}>
                <span className={"icon"}>
                  <Icon icon={"fluent:person-48-regular"} />
                </span>
                <span className={"title"}>Users</span>
              </NavItemLink>

              {isSuperAdmin && (
                <NavItemLink to={"/platform/collectable-cards"}>
                  <span className={"icon"}>
                    <Icon
                      icon={"streamline:cards"}
                    />
                  </span>
                  <span className={"title"}>Collectible Cards</span>
                </NavItemLink>
              )}

              {isSuperAdmin && (
                <NavItemLink to={`/platform/games`}>
                  <span className={"icon"}>
                    <Icon icon={"ion:game-controller-outline"} />
                  </span>
                  <span className={"title"}>Games</span>
                </NavItemLink>
              )}

              {isSuperAdmin && (
                <NavItemLink to={"/platform/admins"}>
                  <span className={"icon"}>
                    <Icon icon={"fluent:person-lock-20-regular"} />
                  </span>
                  <span className={"title"}>Platform Admins</span>
                </NavItemLink>
              )}

              <NavItemLink to={`/platform/analytics`}>
                <span className={"icon"}>
                  <Icon icon={"fluent:calendar-data-bar-28-regular"} />
                </span>
                <span className={"title"}>Platform Analytics</span>
              </NavItemLink>

              {isSuperAdmin && (
                <NavItemLink to={"/platform/merchant-targets"}>
                  <span className={"icon"}>
                    <Icon
                      icon={"fluent:target-24-regular"}
                    />
                  </span>
                  <span className={"title"}>Merchant Targeting</span>
                </NavItemLink>
              )}

              {isSuperAdmin && (
                <NavItemLink to={"/platform/settings"}>
                  <span className={"icon"}>
                    <Icon icon={"ci:settings-filled"} />
                  </span>
                  <span className={"title"}>Settings</span>
                </NavItemLink>
              )}
            </Stack>
          )}
        </Stack>
      </NavbarBase.Section>

      <NavbarBase.Section p={"lg"} style={{ borderTop: "1px solid #e9ecef" }}>
        {accountList.map((item) => (
          <NavItemLink to={item.to()} key={item.title}>
            {!!item.icon && (
              <span className={"icon"}>
                <Icon icon={item.icon} />
              </span>
            )}
            <span className={"title"}>{item.title}</span>
          </NavItemLink>
        ))}

        <NavItemButton onClick={logout}>
          <span className={"icon"}>
            <Icon icon={"fluent:door-arrow-left-24-regular"} />
          </span>
          <span className={"title"}>Logout</span>
        </NavItemButton>
      </NavbarBase.Section>
    </NavbarRoot>
  );
}

// CSS Helper
const NavItemCSS = css`
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 14px;
  color: rgb(73, 80, 87);
  padding: 10px 12px;
  border-radius: 4px;
  font-weight: 500;
  width: 100%;
  cursor: pointer;

  & {
    transition-property: background-color, color;
    transition-duration: 250ms;
    transition-timing-function: linear;
  }

  &:hover {
    background-color: rgb(248, 249, 250);
    color: rgb(0, 0, 0);
  }

  &.active,
  &:hover.active {
    background-color: rgb(12, 166, 120, 0.1);
    color: rgb(12, 166, 120);
  }

  & .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    margin-right: 12px;
  }

  &:not(:last-child) {
    margin-bottom: 2px;
  }
`;

// Styling
const NavbarRoot = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  min-height: 100vh;
  height: 100vh;
  border-right: 1px solid #e9ecef;
  width: 300px;
  overflow-y: auto;
  background-color: #fff;
`;

const NavItemLink = styled(NavLink)`
  ${NavItemCSS}
`;

const NavItemButton = styled.button`
  ${NavItemCSS};
`;

const BrandLogo = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  height: 60px;
  border-bottom: 1px solid #e9ecef;

  & > * {
    font-family: "Fredoka One", serif;
    font-size: 28px;
  }

  & .name {
    color: rgb(18, 184, 134);
  }

  & .type {
    color: #2c3e50;
  }
`;
