import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export type SharingData = {
    game_id: number;
    merchant_ids: number[];
    collectible_card_ids: number[];
}

interface ISharingGameStore {
    sharedGames: SharingData[];

    addGame: (game_id: SharingData['game_id']) => void;
    removeGame: (data: SharingData['game_id']) => void;
    sync: (game_ids: SharingData['game_id'][]) => void;

    addMerchants: (game_id: SharingData['game_id'], merchant_ids: SharingData['merchant_ids']) => void;
    removeMerchants: (game_id: SharingData['game_id'], merchant_ids: SharingData['merchant_ids']) => void;

    addCollectibleCards: (game_id: SharingData['game_id'], collectible_card_ids: SharingData['collectible_card_ids']) => void;
    removeCollectibleCards: (game_id: SharingData['game_id'], collectible_card_ids: SharingData['collectible_card_ids']) => void;
}

const InitialState: Pick<ISharingGameStore, 'sharedGames'> = {
    sharedGames: localStorage.getItem('shared_games') ? JSON.parse(localStorage.getItem('shared_games')!) : [],
};

export const useSharingGameStore = create<ISharingGameStore>()(
    devtools(
        (set, get) => ({
            ...InitialState,

            sync: (game_ids) => {
                const local_saved = localStorage.getItem('shared_games');
                if (!local_saved) {
                    game_ids.forEach((game_id) => get().addGame(game_id));
                    return;
                }

                let local_games = JSON.parse(local_saved) as SharingData[];

                for (const game_id of local_games) {
                    if (game_ids.includes(game_id.game_id)) continue;
                    get().removeGame(game_id.game_id);
                }

                for (const game_id of game_ids) {
                    if (local_games.some(({ game_id: id }) => id === game_id)) continue;
                    get().addGame(game_id);
                }
            },

            addGame: (game_id) => {
                const shared_games = get().sharedGames;

                if (shared_games.some(({ game_id: id }) => id === game_id)) return;

                const new_shared_games = [...shared_games, { game_id, merchant_ids: [], collectible_card_ids: [] }] as SharingData[];
                localStorage.setItem('shared_games', JSON.stringify(new_shared_games));
                set({ sharedGames: new_shared_games });
            },

            removeGame: (game_id) => {
                const shared_games = get().sharedGames;

                if (!shared_games.some(({ game_id: id }) => id === game_id)) return;

                const new_shared_games = shared_games.filter(({ game_id: id }) => id !== game_id);
                localStorage.setItem('shared_games', JSON.stringify(new_shared_games));
                set({ sharedGames: new_shared_games });
            },

            addMerchants: (game_id, merchant_ids) => {
                const shared_games = get().sharedGames;

                if (!shared_games.some((item) => item.game_id === game_id)) return;

                const updated_shared_games = shared_games.map((item) => {
                    if (item.game_id !== game_id) return item;
                    
                    const new_merchant_ids = merchant_ids.filter((id) => !item.merchant_ids.includes(id));
                    return { ...item, merchant_ids: [...item.merchant_ids, ...new_merchant_ids] };
                });

                localStorage.setItem('shared_games', JSON.stringify(updated_shared_games));
                set({ sharedGames: updated_shared_games });
            },

            removeMerchants: (game_id, merchant_ids) => {
                const shared_games = get().sharedGames;

                if (!shared_games.some((item) => item.game_id === game_id)) return;

                const updated_shared_games = shared_games.map((item) => {
                    if (item.game_id !== game_id) return item;

                    const new_merchant_ids = item.merchant_ids.filter((id) => !merchant_ids.includes(id));
                    return { ...item, merchant_ids: new_merchant_ids };
                });

                localStorage.setItem('shared_games', JSON.stringify(updated_shared_games));
                set({ sharedGames: updated_shared_games });
            },

            addCollectibleCards: (game_id, collectible_card_ids) => {
                const shared_games = get().sharedGames;

                if (!shared_games.some((item) => item.game_id === game_id)) return;

                const updated_shared_games = shared_games.map((item) => {
                    if (item.game_id !== game_id) return item;

                    const new_collectible_card_ids = collectible_card_ids.filter((id) => !item.collectible_card_ids.includes(id));
                    return { ...item, collectible_card_ids: [...item.collectible_card_ids, ...new_collectible_card_ids] };
                });

                localStorage.setItem('shared_games', JSON.stringify(updated_shared_games));
                set({ sharedGames: updated_shared_games });
            },

            removeCollectibleCards: (game_id, collectible_card_ids) => {
                const shared_games = get().sharedGames;

                if (!shared_games.some((item) => item.game_id === game_id)) return;

                const updated_shared_games = shared_games.map((item) => {
                    if (item.game_id !== game_id) return item;

                    const new_collectible_card_ids = item.collectible_card_ids.filter((id) => !collectible_card_ids.includes(id));
                    return { ...item, collectible_card_ids: new_collectible_card_ids };
                });

                localStorage.setItem('shared_games', JSON.stringify(updated_shared_games));
                set({ sharedGames: updated_shared_games });
            },
        }),
        { name: 'Shared Games' },
    ),
);
