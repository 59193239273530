import styled from "@emotion/styled";
import LocationEventItem from "./components/LocationEventItem";
import {LocationEvent} from "../../api/interfaces/locationEvents";

// Props
type ComponentProps = {
  locationEvents: LocationEvent[]
}

// Component
export default function LocationEventsList({ locationEvents }: ComponentProps) {
  return (
    <Root>
      {locationEvents.map(locationEvent => (
        <LocationEventItem key={locationEvent.id} locationEvent={locationEvent} />
      ))}
    </Root>
  )
}

// Styling
const Root = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  background: #FFFFFF;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  overflow-y: hidden;
`


