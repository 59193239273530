import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import TextContentList from "features/TextContentList";
import useTextContentList from "features/TextContentList/hooks/useTextContentList";
import ContentLoader from "components/ContentLoader";
import { Button, Group, Stack, Text } from "@mantine/core";
import {useMerchantNavigation} from "../../../hooks/useMerchantNavigation";

// Page
export default function TextContents() {
  const { textContents, isLoading } = useTextContentList();
  const { route } = useMerchantNavigation()
  
  return (
    <Stack p={"lg"} spacing={0}>
      <Group position="apart">
        <Group position="left">
          <Text size="xl" weight="bold">
            Text Contents
          </Text>
        </Group>
        <Group position="right">
          <Button
            component={Link}
            to={`${route}/text-contents/create`}
            leftIcon={<Icon icon={"fluent:add-12-filled"} height={18} />}
            radius={"sm"}
            color={"blue"}
            variant={"filled"}
          >
            Create
          </Button>
        </Group>
      </Group>

      {!isLoading ? (
        <TextContentList textContents={textContents} />
      ) : (
        <ContentLoader />
      )}
    </Stack>
  );
}
