import { api } from "index";
import { useMutation, useQueryClient } from "react-query";
import { useCallback } from "react";
import { ICouponOrigin } from "api/interfaces/coupon";
import useMerchantSelectorStore from "features/MerchantSelector/hooks/useMerchantSelectorStore";
import { ErrorResponse } from "api/interfaces/api";
import { showNotification } from "@mantine/notifications";

export const useCouponUpdate = (id?: string | number) => {
  const queryClient = useQueryClient();

  const merchantSelector = useMerchantSelectorStore();

  const selectedId = merchantSelector.useStore((state) => state.selected);

  const { mutateAsync: update, isLoading, isError } = useMutation({
    mutationFn: async (input: ICouponOrigin) => id ? await api.coupons.update(selectedId, id, input) : () => { },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries(["COUPONS_LIST", selectedId]),
        queryClient.invalidateQueries(["COUPONS_LIST"]),
        queryClient.invalidateQueries(["COUPON"]),
      ]);
      showNotification({
        autoClose: 3000,
        title: "Success!",
        message: "Coupon successfully updated!",
        color: "green",
      });
    },
    onError: (e: ErrorResponse) => {
      showNotification({
        message: e.error ?? "Something went wrong.",
        title: "Error!",
        color: "red",
        autoClose: 5000,
      });
    }

  });

  const handleUpdate = useCallback(
    async (input: ICouponOrigin) => {
      if (!id) return;
      await update(input);
    },
    [id, update]
  );

  return {
    handleUpdate,
    isLoading,
    isError,
  };
};
