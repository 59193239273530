import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { yupResolver } from "@hookform/resolvers/yup";
import { Icon } from "@iconify/react";
import {
  ActionIcon,
  Button,
  Group,
  NumberInput,
  Select,
  Stack,
  Text,
} from "@mantine/core";
import useContentList from "features/ContentList/hooks/useContentList";
import { useUsersList } from "features/Users/hooks/useUsersList";
import { FC, useEffect, useMemo } from "react";
import { Controller, useForm, useFormState, useWatch } from "react-hook-form";
import * as yup from "yup";
import { TRandomReward } from "../types";

const schema = yup.object({
    rewardType: yup.string().required(),
    rewardName: yup.string().required(),
    contentId: yup.number().required(),
    rewardsQuantity: yup.number().required(),
    extraChance: yup.number().required(),
    userId: yup.number()
});

const defaultValues = {
    rewardType: undefined,
    rewardName: "",
    contentId: undefined,
    rewardsQuantity: undefined,
    extraChance: undefined,
    userId: undefined
}

type FormState = typeof defaultValues;

interface CreateRewardModalProps {
  handleCreate: (data: Omit<TRandomReward, "merchantId">) => void;
  close: () => void;
}

const rewardTypes = [
    { label: "User", value: "user" },
    { label: "All users", value: "all-users" },
]

export const CreateRewardModal: FC<CreateRewardModalProps> = ({ close, handleCreate}) => {
    const { control, ...form } = useForm<FormState>({
        resolver: yupResolver(schema),
        mode: "onChange",
        defaultValues
    });

    const { contents } = useContentList();
    const { users, setSearch } = useUsersList();
    
    const contentsData = useMemo(() => {
        return contents.map((content) => ({
            value: String(content.id),
            label: content.name
        }))
    }, [contents]);

    const usersData = useMemo(() => {
        return users.map((user) => ({
            value: String(user.id),
            label: user.email
        }))
    }, [users]);

    const handleSubmit = async (data: any) => {
        handleCreate(data);
        close();
    };
  
    const { isValid: isFormValid } = useFormState({ control });
    const rewardType = useWatch({ control, name: "rewardType" });
    const contentId = useWatch({ control, name: "contentId" });
    
    useEffect(() => {
        form.setValue("rewardName", contents.find(item => item.id === contentId)?.name!);
    }, [contentId]);

    return (
        <form onSubmit={form.handleSubmit(handleSubmit)}>
            <Stack>
                <Group position={"apart"} align={"center"}>
                    <Group position={"left"}>
                        <Text size={"md"} weight={"bold"}>
                        Create Reward
                        </Text>
                    </Group>

                    <Group position={"right"}>
                        <ActionIcon
                            onClick={() => close()}
                            variant={"transparent"}
                        >
                            <Icon color={"red"} icon="ep:close" />
                        </ActionIcon>
                    </Group>
                </Group>

                <Stack>
                    <Controller
                        name="rewardType"
                        control={control}
                        render={({ field }) => (
                            <SelectField
                                label="Reward Type"
                                placeholder="Pick one"
                                searchable
                                nothingFound="No options"
                                data={rewardTypes}
                                classNames={{ input: "input-field" }}
                                withAsterisk
                                {...field}
                            />
                        )}
                    />
                    
                    <Stack spacing={0}>
                        <Controller
                            name="contentId"
                            control={control}
                            render={({ field }) => (
                                <SelectField
                                    label={"Select Reward"}
                                    placeholder="Pick one"
                                    searchable
                                    nothingFound="No options"
                                    data={contentsData}
                                    classNames={{ input: "input-field" }}
                                    withAsterisk
                                    {...field}
                                    value={String(field.value)}
                                    onChange={(value) => field.onChange(parseInt(value!))}
                                />
                            )}
                        />
                    </Stack>

                    {(contentId && rewardType) && (
                        <>
                            {rewardType === "user" && (
                                <Controller
                                    name="userId"
                                    control={control}
                                    render={({ field }) => (
                                        <SelectField
                                            label={"Select User"}
                                            placeholder="Pick user"
                                            searchable
                                            nothingFound="No options"
                                            data={usersData}
                                            classNames={{ input: "input-field" }}
                                            {...field}
                                            value={String(field.value)}
                                            onChange={(value) => field.onChange(parseInt(value!))}
                                            onSearchChange={(search) => setSearch(search)}
                                        />
                                    )}
                                />
                            ) }

                            <Controller
                                name="rewardsQuantity"
                                control={control}
                                render={({ field }) => (
                                    <NumberInput
                                        label="Number of rewards"
                                        placeholder="Enter number of rewards"
                                        classNames={{ input: "input-field" }}
                                        withAsterisk
                                        {...field}
                                        min={0}
                                    />
                                )}
                            />

                            <Controller
                                name="extraChance"
                                control={control}
                                render={({ field }) => (
                                    <NumberInput
                                        label="Extra reward chance"
                                        placeholder="Enter chance of extra reward"
                                        classNames={{ input: "input-field" }}
                                        withAsterisk
                                        {...field}
                                        precision={3}
                                        min={0}
                                        step={0.001}
                                        max={1}
                                    />
                                )}
                            />
                        </>   
                    )}
                    
                    <Group grow align={"center"}>
                        <Button onClick={form.handleSubmit(handleSubmit)} disabled={!isFormValid}>
                            Create
                        </Button>
                    </Group>
                </Stack>
            </Stack>
        </form>
    );
};

const InputCSS = css`
  display: flex;
  font-size: 14px;
  font-weight: 400;
  background: #fcfdfe;
  color: #9fa2b4;
  line-height: 1.5;
  min-height: 42px;
  outline: none;
  padding: 10px 16px;
  width: 100%;
  transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out,
    color 0.2s ease-in-out;

  &:hover,
  &:focus {
    color: #252733;
    border-color: #0095e5;
  }

  &::placeholder {
    color: rgb(75, 80, 109, 0.5);
  }
`;

//const FieldCSS = css`
//  flex: 1;

//  & .input-field {
//    ${InputCSS};
//  }
//`;
//// Styling

//const TextField = styled<any>(TextInput)`
//  ${FieldCSS};

//  & .input-field {
//    border: 1px solid ${({ error }) => (error ? "#f03e3e" : "#f0f1f7")};
//  }
//`;

const SelectField = styled(Select)`
  flex: 1;

  & .input-field {
    ${InputCSS};;
  }
`;