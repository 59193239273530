import useMerchantSelectorStore from "features/MerchantSelector/hooks/useMerchantSelectorStore";
import { api } from "index";
import { useQuery } from "react-query";

export const useLocationEventAnalytic = (id?: number) => {
  const merchantSelector = useMerchantSelectorStore();
  const selected = merchantSelector.useStore((state) => state.selected);

  const { data, ...params } = useQuery(
    ["LOCATION_EVENT_ANALYTICS", Number(selected), id],
    () => api.locationEvents.getLocationEventAnalytics(id, Number(selected))
  );

  return { ...params, analytics: data };
};
