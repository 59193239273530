import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useParams } from "react-router-dom";
import { Controller, useController, useFormContext } from "react-hook-form";
import { Dropzone, MIME_TYPES } from "@mantine/dropzone";
import { Center, Input, Group, Image, Paper, Stack, Text, TextInput, Select } from "@mantine/core";
import useLocationList from "../LocationList/hooks/useLocationList";
import { useMemo } from "react";
import { DatePickerInput } from "@mantine/dates";
import dayjs from "dayjs";
import LocationSelect from "./components/LocationSelect";
import { AdvertisementBtnTypes } from "../../api/interfaces/advertisements";

// Component
export default function AdvertisementsEdit() {
	const { control } = useFormContext();
	const { id } = useParams();
	const { locations } = useLocationList();
	const { field } = useController({ name: "buttonActionType", control });
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const btnTypes = ["Location", "Link"];

	const currentDate = new Date();
	const minEndDate = dayjs().add(1, "day").toDate();

	const locationList = useMemo(() => {
		return locations.map((location) => ({
			value: String(location.id),
			label: location.name,
		}));
	}, [locations]);

	const btnTypesList = useMemo(() => {
		return btnTypes.map((type) => ({
			value: type,
			label: type,
		}));
	}, [btnTypes]);

	return (
		<Root radius={"md"} withBorder p={"lg"}>
			<Stack spacing={"lg"}>
				<Group grow spacing={"lg"} align={"flex-start"}>
					<Stack spacing={"lg"}>
						{/* Title */}
						<Group grow>
							<Controller
								name="title"
								control={control}
								render={({ field, fieldState }) => (
									<TextField
										label="Title"
										placeholder="Title"
										variant="filled"
										error={fieldState.error?.message}
										required
										classNames={{ input: "input-field" }}
										{...field}
									/>
								)}
							/>
						</Group>

						{/* Subtitle */}
						<Group grow>
							<Controller
								name="subtitle"
								control={control}
								render={({ field, fieldState }) => (
									<TextField
										label="Subtitle"
										placeholder="Subtitle"
										variant="filled"
										error={fieldState.error?.message}
										required
										classNames={{ input: "input-field" }}
										{...field}
									/>
								)}
							/>
						</Group>
					</Stack>
					<Stack spacing={"lg"}>
						{/* Image Preview */}
						{!!id && (
							<Group grow>
								<Controller
									name="url"
									control={control}
									render={({ field }) => (
										<Input.Wrapper label={"Preview"} style={{ flex: 1 }}>
											<Image
												styles={{ image: { objectFit: "contain" } }}
												fit={"contain"}
												height={300}
												src={field.value}
												alt="preview picture"
												withPlaceholder
											/>
										</Input.Wrapper>
									)}
								/>
							</Group>
						)}

						{/* Image Form */}
						<Group grow>
							<Controller
								name="image"
								control={control}
								render={({ field, fieldState, formState }) => (
									<Input.Wrapper
										label="Upload image"
										style={{ flex: 1 }}
										error={fieldState.error?.message}
									>
										<Dropzone
											loading={formState.isSubmitting}
											maxSize={3 * 1024 ** 2}
											accept={[MIME_TYPES.png, MIME_TYPES.jpeg]}
											multiple={false}
											onDrop={(files) => field.onChange(files[0])}
											p={0}
										>
											<Center
												style={{
													pointerEvents: "none",
													padding: Boolean(field.value) ? 0 : 16,
												}}
											>
												{Boolean(field.value) && (
													<Image
														fit={"contain"}
														styles={{ image: { objectFit: "contain" } }}
														radius={"sm"}
														src={URL.createObjectURL(field.value)}
														alt="preview picture"
													/>
												)}

												{!Boolean(field.value) && (
													<Stack spacing={0}>
														<Text size="lg" inline>
															Drag image here or click to select files
														</Text>
														<Text size="sm" color="dimmed" inline mt={8}>
															Attach as many files as you like, each file should not
															exceed 5mb
														</Text>
													</Stack>
												)}
											</Center>
										</Dropzone>
									</Input.Wrapper>
								)}
							/>
						</Group>
					</Stack>
				</Group>

				<LocationSelect />

				<Group grow spacing={"lg"} align={"flex-start"}>
					{/* Button name */}
					<Group grow spacing={"lg"} align={"flex-start"}>
						<Group grow>
							<Controller
								name="buttonName"
								control={control}
								render={({ field, fieldState }) => (
									<TextField
										label="Button name"
										placeholder="Button name"
										variant="filled"
										error={fieldState.error?.message}
										required
										classNames={{ input: "input-field" }}
										{...field}
									/>
								)}
							/>
						</Group>

						{/* Button type */}
						<Group grow>
							<Controller
								name="buttonActionType"
								control={control}
								render={({ field }) => (
									<SelectField
										label="Button type"
										placeholder="Select button type"
										required
										variant="filled"
										searchable
										data={btnTypesList}
										classNames={{ input: "input-field" }}
										{...field}
										value={field.value}
										onChange={(value) => field.onChange(value)}
									/>
								)}
							/>
						</Group>
					</Group>
				</Group>

				<Stack spacing={"lg"}>
					{/* Location */}
					{field.value === AdvertisementBtnTypes.LOCATION && (
						<Group grow>
							<Controller
								name="buttonActionData"
								control={control}
								render={({ field }) => (
									<SelectField
										label="Location"
										placeholder="Select location"
										variant="filled"
										searchable
										required
										data={locationList}
										classNames={{ input: "input-field" }}
										{...field}
										value={String(field.value)}
										onChange={(value) => field.onChange(Number(value))}
									/>
								)}
							/>
						</Group>
					)}

					{/* Link */}
					{field.value === AdvertisementBtnTypes.LINK && (
						<Group grow>
							<Controller
								name="buttonActionData"
								control={control}
								render={({ field, fieldState }) => (
									<TextField
										label="Link"
										placeholder="Enter link"
										variant="filled"
										required
										error={fieldState.error?.message}
										classNames={{ input: "input-field" }}
										{...field}
									/>
								)}
							/>
						</Group>
					)}
				</Stack>
				<Group grow spacing={"lg"} align={"flex-start"}>
					{/* Start Date */}
					<Stack>
						<Controller
							name="startDate"
							control={control}
							render={({ field, fieldState }) => (
								<DatePickField
									label="Start date"
									placeholder="Pick date"
									variant="filled"
									// disableOutsideEvents
									minDate={currentDate}
									required
									classNames={{ input: "input-field" }}
									error={fieldState.error?.message}
									value={field.value ? new Date(field.value) : undefined}
									onChange={field.onChange}
								/>
							)}
						/>
					</Stack>

					{/* End Date */}
					<Stack>
						<Controller
							name="endDate"
							control={control}
							render={({ field, fieldState }) => (
								<DatePickField
									label="End date"
									placeholder="Pick date"
									variant="filled"
									minDate={minEndDate}
									required
									classNames={{ input: "input-field" }}
									error={fieldState.error?.message}
									value={field.value ? new Date(field.value) : undefined}
									onChange={field.onChange}
								/>
							)}
						/>
					</Stack>
				</Group>
			</Stack>
		</Root>
	);
}

// CSS Helper
const InputCSS = css`
	display: flex;
	font-size: 14px;
	font-weight: 400;
	background: #fcfdfe;
	color: #9fa2b4;
	line-height: 1.5;
	min-height: 42px;
	outline: none;
	padding: 10px 16px;
	width: 100%;
	transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out, color 0.2s ease-in-out;

	&:hover,
	&:focus {
		color: #252733;
		border-color: #0095e5;
	}

	&::placeholder {
		color: rgb(75, 80, 109, 0.5);
	}
`;

const FieldCSS = css`
	flex: 1;

	& .input-field {
		${InputCSS};
	}
`;
// Styling
const Root = styled(Paper)<any>`
	margin-top: 20px;
	overflow: hidden;
`;

const TextField = styled<any>(TextInput)`
	${FieldCSS};
	position: relative;

	.mantine-TextInput-error {
		position: absolute;
		left: 0;
		bottom: -24px;
	}

	& .input-field {
		border: 1px solid ${({ error }) => (error ? "#f03e3e" : "#f0f1f7")};
	}
`;

const SelectField = styled(Select)`
	${FieldCSS};

	& .input-field {
		border: 1px solid ${({ error }) => (error ? "#f03e3e" : "#f0f1f7")};
	}
`;

const DatePickField = styled(DatePickerInput as any)`
	${FieldCSS};
`;
