import { UpdateUserForm } from "api/interfaces/user";
import { api } from "index";
import { useMutation, useQueryClient } from "react-query";
import { useCallback } from "react";
import { useParams } from "react-router-dom";
import { showNotification } from "@mantine/notifications";
import { ErrorResponse } from "api/interfaces/api";

export const useUserUpdate = () => {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const { mutateAsync: update, ...rest } = useMutation((data: UpdateUserForm) =>
    api.users.updateUser(data, Number(id))
  );

  const handleUpdate = useCallback(
    async (data: UpdateUserForm) => {
      try {
        await update(data);

        await Promise.all([
          queryClient.invalidateQueries(["USER", Number(id)]),
        ]);
        showNotification({
          autoClose: 3000,
          title: "Success!",
          message: "User successfully updated!",
          color: "green",
        });
      } catch (e: any) {
        const error: ErrorResponse = e.response.data;
        showNotification({
          message: error.error ?? "Something went wrong.",
          title: "Error!",
          color: "red",
          autoClose: 5000,
        });
      }
    },
    [queryClient, update]
  );

  return { ...rest, handleUpdate };
};
