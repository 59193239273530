import {api} from "index";
import {useMutation, useQueryClient} from "react-query";
import {useCallback} from "react";
import {useMerchantDetails} from "../../MerchantProfile/hooks/useMerchantDetails";
import {useAuth} from "../../Auth/hooks/useAuth";
import {wait} from "../../../helpers/wait";

export const useSubscriptionCancel = () => {
	const queryClient = useQueryClient();
	const {merchant} = useMerchantDetails()
	const { user } = useAuth()
	
	const {mutateAsync, ...options} = useMutation(() => api.merchants.cancelSubscribe(merchant?.id));
	
	const cancelSubscribe = useCallback(async () => {
		try {
			const res = await mutateAsync();
			if (!res?.isSuccess) return
			
			await wait(500)
			
			const queries = [
				["MERCHANT", merchant?.id],
			]
			
			if (user?.Merchant?.id === merchant?.id) {
				queries.push(["ACCOUNT"])
			}
			
			await Promise.all(queries.map(keys => queryClient.invalidateQueries(keys)))
		} catch (e: any) {
			console.debug(e);
		}
	}, [user, merchant, mutateAsync]);
	
	return {...options, cancelSubscribe};
};
