import { Icon } from "@iconify/react";
import { Button, Group, Modal, Paper, Stack, Text } from "@mantine/core";
import { AppInvite } from "api/interfaces/appInvite";
import { FC, useState } from "react";
import { InviteItem } from "./InviteItem";
import { useAppInviteDelete } from "../hooks/useAppInviteDelete";
import { CreateInviteModal } from "./CreateInviteModal";
import { useAppInviteCreate } from "../hooks/useAppInviteCreate";

interface InvitesListProps {
  invites: AppInvite[];
}

export const InvitesList: FC<InvitesListProps> = ({ invites }) => {
  const { handleDelete } = useAppInviteDelete();
  const { handleCreate, isLoading } = useAppInviteCreate();
  const [createModalStatus, setCreateModalStatus] = useState<boolean>(false);
  return (
    <Paper withBorder radius={"md"} style={{ overflow: "hidden" }}>
      <Stack spacing={0}>
        {/* Header */}
        <Group grow p={"lg"} style={{ borderBottom: "1px solid #e9ecef" }}>
          <Group position="left">
            <Text size="md" weight="bold">
              Invites List
            </Text>
          </Group>
          <Group position="right">
            <Button
              onClick={() => setCreateModalStatus(true)}
              leftIcon={<Icon icon={"fluent:add-12-filled"} height={18} />}
              radius={"sm"}
              color={"dark"}
              variant={"filled"}
            >
              Create Invite
            </Button>
          </Group>
        </Group>
        <Stack spacing={0}>
          {!invites.length && (
            <Group grow p={"lg"}>
              <Text size="sm" color={"dimmed"}>
                Not found...
              </Text>
            </Group>
          )}
          {invites.map((invite) => (
            <InviteItem
              invite={invite}
              key={invite.id}
              onRemove={handleDelete}
            />
          ))}
        </Stack>
      </Stack>
      <Modal
        opened={createModalStatus}
        onClose={() => setCreateModalStatus(false)}
        withCloseButton={false}
        overlayProps={{opacity: 0.5}}
        transitionProps={{ transition: "slide-down", duration: 400 }}
        closeOnClickOutside={false}
        padding={30}
        centered
        size={"auto"}
      >
        <CreateInviteModal
          closeModal={setCreateModalStatus}
          handleCreate={handleCreate}
          isLoading={isLoading}
        />
      </Modal>
    </Paper>
  );
};
