import { useQuery } from "react-query";
import { api } from "index";

export function useEventTypes() {
    const {
        data: eventTypes,
        isError,
        isLoading,
        refetch,
    } = useQuery(
        ["EVENT_TYPES"],
        () => api.events.getEventTypes(),
        {
            refetchOnWindowFocus: false,
            refetchIntervalInBackground: false,
            retry: 0,
        }
    );

    return { eventTypes, isError, isLoading, refetch };
}
