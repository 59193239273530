import styled from "@emotion/styled";
import { Icon } from "@iconify/react";
import { ActionIcon, Group, NumberInput, Stack, Title } from "@mantine/core";
import { useController } from "react-hook-form";
import { GameSettings } from "api/interfaces/locationEvents";
import { useShareLocationEvents } from "features/MerchantGameDetails/hooks/useSharedLocationEvents";

// Props
type ComponentProps = {
	content: {
		gameId: number;
		name: string;
		maxAttempts: number;
		frequencyOccurrence: number;
	};
	onRemove: () => void;
	eventId: string | undefined;
	merchantId: string;
};

// Component
export default function GameItem({
	content,
	onRemove,
	eventId,
	merchantId,
}: ComponentProps) {
	const { field: gameSettings } = useController({ name: "gameSettings" });
	const { removeEvent, removeEventLoading } = useShareLocationEvents(
		content.gameId,
		merchantId
	);

	const handleRemove = async () => {
		onRemove();
		if (eventId) {
			try {
				await removeEvent({
					gameId: content.gameId,
					merchantId: merchantId,
					locationEventId: eventId,
				});
			} catch {}
		}
	};

	const handleChange = (type: string, value: number) => {
		let field: GameSettings = gameSettings.value.filter(
			(item: GameSettings) => item.gameId === content.gameId
		)[0];
		const filteredData = gameSettings.value.filter(
			(item: GameSettings) => item.gameId !== content.gameId
		);

		if (type === "maxAttempts") {
			field.maxAttempts = value;
		} else {
			field.frequencyOccurrence = value;
		}
		// gameSettings.value = [ ...filteredData, field];

		gameSettings.onChange([...filteredData, field]);
	};

	return (
		<Root position="apart" spacing={48}>
			<Group w="100%" position="apart" sx={{ flex: 1 }}>
				<Stack spacing={0}>
					<Title order={5} style={{ textTransform: "capitalize" }}>
						{content.name}
					</Title>
				</Stack>
				<Group spacing={16}>
					<NumberInput
						defaultValue={content.frequencyOccurrence}
						key={content.frequencyOccurrence}
						value={gameSettings.value.frequencyOccurrence}
						label="Frequency"
						precision={2}
						min={0}
						step={0.05}
						max={1}
						size="sm"
						placeholder="Frequency"
						onChange={(value) =>
							handleChange("frequencyOccurrence", Number(value))
						}
					/>
					<NumberInput
						defaultValue={content.maxAttempts}
						key={content.maxAttempts}
						label="Max Attempts"
						size="sm"
						placeholder="Max Attempts"
						value={gameSettings.value.maxAttempts}
						min={1}
						onChange={(value) => handleChange("maxAttempts", Number(value))}
					/>
				</Group>
			</Group>

			<Group spacing={"lg"}>
				<Group position="right" spacing={"xs"}>
					<ActionIcon
						variant="subtle"
						color="red"
						onClick={handleRemove}
						loading={removeEventLoading}
					>
						<Icon icon={"fluent:dismiss-12-filled"} height={20} />
					</ActionIcon>
				</Group>
			</Group>
		</Root>
	);
}

// Styling
const Root = styled(Group)`
	flex-direction: row;

	background-color: #ffffff;
	padding: 8px 16px;
	user-select: none;

	&:not(:last-child) {
		border-bottom: 1px solid #e9ecef;
	}

	& {
		transition-property: background-color;
		transition-duration: 250ms;
		transition-timing-function: ease-in-out;
	}
`;
