import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export type TargetMerchant = {
    merchant_id: number;
    preferences: number[];
    age_groups: number[];
}

interface IPlatformTargetStore {
    targets: TargetMerchant[];

    sync: (ids: number[]) => void;

    set: (target: TargetMerchant) => void;
    remove: (id: number) => void;
}

const InitialState: Pick<IPlatformTargetStore, 'targets'> = {
    targets: localStorage.getItem('PLATFORM_TARGET_STORE') ? JSON.parse(localStorage.getItem('PLATFORM_TARGET_STORE')!) : [],
};

export const usePlatformTargetStore = create<IPlatformTargetStore>()(
    devtools(
        (set, get) => ({
            ...InitialState,

            set: (target) => {
                const targets = get().targets;

                if (!targets.some((item) => item.merchant_id === target.merchant_id)) {
                    targets.push(target);
                }

                const updatedTargets = targets.map((item) => {
                    if (item.merchant_id !== target.merchant_id) return item;
                    return target;
                });

                localStorage.setItem('PLATFORM_TARGET_STORE', JSON.stringify(updatedTargets));
                set({ targets: updatedTargets });
            },

            remove: (id) => {
                const targets = get().targets;

                const updated_targets = targets.filter((item) => item.merchant_id !== id);

                localStorage.setItem('PLATFORM_TARGET_STORE', JSON.stringify(updated_targets));
                set({ targets: updated_targets });
            },

            sync: (ids) => {
                const local_saved = localStorage.getItem('PLATFORM_TARGET_STORE');
                if (!local_saved) return

                const local_targets = JSON.parse(local_saved) as TargetMerchant[];

                // if local storage do not have merchant in store, remove it from local storage
                for (const target of local_targets) {
                    if (!ids.includes(target.merchant_id)) {
                        get().remove(target.merchant_id);
                    }
                }
            },
        }),
        { name: 'Platform Target' },
    ),
);
