
import { api } from 'index';
import { useQuery } from 'react-query';



export const useAllowedCities = () => {
    const { data, ...options } = useQuery(
      ["ALLOWED_CITIES_LIST"],
      () => api.allowedCities.readAll({}),
      {
        refetchOnWindowFocus: false,
        refetchIntervalInBackground: false,
        retry: 0,
      }
    );

    return {data, ...options}
}