import { Base } from "./Base";
import qs from "qs";

import {
  ApiResponse,
  ApiResponseList,
  ErrorResponse,
  OperationResponse,
  PaginationParams,
} from "./interfaces/api";
import { LocationEvent, LocationEventForm } from "./interfaces/locationEvents";
import { MerchantAnalytic } from "./interfaces/analytic";

export default class LocationEvents extends Base {
  async create(input: LocationEventForm, merchantId: number) {
    const response = await this.request.post<ApiResponse<LocationEvent>>(
      `${this.baseUrl}/admin/merchants/${merchantId}/location-events`,
      input
    );
    return response.data.data;
  }

  async readAll(
    merchantId: number,
    { skip = 0, limit = 1000 }: PaginationParams
  ) {
    const query = qs.stringify({ skip, limit });
    const response = await this.request.get<ApiResponseList<LocationEvent>>(
      `${this.baseUrl}/admin/merchants/${merchantId}/location-events?${query}`
    );
    return response.data.data;
  }

  async readOne(id?: LocationEvent["id"], merchantId?: number) {
    const response = await this.request.get<ApiResponse<LocationEvent>>(
      `${this.baseUrl}/admin/merchants/${merchantId}/location-events/${id}`
    );
    return response.data.data;
  }

  async update({ id, ...data }: LocationEventForm, merchantId: number) {
    const response = await this.request.patch<ApiResponse<LocationEvent>>(
      `${this.baseUrl}/admin/merchants/${merchantId}/location-events/${id}`,
      data
    );
    return response.data.data;
  }

  async remove(id?: LocationEvent["id"], merchantId?: number) {
    try {
      const response = await this.request.delete<OperationResponse>(
        `${this.baseUrl}/admin/merchants/${merchantId}/location-events/${id}`
      );
      return response.data.isSuccess;
    } catch (e: any) {
      const error: ErrorResponse = await e.response.data;
      return error;
    }
  }

  async getLocationEventAnalytics(id?: LocationEvent["id"], merchantId?: number) {
    const response = await this.request.get<ApiResponse<MerchantAnalytic>>(
      `${this.baseUrl}/admin/merchants/${merchantId}/location-events/${id}/analytics-iframe`
    );

    return response.data.data;
  }
}
