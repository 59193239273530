import styled from "@emotion/styled";
import { Group, Stack, Text, Title } from "@mantine/core";
import { User } from "api/interfaces/user";
import { FC } from "react";
import { Link } from "react-router-dom";

interface UserItemProps {
  user: User;
}

export const UserItem: FC<UserItemProps> = ({ user }) => {
  return (
    <Link to={`${user.id}`}>
      <Root grow position="apart">
        <Group position="left">
          <Stack spacing={1}>
            <Title order={5}>{user.email}</Title>
            <Text color="gray" fz={14}>
              {[user.firstName, user.lastName].join(" ")}
            </Text>
          </Stack>
        </Group>
      </Root>
    </Link>
  );
};

const Root = styled(Group)`
  flex-direction: row;
  overflow: auto;

  background-color: #ffffff;
  padding: 8px 16px;
  user-select: none;
  cursor: pointer;
  &:not(:last-child) {
    border-bottom: 1px solid #e9ecef;
  }

  & {
    transition-property: background-color;
    transition-duration: 250ms;
    transition-timing-function: ease-in-out;
  }

  &:hover {
    background-color: rgba(44, 62, 80, 0.05);
  }
`;
