import {useCallback, useEffect} from "react";
import styled from "@emotion/styled";
import {Icon} from "@iconify/react";
import {Link, useParams} from "react-router-dom";
import {FormProvider, useForm} from "react-hook-form";
import {Button, Group, Stack, Text} from "@mantine/core";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import ContentLoader from "components/ContentLoader";
import {useAuth} from "features/Auth/hooks/useAuth";
import {useAdmins} from "features/Admins/useAdmins";
import {api} from "index";
import {useMerchantNavigation} from "../../../hooks/useMerchantNavigation";
import {useAdvertisements} from "../../../features/AdvertisementsEdit/hooks/useAdvertisements";
import AdvertisementsEdit from "../../../features/AdvertisementsEdit";
import {useAdvertisementsCreate} from "../../../features/AdvertisementsEdit/hooks/useAdvertisementsCreate";
import {useAdvertisementsUpdate} from "../../../features/AdvertisementsEdit/hooks/useAdvertisementsUpdate";
import {useAdvertisementsDelete} from "../../../features/AdvertisementsEdit/hooks/useAdvertisementsDelete";
import {AdvertisementBtnTypes, Advertisement} from "../../../api/interfaces/advertisements";
import {LocationInput} from "../../../api/interfaces/location";

// Validation Schema
const schema = yup
    .object({
        title: yup
            .string()
            .max(32, `Name is too long. Maximum length is 32 chars.`)
            .lowercase()
            .trim()
            .required("Required"),
        subtitle: yup
            .string()
            .max(32, `Name is too long. Maximum length is 32 chars.`)
            .lowercase()
            .trim()
            .required("Required"),
        startDate: yup
            .date()
            .required("Required")
            .typeError("Required")
            .nullable()
            .default(undefined),
        endDate: yup
            .date()
            .min(yup.ref("startDate"), "End date can't be before start date")
            .required("Required")
            .typeError("Required")
            .nullable()
            .default(undefined),
        buttonName: yup
            .string()
            .max(32, `Name is too long. Maximum length is 32 chars.`)
            .lowercase()
            .trim()
            .required("Required"),
    })
    .required();

// Form Values Types
interface FormState {
    type: "update" | "create";
    title: string;
    subtitle: string;
    image?: File;
    buttonName: string,
    buttonActionType: AdvertisementBtnTypes,
    buttonActionData: string,
    startDate: string,
    endDate: string,
    locationsIds: number[] | LocationInput[];
    url?: string;
}

// Page
export default function AdvertisementsUpdate() {
    const {user} = useAuth();
    const {id} = useParams();
    const {isLoading} = useAdmins();
    const {advertisements} = useAdvertisements(Number(id));
    const {handleCreate} = useAdvertisementsCreate();
    const {handleUpdate} = useAdvertisementsUpdate(Number(id));
    const {handleDelete} = useAdvertisementsDelete(Number(id));
    const {route} = useMerchantNavigation();
    const form = useForm<FormState>({
        resolver: yupResolver(schema),
        defaultValues: {
            type: id ? "update" : "create",
            title: "",
            subtitle: "",
            buttonName: "",
            buttonActionType: AdvertisementBtnTypes.LINK,
            buttonActionData: "",
            startDate: "",
            endDate: "",
            locationsIds: [],
        },
    });

    const onSubmit = useCallback(
        async (input: FormState) => {
            const data = {} as Advertisement;

            try {
                Object.assign(data, {
                    title: input.title,
                    subtitle: input.subtitle,
                    buttonName: input.buttonName,
                    buttonActionType: input.buttonActionType,
                    buttonActionData: input.buttonActionData.toString(),
                    startDate: input.startDate,
                    endDate: input.endDate,
                    locationsIds: input.locationsIds,
                });
                // IMAGE CONTENT
                if (input.image) {
                    const response = await api.files.uploadImage(input.image);
                    Object.assign(data, {
                        image: response.url,
                    });
                }
                if (!input.image) {
                    Object.assign(data, {
                        image: advertisements!.image,
                    });
                }

                if (!advertisements) {
                    await handleCreate(data);
                    console.log("CREATED ADVERTISEMENTS");
                }

                if (!!advertisements) {
                    await handleUpdate({...advertisements, ...data});
                }
            } catch (e: any) {
                console.log("error", e);
            } finally {
                form.setValue('image', undefined)
            }
        },
        [form, handleCreate, handleUpdate, advertisements]
    );

    // Update Form Data
    useEffect(() => {
        if (!advertisements) return;

        form.reset({
            type: "update",
            title: advertisements.title,
            subtitle: advertisements.subtitle,
            buttonName: advertisements.buttonName,
            buttonActionType: advertisements.buttonActionType,
            buttonActionData: advertisements.buttonActionData,
            startDate: advertisements.startDate,
            endDate: advertisements.endDate,
            locationsIds: advertisements.locationsIds.map((location) => location.id) || advertisements.locationsIds,
            url: advertisements.image,
        });
    }, [advertisements, form, id, user]);

    if ((id && !advertisements) || isLoading) return <ContentLoader/>;

    return (
        <FormProvider {...form}>
            <Root onSubmit={form.handleSubmit(onSubmit)}>
                <Stack spacing={0}>
                    <Group position="apart">
                        <Group position="left">
                            <BackLink to={`/platform/advertisements`}>
                                <span className={"icon"}>
                                  <Icon icon={"fluent:arrow-left-12-filled"}/>
                                </span>
                                <Text size="xl" weight={"bold"}>
                                    Back to Advertisements
                                </Text>
                            </BackLink>
                        </Group>
                        <Group position="right">
                            {!!advertisements && (
                                <Button
                                    leftIcon={
                                        <Icon icon={"fluent:delete-24-filled"} height={18}/>
                                    }
                                    onClick={handleDelete}
                                    radius={"sm"}
                                    color={"red"}
                                    variant={"filled"}
                                >
                                    Delete
                                </Button>
                            )}
                            {!advertisements && (
                                <Button
                                    leftIcon={
                                        <Icon icon={"fluent:delete-24-filled"} height={18}/>
                                    }
                                    component={Link}
                                    to={`${route}/advertisements`}
                                    radius={"sm"}
                                    color={"red"}
                                    variant={"filled"}
                                >
                                    Cancel
                                </Button>
                            )}

                            <Button
                                type="submit"
                                leftIcon={<Icon icon={"fluent:save-24-filled"} height={18}/>}
                                radius={"sm"}
                                color={"teal"}
                                variant={"filled"}
                                loading={form.formState.isSubmitting}
                            >
                                {advertisements ? "Update" : "Create"}
                            </Button>
                        </Group>
                    </Group>

                    <AdvertisementsEdit/>
                </Stack>
            </Root>
        </FormProvider>
    );
}

// Styling
const Root = styled.form`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const BackLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;

  color: #2c3e50;

  & {
    transition-property: color;
    transition-duration: 250ms;
    transition-timing-function: ease-in-out;
  }

  &:hover {
    color: #0083ce;
  }

  & .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    margin-right: 8px;
  }

  & .title {
    font-size: 20px;
    font-weight: bold;
  }
`;
