import { api } from "index";
import { useMutation, useQueryClient } from "react-query";
import { useCallback } from "react";
import { useModalStore } from "features/Modals/hooks/useModalStore";
import { showNotification } from "@mantine/notifications";
import { ErrorResponse } from "api/interfaces/api";

export const usePlatformAdminDelete = () => {
  const queryClient = useQueryClient();
  const { mutateAsync: deleteAdmin, ...params } = useMutation((id: number) =>
    api.admins.deletePlatformAdmin(id)
  );
  const { setupModal, closeModal, setModalStatus } = useModalStore();

  const handleDelete = useCallback(
    async (id: number) => {
      const onDelete = async () => {
        closeModal();
        try {
          await deleteAdmin(id);
          await Promise.all([
            queryClient.invalidateQueries(["PLATFORM_ADMINS"]),
            queryClient.invalidateQueries(["PLATFORM_ADMINS"]),
          ]);
          showNotification({
            autoClose: 3000,
            title: "Success!",
            message: "Admin successfully deleted!",
            color: "green",
          });
        } catch (e: any) {
          const error: ErrorResponse = e.response.data;
          showNotification({
            message: error.error ?? "Something went wrong.",
            title: "Error!",
            color: "red",
            autoClose: 5000,
          });
        }
      };

      setupModal({
        action: onDelete,
        type: "delete",
        content:
          "Please confirm your action. <br/>Selected Admin will be deleted!",
      });

      setModalStatus(true);
    },
    [deleteAdmin]
  );

  return { handleDelete, ...params };
};
