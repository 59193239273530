import styled from "@emotion/styled";
import { Icon } from "@iconify/react";
import { Group, Avatar, ActionIcon, Text } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { Merchant } from "api/interfaces/merchants";
import QueryKeys from "constants/query-keys";
import useMutationData from "hooks/useMutationData";
import { useParams } from "react-router-dom";

type SharedItemProps = {
    merchant: Merchant;
}

export default function SharedItem({ merchant }: SharedItemProps) {
    const { id } = useParams<{ id: string }>();

    const unshareMutation = useMutationData({
        url: () => `/admin/collectible-cards/${id}/merchants/${merchant.id}`,
        method: 'delete',
        queryKeys: {
            invalidate: [
                { queryKey: [QueryKeys.PLATFORM_COLLECTABLE_CARD, { cardId: Number(id) }] },
                { queryKey: [QueryKeys.MERCHANT_COLLECTABLE_CARDS] },
                { queryKey: [QueryKeys.MERCHANT_COLLECTABLE_CARD, { cardId: Number(id) }] },
            ],
        },
        onSuccess: async () => {
            showNotification({
                autoClose: 3000,
                title: 'Success!',
                message: 'Merchant access successfully removed!',
                color: 'green',
            });
        }
    })

    return (
        <ListItem position='apart' px={16} py={8}>
            <Group key={merchant.id} position='left' align={'center'} spacing={8}>
                {merchant.logo
                    ? <Avatar size="sm" radius={'xl'} src={merchant.logo} alt="it's me" />
                    : <Avatar size="sm" radius={'xl'} color='blue'>{merchant.name[0].toUpperCase()}</Avatar>
                }
                <Text>{merchant.name}</Text>
            </Group>
            <Group position='right'>
                <ActionIcon
                    variant={'subtle'}
                    onClick={() => unshareMutation.mutate({})}
                    loading={unshareMutation.isLoading}
                    sx={(theme) => ({
                        background: '#FCFDFE',
                        border: '1px solid #F1F3F5',
                        borderRadius: '6px',
                        color: theme.colors.red[5],
                        "& svg[data-action-icon-loader]": {
                            stroke: theme.colors.red[5],
                        }
                    })}
                >
                    <Icon icon={'fluent:delete-16-regular'} height={14} />
                </ActionIcon>
            </Group>
        </ListItem>
    )
}

const ListItem = styled(Group)`
    &:not(:last-child) {
        border-bottom: 1px solid #f8f9fa;
    }
`