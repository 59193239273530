import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { yupResolver } from "@hookform/resolvers/yup";
import { Icon } from "@iconify/react";
import {
  ActionIcon,
  Button,
  Group,
  Stack,
  Text,
  TextInput,
  createStyles,
} from "@mantine/core";
import { RegisterInviteInput } from "api/interfaces/auth";
import { FC } from "react";
import { Controller, useForm, useFormState } from "react-hook-form";
import * as yup from "yup";

const schema = yup.object({
  email: yup.string().required().email(),
});

interface InviteAdminModalProps {
  handleInvite: (
    data: Omit<RegisterInviteInput, "companyName">
  ) => Promise<void>;
  close: () => void;
  isLoading: boolean;
}

export const InviteAdminModal: FC<InviteAdminModalProps> = ({
  handleInvite,
  close,
  isLoading,
}) => {
  const { classes } = useStyles();
  const { control, ...form } = useForm<{ email: string }>({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const handleSubmit = async (data: { email: string }) => {
    await handleInvite(data);
    close();
  };
  const { isValid: isFormValid } = useFormState({ control });
  return (
    <form onSubmit={form.handleSubmit(handleSubmit)}>
      <Stack className={classes.root}>
        <Group position={"apart"} align={"center"}>
          <Group position={"left"}>
            <Text size={"md"} weight={"bold"}>
              Invite Admin
            </Text>
          </Group>

          <Group position={"right"}>
            <ActionIcon
              disabled={isLoading}
              onClick={() => close()}
              variant={"transparent"}
            >
              <Icon color={"red"} icon="ep:close" />
            </ActionIcon>
          </Group>
        </Group>

        <Stack>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextField
                label="Email"
                placeholder="Email"
                variant="filled"
                required
                classNames={{ input: "input-field" }}
                {...field}
              />
            )}
          />

          <Group grow align={"center"}>
            <Button onClick={() => close()} color={"red"} loading={isLoading}>
              Close
            </Button>
            <Button type="submit" disabled={isLoading || !isFormValid}>
              Invite
            </Button>
          </Group>
        </Stack>
      </Stack>
    </form>
  );
};

const useStyles = createStyles(() => ({
  root: {
    position: "relative",
    width: 350,
  },
}));

const InputCSS = css`
  display: flex;
  font-size: 14px;
  font-weight: 400;
  background: #fcfdfe;
  color: #9fa2b4;
  line-height: 1.5;
  min-height: 42px;
  outline: none;
  padding: 10px 16px;
  width: 100%;
  transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out,
    color 0.2s ease-in-out;

  &:hover,
  &:focus {
    color: #252733;
    border-color: #0095e5;
  }

  &::placeholder {
    color: rgb(75, 80, 109, 0.5);
  }
`;

const FieldCSS = css`
  flex: 1;

  & .input-field {
    ${InputCSS};
  }
`;
// Styling

const TextField = styled<any>(TextInput)`
  ${FieldCSS};

  & .input-field {
    border: 1px solid ${({ error }) => (error ? "#f03e3e" : "#f0f1f7")};
  }
`;
