import styled from "@emotion/styled";
import { Icon } from "@iconify/react";
import { Button, Card, Group, Input, MultiSelect, Radio, Select, Stack, Text } from "@mantine/core";
import { useCallback, useMemo, useState } from "react";
import { Controller, useController, useFormContext } from "react-hook-form";
import { Content } from "../../../api/interfaces/content";
import useContentList from "../../ContentList/hooks/useContentList";
import ContentItem from "../../LocationEventEdit/components/ContentItem";
import { useAllDaysOfWeek } from "../hooks/useAllDaysOfWeek";
import { useReceiveRewardTypes } from "../hooks/useReceiveRewardTypes";
import CollectableSelector from "./CollectableSelector";

export default function ScavengerHuntForm() {
	const form = useFormContext();

	const { allDaysOfWeek } = useAllDaysOfWeek();
	const { receiveRewardTypes } = useReceiveRewardTypes();
	const eventType = form.watch("eventTypeId");
	const { field } = useController({name: 'contents', control: form.control})
	const { contents } = useContentList();

	const [selectId, setSelectId] = useState<Content['id'] | undefined | null>(undefined)
	const contentList: Content[] = useMemo(() => {
		return (field.value || []).map((id: Content['id']) => {
			const content = contents.find((item => item.id === id))
			if (!content) return undefined;
			return content
		}).filter((item: Content | undefined) => !!item)
	}, [contents, field.value])

	const contentsData = useMemo(() => {
		return contents.filter(i => !field.value.includes(i.id)).map((content) => ({
			value: String(content.id),
			label: content.name
		}))
	}, [contents, field])

	const onAdd = useCallback(() => {
		if (!selectId) return
		field.onChange([Number(selectId), ...field.value])
		setSelectId(undefined)
	}, [field, selectId])

	const onRemove = useCallback((inputId: Content["id"]) => {
		if (!field.value.includes(inputId)) return
		const values = field.value.filter((id: Content['id']) => inputId !== id)
		field.onChange(values)
	}, [field])

	if (eventType !== "2") return null;

	return (
		<Card radius={8} withBorder style={{ overflow: "visible" }}>
			<Card.Section inheritPadding withBorder py={16}>
				<Group position="apart">
					<Text size="lg" weight={700}>
						Multiple Locations
					</Text>
					<Group></Group>
				</Group>
			</Card.Section>

			<Card.Section inheritPadding py={16}>
				<Stack>
					<Group align="flex-start" spacing={16} noWrap>
						<Stack spacing={16} w={"100%"}>
							<Controller
								name="receiveRewardTimeTypeId"
								control={form.control}
								render={({ field }) => (
									<Radio.Group
										label="Method of Receiving a Reward"
										defaultValue="1"
										withAsterisk
										{...field}
									>
										<Stack spacing={8}>
											{receiveRewardTypes?.items?.map((el) => (
												<Radio key={el.id} value={String(el.id)} label={el.name} />
											))}
										</Stack>
									</Radio.Group>
								)}
							/>
						</Stack>
						<Stack spacing={16} w={"100%"}>
							<Controller
								name="daysOfWeekId"
								control={form.control}
								defaultValue={[]}
								render={({ field }) => (
									<Stack align="flex-start" spacing={16}>
										<Stack spacing={8} w={"100%"}>
											<Stack spacing={0}>
												<Input.Label>Dates with rewards</Input.Label>
												<Input.Description>Click on added date to change it</Input.Description>
											</Stack>
											<MultiSelectField {...field} data={
												allDaysOfWeek?.items?.map((el) => ({
													value: String(el.id),
													label: el.name,
												})) ?? []
											}
											/>
										</Stack>
									</Stack>
								)}
							/>
						</Stack>
					</Group>
				</Stack>
			</Card.Section>
			<Group grow align='flex-start'>
				<Stack spacing={0} >
					<Text size="md" weight="bold">
						Rewards
					</Text>
					<Group mb={8} position="apart">
						<SelectField
							placeholder="Pick one"
							searchable
							nothingFound="No options"
							data={contentsData}
							classNames={{ input: "input-field" }}
							onChange={(id) => setSelectId(Number(id))}
							value={String(selectId)}
							sx={{ width: "100%", maxWidth: 300 }}
						/>
						<Button
							leftIcon={<Icon icon={"fluent:add-12-filled"} height={18} />}
							radius={"sm"}
							color={"dark"}
							variant={"filled"}
							onClick={onAdd}
							disabled={!selectId}
							sx={{ maxWidth: 300 }}
						>
							Add Reward
						</Button>
					</Group>
					{/* Content */}
				<Stack spacing={0}>
					{!contentList.length && (
						<Group grow p={"lg"}>
							<Text size="sm" color={"dimmed"}>
								Not found...
							</Text>
						</Group>
					)}
					{contentList.map((content) => (
						<ContentItem
							key={content.id}
							content={content}
							onRemove={() => onRemove(content.id)}
						/>
					))}
				</Stack>
				</Stack>
				<CollectableSelector />
			</Group>
		</Card>
	);
}
const SelectField = styled(Select)`
  & .input-field {
    border: 1px solid ${({error}) => (error ? "#f03e3e" : "#f0f1f7")};
  };
`;
const MultiSelectField = styled(MultiSelect)`
	& .input-field {
		border: 1px solid ${({ error }) => (error ? "#f03e3e" : "#f0f1f7")};
	}
`;
