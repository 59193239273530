import { Button, Card, Group, Stack, Text, TextInput } from "@mantine/core";
import Tabs from "components/Tabs";
import { useFormContext } from "react-hook-form";

const tabs = [
    { label: "Sharing", value: "sharing" }
]

export default function PlatformGameDetails() {
    const form = useFormContext()

    return (
        <Card radius={8} shadow="0px 1px 4px rgba(0, 0, 0, 0.05)">
            <Card.Section inheritPadding py={16} sx={{ borderBottom: "1px solid #f8f9fa" }}>
                <Group position="apart">
                    <Text size={16} weight={600} lh={1.5}>
                        Overview
                    </Text>
                    <Group>
                        <Button loading={form.formState.isSubmitting} disabled={!form.formState.isDirty || !form.formState.isValid} type="submit" variant="filled" h={28} px={12} py={4} color="dark.8" radius={4}>
                            <Text size={"14px"} weight={500} tt={'uppercase'} lh={1.5}>UPDATE</Text>
                        </Button>
                    </Group>
                </Group>
            </Card.Section>
            <Card.Section inheritPadding p={24}>
                <Stack spacing={16}>
                    <TextInput
                        {...form.register('name')}
                        label="Name"
                        placeholder="Enter game name"
                        required
                        styles={{
                            label: {
                                marginBottom: 12,
                            },
                            input: {
                                background: '#FCFDFE',
                                border: '1px solid #F1F3F5',
                                borderRadius: '4px',
                                "::placeholder": {
                                    color: '#9FA2B4'
                                }
                            }
                        }}
                    />
                </Stack>
            </Card.Section>
            <Card.Section inheritPadding px={12} sx={{ borderTop: "1px solid #f8f9fa" }}>
                <Tabs queryKey="tab" defaultValue={tabs[0].value} tabs={tabs} />
            </Card.Section>
        </Card>
    )
}