import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Location } from "api/interfaces/location";

// Types
export type LocationCoords = {
	lat: Location['latitude'];
	lng: Location['longitude'];
	radius?: Location['radiusMeters'];
	bbox?: number[];
}

export type LocationMark = {
	id?: Location['id'];
	coords: LocationCoords;
	title?: string;
	city?: string;
	address?: string;
}

export type PathToMap = {
	path?: boolean;	
}

export interface LocationState {
	searchText?: string;
	searchCoords?: LocationCoords;
  	default: LocationCoords;
	current?: LocationMark;
	marks: LocationMark[];
	tempLocationData?: LocationMark;
	setLocationFlag?: boolean;
	mapPath?: boolean;
}

// Initial State
const initialState: LocationState = {
  default: { lat: 48.38302, lng: 31.18287 },
	marks: [],
	mapPath: false,
	setLocationFlag: false,
};

// Auth Store
export default createSlice({
  name: "locations",
  initialState,
  reducers: {
    setCurrentLocation: (state, action: PayloadAction<LocationMark>) => ({
			...state,
			current: action.payload
		}),
		setLocationMarks: (state, action: PayloadAction<LocationMark[]>) => ({
			...state,
			marks: action.payload
		}),
		setDefaultLocation: (state, action: PayloadAction<LocationCoords>) => ({
			...state,
			default: action.payload
		}),
		setSearchLocation: (state, action: PayloadAction<LocationState['searchText']>) => ({
			...state,
			searchText: action.payload
		}),
		setCoordsLocation: (state, action: PayloadAction<LocationCoords>) => ({
			...state,
			searchCoords: action.payload
		}),
		setTempLocationData: (state, action: PayloadAction<LocationMark>) => ({
			...state,
			tempLocationData: action.payload
		}),
		setLocationPossibility: (state, action: PayloadAction<boolean>) => ({
			...state,
			setLocationFlag: action.payload
		}),
		setMapPath: (state, action: PayloadAction<boolean>) => ({
			...state,
			mapPath: action.payload
		}),
  },
});
