import { api } from "index";
import { useMutation, useQueryClient } from "react-query";
import { useCallback } from "react";
import { Event } from "api/interfaces/events";
import { LocationEventForm } from "../../../api/interfaces/locationEvents";
import useMerchantSelectorStore from "features/MerchantSelector/hooks/useMerchantSelectorStore";
import { ErrorResponse } from "api/interfaces/api";
import { showNotification } from "@mantine/notifications";

export const useLocationEventUpdate = (id?: Event["id"]) => {
  const queryClient = useQueryClient();
  const merchantSelector = useMerchantSelectorStore();
  const selectedId = merchantSelector.useStore((state) => state.selected);

  // Event Update Handler
  const { mutateAsync: update, ...options } = useMutation(
    (values: LocationEventForm) => api.locationEvents.update(values, Number(selectedId))
  );

  // Handler for forms
  const handleUpdate = useCallback(
    async (input: LocationEventForm) => {
      if (!id) return;
      try {
        await update({ ...input, id });
        await Promise.all([
          queryClient.invalidateQueries(["LOCATION_EVENTS_LIST", selectedId]),
          queryClient.invalidateQueries(["LOCATION_EVENT", id]),
          queryClient.invalidateQueries(["LOCATION_EVENT", selectedId, id]),
        ]);
        showNotification({
          autoClose: 3000,
          title: "Success!",
          message: "Location event successfully updated!",
          color: "green",
        });
      }
      catch(e: any){
        const error: ErrorResponse = e.response.data;
        showNotification({
          message: error.error ?? "Something went wrong.",
          title: "Error!",
          color: "red",
          autoClose: 5000,
        });
      }
    },
    [id, update]
  );

  return {
    handleUpdate,
    ...options,
  };
};
