import { useCallback } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { api } from "index";
import { Event } from "api/interfaces/events";
import { useModalStore } from "../../Modals/hooks/useModalStore";
import {useMerchantNavigation} from "../../../hooks/useMerchantNavigation";
import { showNotification } from "@mantine/notifications";

export const useLocationEventDelete = (id?: Event["id"]) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate()
  const { id: merchantId, route } = useMerchantNavigation()
  const { setupModal, setModalStatus, closeModal } = useModalStore();

  // Remove Event
  const { mutateAsync, ...options } = useMutation(() => api.locationEvents.remove(id, Number(merchantId)));

  const handleDelete = useCallback(async () => {
    if (!id) return;
    const onDelete = async () => {
      closeModal();
      const res = await mutateAsync();
      if (typeof res === "object") {
        showNotification({
          message: res.error ?? "Something went wrong.",
          title: "Error!",
          color: "red",
          autoClose: 5000,
        });
      } else {
        closeModal();
        showNotification({
          autoClose: 3000,
          title: "Success!",
          message: "Location event successfully deleted!",
          color: "green",
        });
        navigate(`${route}/location-events`);

        await Promise.all([
          queryClient.invalidateQueries(["LOCATION_EVENTS_LIST", merchantId]),
          queryClient.invalidateQueries(["LOCATION_EVENT", id]),
        ]);

      }
    };

    setupModal({
      action: onDelete,
      type: "delete",
      content:
        "Please confirm your action. <br/>Selected Location Event will be deleted!",
    });

    setModalStatus(true);
  }, [mutateAsync, navigate, id]);

  return {
    handleDelete,
    ...options,
  };
};
