import styled from "@emotion/styled";

// Component
export default function TextField({ label, description, errorText, children }) {
  return (
    <Field>
      {label && <Label>{label}</Label>}
      {children}
      {description && <LabelText>{description}</LabelText>}
      <ErrorText>{errorText}</ErrorText>
    </Field>
  );
}

// Styling
const Field = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
  position: relative;
`;

const Label = styled.label`
  margin-bottom: 6px;
  font-size: 12px;
  text-transform: uppercase;
  line-height: 15px;
  transition: color 0.2s ease-in-out;
  font-weight: 700;
  color: #0a001e;
`;

const LabelText = styled.label`
  display: flex;
  margin-top: 6px;
  text-align: center;
  line-height: 18px;
  font-size: 14px;
  transition: color 0.2s ease-in-out;
  font-weight: 400;

  & > a {
    color: #666892;
  }

  & > a:hover {
    cursor: pointer;
    text-decoration: underline;
    color: #0095e5;
  }
`;

const ErrorText = styled.span`
  position: absolute;
  margin-top: 6px;
  display: flex;
  top: 100%;
  align-items: flex-start;
  margin-left: 4px;

  font-size: 13px;
  line-height: 1;
  letter-spacing: 0.3px;
  color: #ff0049;

  flex-direction: row;
  flex-wrap: wrap;
`;
