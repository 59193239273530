import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from 'yup';
import { Button, Group, MultiSelect, Select, Stack, Text } from "@mantine/core";
import { useModalStore } from "features/Modal/store/modal.store";
import usePaginatedData from "hooks/usePaginatedData";
import useSelectData from "hooks/useSelectData";
import { AgeGroups, Preferences } from "mock/target-group";
import { listify } from "radash";
import { useMemo, useState } from "react";
import { usePlatformTargetStore, type TargetMerchant } from "store/platform-target.store";
import type { Merchant } from "api/interfaces/merchants";


// Component
export function EditForm() {
    const [loading, setLoading] = useState(false);

    const payload = useModalStore(state => state.payload as TargetMerchant)
    const close = useModalStore(state => state.setClose)

    const targets = usePlatformTargetStore(state => state.targets)
    const setTarget = usePlatformTargetStore(state => state.set)

    const form = useForm<TargetMerchant>({
        mode: 'onChange',
        resolver: yupResolver(yup.object({
            merchant_id: yup.number().required('Merchant is required'),
            preferences: yup.array().of(yup.number()).required('Preferences is required'),
            age_groups: yup.array().of(yup.number()).required('Age groups is required'),
        })),
        defaultValues: {
            merchant_id: payload?.merchant_id || undefined,
            age_groups: payload?.age_groups || [],
            preferences: payload?.preferences || [],
        }
    });

    const { items: merchants } = usePaginatedData<Merchant>({
        path: `/admin/merchants`,
        defaultLimit: 1000,
        queryKey: ["EXTENDED_MERCHANT_LIST"],
    });

    const availableContents = useMemo(() => {
        if (payload) return merchants.filter(c => c.id === payload.merchant_id)
        return merchants.filter(c => !targets.find(sc => sc.merchant_id === c.id))
    }, [merchants, targets, payload])

    const contentSelectOptions = useSelectData(availableContents, {
        labelKey: 'name',
    })

    const preferences = listify(Preferences, (key, value) => ({ id: key, name: value }))
    const preferencesSelectOptions = useSelectData(preferences, {
        labelKey: 'name',
    })

    const ageGroups = listify(AgeGroups, (key, value) => ({ id: key, name: value }))
    const ageGroupSelectOptions = useSelectData(ageGroups, {
        labelKey: 'name',
    })

    const onSubmit = form.handleSubmit(async (values) => {
        setLoading(true)
        const timeout = setTimeout(() => {
            setTarget(values)
            setLoading(false)
            close()
            clearTimeout(timeout)
        }, 500);
    });

    return (
        <form onSubmit={onSubmit}>
            <Stack px={24} pb={24} spacing={0}>
                <Stack spacing={24}>
                    <Select
                        value={String(form.watch('merchant_id'))}
                        onChange={(value) => form.setValue('merchant_id', Number(value))}
                        data={contentSelectOptions.items}
                        nothingFound={'All merchants are added, please remove some to add new'}
                        placeholder={'Select merchant'}
                        variant={'filled'}
                        readOnly={!!payload}
                        withinPortal
                        label={"Merchant"}
                        styles={inputStyles}
                    />

                    <MultiSelect
                        value={form.watch('preferences').map((item: any) => String(item))}
                        onChange={(value) => form.setValue('preferences', value.map(v => Number(v)))}
                        data={preferencesSelectOptions.items}
                        placeholder={'Select preferences'}
                        variant={'filled'}
                        withinPortal
                        label={"Preferences"}
                        styles={inputStyles}
                    />

                    <MultiSelect
                        value={form.watch('age_groups').map((item: any) => String(item))}
                        onChange={(value) => form.setValue('age_groups', value.map(v => Number(v)))}
                        data={ageGroupSelectOptions.items}
                        placeholder={'Select age groups'}
                        variant={'filled'}
                        withinPortal
                        label={"Age Groups"}
                        styles={inputStyles}
                    />

                </Stack>
                <Group grow align={'center'} mt={24}>
                    <Button type='submit' loading={loading} variant="filled" h={36} px={12} py={4} color="teal.7" radius={4} >
                        <Text size={"14px"} weight={500} tt={'uppercase'} lh={1.5}>SUBMIT</Text>
                    </Button>
                </Group>
            </Stack>
        </form>
    );
};

const inputStyles = {
    values: {
        marginLeft: -5,
    },
    value: {
        backgroundColor: '#F1F3F5',
        border: '1px solid #F1F3F5',
        borderRadius: '3px',
        color: '#868E96',
        fontSize: '12px',
        fontWeight: 400,
    },
    defaultValueRemove: {
        color: '#868E96',
        width: 18,
        height: 18,
        minHeight: 18,
        minWidth: 18,
    },
    label: {
        marginBottom: 12,
    },
    input: {
        background: '#FCFDFE',
        border: '1px solid #F1F3F5',
        borderRadius: '4px',
        paddingTop: 3,
        paddingBottom: 3,
        "::placeholder": {
            color: '#9FA2B4'
        }
    }
}