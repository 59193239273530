import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import ContentLoader from "components/ContentLoader";
import useCouponsList from "features/CouponsList/hooks/useCouponsList";
import CouponsList from "features/CouponsList";
import { Button, Group, Stack, Text } from "@mantine/core";
import {useMerchantNavigation} from "../../../hooks/useMerchantNavigation";

// Page
export default function Coupons() {
  const { coupons, isLoading } = useCouponsList();
  const { route } = useMerchantNavigation()
  
  return (
    <Stack p={"lg"} spacing={0}>
      <Group position="apart">
        <Group position="left">
          <Text size="xl" weight="bold">
            Coupons
          </Text>
        </Group>
        <Group position="right">
          <Button
            component={Link}
            to={`${route}/coupons/create`}
            leftIcon={<Icon icon={"fluent:add-12-filled"} height={18} />}
            radius={"sm"}
            color={"blue"}
            variant={"filled"}
          >
            Create
          </Button>
        </Group>
      </Group>

      {!isLoading ? (
        <CouponsList coupons={coupons as any} />
      ) : (
        <ContentLoader />
      )}
    </Stack>
  );
}
