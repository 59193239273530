import {Button, Group, Paper, Stack, Text} from "@mantine/core";
import {Icon} from "@iconify/react";

import {useSubscription} from "../../Subscriptions/hooks/useSubscription";

export const PlanCard = () => {
	const {
		isSubscribeExpired,
		expireTime,
		handleBuySubscription,
		isSubscriptionCancelled,
	} = useSubscription();
	
	return (
			<Group position="center">
				<Paper
						p={0}
						radius={20}
						withBorder
						style={{width: 320, overflow: "hidden"}}
				>
					<Stack
							p={40}
							style={{
								borderBottom: "1px solid #e9ecef",
								backgroundColor: "#00a0ff",
							}}
					>
						<Stack spacing={0}>
							<Text
									color="white"
									weight={"bold"}
									align="center"
									style={{fontFamily: "Poppins", fontSize: 28}}
							>
								Standard
							</Text>
							<Text
									color="#cce1ff"
									weight={"bold"}
									align="center"
									style={{fontFamily: "Poppins", fontSize: 18}}
							>
								$100/months
							</Text>
						</Stack>
					</Stack>
					<Stack p={40}>
						<Group align={"center"}>
							<Icon
									icon={"fluent:checkmark-12-filled"}
									color={"#00a0ff"}
									height={24}
							/>
							<Text color="dimmed" style={{fontFamily: "Poppins"}}>
								10 Projects
							</Text>
						</Group>
						<Group align={"center"}>
							<Icon
									icon={"fluent:checkmark-12-filled"}
									color={"#00a0ff"}
									height={24}
							/>
							<Text color="dimmed" style={{fontFamily: "Poppins"}}>
								10 Users
							</Text>
						</Group>
						<Group align={"center"}>
							<Icon
									icon={"fluent:checkmark-12-filled"}
									color={"#00a0ff"}
									height={24}
							/>
							<Text color="dimmed" style={{fontFamily: "Poppins"}}>
								20 Storage
							</Text>
						</Group>
						<Group align={"center"}>
							<Icon
									icon={"fluent:checkmark-12-filled"}
									color={"#00a0ff"}
									height={24}
							/>
							<Text color="dimmed" style={{fontFamily: "Poppins"}}>
								Tasks
							</Text>
						</Group>
						<Group align={"center"}>
							<Icon
									icon={"fluent:checkmark-12-filled"}
									color={"#00a0ff"}
									height={24}
							/>
							<Text color="dimmed" style={{fontFamily: "Poppins"}}>
								Goals
							</Text>
						</Group>
						<Group align={"center"}>
							<Icon
									icon={"fluent:checkmark-12-filled"}
									color={"#00a0ff"}
									height={24}
							/>
							<Text color="dimmed" style={{fontFamily: "Poppins"}}>
								Communication
							</Text>
						</Group>
						<Group align={"center"}>
							<Icon
									icon={"fluent:checkmark-12-filled"}
									color={"#00a0ff"}
									height={24}
							/>
							<Text color="dimmed" style={{fontFamily: "Poppins"}}>
								Time-tracking
							</Text>
						</Group>
						<Group align={"center"}>
							<Icon
									icon={"fluent:checkmark-12-filled"}
									color={"#00a0ff"}
									height={24}
							/>
							<Text color="dimmed" style={{fontFamily: "Poppins"}}>
								Dashboards
							</Text>
						</Group>
					</Stack>
					<Stack style={{margin: 16}}>
						<Button
								onClick={handleBuySubscription}
								disabled={!isSubscriptionCancelled && !isSubscribeExpired}
						>
							Subscribe
						</Button>
					</Stack>
					
					{!isSubscriptionCancelled && !isSubscribeExpired && (
							<Group style={{margin: 16}} align={"center"}>
								<Group align={"center"}>
									<Icon
											icon={"fluent:checkmark-12-filled"}
											color={"green"}
											height={24}
									/>
								</Group>
								<Text color="dimmed" style={{fontFamily: "Poppins"}}>
									You are already subscribed
								</Text>
								<Group style={{width: "100%"}} align={"center"}>
									<Icon
											style={{opacity: 0}}
											icon={"fluent:checkmark-12-filled"}
											height={24}
									/>
									<Text
											align={"center"}
											color="dimmed"
											style={{fontFamily: "Poppins"}}
									>
										Expires at: {expireTime}
									</Text>
								</Group>
							</Group>
					)}
				</Paper>
			</Group>
	);
};
