import { Box, Group, Stack, Text } from "@mantine/core";
import { FC, useEffect } from "react";

import styled from "@emotion/styled";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { useAppConfigConstants } from "features/AppAccess/hooks/useAppConfigConstants";
import { UpdateAppAccessConfigForm } from "features/AppAccess/components/UpdateAppAccessConfigForm";
import ContentLoader from "components/ContentLoader";
import { useAppConfigConstantsUpdate } from "features/AppAccess/hooks/useAppConfigConstantsUpdate";
import { InvitesList } from "../../../features/AppAccess/components/InvitesList";
import { AllowedCitiesList } from "features/AppAccess/components/AllowedCitiesList";
import { useAppInvites } from "../../../features/AppAccess/hooks/useAppInvites";
import { useAllowedCities } from "features/AppAccess/hooks/useAllowedCities";
import { showNotification } from "@mantine/notifications";

const schema = yup.object({
  byInvite: yup.boolean(),
  byAllowedCity: yup.boolean(),
});

interface FormState {
  byInvite: boolean;
  byAllowedCity: boolean;
}

// id = 7 - sign up by allowed city, id = 6 - sign up by invite
type ConfigConstantsId = 6 | 7;

const AppAccessControl: FC = () => {
  const { data: constants, isLoading: isConstantsLoading } =
    useAppConfigConstants();
  const { handleUpdate } = useAppConfigConstantsUpdate();
  const { data: appInvites, isLoading: isInvitesLoading } = useAppInvites();
  const { data: allowedCities, isLoading: isCitiesLoading } =
    useAllowedCities();

  const getConfigValue = (id: ConfigConstantsId) => {
    return constants?.items.filter((val) => val.id === id)[0];
  };

  const form = useForm<FormState>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (!constants) return;
    form.setValue("byInvite", getConfigValue(6)!.booleanValue);
    form.setValue("byAllowedCity", getConfigValue(7)!.booleanValue);
  }, [constants]);

  useEffect(() => {
    if (appInvites?.items?.length === 0 && constants && getConfigValue(6)!.booleanValue) {
      const inviteConfig = getConfigValue(6)!;
      handleUpdate({...inviteConfig, booleanValue: false});
    }
    if (allowedCities?.items?.length === 0 && constants && getConfigValue(7)!.booleanValue) {
      const allowedCityConfig = getConfigValue(7)!;
      handleUpdate({...allowedCityConfig, booleanValue: false});
    }
  }, [appInvites, allowedCities])

  const onConfigSubmit = async (values: FormState) => {
    const inviteConfig = getConfigValue(6)!;
    const allowedCityConfig = getConfigValue(7)!;
    if (inviteConfig!.booleanValue !== values.byInvite) {
      if (values.byInvite && appInvites && appInvites.items.length === 0) {
        showNotification({
          autoClose: 3000,
          title: "Error!",
          message: "Add at least one item to the invite list!",
          color: "red",
        });
      } else {
        inviteConfig!.booleanValue = values.byInvite;
        await handleUpdate(inviteConfig);
      }
    }
    if (allowedCityConfig!.booleanValue !== values.byAllowedCity) {
      if (values.byAllowedCity && allowedCities && allowedCities.items.length === 0) {
        showNotification({
          autoClose: 3000,
          title: "Error!",
          message: "Add at least one element to the list of allowed cities!",
          color: "red",
        });
      } else {
        allowedCityConfig!.booleanValue = values.byAllowedCity;
        await handleUpdate(allowedCityConfig);
      }
    }
  };

  return (
    <Box p={20}>
      <Text size="xl" weight="bold">
        App access
      </Text>

      {!isConstantsLoading && !isInvitesLoading && !isCitiesLoading ? (
        <Stack spacing={"lg"}>
          <FormProvider {...form}>
            <ConfigForm onSubmit={form.handleSubmit(onConfigSubmit)}>
              <UpdateAppAccessConfigForm />
            </ConfigForm>
          </FormProvider>
          <Group position="apart" align={"flex-start"} grow>
            <Stack>
              <InvitesList invites={appInvites!.items} />
            </Stack>
            <Stack>
              <AllowedCitiesList cities={allowedCities!.items} />
            </Stack>
          </Group>
        </Stack>
      ) : (
        <ContentLoader />
      )}
    </Box>
  );
};

const ConfigForm = styled.form`
  display: flex;
  flex-direction: column;
`;

export default AppAccessControl;
