import {Base} from "./Base";
import {
    ApiResponse,
    ApiResponseList,
    ErrorResponse,
    OperationResponse,
} from "./interfaces/api";
import {Advertisement} from "./interfaces/advertisements";

export default class Advertisements extends Base {
    async create(input: Advertisement) {
        const response = await this.request.post<ApiResponse<Advertisement>>(
            `${this.baseUrl}/admin/advertisements`,
            input
        );
        return response.data.data;
    }

    async readAll() {
        const response = await this.request.get<ApiResponseList<Advertisement>>(
            `${this.baseUrl}/admin/advertisements`
        );
        return response.data.data;
    }

    async readOne(id?: Advertisement["id"]) {
        const response = await this.request.get<ApiResponse<Advertisement>>(
            `${this.baseUrl}/admin/advertisements/${id}`
        );
        return response.data.data;
    }

    async update({id, ...data}: Advertisement) {
        const response = await this.request.patch<ApiResponse<Advertisement>>(
            `${this.baseUrl}/admin/advertisements/${id}`,
            data
        );
        return response.data.data;
    }

    async remove(id: Advertisement["id"]) {
        try {
            const response = await this.request.delete<OperationResponse>(
                `${this.baseUrl}/admin/advertisements/${id}`
            );
            return response.data.isSuccess;
        } catch (e: any) {
            const error: ErrorResponse = await e.response.data;

            return error;
        }
    }
}
