import { Icon } from "@iconify/react";
import { openSpotlight } from "@mantine/spotlight";
import useLocationList from "features/LocationList/hooks/useLocationList";
import ContentLoader from "components/ContentLoader";
import LocationsMap from "features/LocationsMap";
import FinderProvider from "features/LocationsMap/component/FinderProvider";
import { useToggle } from "@mantine/hooks";
import { Button, Group, SegmentedControl, Stack, Text } from "@mantine/core";
import LocationList from "features/LocationList";
import { Link } from "react-router-dom";
import { useMerchantNavigation } from 'hooks/useMerchantNavigation';
import { useLocationStore } from 'features/LocationsMap/hooks/useLocationStore';
import { useEffect } from 'react';

// Page
export default function Locations() {
  const { locations, isLoading } = useLocationList();
  const [viewMode, changeViewMode] = useToggle( ["list", "map"]);
  const { route } = useMerchantNavigation()
  const { setMapPath, setLocationPossibility, state: { mapPath } } = useLocationStore();

  useEffect(() =>{
    mapPath && changeViewMode('map');
    return () => {
      setMapPath(false);
      setLocationPossibility(false);
    }
  }, [])
  
  return (
    <FinderProvider onTrigger={() => changeViewMode("map")}>
      <Stack p="lg" spacing={0} style={{ flex: 1 }}>
        <Group position="apart">
          <Group position="left">
            <Text size="xl" weight="bold">
              Locations
            </Text>
          </Group>
          <Group position="right" spacing={'lg'}>
            <Group>
              <SegmentedControl
                sx={{ label: { paddingBlock: 4 } }}
                radius={"md"}
                value={viewMode}
                onChange={(value) => changeViewMode(value)}
                data={[
                  { label: "List View", value: "list" },
                  { label: "Map View", value: "map" },
                ]}
              />
            </Group>
            <Group>
              {viewMode == "map" && (
                <Button
                  onClick={() => openSpotlight()}
                  leftIcon={<Icon icon={"fluent:search-12-filled"} />}
                  radius={'sm'}
                  color={'blue'}
                  variant={'filled'}
                >
                  Search Location
                </Button>
              )}
              <Button
                component={Link}
                to={`${route}/locations/create`}
                leftIcon={<Icon icon={"fluent:add-12-filled"} height={18} />}
                radius={"sm"}
                color={"blue"}
                variant={"filled"}
              >
                Create
            </Button>
            </Group>
          </Group>
        </Group>

        {isLoading && <ContentLoader />}

        {!isLoading && viewMode === "map" && (
          <LocationsMap locations={locations} />
        )}

        {!isLoading && viewMode === "list" && (
          <LocationList locations={locations} />
        )}
      </Stack>
    </FinderProvider>
  );
}
