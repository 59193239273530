import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "store";
import locationSlice, { LocationCoords, LocationMark, LocationState } from "../store";

export const useLocationStore = () => {
  const state = useSelector((state: AppState) => state.location);
  const dispatch = useDispatch();

  const setDefaultLocation = useCallback((location: LocationCoords) => {
    dispatch(locationSlice.actions.setDefaultLocation(location));
  }, [dispatch]);

  const setCurrentLocation = useCallback((location: LocationMark) => {
    dispatch(locationSlice.actions.setCurrentLocation(location));
  }, [dispatch]);

	const setMarks = useCallback((marks: LocationMark[]) => {
    dispatch(locationSlice.actions.setLocationMarks(marks));
  }, [dispatch]);

  const setSearchLocation = useCallback((text: LocationState['searchText']) => {
    dispatch(locationSlice.actions.setSearchLocation(text));
  }, [dispatch]);

  const setCoordsLocation = useCallback((coords: LocationCoords) => {
    dispatch(locationSlice.actions.setCoordsLocation(coords));
  }, [dispatch]);

  const setTempLocationData = useCallback((tempLocationData: LocationMark) => {
    dispatch(locationSlice.actions.setTempLocationData(tempLocationData));
  }, [dispatch]);

  const setLocationPossibility = useCallback((setLocationFlag: boolean) => {
    dispatch(locationSlice.actions.setLocationPossibility(setLocationFlag));
  }, [dispatch]);

  const setMapPath = useCallback((mapPath: boolean) => {
    dispatch(locationSlice.actions.setMapPath(mapPath));
  }, [dispatch]);

  return {
    state,
    setMarks,
		setDefaultLocation,
		setCurrentLocation,
    setSearchLocation,
    setCoordsLocation,
    setTempLocationData,
    setLocationPossibility,
    setMapPath
  };
};
