import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Icon } from "@iconify/react";
import {
    Button,
    Center,
    Group,
    Image,
    Input,
    NumberInput,
    Select,
    Stack,
    Text,
    Textarea,
    TextInput,
} from "@mantine/core";
import { TimeInput } from "@mantine/dates";
import { Dropzone, MIME_TYPES } from "@mantine/dropzone";
import { showNotification } from "@mantine/notifications";
import { ErrorResponse } from "api/interfaces/api";
import { useAuth } from "features/Auth/hooks/useAuth";
import { useModalStore } from "features/Modals/hooks/useModalStore";
import useSelectData from "hooks/useSelectData";
import { useServerConstants } from "hooks/useServerConstants";
import { Preferences } from "mock/target-group";
import { listify } from "radash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useController, useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useExtendedMerchantStore } from "store/extended-merchant.store";
import { DayOfWeekWithTime } from "../../api/interfaces/events";
import PhoneInput from "../../components/PhoneInput";
import { UserRoles } from "../../config";
import { useAllDaysOfWeek } from "../EventEdit/hooks/useAllDaysOfWeek";
import WorkDay from "./components/workDay";
import { useMerchantBalance } from "./hooks/useMerchantBalance";
import { useMerchantWorkingDays } from "./store/merchant-working-days.store";

// Component
export default function MerchantProfileEdit() {
    const { control, setError } = useFormContext();
    const { merchantId } = useParams();
    const { Country } = useServerConstants();
    const { setModalStatus, setupModal } = useModalStore();
    const { isPlatformAdmin } = useAuth();
    const { handleRefill } = useMerchantBalance();
    const { user, isMerchantSubscribed } = useAuth();
    const { allDaysOfWeek } = useAllDaysOfWeek();
    const { field: workDays } = useController({ name: 'workDays', control: control })
    const { field: workDaysTimes } = useController({ name: 'workDaysTimes', control: control })
    const [selectId, setSelectId] = useState<DayOfWeekWithTime['id'] | undefined | null>(undefined)
    const [startTime, setStartTime] = useState<string>("");
    const [endTime, setEndTime] = useState<string>("");
    const {setDate, merchantDates, addNewDate, removeDate } = useMerchantWorkingDays(state => state)

    const workDaysData = useMemo(() => {
        const ids = merchantDates.map((data) => data.dayId);
        if (allDaysOfWeek) {
            return allDaysOfWeek?.items.filter(i => !ids.includes(i.id)).map((day) => ({
                value: String(day.id),
                label: day.name
            }))
        } else {
            return []
        }
    }, [allDaysOfWeek, workDays])

    // const selectedDays: DayOfWeekWithTime[] = useMemo(() => {
    //     return (workDays.value || []).map((id: DayOfWeekWithTime['id'], index: number) => {
    //         const day = allDaysOfWeek?.items.find((item => item.id === id))
    //         const times = workDaysTimes.value[index];
    //         if (!day) return undefined;
    //         if (!times) return day;
    //         return { ...day, ...times }
    //     }).filter((item: DayOfWeekWithTime | undefined) => !!item)
    // }, [allDaysOfWeek, workDays.value, workDaysTimes])

    // const selectedDays = getDate(merchantId);

    useEffect(() => {
        setDate(merchantId);
    }, [merchantId]);

    const onAdd = useCallback(() => {
        if (!selectId) return
        if (!startTime) {
            showNotification({
                message: "The start of the working day is not specified.",
                title: "Error!",
                color: "red",
                autoClose: 5000,
            });
            return;
        } else if (!endTime) {
            showNotification({
                message: "The end of the working day is not specified",
                title: "Error!",
                color: "red",
                autoClose: 5000,
            });
            return;
        } else if (startTime > endTime) {
            showNotification({
                message: "The start of the working day cannot be later than the end of the working day",
                title: "Error!",
                color: "red",
                autoClose: 5000,
            });
            return;
        } else {
            workDays.onChange([Number(selectId), ...workDays.value])
            workDaysTimes.onChange([{ startTime: startTime, endTime: endTime }, ...workDaysTimes.value]);
            addNewDate(selectId, startTime, endTime);
            setSelectId(undefined);
            setStartTime("");
            setEndTime("")
        }
    }, [workDays, selectId, workDaysTimes])

    const onRemove = useCallback((inputId: DayOfWeekWithTime["id"]) => {
        const ids = merchantDates.map((data) => data.dayId);
        if (!ids.includes(inputId)) return
        const values = workDays.value.filter((id: DayOfWeekWithTime['id']) => inputId !== id)
        const index = workDays.value.findIndex((id: number) => id === inputId)
        if (index > -1) {
            const currentArray = workDaysTimes.value
            currentArray.splice(index, 1);
            workDaysTimes.onChange(currentArray);
        }
        workDays.onChange(values)
        removeDate(inputId);
    }, [workDays])

    const onRefillClick = async () => {
        if (user?.Role.id !== UserRoles.SUPER_ADMIN) {
            if (!user?.Merchant?.isVerification) {
                setupModal({
                    type: "verification",
                    content: "Please wait until the administrator confirms your account",
                });
                setModalStatus(true);
                console.debug('kyc')
                return;
            }
            if (!isMerchantSubscribed) {
                setupModal({
                    type: 'error',
                    content: 'You must buy a subscription before you top up your balance'
                })
                setModalStatus(true);
                return
            }
        }
        try {
            await handleRefill();
        } catch (e: any) {
            const error: ErrorResponse = e.response.data;
            setupModal({
                type: "error",
                content: error.error ?? "Something went wrong.",
            });
            setModalStatus(true);
        }
    };

    const extendedMerchant = useExtendedMerchantStore(state => state.merchants.find(item => item.id === Number(merchantId)));
    const setExtendedMerchant = useExtendedMerchantStore(state => state.rewrite);

    const preferences = listify(Preferences, (key, value) => ({ id: key, name: value }))
    const preferencesSelectOptions = useSelectData(preferences, {
        labelKey: 'name',
    })

    return (
        <Root>
            <Group grow position={"apart"}>
                <Stack>
                    <Controller
                        name="name"
                        control={control}
                        render={({ field, fieldState }) => (
                            <TextField
                                label="Name"
                                placeholder="Enter company name"
                                variant="filled"
                                required={!isPlatformAdmin}
                                error={fieldState.error?.message}
                                classNames={{ input: "input-field" }}
                                maxLength={50}
                                {...field}
                            />
                        )}
                    />
                    <Controller
                        name="email"
                        control={control}
                        render={({ field, fieldState }) => (
                            <TextField
                                label="Email"
                                placeholder="Enter company email address"
                                variant="filled"
                                error={fieldState.error?.message}
                                classNames={{ input: "input-field" }}
                                {...field}
                            />
                        )}
                    />
                    <Controller
                        name="phone"
                        control={control}
                        render={({ field, fieldState }) =>
                            <PhoneInput {...field} error={fieldState.error?.message} />
                        }
                    />
                    <Controller
                        name="website"
                        control={control}
                        render={({ field, fieldState }) => (
                            <TextField
                                label="Website"
                                placeholder="Enter website address"
                                variant="filled"
                                // required
                                error={fieldState.error?.message}
                                classNames={{ input: "input-field" }}
                                maxLength={50}
                                {...field}
                            />
                        )}
                    />

                    <Controller
                        name="address"
                        control={control}
                        render={({ field, fieldState }) => (
                            <TextField
                                label="Address"
                                placeholder="Enter company address"
                                variant="filled"
                                // required
                                error={fieldState.error?.message}
                                classNames={{ input: "input-field" }}
                                maxLength={100}
                                {...field}
                            />
                        )}
                    />

                    <Select
                        label="Preference"
                        placeholder="Select asociated preference"
                        data={preferencesSelectOptions.items}
                        value={extendedMerchant?.preference ? String(extendedMerchant?.preference) : undefined}
                        onChange={(value) => value && setExtendedMerchant({ id: Number(merchantId), preference: Number(value) as keyof typeof Preferences })}
                        styles={inputStyles}
                    />

                </Stack>
                <Stack h={"100%"}>
                    <Controller
                        name="logo"
                        control={control}
                        render={({ field, fieldState, formState }) => (
                            <Input.Wrapper
                                label="Logo"
                                style={{ flex: 1 }}
                                error={fieldState.error?.message}
                                pos={"relative"}
                                styles={{ error: { position: "absolute", bottom: 0 } }}
                            >
                                <Stack align={"center"} justify={"center"} h={"100%"}>
                                    <Dropzone
                                        p={0}
                                        loading={formState.isSubmitting}
                                        maxSize={1024 ** 2}
                                        accept={[MIME_TYPES.png, MIME_TYPES.jpeg]}
                                        styles={{
                                            root: {
                                                width: 256,
                                                height: 256,
                                                borderRadius: "50%",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                display: "flex",
                                            },
                                        }}
                                        multiple={false}
                                        onDrop={(files) => field.onChange(files[0])}
                                        onReject={(e) => {
                                            if (!e || !Array.isArray(e)) return;

                                            e.forEach(({ errors }) =>
                                                errors.forEach((err) => {
                                                    if (err.code !== "file-too-large") return;
                                                    setError("logo", {
                                                        message: "File larger than 1 mb",
                                                    });
                                                })
                                            );
                                        }}
                                    >
                                        <Center
                                            style={{
                                                pointerEvents: "none",
                                                padding: Boolean(field.value) ? 5 : 0,
                                            }}
                                        >
                                            {Boolean(field.value) &&
                                                typeof field.value === "object" && (
                                                    <Image
                                                        fit={"cover"}
                                                        styles={ImageStyles}
                                                        radius={"sm"}
                                                        src={URL.createObjectURL(field.value)}
                                                        alt="preview picture"
                                                    />
                                                )}

                                            {!Boolean(field.value) && (
                                                <Stack spacing={0}>
                                                    <Icon
                                                        width={72}
                                                        color={"#00000070"}
                                                        icon="fa-solid:file-upload"
                                                    />
                                                </Stack>
                                            )}

                                            {Boolean(field.value) &&
                                                typeof field.value === "string" && (
                                                    <Stack spacing={0}>
                                                        <Image
                                                            fit={"cover"}
                                                            styles={ImageStyles}
                                                            radius={"sm"}
                                                            src={field.value}
                                                            alt="preview picture"
                                                        />
                                                    </Stack>
                                                )}
                                        </Center>
                                    </Dropzone>
                                </Stack>
                            </Input.Wrapper>
                        )}
                    />
                </Stack>
            </Group>

            <Group grow>
                <Group>
                    <Controller
                        name="countryId"
                        control={control}
                        render={({ field, fieldState }) => (
                            <SelectField
                                label="Country"
                                placeholder="Pick country"
                                variant="filled"
                                searchable
                                required={!isPlatformAdmin}
                                data={Country.map((item) => ({
                                    value: String(item.id),
                                    label: item.name,
                                }))}
                                defaultValue={String(field.value)}
                                error={fieldState.error?.message}
                                classNames={{ input: "input-field" }}
                                {...field}
                            />
                        )}
                    />
                </Group>

                {user?.Role.id !== 4 &&
                    (<Group align={"flex-end"}>
                        <Controller
                            name="balance"
                            control={control}
                            render={({ field }) => (
                                <NumberInput
                                    label="Balance (Qubi coins)"
                                    placeholder="0"
                                    variant="filled"
                                    required={!isPlatformAdmin}
                                    hideControls
                                    disabled
                                    classNames={{ input: "input-field" }}
                                    {...field}
                                />
                            )}
                        />
                        <Button onClick={() => onRefillClick()}>Refill balance</Button>
                    </Group>)
                }
            </Group>
            <Group grow>
                <Stack>
                    <Stack spacing={0} pb={"lg"} style={{ borderBottom: "1px solid #e9ecef" }}>
                        <Stack>
                            <Group>
                                <SelectField
                                    placeholder="Pick one"
                                    searchable
                                    nothingFound="No options"
                                    label={"Work Days"}
                                    data={workDaysData}
                                    classNames={{ input: "input-field" }}
                                    onChange={(id) => setSelectId(Number(id))}
                                    value={String(selectId)}
                                    sx={{ width: "100%", maxWidth: 300 }}
                                />
                                {selectId ?
                                    <Group>
                                        <TimeInput
                                            label="Start Time"
                                            value={startTime}
                                            onChange={(e) => setStartTime(e.target.value)}
                                        />
                                        <TimeInput
                                            label="End Time"
                                            value={endTime}
                                            onChange={(e) => setEndTime(e.target.value)}
                                        />
                                    </Group>
                                    : <></>
                                }
                            </Group>
                            <Button
                                leftIcon={<Icon icon={"fluent:add-12-filled"} height={18} />}
                                radius={"sm"}
                                color={"dark"}
                                variant={"filled"}
                                onClick={onAdd}
                                disabled={!selectId}
                                sx={{ width: "100%", maxWidth: 300 }}
                            >
                                Add Work Day
                            </Button>
                        </Stack>
                    </Stack>

                    {/* Content */}
                    <Stack spacing={0}>
                        {!merchantDates.length && (
                            <Group grow p={"lg"}>
                                <Text size="sm" color={"dimmed"}>
                                    Not found...
                                </Text>
                            </Group>
                        )}
                        {merchantDates.length > 0 && merchantDates?.map((day) => (
                            <WorkDay
                                key={day.dayId}
                                day={allDaysOfWeek?.items[Number(day.dayId) - 1]?.name as string}
                                startTime={day.startTime}
                                endTime={day.endTime}
                                onRemove={() => onRemove(day.dayId)}
                            />
                        ))}
                    </Stack>
                </Stack>
            </Group>
            <Group grow>
                <Stack>
                    <Controller
                        name="description"
                        control={control}
                        render={({ field, fieldState }) => (
                            <Textarea
                                label="Description"
                                placeholder="Enter company description"
                                variant="filled"
                                required={!isPlatformAdmin}
                                error={fieldState.error?.message}
                                classNames={{ input: "input-field" }}
                                styles={{ input: { minHeight: 200 } }}
                                {...field}
                            />
                        )}
                    />
                </Stack>
            </Group>
        </Root>
    );
}

// CSS Helper
const InputCSS = css`
  display: flex;
  font-size: 14px;
  font-weight: 400;
  background: #fcfdfe;
  border: 1px solid #f0f1f7;
  color: #9fa2b4;
  line-height: 1.5;
  min-height: 42px;
  outline: none;
  padding: 10px 16px;
  width: 100%;
  transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out,
    color 0.2s ease-in-out;

  &:hover,
  &:focus {
    color: #252733;
    border-color: #0095e5;
  }

  &::placeholder {
    color: rgb(75, 80, 109, 0.5);
  }
`;

// Styling
const Root = styled(Stack)`
  margin-top: 20px;
  background: #ffffff;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  padding: 20px;
  overflow-y: hidden;
  gap: 20px;
`;

const TextField = styled(TextInput)`
  flex: 1;

  & .input-field {
    ${InputCSS};
  }
`;
const SelectField = styled(Select)`
  flex: 1;

  & .input-field {
    ${InputCSS};
    cursor: pointer;
  }
`;

const ImageStyles = {
    image: {
        width: "246px !important",
        height: "246px !important",
        borderRadius: "50%",
    },
};


const inputStyles = {
    values: {
        marginLeft: -5,
    },
    value: {
        backgroundColor: '#F1F3F5',
        border: '1px solid #F1F3F5',
        borderRadius: '3px',
        color: '#868E96',
        fontSize: '12px',
        fontWeight: 400,
    },
    defaultValueRemove: {
        color: '#868E96',
        width: 18,
        height: 18,
        minHeight: 18,
        minWidth: 18,
    },
    label: {
        marginBottom: 12,
    },
    input: {
        background: '#FCFDFE',
        border: '1px solid #F1F3F5',
        borderRadius: '4px',
        paddingTop: 3,
        paddingBottom: 3,
        "::placeholder": {
            color: '#9FA2B4'
        }
    }
}
