import { Base } from "./Base";
import { ApiResponse } from "./interfaces/api";
import { MerchantAnalytic } from "./interfaces/analytic";

export default class Analytics extends Base {
  async merchant(id?: string | number) {
    if (!id) return;

    const response = await this.request.get<ApiResponse<MerchantAnalytic>>(
      `${this.baseUrl}/admin/merchants/${id}/analytics-iframe`
    );
    return response.data.data;
  }

  async user(id?: string | number) {
    if (!id) return;

    const response = await this.request.get<ApiResponse<MerchantAnalytic>>(
      `${this.baseUrl}/admin/users/${id}/analytics-iframe`
    );
    return response.data.data;
  }

  async platform() {
    const response = await this.request.get<ApiResponse<MerchantAnalytic>>(
      `${this.baseUrl}/admin/analytics-iframe`
    );
    return response.data.data;
  }
}
