
import {Controller, useForm} from "react-hook-form";
import {useEffect} from "react";
import {Button, Checkbox, Group, Stack} from "@mantine/core";
import {useUserDetails} from "../hooks/useUserDetails";
import {useUserUpdate} from "../hooks/useUserUpdate";
import {User} from "../../../api/interfaces/user";

export default function UserManageForm() {
	const {user} = useUserDetails();
	const {handleUpdate} = useUserUpdate();
	
	const form = useForm<User>({
		defaultValues: {
			isEmailVerified: user?.isEmailVerified || false,
			isPhoneVerified: user?.isPhoneVerified || false,
			isSuspend: user?.isSuspend || false,
			isVerification: user?.isVerification || false
		},
	});
	
	const onSubmit = form.handleSubmit(async (input: any) => {
		await handleUpdate(input);
	});
	
	useEffect(() => {
		if (!user) return;
		form.reset(user);
	}, [user]);
	
	return (
			<Stack spacing={40}>
				
				<Group>
					<Controller control={form.control} name="isEmailVerified" render={({field, fieldState}) => (
							<Checkbox
									checked={field.value}
									onChange={(e) => field.onChange(e.target.checked)}
									error={fieldState.error?.message}
									label="Email Verification"
							/>
					)}/>
					<Controller control={form.control} name="isPhoneVerified" render={({field, fieldState}) => (
							<Checkbox
									checked={field.value}
									onChange={(e) => field.onChange(e.target.checked)}
									error={fieldState.error?.message}
									label="Phone Verification"
							/>
					)}/>
					<Controller control={form.control} name="isVerification" render={({field, fieldState}) => (
						<Checkbox
								checked={field.value}
								onChange={(e) => field.onChange(e.target.checked)}
								error={fieldState.error?.message}
								label="KYC Verification"
						/>
					)}/>
					
					<Controller control={form.control} name="isSuspend" render={({field, fieldState}) => (
							<Checkbox
									checked={field.value}
									onChange={(e) => field.onChange(e.target.checked)}
									error={fieldState.error?.message}
									label="Suspend"
							/>
					)}/>
				</Group>
				
				<Group>
					{form.formState.isDirty && (
						<Button radius={"sm"} color={"lime"} variant={"filled"}
					        onClick={onSubmit}
					        disabled={form.formState.isSubmitting}
					        loading={form.formState.isSubmitting}
						>
							Save Changes
						</Button>
					)}
				</Group>
			
			</Stack>
	)
}
