import { useCallback } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { api } from "index";
import { useModalStore } from "../../Modals/hooks/useModalStore";
import { showNotification } from "@mantine/notifications";
import {Advertisement} from "../../../api/interfaces/advertisements";

export const useAdvertisementsDelete = (id: Advertisement["id"]) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { setupModal, setModalStatus, closeModal } = useModalStore();

  // Remove Event
  const { mutateAsync, ...options } = useMutation(() =>
    api.advertisements.remove(id)
  );

  const handleDelete = useCallback(async () => {
    if (!id) return;
    const onDelete = async () => {
      closeModal();
      const res = await mutateAsync();
      if (typeof res === "object") {
        showNotification({
          message: (res as any).message ?? res.error ?? "Something went wrong.",
          title: "Error!",
          color: "red",
          autoClose: 5000,
        });
      } else {
        closeModal();
        showNotification({
          autoClose: 3000,
          title: "Success!",
          message: "Advertisements successfully deleted!",
          color: "green",
        });
        navigate(`/platform/advertisements`);

        await Promise.all([
          queryClient.invalidateQueries(["ADVERTISEMENTS_LIST"]),
          queryClient.invalidateQueries(["ADVERTISEMENTS"]),
        ]);
      }
    };
    setupModal({
      action: onDelete,
      type: "delete",
      content:
        "Please confirm your action. <br/>Selected Advertisements will be deleted!",
    });
    setModalStatus(true);
  }, [mutateAsync, navigate, id, setupModal, setModalStatus]);

  return {
    handleDelete,
    ...options,
  };
};
