import { useCallback } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { api } from "index";
import { Location } from "api/interfaces/location";
import { useModalStore } from "../../Modals/hooks/useModalStore";
import { useMerchantNavigation } from "../../../hooks/useMerchantNavigation";
import { showNotification } from "@mantine/notifications";

export const useLocationDelete = (id?: Location["id"]) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { closeModal, setupModal, setModalStatus } = useModalStore();
  const { id: merchantId, route } = useMerchantNavigation();

  // Remove Location
  const { mutateAsync, ...options } = useMutation(() =>
    api.locations.remove(id, Number(merchantId))
  );

  const handleDelete = useCallback(async () => {
    if (!id) return;
    const onDelete = async () => {
      closeModal();
      const res = await mutateAsync();
      if (typeof res === "object") {
        showNotification({
          message: res.error ?? "Something went wrong.",
          title: "Error!",
          color: "red",
          autoClose: 5000,
        });
      } else {
        closeModal();
        showNotification({
          autoClose: 3000,
          title: "Success!",
          message: "Location successfully deleted!",
          color: "green",
        });
        navigate(`${route}/locations`);

        await Promise.all([
          queryClient.invalidateQueries(["LOCATION_EVENTS_LIST", merchantId]),
          queryClient.invalidateQueries(["LOCATION_LIST", merchantId]),
          queryClient.invalidateQueries(["LOCATION"]),
        ]);
      }
    };

    setupModal({
      action: onDelete,
      type: "delete",
      content:
        "Please confirm your action. <br/>Selected Location will be deleted!",
    });

    setModalStatus(true);
  }, [mutateAsync, navigate, id]);

  return {
    handleDelete,
    ...options,
  };
};
