import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { yupResolver } from "@hookform/resolvers/yup";
import { Icon } from "@iconify/react";
import {
  ActionIcon,
  Button,
  Group,
  Stack,
  Text,
  TextInput,
  createStyles,
} from "@mantine/core";
import { AppInvite } from "api/interfaces/appInvite";
import { FC } from "react";
import { Controller, useForm, useFormState } from "react-hook-form";
import * as yup from "yup";

interface CreateInviteModalProps {
  closeModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleCreate: (
    code: Omit<AppInvite, "id" | "createdAt" | "updatedAt">
  ) => Promise<void>;
  isLoading: boolean;
}

type FormState = Omit<AppInvite, "id" | "createdAt" | "updatedAt">;

const schema = yup.object({
  code: yup.string().required(),
});

export const CreateInviteModal: FC<CreateInviteModalProps> = ({
  closeModal,
  handleCreate,
  isLoading,
}) => {
  const { classes } = useStyles();
  const { control, ...form } = useForm<FormState>({
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  const { isValid: isFormValid } = useFormState({ control });
  const handleSubmit = async (data: FormState) => {
   
    try {
      await handleCreate(data);
      closeModal(false);
    } catch (e: any) {
      console.log(e);
      closeModal(false);
    }
  };

  return (
    <form onSubmit={form.handleSubmit(handleSubmit)}>
      <Stack className={classes.root}>
        <Group position={"apart"} align={"center"}>
          <Group position={"left"}>
            <Text size={"md"} weight={"bold"}>
              Create Invite
            </Text>
          </Group>

          <Group position={"right"}>
            <ActionIcon
              disabled={isLoading}
              onClick={() => closeModal(false)}
              variant={"transparent"}
            >
              <Icon color={"red"} icon="ep:close" />
            </ActionIcon>
          </Group>
        </Group>

        <Stack>
          <Controller
            name="code"
            control={control}
            render={({ field }) => (
              <TextField
                label="Code"
                placeholder="Code"
                variant="filled"
                required
                classNames={{ input: "input-field" }}
                {...field}
              />
            )}
          />

          <Group grow align={"center"}>
            <Button
              onClick={() => closeModal(false)}
              color={"red"}
              loading={isLoading}
            >
              Close
            </Button>
            <Button type="submit" disabled={isLoading || !isFormValid}>
              Create
            </Button>
          </Group>
        </Stack>
      </Stack>
    </form>
  );
};

const useStyles = createStyles(() => ({
  root: {
    position: "relative",
    width: 350,
  },
}));

const InputCSS = css`
  display: flex;
  font-size: 14px;
  font-weight: 400;
  background: #fcfdfe;
  color: #9fa2b4;
  line-height: 1.5;
  min-height: 42px;
  outline: none;
  padding: 10px 16px;
  width: 100%;
  transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out,
    color 0.2s ease-in-out;

  &:hover,
  &:focus {
    color: #252733;
    border-color: #0095e5;
  }

  &::placeholder {
    color: rgb(75, 80, 109, 0.5);
  }
`;

const FieldCSS = css`
  flex: 1;

  & .input-field {
    ${InputCSS};
  }
`;
// Styling

const TextField = styled<any>(TextInput)`
  ${FieldCSS};

  & .input-field {
    border: 1px solid ${({ error }) => (error ? "#f03e3e" : "#f0f1f7")};
  }
`;
