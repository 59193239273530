import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Group, Select, Stack, Text } from "@mantine/core";
import { useModalStore } from "features/Modal/store/modal.store";
import { useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import * as yup from 'yup';
import { TWorkingDay, useEventWorkingDates } from "../store/event-days.store";
import { TimeInput } from "@mantine/dates";

const Days = [
    { label: 'Monday', value: 'monday' },
    { label: 'Tuesday', value: 'tuesday' },
    { label: 'Wednesday', value: 'wednesday' },
    { label: 'Thursday', value: 'thursday' },
    { label: 'Friday', value: 'friday' },
    { label: 'Saturday', value: 'saturday' },
    { label: 'Sunday', value: 'sunday' },
] as const;


export default function AddWorkingDate() {
    const { id } = useParams<{ id: string }>();
    const [loading, setLoading] = useState(false);

    const close = useModalStore(state => state.setClose);

    const workingDates = useEventWorkingDates(state => state.workingDates.find(({ event_id }) => event_id === Number(id))!);
    const addWorkingDay = useEventWorkingDates(state => state.addWorkingDay);
    
    const availableDays = useMemo(() => {
        return Days.filter(day => !workingDates.working_days.some(item => item.day === day.value))
    }, [workingDates.working_days]);

    // Form Implementation
    const form = useForm<TWorkingDay>({
        mode: 'onChange',
        resolver: yupResolver(yup.object({
            day: yup.string().required(),
            start: yup.string().required(),
            end: yup.string().required(),
        })),
        defaultValues: {
            day: availableDays.length > 0 ? availableDays[0].value : 'monday',
            start: '00:00 AM',
            end: '12:00 PM',
        }
    });

    const onSubmit = form.handleSubmit((values) => {
        setLoading(true);
        const timeout = setTimeout(() => {
            addWorkingDay(Number(id), values);
            close();
            setLoading(false);
            form.reset();
            clearTimeout(timeout);
        }, 1000);
    });

    return (
        <Stack px={24} pb={24} spacing={0}>
            <Stack spacing={24}>
                <Controller
                    name='day'
                    control={form.control}
                    render={({ field }) => (
                        <Select
                            {...form.register('day')}
                            label="Day"
                            data={availableDays} placeholder={'Select day'}
                            nothingFound={'All days are selected, please remove some of them to add new one'}
                            variant={'filled'}
                            searchable
                            value={String(field.value)}
                            required
                            onChange={value => field.onChange(value)}
                            withinPortal
                            clearButtonProps={{
                                'aria-label': 'Clear input',
                            }}
                            styles={inputStyles}
                        />
                    )}
                />

                <Group spacing={24} grow>
                    <TimeInput
                        {...form.register('start')}
                        label="Start time"
                        placeholder="Select start time"
                        required
                        w={"100%"}
                        styles={inputStyles}
                    />

                    <TimeInput
                        {...form.register('end')}
                        label="End time"
                        placeholder="Select end time"
                        required
                        w={"100%"}
                        styles={inputStyles}
                    />
                </Group>
            </Stack>
            <Group grow align={'center'} mt={24}>
                <Button loading={loading} variant="filled" h={36} px={12} py={4} color="teal.7" radius={4} onClick={onSubmit}>
                    <Text size={"14px"} weight={500} tt={'uppercase'} lh={1.5}>ADD DATE</Text>
                </Button>
            </Group>
        </Stack>
    )
}

const inputStyles = {
    values: {
        marginLeft: -5,
    },
    value: {
        backgroundColor: '#F1F3F5',
        border: '1px solid #F1F3F5',
        borderRadius: '3px',
        color: '#868E96',
        fontSize: '12px',
        fontWeight: 400,
    },
    defaultValueRemove: {
        color: '#868E96',
        width: 18,
        height: 18,
        minHeight: 18,
        minWidth: 18,
    },
    label: {
        marginBottom: 12,
    },
    input: {
        background: '#FCFDFE',
        border: '1px solid #F1F3F5',
        borderRadius: '4px',
        paddingTop: 3,
        paddingBottom: 3,
        "::placeholder": {
            color: '#9FA2B4'
        }
    }
}